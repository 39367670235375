import React from "react";
import { Container, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

import { FiChevronDown } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import OutsideClickHandler from "react-outside-click-handler";
import RouteLeavingGuard from "../../../Common/Components/RouteLeavingGuard";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { IForecastRequestDto } from "../../../Common/Contracts/ILaborForecast";
import { LaborForecast } from "../../../Common/Services/LaborForecast";
import { HidSelector } from "../../../Common/Components/HidSelector";
import { resolve, reject } from "q";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import { Utils } from "../../../Common/Utilis";

let routeLeavingGuard: any = null;
let hotelDetailsData: any;
let ifIdData: any[] = [];
let calenderMinDate=new Date();
let calenderMaxDate: any = new Date();
export class UnApprove extends React.Component<any, any> {
  private childHID: any;
  private closeModal: any;

  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.closeModal = null;

    this.state = {
      loaderTrue: true,
      hidValue: "Select",
      currentDate: "",
      calenderDates: [],
      calenderSelectDate: {},
      unApproveData: [],
      headerDates: [],
      ifIdforSave: [],
      headerDays: [],
      changeValue: false,
      calenderShow: false,
      isRecordFound: false,
      isPayrollExported:"",
      exportFormat : "",
      isPayrollIntegration : ""
    };
  }

  componentDidMount() {
    let currentdate = "";
    let currentMonth = "";
    let currentYear = "";
    let todayDate: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
      todayDate = new Date(
        JSON.parse(localStorage.storage).currentUtcTime
      );
    }
    calenderMinDate=  JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
      JSON.parse(localStorage.storage).currentUtcTime
    ):new Date();
    currentdate = todayDate.getDate();
    currentMonth = todayDate.getMonth() + 1;
    currentYear = todayDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    this.setState({ currentDate: tempDate, calenderSelectDate: todayDate });
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
  }

  // getPayPeriod = () => {
  //   let request = {} as any;
  //   request.hotelID = this.state.hidValue;
  //   let todayDate: any = new Date();
  //  let currentdate = todayDate.getDate()-10;
  //  let currentMonth = todayDate.getMonth() + 1;
  //  let currentYear = todayDate.getFullYear();
  //  let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
  //  request.date =tempDate;
  //  LaborForecast.GetLaborUnapproveDetails(request)
  //     .then(async (result: any) => {
  //       if (result.message==="Success") {
  //         calenderMinDate=result.result[0].payPeriodDate.split('_')[0];
  //       }
  //       resolve();
  //     })
  //     .catch((error: any) => {
  //       reject();
  //       this.setState({ loaderTrue: false });
  //     });
  // };

  getPayPeriod() {
    debugger
    let todayDate = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
      todayDate = new Date(
        JSON.parse(localStorage.storage).currentUtcTime
      );
    }
    todayDate.setDate(todayDate.getDate() - 10);
    let currentdate = todayDate.getDate();
    let currentMonth = todayDate.getMonth() + 1;
    let currentYear = todayDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    laborPerformance
      .getPayPeriodDates(this.state.hidValue, tempDate, "Weekly")
      .then(async (result: any[] | null) => {
        if (result != null) {
          calenderMinDate = result[0].startdate;
          this.setState({
            // isPayrollExported:result[0].exporteddate,
            exportFormat : result[0].exportFormat,
            isPayrollIntegration : result[0].isPayrollIntegration

          });
        }
      });
  }

  getPayPeriod_new() {
    // let todayDate = new Date();
    // if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
    //   todayDate = new Date(
    //     JSON.parse(localStorage.storage).currentUtcTime
    //   );
    // }
    // todayDate.setDate(todayDate.getDate() - 10);
    // let currentdate = todayDate.getDate();
    // let currentMonth = todayDate.getMonth() + 1;
    // let currentYear = todayDate.getFullYear();
    // let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
     var e=this.state.calenderSelectDate;
    let calenderYear = e.getFullYear();
    let calenderDate = e.getDate();
    let calenderMonth = e.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

    laborPerformance
      .getPayPeriodDates(this.state.hidValue, tempDate, "Weekly")
      .then(async (result: any[] | null) => {
        if (result != null) {
        
          this.setState({
            isPayrollExported:result[0].exporteddate,
            exportFormat : result[0].exportFormat,
            isPayrollIntegration : result[0].isPayrollIntegration
          });
        }
      });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.changeValue &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  selectHotelDropDown = (hotelDetails: any) => {
    if (this.state.changeValue) {
      this.setState({ isHandleForecast: "selectHotelDropDown" });
      hotelDetailsData = hotelDetails;
      this.isValidateModelPoupTab();
    } else {
      setTimeout(() => {
        if (!this.state.scheduleError) {
          let todayDate: any = new Date();
          if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
            todayDate = new Date(
              JSON.parse(localStorage.storage).currentUtcTime
            );
          }
          this.setState({ calenderSelectDate: todayDate });
        }
        hotelDetailsData = null;

        this.setState(
          {
            hidValue: hotelDetails.hotelID,
            unApproveData: [],
            isRecordFound: false,
          },
          () => {
            let request = {} as any;
            request.hotelID = this.state.hidValue;
            request.date = this.state.currentDate;

            this.getApproveData(request, "ehidDropdown");
          }
        );
      }, 0);
    }
  };

  getApproveData(request: any, type: string) {
    if (type === "ehidDropdown" || type === "calenderChange") {
      this.setState({ loaderTrue: true });
    }
    this.getPayPeriod();
    this.getPayPeriod_new();
    LaborForecast.GetLaborUnapproveDetails(request)
      .then(async (result: any) => {
        let tempForecastData: any = [];
        let calnderDatesDispaly: any = [];
        let headerDates: any = [];
        let headerDays: any = [];
        let approveFound = false;
        if (result.message === "Success") {
         
          if (result.result.length > 1) {
            for (let i = 1; i < result.result.length; i++) {
              approveFound = false;
              let tempRowObject: any = {};
              tempRowObject.id = i;
              tempRowObject.itemName = result.result[i].deptName;
              tempRowObject.typeid = Number(result.result[i].deptID);

              for (let j = 1; j <= 7; j++) {
                let dayValue = `day${j}`;
                let day_Date = `day${j}_Date`;
                let ifID = `day${j}_IFId`;
                let isChecked = `day${j}_isChecked`;
                let day = result.result[i][dayValue].split("||");
                tempRowObject[day_Date] = day[0];
                if (
                  day[0].toLowerCase() === "approve" &&
                  approveFound === false
                ) {
                  approveFound = true;
                }
                tempRowObject[ifID] = day[3];
                tempRowObject[isChecked] = true;
                if (j > 0) {
                  headerDates.push(day[1].substring(0, day[1].length - 5));
                  headerDays.push(day[2]);
                }
              }
              if (approveFound) {
                tempForecastData.push(tempRowObject);
              }

              let displayDates: any = result.result[i].payPeriodDate.split("_");
              calnderDatesDispaly = displayDates;
            }
            if (tempForecastData.length > 0) {
              const uniqueHeaderDates: any = new Set(headerDates);
              const arrayHeaderDates = [...uniqueHeaderDates];
              const uniqueHeaderDays: any = new Set(headerDays);
              const arrayHeaderDays = [...uniqueHeaderDays];
              this.setState({
                headerDates: arrayHeaderDates,
                headerDays: arrayHeaderDays,
                calenderDates: calnderDatesDispaly,
                unApproveData: tempForecastData,
                loaderTrue: false,
                isRecordFound: true,
                changeValue: false,
                templateName: "",
              });
            } else {
              Utils.toastError(
                "No departments have been approved for any days for selected week.",
                {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                  // // containerId: "Unapprove",
                }
              );
              this.setState({
                calenderDates: calnderDatesDispaly,
                loaderTrue: false,
                isRecordFound: false,
                changeValue: false,
              });
            }
          } else {
            Utils.toastError(
              "No departments have been approved for any days for selected week.",
              {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "Unapprove",
              }
            );
            let displayDates: any = result.result[0].payPeriodDate.split("_");
            calnderDatesDispaly = displayDates;
            this.setState({
              calenderDates: calnderDatesDispaly,
              loaderTrue: false,
              changeValue: false,
            });
          }
        }
        // else {
        //   Utils.toastError("No record found.", {
        //     // // position: toast.POSITION.BOTTOM_RIGHT,
        //     // // containerId: "Unapprove",
        //   });
        //   LaborForecast.GetLaborForecastSummary(request)
        //     .then(async (result: any) => {
        //       let calnderDatesDispaly: any = [];
        //       if (result != null && result.length > 0) {
        //         let displayDates: any = result[0].payPeriodDate.split("_");
        //         calnderDatesDispaly = displayDates;

        //         this.setState({
        //           calenderDates: calnderDatesDispaly,
        //           startDate: displayDates[0],
        //           endDate: displayDates[1],
        //           loaderTrue: false,
        //         });
        //       }
        //       resolve();
        //     })
        //     .catch((error: any) => {
        //       reject();
        //       this.setState({ loaderTrue: false });
        //     });
        // }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "Unapprove",
        });
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  handleDiscard = () => {
    let date = this.state.calenderSelectDate;
    let calenderYear = date.getFullYear();
    let calenderDate = date.getDate();
    let calenderMonth = date.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
    let request = {} as IForecastRequestDto;
    request.hotelID = this.state.hidValue;
    request.date = tempDate;
    this.getApproveData(request, "discard");
    this.setState({
      changeValue: false,
      isActionFooter: false,
    });
    ifIdData=[];
  };

  handleSave = (type: string) => {
    let request = {} as any;
    request.HotelID = this.state.hidValue;
    request.IfRecordID = String(ifIdData);
    request.Date = String(this.state.calenderDates[0]);
    
    LaborForecast.updateDepartmentUnapprove(request)
      .then(async (result: any) => {
        if (result != null) {
          let resultData = result;
          if (resultData.toLowerCase() === "success") {
            let successMessage = "Saved Successfuilly.";
            toast.success(successMessage, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "Unapprove",
            });
            let date = this.state.calenderSelectDate;
            let calenderYear = date.getFullYear();
            let calenderDate = date.getDate();
            let calenderMonth = date.getMonth() + 1;
            let tempDate =
              calenderMonth + "/" + calenderDate + "/" + calenderYear;
            let request = {} as IForecastRequestDto;
            request.hotelID = this.state.hidValue;
            request.date = tempDate;
            this.getApproveData(request, "saveForecast");
            this.setState({ changeValue: false, isActionFooter: false });
          }
        }
        ifIdData=[];
        resolve();
      })
      .catch((err: any) => {
        toast.success(`Server Error: ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "Unapprove",
        });
        reject();
      });
    if (this.closeModal) {
      this.closeModal();
    }
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangeUnsaved = () => {
    if (this.state.isHandleForecast === "selectHotelDropDown") {
      this.setState({ changeValue: false }, () => {
        this.selectHotelDropDown(hotelDetailsData);
      });
    } else if (this.state.isHandleForecast === "calenderShow") {
      this.setState({ changeValue: false }, () => {
        this.handleDiscard();
        this.calenderShow();
      });
    }
  };

  calenderChange = (e: any) => {
    debugger;
    let calenderYear = e.getFullYear();
    let calenderDate = e.getDate();
    let calenderMonth = e.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

    this.setState(
      {
        unApproveData: [],
        isRecordFound: false,
      },
      () => {
        let request = {} as IForecastRequestDto;
        request.hotelID = this.state.hidValue;
        request.date = tempDate;
        this.setState({calenderSelectDate : e} , () => {
          this.getApproveData(request, "calenderChange");
        })
        
        this.setState({calenderShow: false });
      }
    );
  };

  calenderShow = () => {
    if (this.state.changeValue) {
      this.setState({ isHandleForecast: "calenderShow" });
      this.isValidateModelPoupTab();
    } else {
      this.setState({ calenderShow: true });
    }
  };

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  headerFormatter = (column: any, colIndex: any, row: any) => {
    let forecastLockDisplay = false;
    let headerDates = this.state.headerDates;
    let headerDays = this.state.headerDays;
    return (
      <div className="column-header">
        <div className="header-data">
          {forecastLockDisplay && (
            <ReactTooltip
              effect="solid"
              event="mouseover mouseenter"
              eventOff="mouseleave mouseout scroll mousewheel blur"
              place="bottom"
              multiline={true}
            />
          )}

          <span className="header-day">{headerDays[colIndex - 1]}</span>
        </div>
        <p className="header-date">{headerDates[colIndex - 1]}</p>
      </div>
    );
  };

  checkBoxFormatter = (column: any, colIndex: any, row: any) => {};

  checkChange(targetCheckBox: any, event, targetIfID, key) {
    let forecastData = this.state.unApproveData;
    if (key === "1") {
      forecastData = forecastData.map((items) => {
        if (items.day1_IFId === targetIfID) {
          return { ...items, day1_isChecked: event.currentTarget.checked };
        } else {
          return items;
        }
      });
    }
    if (key === "2") {
      forecastData = forecastData.map((items) => {
        if (items.day2_IFId === targetIfID) {
          return { ...items, day2_isChecked: event.currentTarget.checked };
        } else {
          return items;
        }
      });
    }
    if (key === "3") {
      forecastData = forecastData.map((items) => {
        if (items.day3_IFId === targetIfID) {
          return { ...items, day3_isChecked: event.currentTarget.checked };
        } else {
          return items;
        }
      });
    }
    if (key === "4") {
      forecastData = forecastData.map((items) => {
        if (items.day4_IFId === targetIfID) {
          return { ...items, day4_isChecked: event.currentTarget.checked };
        } else {
          return items;
        }
      });
    }
    if (key === "5") {
      forecastData = forecastData.map((items) => {
        if (items.day5_IFId === targetIfID) {
          return { ...items, day5_isChecked: event.currentTarget.checked };
        } else {
          return items;
        }
      });
    }
    if (key === "6") {
      forecastData = forecastData.map((items) => {
        if (items.day6_IFId === targetIfID) {
          return { ...items, day6_isChecked: event.currentTarget.checked };
        } else {
          return items;
        }
      });
    }
    if (key === "7") {
      forecastData = forecastData.map((items) => {
        if (items.day7_IFId === targetIfID) {
          return { ...items, day7_isChecked: event.currentTarget.checked };
        } else {
          return items;
        }
      });
    }
    this.setState((curr: any) => ({
      ...curr,
      unApproveData: forecastData,
      isActionFooter: true,
      changeValue: true,
    }));
    if (event.currentTarget.checked) {
      var index = ifIdData.indexOf(targetIfID);
      if (index > -1) {
        ifIdData.splice(index, 1);
      }
    } else {
      ifIdData.push(targetIfID);
    }
    if (ifIdData.length === 0) {
      this.setState({ isActionFooter: false, changeValue: false });
    }
  }


  ConfirmPayrollExport() {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "Payroll for this period has already been exported. Continuing will require payroll to be re-exported to prevent any disruptions. Would you like to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleSave("Forecast")
         
        },
        {
          label: "No",
          onClick: () => reject()
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  NetChexWarning = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        this.closeModal = onClose;
        return (
          <div className='custom-ui warning-export-modal-UI'>
            <div className="wrapper">
              <div className="modal-body">
                <h1 className="modal-title">Your payroll data has already been exported</h1>
                <p>Time for these roles has already been exported to Netchex.</p>
                <p>If you edit a timesheet for someone, you must export payroll again to ensure time in Inn-Flow is updated in Netchex.</p>
              </div>
              <div className="modal-footer">
                <button onClick={onClose}>Cancel</button>
                <button
                  onClick={() => this.handleSave("Forecast")}
                >
                  Unapprove & Continue
                </button>
              </div>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
    
  }


  handleSaveChangesWarning = () => {
    if(this.state.isPayrollExported !="")
    {
      if(this.state.exportFormat === "NETCHEX" && this.state.isPayrollIntegration === "Yes")
      {
          this.NetChexWarning();
      }
      else
      {
        this.ConfirmPayrollExport();
      }
    }
    else
    {
      this.handleSave("Forecast")
    }
  }




  render() {
    console.log(this.state.isPayrollExported);
    const columns = [
      {
        dataField: "itemName",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="tableHasCheckBox item-name">
            <EllipsisWithTooltip placement="bottom">
              <span>{row.itemName}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "day1",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="tableHasCheckBox item-name">
            <label className="form-check">
              {row.day1_Date === "Approve" ? (
                <React.Fragment>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={row.day1_isChecked}
                    // value={row.day1_IFId}
                    id={rowIndex}
                    onChange={(e: any) => {
                      this.checkChange(row, e, row.day1_IFId, "1");
                    }}
                  ></input>
                  <span></span>
                </React.Fragment>
              ) : (
                <div style={{ visibility: "hidden" }}>No</div>
              )}
            </label>
          </div>
        ),
      },
      {
        dataField: "day2",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="tableHasCheckBox item-name">
            <label className="form-check">
              {row.day2_Date === "Approve" ? (
                <React.Fragment>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={row.day2_isChecked}
                    // value={row.day1_IFId}
                    onChange={(e: any) => {
                      this.checkChange(row, e, row.day2_IFId, "2");
                    }}
                  ></input>
                  <span></span>
                </React.Fragment>
              ) : (
                <div style={{ visibility: "hidden" }}>No</div>
              )}
            </label>
          </div>
        ),
      },
      {
        dataField: "day3",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="tableHasCheckBox item-name">
            <label className="form-check">
              {row.day3_Date === "Approve" ? (
                <React.Fragment>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={row.day3_isChecked}
                    onChange={(e: any) => {
                      this.checkChange(row, e, row.day3_IFId, "3");
                    }}
                  ></input>
                  <span></span>
                </React.Fragment>
              ) : (
                <div style={{ visibility: "hidden" }}>No</div>
              )}
            </label>
          </div>
        ),
      },
      {
        dataField: "day4",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="tableHasCheckBox item-name">
            <label className="form-check">
              {row.day4_Date === "Approve" ? (
                <React.Fragment>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={row.day4_isChecked}
                    onChange={(e: any) => {
                      this.checkChange(row, e, row.day4_IFId, "4");
                    }}
                  ></input>
                  <span></span>
                </React.Fragment>
              ) : (
                <div style={{ visibility: "hidden" }}>No</div>
              )}
            </label>
          </div>
        ),
      },
      {
        dataField: "day5",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="tableHasCheckBox item-name">
            <label className="form-check">
              {row.day5_Date === "Approve" ? (
                <React.Fragment>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={row.day5_isChecked}
                    onChange={(e: any) => {
                      this.checkChange(row, e, row.day5_IFId, "5");
                    }}
                  ></input>
                  <span></span>
                </React.Fragment>
              ) : (
                <div style={{ visibility: "hidden" }}>No</div>
              )}
            </label>
          </div>
        ),
      },
      {
        dataField: "day6",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="tableHasCheckBox item-name">
            <label className="form-check">
              {row.day6_Date === "Approve" ? (
                <React.Fragment>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={row.day6_isChecked}
                    onChange={(e: any) => {
                      this.checkChange(row, e, row.day6_IFId, "6");
                    }}
                  ></input>
                  <span></span>
                </React.Fragment>
              ) : (
                <div style={{ visibility: "hidden" }}>No</div>
              )}
            </label>
          </div>
        ),
      },
      {
        dataField: "day7",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="tableHasCheckBox item-name">
            <label className="form-check">
              {row.day7_Date === "Approve" ? (
                <React.Fragment>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={row.day7_isChecked}
                    onChange={(e: any) => {
                      this.checkChange(row, e, row.day7_IFId, "7");
                    }}
                  ></input>
                  <span></span>
                </React.Fragment>
              ) : (
                <div style={{ visibility: "hidden" }}>No</div>
              )}
            </label>
          </div>
        ),
      },
    ];

    return (
      <>
        <RouteLeavingGuard
          when={this.state.changeValue}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            if (this.state.changeValue) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />
        {/* <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"Unapprove"}
        /> */}
        <div className="forecastModule">
          <Container fluid className="body-sec unapprove-page">
            <div className="page-heading underline d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
              />

              <div className="mr-auto">Unapprove</div>
            </div>
            {this.state.hidValue !== "Select" && (
              <>
                {this.state.loaderTrue ? (
                  <ReactPageLoader useas={"forecastMain"} />
                ) : (
                  <div className="main-Page">
                    <div className="navigation-menus d-flex">
                      <div className="calender-container ml-0">
                        <div
                          className={
                            this.state.calenderShow
                              ? "calender-display open"
                              : "calender-display"
                          }
                          onClick={this.calenderShow}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>
                          <span className="prev-Date">
                            {this.state.calenderDates[0]}
                          </span>
                          <span className="seperator"> - </span>
                          <span className="next-Date">
                            {this.state.calenderDates[1]}
                          </span>
                          <span className="chevron-down">
                            <FiChevronDown />
                          </span>
                        </div>
                        {this.state.calenderShow && (
                          <div className="calender">
                            <OutsideClickHandler
                              onOutsideClick={this.calenderHide}
                            >
                              <Calendar
                                calendarType={"US"}
                                onChange={this.calenderChange}
                                value={this.state.calenderSelectDate}
                               // maxDate={new Date()}
                               maxDate={JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
                                JSON.parse(localStorage.storage).currentUtcTime
                              ):new Date()}
                                minDate={new Date(calenderMinDate)}
                              />
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="unapprove-table unapproveTable">
                      {this.state.isRecordFound && (
                        <BootstrapTable
                          id="tblForeCast"
                          keyField="id"
                          data={this.state.unApproveData}
                          columns={columns}
                          hover
                          condensed
                        />
                      )}
                    </div>
                    {this.state.isActionFooter && (
                      <div className="fixed-action relative">
                        <div className="d-flex align-content-center flex-wrap">
                          <div className="mr-auto message">
                            <span>You have unsaved changes</span>
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary btn-discard"
                            onClick={this.handleDiscard}
                          >
                            Discard
                          </button>
                          <button
                            type="button"
                            className={
                              this.state.saveForecastButtonSpinner
                                ? "btn btn-primary p-none"
                                : "btn btn-primary"
                            }
                            onClick={() => this.handleSaveChangesWarning()}
                          >
                            {this.state.saveForecastButtonSpinner && (
                              <Spinner
                                className="mr-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            Save Changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </Container>
        </div>
      </>
    );
  }
}