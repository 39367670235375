import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Row,
} from "react-bootstrap";
import { UserManagement } from "./../../../Common/Services/UserManagement";
import { AddHotelWiseSetting } from "./AddHotelWiseSetting";
import { AddSpecialRate } from "./AddSpecialRate";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../../Common/Utilis";
import BootstrapTable from "react-bootstrap-table-next";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { IHotelWideSettingProps } from "./../../../Common/Contracts/IHotelWideSettingProps";

export const HotelWideSettings = ({
  hotelWideTableData,
  showSpecialRateAddEditModal,
  showSpecialRateAddEditModalForm,
  userGeneralInfo,
  deletePayrollSpecialRate,
  spacialRates,
  isSpecialRateLoaded,
  hideSpecialRateAddEditModalForm,
  hotelWideTableExpended,
  specialRateRowEdit,
  handleOnHotelWideExpand,
  isHotelWideExpand,
  showHotelWiseAddEditModal,
  showHotelWiseAddEditModalForm,
  isHotelWisePayrollLoaded,
  hideHotelWiseAddEditModalForm,
  hotelWisePayrollRowEdit,
  isPayrollTab,
  userUniqueNo,
  clientSetupData,
  hasHWPSetting,
  hasViewSensitiveDataPermission,
  isFromPayroll,
  isEditLaborandPayrollInfo,
  employeeState,
  localAndStateTaxesFormFields
}: IHotelWideSettingProps) => {

  const [positionList, setPositionList] = useState<any[]>([]);
  const [mainPositionList, setMainPositionList] = useState<any[]>([]);
  const [moduleWithHids, setModuleWithHids] = useState<any[]>([]);
  const [excludedStates, setExcludedStates] = useState<any[]>([]);

  
// const excludedStates = ["AK",
//   "FL",
//   "NV",
//   "NH",
//   "SD",
//   "TN",
//   "TX",
//   "WA",
//   "WY"];


  useEffect(() => {
    if (positionList?.length === 0) {
      getAllPositionlist();
    }
    if (moduleWithHids?.length === 0) {
      getModuleWithHids();
    }
    if(excludedStates?.length ===0) {
      GetPayrollStateAndLocalTaxesMaster(); 
    }
  }, []);



  const getAllPositionlist = () => {
    UserManagement.GetAllPositionlistbyHid().then((_positionList: any[]) => {
      setPositionList(_positionList);
      setMainPositionList(_positionList);
    });
  };

  const GetPayrollStateAndLocalTaxesMaster = () => {
    UserManagement.GetPayrollStateAndLocalTaxesMaster().then((statelist: any[]) => {
      setExcludedStates(statelist);
    });
  };



  const checkIfChildFieldNeedToDisplay=(item): boolean =>{

    const parentItem = (localAndStateTaxesFormFields || [])?.find(x=>x.fieldId === item.parentid && x.hid === item.hid);
    if(parentItem) {
   return   ((item.displayOnParentCheck && parentItem?.fieldValue?.toString()==="true") ||
   !item.displayOnParentCheck )? true : false;
    } else {
    return  true
    }
  }

  

  const getStatusString = (status) => {
    return status === "Active" ? (
      <span className="badge color-green">Active</span>
    ) : (
      <span className="badge color-grey">Inactive</span>
    );
  };
  
  const handleTableCollapse = (e,row,rowIndex, ExpandOnly=false)=>{
      const expandedItem=hotelWideTableExpended?.find(x => x == row.hid)
      let isExpand = expandedItem ? false : isHotelWideExpand
      console.log({isExpand})
      if(ExpandOnly )
      {
        isExpand=true
      }
      handleOnHotelWideExpand(row,isExpand,rowIndex,e)
    
  }
  const tableColumnEvent = {
    onClick: (e, column, columnIndex, row, rowIndex) => handleTableCollapse(e,row,rowIndex),
  };
  const hotelWideMainColumns = [
    {
      dataField: "lettercode",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="hotel-wide-details d-flex flex-column">
   <>{(isPayrollTab && row?.status ==="Active" &&
    (!row?.withholdingState || !row?.unemploymentState ))  &&
            <div className="pips"><div className="pip light-red"></div></div>}</>

          <span className="lettercode">{cell}</span>
          {/* <span className="effective-date">Effective {row?.ptoStartDate}</span> */}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>{getStatusString(row?.status)}</div>
      ),
    },
    {
      dataField: "regPosition",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="position-details d-flex flex-column">
          <EllipsisWithTooltip>
            <span className="position">{cell}</span>
          </EllipsisWithTooltip>
          <span className="position-label">Regular Position</span>
        </div>
      ),
    },
    {
      dataField: "empType1",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="employee-type-details d-flex flex-column">
          <EllipsisWithTooltip>
            <span className="employee-type">{cell}</span>
          </EllipsisWithTooltip>
          <span className="employee-type-label">Type</span>
        </div>
      ),
    },
    {
      dataField: "isPrimary_Property",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
           {row?.isPrimary_Property === true && <span className="badge primary-property">Primary Property</span>}
        </div>
      ),
    },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <Dropdown
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
          className="more-action position-static"
          alignRight
        >
          <Dropdown.Toggle
            disabled={!hasViewSensitiveDataPermission || !isEditLaborandPayrollInfo
              || userGeneralInfo.hids?.filter(x =>x?.status?.toLowerCase() == "active" && x.lettercode === row?.lettercode && x?.loginUserHasAccess)?.length === 0}
            className="btn-outline-primary btn btn-primary btn-ghost more"
            id="dropdown-more"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>

            <Dropdown.Item
              key={"Edit"}
              onClick={(e) => {
                handleTableCollapse(e,row,rowIndex,true)
                showHotelWiseAddEditModalForm(true, row)}
              }
              eventKey={"Edit"}
            >
              Edit
            </Dropdown.Item>



          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const hotelWideChildColumns = [
    {
      dataField: "regPosition",
      text: "Position",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <EllipsisWithTooltip>
          <div className="position-name">{cell}</div>
        </EllipsisWithTooltip>
      ),
    },
    {
      dataField: "regularRate",
      text: "Regular",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
          {!hasViewSensitiveDataPermission ? cell : Utils.amountFormatted(cell)}
        </div>
      ),
    },
    {
      dataField: "overTime",
      text: "OT",
      hidden: userGeneralInfo?.employeeType == "ExcludefromPayroll" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
          {!hasViewSensitiveDataPermission ? cell : Utils.amountFormatted(cell)}
        </div>
      ),
    },
    {
      dataField: "doubleOverTime",
      text: "DOT",
      hidden: userGeneralInfo?.employeeType == "ExcludefromPayroll" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
          {!hasViewSensitiveDataPermission ? cell : Utils.amountFormatted(cell)}

        </div>
      ),
    },
    {
      dataField: "holidayRate",
      text: "Holiday",
      hidden: userGeneralInfo?.employeeType == "ExcludefromPayroll" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
          {!hasViewSensitiveDataPermission ? cell : Utils.amountFormatted(cell)}

        </div>
      ),
    }
  ];

  // const hotelWideMainPayrollColumns = [
  //   {
  //     dataField: "lettercode",
  //     text: "",
  //     formatter: (cell: any, row: any, rowIndex: any) => (
  //       <div className="hotel-wide-details d-flex flex-column">
  //         <span className="lettercode">{cell}</span>
  //         {/* <span className="effective-date">Effective {row?.ptoStartDate}</span> */}
  //       </div>
  //     ),
  //   },
  //   {
  //     dataField: "status",
  //     text: "",
  //     formatter: (cell: any, row: any, rowIndex: any) => (
  //       <div>{getStatusString(row?.status)}</div>
  //     ),
  //   },
  //   {
  //     dataField: "withholdingState",
  //     text: "",
  //     formatter: (cell: any, row: any, rowIndex: any) => (
  //       <div className="position-details d-flex flex-column">
  //         <EllipsisWithTooltip>
  //           <span className="position">{cell}</span>
  //         </EllipsisWithTooltip>
  //         <span className="position-label">Working State</span>
  //       </div>
  //     ),
  //   },
  //   {
  //     dataField: "unemploymentState",
  //     text: "",
  //     formatter: (cell: any, row: any, rowIndex: any) => (
  //       <div className="employee-type-details d-flex flex-column">
  //         <EllipsisWithTooltip>
  //           <span className="employee-type">{cell}</span>
  //         </EllipsisWithTooltip>
  //         <span className="employee-type-label">Unemployment State</span>
  //       </div>
  //     ),
  //   },
  //   {
  //     dataField: "stateFillingStatus",
  //     text: "",
  //     formatter: (cell: any, row: any, rowIndex: any) => (
  //       <div className="employee-type-details d-flex flex-column">
  //         <EllipsisWithTooltip>
  //           <span className="employee-type">{cell}</span>
  //         </EllipsisWithTooltip>
  //         <span className="employee-type-label">State Filing Status</span>
  //       </div>
  //     ),
  //   },
  //   {
  //     dataField: "",
  //     text: "",
  //     formatter: (cell: any, row: any, rowIndex: any) => (
  //       <Dropdown
  //         onClick={(e) => {
  //           e.stopPropagation();
  //         }}
  //         className="more-action"
  //         alignRight
  //       >
  //         <Dropdown.Toggle
  //           disabled={(!hasViewSensitiveDataPermission || !isEditLaborandPayrollInfo) || userGeneralInfo.hids?.filter(x => x.lettercode === row?.lettercode && x?.loginUserHasAccess)?.length === 0}
  //           className="btn-outline-primary btn btn-primary btn-ghost more"
  //           id="dropdown-more"
  //         >
  //           <svg
  //             width="20"
  //             height="20"
  //             viewBox="0 0 20 20"
  //             fill="none"
  //             xmlns="http://www.w3.org/2000/svg"
  //           >
  //             <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
  //             <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
  //             <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
  //           </svg>
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu>
  //           <Dropdown.Item
  //             key={"Edit"}
  //             onClick={() => showHotelWiseAddEditModalForm(true, row)}
  //             eventKey={"Edit"}
  //           >
  //             Edit
  //           </Dropdown.Item>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     ),
  //   },
  // ];

  const speacialRateColumns = [
    {
      dataField: "positionName",
      text: "Position",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <EllipsisWithTooltip>
          <div className="position-name">{cell}</div>
        </EllipsisWithTooltip>
      ),
    },

    {
      dataField: "regularRateStr",
      text: "Special",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
          {!hasViewSensitiveDataPermission ? cell : Utils.ShowNumberUptoLastTwoDecimal(cell)}
        </div>
      ),
    },
    {
      dataField: "wefDate",
      text: "Effective",
      formatter: (cell: any, row: any, rowIndex: any) => <div>{cell}</div>,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <Dropdown
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
          className="more-action test+"
          alignRight
        >
          <Dropdown.Toggle
            disabled={(!hasViewSensitiveDataPermission || !isEditLaborandPayrollInfo) || userGeneralInfo.hids?.filter(x => +x.hotelID === +hotelWideTableExpended[0] && x?.loginUserHasAccess)?.length === 0}
            className="btn-outline-primary btn btn-primary btn-ghost more"
            id="dropdown-more"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <>
              <Dropdown.Item
                key={"Edit"}
                onClick={() => showSpecialRateAddEditModalForm(true, row)}
                eventKey={"Edit"}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                key={"Delete"}
                onClick={() => deletePayrollSpecialRate(row)}
                eventKey={"Delete"}
              >
                Delete
              </Dropdown.Item>
            </>

          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const expandRowHotelWide = {

      
    renderer: (row: any) => {

      // let localAndStateTaxesFormFieldsFiltered= (localAndStateTaxesFormFields || []).filter(x=>x?.hid ===row?.hid)
      // const end = localAndStateTaxesFormFieldsFiltered && localAndStateTaxesFormFieldsFiltered?.length - 1;
      // const _localAndStateTaxesFormFieldsFiltered = localAndStateTaxesFormFieldsFiltered && localAndStateTaxesFormFieldsFiltered.slice(2, end+1);
     return  <div className="rowExpended" id="laborDayDetails">

        <div>

{
(isPayrollTab 
//   && row?.showLocalTaxFields?.toString()?.toLowerCase() === "yes"
//  && excludedStates.filter(x=> x?.stateCode?.toString()?.toLowerCase() ===
//  (row?.withholdingState || employeeState)?.toString()?.toLowerCase()
//    && x?.isEnabledLocalTaxes?.toString()?.toLowerCase()==="yes")?.length>0
) &&
<>
<div className="section-heading">State & Local Taxes</div>
          <div className="form-section d-flex flex-column">

          <Row className="d-flex">
            <Col sm="12" >
              <Form.Group className="d-flex flex-row">
                <Form.Label>Unemployment State</Form.Label>
                <div className="readonly-value">{row?.unemploymentState}</div>
          
              </Form.Group>
            </Col>

            {/* {[( localAndStateTaxesFormFieldsFiltered || [])[0]]?.map(item =>   <Col sm="6" ><Form.Group className="d-flex flex-row">
              <Form.Label>{item?.displayName}</Form.Label>
              <div className="readonly-value">{item?.fieldValue || ""}</div>
              
            </Form.Group></Col>  )} */}

            <Col sm="12" > 
            <Form.Group className="d-flex flex-row">
              <Form.Label>Working State</Form.Label>
              <div className="readonly-value">{row?.withholdingState}</div>
             
            </Form.Group>
            </Col>
            {/* {[( localAndStateTaxesFormFieldsFiltered || [])[1]]?.map(item =>   <Col sm="6" ><Form.Group className="d-flex flex-row">
              <Form.Label>{item?.displayName}</Form.Label>
              <div className="readonly-value">{item?.fieldValue || ""}</div>
              
            </Form.Group></Col>  )} */}

            <Col sm="12" > 
            <Form.Group className="d-flex flex-row">
              <Form.Label>Working State #2</Form.Label>
              <div className="readonly-value">{row?.workingState2}</div>
             
            </Form.Group>
            </Col>
            {(localAndStateTaxesFormFields || []).filter(x=>x?.hid ===row?.hid)?.map(item =>   {
              // if(item.isLocalTax == 1){
                // if(row?.showLocalTaxFields?.toString()?.toLowerCase() === "yes")
                // {
                //   return  <Col sm="12" ><Form.Group className="d-flex flex-row">
                //   <Form.Label>{item?.displayName}</Form.Label>
                //   <div className="readonly-value">{item?.fieldValue ===  "true" ? "Yes": item?.fieldValue === "false" ?"No" : item?.fieldValue  || ""}</div>
                //   </Form.Group></Col>
                // }
               
                
              // }else{
                return  checkIfChildFieldNeedToDisplay(item) ?
                 <Col sm="12" className={`${item.controlType !== "checkbox" && item?.displayOnParentCheck ? "child-item":""}`}>
                  <Form.Group className="d-flex flex-row">
                <Form.Label>{item?.displayName}</Form.Label>
                <div className="readonly-value">{item?.fieldValue ===  "true" ? "Yes": item?.fieldValue === "false" ?"No" :
                (item?.isCurrency ? item?.fieldValue==""? "": Utils.currencyFormat(item?.fieldValue):
                <EllipsisWithTooltip>{item?.fieldValue}</EllipsisWithTooltip>) || ""}</div> 
                </Form.Group></Col> :<></>
              // }
            } 
             )}
            
          </Row>
         

            

         
           

           


          </div>
          </>

  }




          <div className="section-heading">Details</div>
          <div className="adjustments-labor-hotel-wide-regular-table">
            <BootstrapTable
              keyField="rowNo"
              data={[row]}
              columns={
                (userGeneralInfo?.employeeType ==="Contractor" &&
                !userGeneralInfo?.allowContactorOverTime) ?
                hotelWideChildColumns?.filter(x=> !["overTime","doubleOverTime","holidayRate"]?.includes(x.dataField))
                 :hotelWideChildColumns }
              hover
            />
          </div>
        </div>

        {userGeneralInfo?.employeeType === "HourlyWage" && (
          <div>
            <div className="section-heading">Special Rates</div>
            <div className="adjustments-labor-hotel-wide-special-table">
              <BootstrapTable
                keyField="rowNo"
                data={spacialRates?.filter(x=>+x.hid===+row.hid)}
                columns={speacialRateColumns}
                hover
              />
            </div>
             { (!isFromPayroll && spacialRates?.filter(x=> +x.hid===+row.hid)?.length < 5 && hasViewSensitiveDataPermission && isEditLaborandPayrollInfo &&
              userGeneralInfo.hids?.filter(x => +x.hotelID === +hotelWideTableExpended[0] && x?.loginUserHasAccess)?.length !== 0 &&
              userGeneralInfo?.employeeType !=="Contractor" ) &&
              (
                <Button
                  className="btn-ghost"
                  onClick={() => showSpecialRateAddEditModalForm()}
                >
                  Add Special Rate
                </Button>
              )}
            {showSpecialRateAddEditModal && isSpecialRateLoaded && (
              <AddSpecialRate
                showSpecialRateAddEditModal={showSpecialRateAddEditModal}
                hideSpecialRateAddEditModalForm={
                  hideSpecialRateAddEditModalForm
                }
                userName={userGeneralInfo?.userName}
                userUniqueNo={userUniqueNo}
                speacialRates={spacialRates.filter(x=>+x.hid ===+row?.hid)}
                rowPositionId={row?.coa}
                hid={row?.hid}
                dateList={row?.dateList?.map((x) => ({ label: x, value: x }))}
                specialRateRowEdit={specialRateRowEdit}
                positionList={mainPositionList.filter(x => +x.hid === +row?.hid)}
              />
            )}
          </div>
        )}
      </div>
    },
    expanded: hotelWideTableExpended,
    onExpand: handleOnHotelWideExpand,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <span className="chevronExpended">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      }
      return (
        <span className="chevronCollpsed">
          <img src={chevronRight} alt="chevron Right" />
        </span>
      );
    },
  };

  const rowHotelWideClasses = (row: any, rowIndex: any) => {
    if (isHotelWideExpand === true && row?.action === "Expended") {
      return "row-Expended";
    } else if (row?.approveButtonSpinner) {
      return "p-none";
    } else if (isHotelWideExpand === true) {
      return "row-Collapsed";
    } else {
      return "row-Action";
    }
  };

  const getModuleWithHids = () => {
    UserManagement.GetModuleWithHids().then((result: any) => {
      setModuleWithHids(result?.result);
    });
  }

  const getHotelsForButton = (): any[] => {

    let assignableHotels = (hotelWideTableData.length > 0
      ? userGeneralInfo?.hids.filter(
        (x) => x?.loginUserHasAccess &&
          !hotelWideTableData
            ?.map((l) => l?.lettercode)
            .includes(x?.lettercode)
      )
      : userGeneralInfo?.hids?.filter(
        (x) => x?.loginUserHasAccess) || []);
    const _assignableHotels: any[] = [];



    assignableHotels.forEach((hotel: any) => {
      moduleWithHids?.forEach((item: any) => {

        if (item?.moduleName === "Labor Management" && item?.hotelID === hotel?.hotelID) {
          _assignableHotels.push(hotel);
        }
      });
    });

    return _assignableHotels;
  }


const getHotelsforPip= ()=> {
  let assignableHotels =  userGeneralInfo?.hids?.filter(
      (x) => x?.loginUserHasAccess) || [];
  const _assignableHotels: any[] = [];
  assignableHotels.forEach((hotel: any) => {
    moduleWithHids?.forEach((item: any) => {
      if (item?.moduleName === "Labor Management" && item?.hotelID === hotel?.hotelID) {
        _assignableHotels.push(hotel);
      }
    });
  });
  return _assignableHotels;
}


  const getHotelsForForm = (): any[] => {
    let assignableHotels = (hotelWisePayrollRowEdit?.uniqueNo > 0
      ? userGeneralInfo?.hids.filter(
        (x) => x?.loginUserHasAccess && +x.hotelID === hotelWisePayrollRowEdit.hid
      )
      : hotelWideTableData.length > 0
        ? userGeneralInfo?.hids.filter(
          (x) => x?.loginUserHasAccess &&
            !hotelWideTableData
              ?.map((l) => l?.lettercode)
              .includes(x?.lettercode)
        )
        : userGeneralInfo?.hids?.filter(x => x?.loginUserHasAccess) || []);
    //    if(!hotelWisePayrollRowEdit?.uniqueNo && !isFromPayroll) {
    //   if(userGeneralInfo?.employeeType === "Salaried" ||userGeneralInfo?.employeeType === "SalariedNonExempt") {

    //    assignableHotels= assignableHotels.;
    //   }
    // }
    const _assignableHotels: any[] = [];
    assignableHotels.forEach((hotel: any) => {
      moduleWithHids?.forEach((item: any) => {

        if (isPayrollTab) {
          if ((item?.moduleName === "Payroll" || item?.moduleName === "Payroll Service"
            || item?.moduleName === "ShowPayroll")
            && item?.hotelID === hotel?.hotelID) {
            _assignableHotels.push(hotel);
          }
        } else {

          if ((item?.moduleName === "Labor Management" ||
            item?.moduleName === "Labor")
            && item?.hotelID === hotel?.hotelID) {
            _assignableHotels.push(hotel);
          }
        }

      });
    });

    return _assignableHotels;
  }



  const handleAddHotelWideSettings = () => {

    if (getHotelsForForm()?.length === 0) {


      showHotelWiseAddEditModalForm(false, {}, true);

    } else {
      showHotelWiseAddEditModalForm();
    }


  }


  return (

    <>
    {
      ((isPayrollTab &&
      hotelWideTableData?.length>0)
      ||  !isPayrollTab) &&
      getHotelsforPip()?.length>0 &&
      <>
        <div className="separator-line"></div>
        <div className="body-section">
          <div className="heading mod-for-pips-icon"><>
            {
              ( 
                userGeneralInfo?.groupHWPPayrollSetting &&
              !isPayrollTab&&
              userGeneralInfo?.employeeType !== "ExcludefromPayroll"   ) &&
             
              <div className="pips"><div className="pip light-red"></div></div>
            }
          </> Entity Wide Setting</div>
          <div className="labor-hotel-wide-setting-table">
            <BootstrapTable
              keyField="hid"
              data={hotelWideTableData}
              columns={hotelWideMainColumns
              }
              expandRow={expandRowHotelWide}
              rowClasses={rowHotelWideClasses}
              hover
            />
          </div>
          <div className="add-deduction-wrapper add-hotel-wide-wrapper">
            {getHotelsForButton()?.length > 0 && !isPayrollTab && hasViewSensitiveDataPermission && isEditLaborandPayrollInfo &&
              userGeneralInfo?.employeeType && (
                <Button
                  className="btn-ghost"
                  onClick={() => handleAddHotelWideSettings()}
                >
                  Add Entity Wide Setting
                </Button>
              )}{" "}
          </div>
          {showHotelWiseAddEditModal &&
            userGeneralInfo?.employeeType &&
            isHotelWisePayrollLoaded && moduleWithHids?.length > 0 && (
              <AddHotelWiseSetting
                isPayrollTab={isPayrollTab}
                userHotels={getHotelsForForm()}
                hireDate={userGeneralInfo?.hireDate}
                showHotelWiseAddEditModal={showHotelWiseAddEditModal}
                hideHotelWiseAddEditModalForm={hideHotelWiseAddEditModalForm}
                userName={userGeneralInfo?.userName}
                userUniqueNo={userUniqueNo}
                isEmpCodeRequired={clientSetupData?.empCodeADP === 'Yes'&& userGeneralInfo?.employeeType !=="Contractor"}
                employeeType={userGeneralInfo?.employeeType}
                hotelWisePayrollRowEdit={hotelWisePayrollRowEdit}
                positionList={mainPositionList}
                hotelWideTableData={hotelWideTableData}
                employeeState ={employeeState}
                userPayrollMapping={userGeneralInfo.userPayrollMapping}
                showLocalTaxesFields= {
                  (isPayrollTab 
                  //   && hotelWisePayrollRowEdit?.showLocalTaxFields?.toString()?.toLowerCase() === "yes"
                  // && excludedStates.filter(x=> x?.stateCode?.toString()?.toLowerCase() ===
                  // employeeState?.toString()?.toLowerCase()
                  //   && x?.isEnabledLocalTaxes?.toString()?.toLowerCase()==="yes")?.length>0
                 )

                }
              />
            )}
        </div>
      </>
}
    </>
  );
};
