import * as React from 'react';
import { Container, Dropdown, Form, Tab, Tabs, Spinner } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ToastContainer, toast } from "react-toastify";
import { Hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import "./communication.scss";
import "../Sales/component/component.scss";
import _ from "lodash";
import { Register as registersevice } from "../../Common/Services/Register";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";
import { LaborPerformance as laborPerformance } from "../../Common/Services/LaborPerfomance";
import { InvoiceEntrySlideout } from "../Register/InvoiceEntrySlideout";
import { TransactionSlideout } from "../Register/TransactionSlideout";
import { bankSyncServices } from "../../Common/Services/bankSyncServices";
import { CreditCardSlideout } from '../Register/CreditCardSlideout';
// import "../Accounting/Reconcile/reconcile.scss"




export class CommunicationsInbox extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoader :false,
            resetflag: false,
            isBanner: true,
            isLoading: false,
            commDataList: [],
            AllcommData: [],
            defpageTabs: [{ id: 1, name: "Flagged by Me", value: "By me", isActive: false, isHidden: true }, { id: 2, name: "All", value: "All", isActive: false, isHidden: true }],
            pageTabs: [],
            baseData: [],
            groupList: [],
            filterGroupList: [],
            searchGroup: "",
            groupFilterSelected: "Groups",
            defehidList: [],
            ehidList: [],
            ehidFilterSelected: "EHID",
            searchEhid: "",
            statusFilter: [
                { id: 1, name: "Needs Review", class: "orange", isChecked: false },
                { id: 2, name: "Reviewed", class: "blue", isChecked: false },
                { id: 3, name: "Review Complete", class: "green", isChecked: false }
            ],
            statusFilterSelected: "Status",
            savedStatus: [{ id: -1, name: '', class: '', isChecked: false }],
            dataTypeFilter: [{ id: 1, name: "All" }, { id: 2, name: "Invoice" }, { id: 3, name: "Credit Card Transaction" }],
            dataTypeFilterSelected: "Invoice",
            dateFilter: [{ id: 1, name: "Date (New to Old)" }, { id: 2, name: "Date (Old to New)" }],
            dateFilterSelected: "Date (New to Old)",
            pageSetting: [{ id: 1, name: "Save as Default" }, { id: 2, name: "Clear All Filters" }],
            searchFilter: "",
            isActiveFlaggedbyMe: false,

            isSlideOut: false,
            hidValue: "",
            hotelName: "",
            uniqueNo: "",
            pageType: "",
            uploadedDate: "",
            uploadedBy: "",
            isApproved: "",

            isTransationSlideOut: false,
            isCreditCardSlideOut: false,
            slideoutData: {},
            isForComments: false,
            sortingOrder: "asc",
            sortingField: "defaultOrder",
            typeFilter: [
                { id: 1, name: "Invoice", class: "orange", isChecked: false },
                { id: 2, name: "CC - Debit", class: "blue", isChecked: false },
                { id: 3, name: "CC - Credit", class: "green", isChecked: false },
                { id: 4, name: "Withdrawal", class: "green", isChecked: false },
                { id: 5, name: "Deposit", class: "green", isChecked: false }
            ],
            typeFilterSelected: "Type",
            savedType: [{ id: -1, name: '', class: '', isChecked: false }],
        };
    }

    componentDidMount(): void {

        this.GetCommInboxPermission();
    }

    getParamValueByParamName(paramName: string): any {
        let valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
        window.history.replaceState(null, '');
        return valuesArray;
    }

    GetCommInboxPermission = () => {
        this.setState({isLoader : true});
        registersevice.GetCommInboxPermission().then(async (result: any | null) => {
            if (result.length > 0) {
                let { pageTabs, defpageTabs, isActiveFlaggedbyMe } = this.state;
                pageTabs = defpageTabs;
                result.forEach((item: any) => {
                    result.forEach((o: any) => {
                        if ((o?.permissionName?.toString()?.toLocaleLowerCase() === "can flag/complete" && item?.permissionName?.toString()?.toLocaleLowerCase() === "can review") || (o?.permissionName?.toString()?.toLocaleLowerCase() === "can flag/complete")) {
                            isActiveFlaggedbyMe = true;
                        }
                    })
                });
                if (isActiveFlaggedbyMe) {
                    pageTabs.forEach((o: any) => {
                        o.isHidden = false
                        if (o.name === "Flagged by Me") {
                            o.isActive = true
                        }
                    })
                }
                result.forEach((item: any) => {
                    if (item?.permissionName?.toString()?.toLocaleLowerCase() === "can review") {
                        pageTabs.forEach((o: any) => {
                            if (o.name === "All") {
                                o.isHidden = false
                                o.isActive = isActiveFlaggedbyMe ? false : true;
                            }
                        })
                    }
                });

                const valuesArray = this.getParamValueByParamName("");
                if (valuesArray.length > 0) {


                    const activePageTab = valuesArray ? valuesArray[0]?.split('=')[1] as any : "";
                    if (activePageTab) {
                        pageTabs.forEach((o: any) => {
                            if (o.name === activePageTab) {
                                o.isActive = true;
                                isActiveFlaggedbyMe = false
                            }
                            else {
                                o.isActive = false;
                            }

                        })
                    }


                }
                this.setState({ pageTabs, isActiveFlaggedbyMe }, () => {
                    
                    this.getHotels();
                    this.GetGroupSubGroupDetails();
                })
            }
            else{
                this.setState({isLoader : false});
            }
            resolve();
        }).catch((error) => {
            Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "commInbox" });
            this.setState({isLoader : false});
            reject();
        });
        
    }

    selectedStatus = () => {
        let { statusFilter } = this.state;
        let checkedLen = statusFilter.filter((o: any) => o.isChecked);
        let isStatusSelected = (checkedLen.length > 1 ? `${checkedLen.length} Statuses Selected` : (checkedLen.length === 1 ? checkedLen[0].name : "Status"));
        return isStatusSelected;
    }

    setStatusPerTab = () => {
        let { statusFilter, statusFilterSelected, isActiveFlaggedbyMe } = this.state;
        statusFilter.forEach(o => o.isChecked = false);
        if (this.state.savedStatus.length > 0 && this.state.savedStatus.some((o: any) => o.id !== -1)) {
            statusFilter.forEach(o => o.isChecked = false);
            statusFilter.forEach(o => {
                this.state.savedStatus?.forEach(res => {
                    if (o?.name?.toString()?.toLowerCase() === res?.name?.toString()?.toLowerCase() && res.isChecked) {
                        o.isChecked = true;
                    }
                })
            })
        } else if (this.state.savedStatus.length === 1 && this.state.savedStatus[0].id === -1) {
            if (isActiveFlaggedbyMe) {
                statusFilter.forEach((o: any) => {
                    if (o.name === "Reviewed" || o.name === "Needs Review") {
                        o.isChecked = true
                    }
                })
            } else {
                const valuesArray = this.getParamValueByParamName("");
                if (valuesArray.length > 0) {
                    const activePageTab = valuesArray ? valuesArray[0]?.split('=')[1] as any : "";
                    if((activePageTab != null || activePageTab != undefined )  && activePageTab.toLowerCase() == "all"){
                        statusFilter.forEach((o: any) => {
                            if (o.name === "Reviewed" || o.name === "Needs Review") {
                                o.isChecked = true
                            }
                        })
                    }
                }
                else{
                    statusFilter.forEach((o: any) => {
                        if (o.name === "Needs Review") {
                            o.isChecked = true
                        }
                    })
                }
            }
        }
        statusFilterSelected = this.selectedStatus();
        this.setState({ statusFilter, statusFilterSelected });
    }

    handlePageTabs = (item: any) => {
        this.setState({isLoader : true});
        let { pageTabs } = this.state;
        pageTabs.forEach((o: any) => o.isActive = o.id === item.id);
        this.setState({ pageTabs }, () => {
            this.GetFlaggedTransactions();
        });
    }

    resetAllFilters = (isResetLoader : boolean = false) => {
        let { ehidList, defehidList, statusFilter, typeFilter } = this.state;
        ehidList.forEach(o => o.isChecked = false);
        defehidList.forEach(o => o.isChecked = false);
        statusFilter.forEach(o => o.isChecked = false);
        typeFilter.forEach(o => o.isChecked = false);
        this.setState({
            sortingOrder: "asc",
            sortingField: "defaultOrder",
            groupFilterSelected: "Groups",
            searchFilter: "",
            searchEhid: "",
            ehidList,
            defehidList,
            dataTypeFilterSelected: "Invoice",
            dateFilterSelected: "Date (New to Old)",
            savedStatus: [{ id: -1, name: '', class: '', isChecked: false }],
            savedType: [{ id: -1, name: '', class: '', isChecked: false }],
            resetflag: true,
            isLoader : true,
            typeFilter,
            typeFilterSelected: "Type",
            statusFilter,
            statusFilterSelected: "Status",

        }, () => {
            // this.setDefaultStatus();
            // this.setDefaultType();
            // this.GetFlaggedTransactions();
            if(isResetLoader){
                // this.getHotels();
                // this.GetGroupSubGroupDetails();
                this.GetFlaggedTransactions();
            }
            else{
                this.setDefaultStatus();
                this.GetFlaggedTransactions();
                this.setState({isLoader : false});
            }
        });
    }

    setDefaultStatus = () => {
        let { statusFilter } = this.state;
        statusFilter.forEach(o => o.isChecked = false);
        this.setStatusPerTab();
        this.setState({ statusFilter }, () => { this.setStatusFilter() });
    }

    setDefaultType = () => {
        let { typeFilter } = this.state;
        typeFilter.forEach(o => o.isChecked = false);
        this.setTypePerTab();
        this.setState({ typeFilter }, () => { this.setTypeFilter() });
    }

    saveAllFilters = () => {
        let { groupFilterSelected, ehidList, statusFilter, statusFilterSelected, dataTypeFilterSelected, dateFilterSelected, typeFilter, typeFilterSelected, } = this.state;
        let fieldValue: any = {
            group: groupFilterSelected,
            ehid: ehidList.filter(o => o.isChecked),
            status: statusFilter.filter(o => o.isChecked),
            statusText: statusFilterSelected,
            dataType: dataTypeFilterSelected,
            date: dateFilterSelected,
            type: typeFilter.filter(o => o.isChecked),
            typeText: typeFilterSelected,
        }
        const saveDefaultSetting: any[] = [];
        saveDefaultSetting.push({
            fieldName: "sort",
            fieldValue: JSON.stringify(fieldValue),
            pageName: "communicationInbox",
        });
        laborPerformance.saveDefaultViewProperties(saveDefaultSetting)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.success) {
                        toast.success(result?.result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                        this.getDefaultView(false)
                    }
                }
                resolve();
            })
            .catch((error) => {
                reject();
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ showLoader: false });
            });
    }

    getDefaultView(isLoadData: boolean = true) {
        this.setState({ isLoading: isLoadData });
        let { groupFilterSelected, ehidList, statusFilterSelected, savedStatus, dataTypeFilterSelected, dateFilterSelected, typeFilterSelected, savedType } = this.state;
        laborPerformance.getDefaultViewProperties("communicationInbox")
            .then(async (response: any | null) => {
                if (response?.length > 0) {
                    let savedSetting = JSON.parse(response[0].fieldValue);
                    groupFilterSelected = savedSetting.group;
                    statusFilterSelected = savedSetting.statusText;
                    dateFilterSelected = savedSetting.date;
                    typeFilterSelected = savedSetting.typeText;
                    if (savedSetting?.ehid.length > 0) {
                        ehidList.forEach(o => {
                            savedSetting?.ehid?.forEach(res => {
                                if (o.id === res.id) {
                                    o.isChecked = true;
                                }
                            })
                        })
                    }
                    // else {
                    //     ehidList.forEach(o => o.isChecked = isLoadData)
                    // }
                    savedStatus = [];
                    savedStatus = savedSetting.status;
                    savedType = [];
                    savedType = savedSetting.type;
                    this.setState({ groupFilterSelected, ehidList, savedStatus, statusFilterSelected, dataTypeFilterSelected, dateFilterSelected, savedType, typeFilterSelected, }, () => {
                        if (isLoadData) {
                            this.setStatusPerTab();
                            this.setTypePerTab();
                            this.GetFlaggedTransactions();
                        }
                    });
                } else {
                    this.resetAllFilters(false);
                }
                resolve();
            })
            .catch((error) => {
                reject();
                this.setState({isLoader : false});
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
            });
    }

    GetFlaggedTransactions = () => {
        this.setState({ isLoading: true });
        let ehidListString: any = [];
        this.state.defehidList.filter((o: any) => o.isChecked && ehidListString.push(o.id));
        let request: any = {};
        request.hid = ehidListString.toString() === "" ? "ALL" : ehidListString.toString();
        request.transType = this.state.dataTypeFilterSelected;
        request.flagged = (this.state.pageTabs.filter((o: any) => o.isActive)[0]?.value);
        registersevice.GetFlaggedTransactions(request).then(async (result: any | null) => {
            if (result.length > 0) {
                //let dEvent = this.state.dateFilterSelected === "Date (New to Old)" ? 1 : 2;
                // result.forEach((o: any) => {
                //     o["isRowToggle"] = false;
                //     o["TransactionComments"] = [];
                //     o["CommentsLoading"] = false;
                // });
                // result = _.orderBy(result, [(obj) => obj?.createdDate], [+dEvent === 1 ? 'desc' : 'asc']);
                this.setState({ AllcommData: [] }, () => {
                    this.setState({ AllcommData: result, commDataList: result ,isLoader : false}, () => {
                        
                        this.setStatusFilter();
                        this.setTypeFilter();
                        if (this.state.resetflag) {
                            this.sortTableData(result, this.state.sortingField);
                        }

                    });
                })
            } else {
                this.setState({ AllcommData: [], commDataList: [], isLoading: false, isBanner: false,isLoader : false });
            }
            resolve();
        }).catch((error) => {
            this.setState({ AllcommData: [], commDataList: [], isLoading: false, isBanner: false,isLoader : false })
            Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "commInbox" });
            reject();
        });
    }

    GetGroupSubGroupDetails = () => {
        let request: any = {};
        request.moduleName = "Accounting Service";
        request.isAll = 0;
        request.notIncludeEnterpriseAccounts = 0;
        registersevice.GetGroupSubGroupDetails(request)
            .then(async (result: any | null) => {
                this.setState({ baseData: result });
                this.buildDropDownlist(result);
                resolve();
            }).catch((error) => {
                Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "commInbox" });
                reject();
            });
    };

    buildDropDownlist = (data: any[]) => {
        if (data?.length === 0) {
            this.setState({ groupList: [], filterGroupList: [] });
            return false;
        }
        const groupedData = _.groupBy(data, "groupName");
        let groupList: any = [];
        Object.keys(groupedData).forEach((key) => {
            const childItems: any = groupedData[key];
            if (childItems?.length > 0) {
                const item: any = {};
                item.groupID = childItems[0].groupID;
                item.groupName = childItems[0].groupName;
                item.subGroups = childItems;
                groupList.push(item);
            }
        });
        const item: any = {};
        item.groupID = -1;
        item.groupName = "Select a Group";
        item.subGroups = [];
        const item2: any = {};
        item2.groupID = 0;
        item2.groupName = "All";
        item2.subGroups = [];
        groupList = [...[item, item2], ...groupList];
        this.setState({ groupList: groupList, filterGroupList: groupList, });
    };

    getHotels = () => {
        Hotel.UserHotelAccessForCommInbx(this.state.dataTypeFilterSelected).then(
            async (response: IHotelResponseDto[] | null) => {
                    if(response != null && response?.length > 0){                      
                    const hotels = [...(_.sortBy(response?.map((item) => ({ id: item.hotelID, name: item.hotelName, lattercode: item.lettercode, hotelType: item.hotelType })), "hotelType"))];
                    hotels.forEach((o: any) => o["isChecked"] = false);
                    this.setState({ ehidList: hotels, defehidList: hotels }, () => {
                        this.getDefaultView();
                    });
                }
                else{
                    this.setState({isLoader : false});
                }
                resolve();
            }).catch((error) => {
                Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "commInbox" });
                this.setState({isLoader : false});
                reject();
            });
    };

    getHotelsByGroup = (item) => {
        let { ehidList } = this.state;
        if (item.groupName.toString().toLowerCase() === "all") {
            ehidList.forEach(o => o.isChecked = true)
            this.setState({ ehidList, defehidList: ehidList }, () => {
                this.GetFlaggedTransactions();
            });
        } else {
            let request: any = {};
            request.rGroupID = item?.subGroupID;
            request.moduleName = "Accounting Service"
            request.isAll = 0;
            request.notIncludeEnterpriseAccounts = 0;
            request.showOnlyHotelAccounts = 0;
            registersevice.GetHotelIdsByGroupId(request).then(
                async (response: IHotelResponseDto[] | null) => {
                    if (response !== null) {
                        ehidList.forEach(o => {
                            response?.forEach(res => {
                                if (o.id === res.hotelID) {
                                    o.isChecked = true;
                                }
                            });
                        });
                        this.setState({ ehidList, defehidList: ehidList }, () => {
                            this.GetFlaggedTransactions();
                        });
                        resolve();
                    }
                }).catch((error) => {
                    Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "commInbox" });
                    reject();
                });
        }
    };

    setStatusFilterForAll = (data: any) => {
        let { statusFilter } = this.state;
        let { typeFilter } = this.state;
        let commDataList: any = [];
        if (statusFilter.some((st: any) => st.isChecked)) {
            data.forEach((o: any) => {
                statusFilter.forEach((st: any) => {
                    if (o.status === st.name && st.isChecked) {
                        commDataList.push(o);
                    }
                })
            });
        }
        else {
            commDataList = data;
        }
        return commDataList;
    }

    setTypeFilterForAll = (data: any) => {

        let { typeFilter } = this.state;
        let commDataList: any = [];

        if (typeFilter.some((st: any) => st.isChecked)) {
            data.forEach((o: any) => {
                typeFilter.forEach((st: any) => {
                    if (o.displayentrytype === st.name && st.isChecked) {
                        commDataList.push(o);
                    }
                })
            });
        }
        else {
            commDataList = data;
        }
        return commDataList;
    }
    handlesearchFilter = (e) => {
        let searchval = e.target.value;
        const filterTypeData = this.setTypeFilterForAll(this.state.AllcommData);
        const filterData = this.setStatusFilterForAll(filterTypeData);
        const updatefilterState = filterData.filter((o: any) => (
            // o?.label?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
            o?.invoiceno?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
            o?.acctName?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
            o?.transTotal?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
            o?.transTotal1?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
            o?.displayentrytype?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
            o?.vendor?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
            o?.status?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
            o?.lastUpdated?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
            o?.lettercode?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1
        ));
        this.setState({ commDataList: updatefilterState, searchFilter: searchval }, () => {
            setTimeout(() => {
                this.sortTableData(updatefilterState, this.state.sortingField);
                this.setState({ isLoading: false,isLoader:false });
            }, 100)
        });
        
    }

    handleDropSelect = (event: any, type: any) => {
        let { dataTypeFilter, AllcommData, dateFilter } = this.state;
        if (type === "ehid") {
            this.setState({ ehidFilterSelected: "" })
        } else if (type === "dataType") {
            let filterlist: any = dataTypeFilter.filter((o: any) => o.id === +event);
            this.setState({ dataTypeFilterSelected: filterlist.length > 0 ? filterlist[0].name : "Invoice" })
        } else if (type === "date") {
            let { commDataList } = this.state;
            let filterlist: any = dateFilter.filter((o: any) => o.id === +event);
            commDataList = _.orderBy(commDataList, [(obj) => obj?.createdDate], [+event === 1 ? 'desc' : 'asc'])
            AllcommData = _.orderBy(AllcommData, [(obj) => obj?.createdDate], [+event === 1 ? 'desc' : 'asc'])
            this.setState({ commDataList, AllcommData, dateFilterSelected: filterlist.length > 0 ? filterlist[0].name : "Date (New to Old)" })
        } else if (type === "page") {
            if (+event === 1) {
                this.saveAllFilters();
            } else if (+event === 2) {
                this.resetAllFilters(true);
            }
        }
    }

    itemCheckChange = (event: any, item: any, type: any) => {
        this.setState({ isLoader:true });
        if (type === "status") {
            let { statusFilter, statusFilterSelected } = this.state;
            statusFilter.forEach((o: any) => (o?.name?.toString()?.toLocaleLowerCase() === item.name?.toString()?.toLocaleLowerCase()) && (o.isChecked = event.target.checked))
            statusFilterSelected = this.selectedStatus();
            this.setState({ statusFilter, statusFilterSelected }, () => {
                this.setStatusFilter();
            });
        } else if (type === "ehid") {
            let { ehidList, defehidList } = this.state;
            ehidList.forEach((o: any) => (o.id === item.id) && (o.isChecked = event.target.checked));
            this.setState({ ehidList, defehidList }, () => {
                this.handleDropdownChange({ groupID: -1, groupName: "Select a Group", subGroups: [] }, false);
                this.GetFlaggedTransactions();
            });
        }
        else if (type === "type") {
            let { typeFilter, typeFilterSelected } = this.state;
            typeFilter.forEach((o: any) => (o?.name?.toString()?.toLocaleLowerCase() === item.name?.toString()?.toLocaleLowerCase()) && (o.isChecked = event.target.checked))
            typeFilterSelected = this.selectedType();
            this.setState({ typeFilter, typeFilterSelected }, () => {
                this.setTypeFilter();
            });
        }
    }

    handleSearchItem = (event: any, type: any) => {
        let searchval = event.target.value;
        if (type === "group") {
            const baseData = [...this.state.baseData];
            const updatefilterState = baseData.filter((item) => {
                return (
                    item?.groupName?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1 ||
                    item.subGroupName?.toString()?.toLowerCase().indexOf(searchval?.toString()?.toLowerCase()) > -1
                );
            });
            this.setState({ searchGroup: searchval }, () => {
                this.buildDropDownlist(updatefilterState);
            });
        } else if (type === "ehid") {
            const defehidList = [...this.state.defehidList];
            const updatefilterState = defehidList.filter((o: any) => {
                return (
                    o.name.toLowerCase().indexOf(searchval.toLowerCase()) > -1 ||
                    o.lattercode.toLowerCase().indexOf(searchval.toLowerCase()) > -1
                )
            });
            this.setState({ ehidList: updatefilterState, searchEhid: searchval });
        }
    }

    setStatusFilter = () => {
        let { commDataList, statusFilter } = this.state;
        commDataList = [];
        if (statusFilter.some((st: any) => st.isChecked)) {
            this.state.AllcommData.forEach((o: any) => {
                statusFilter.forEach((st: any) => {
                    if (o.status === st.name && st.isChecked) {
                        commDataList.push(o);
                    }
                });
            });
        } else {
            commDataList = this.state.AllcommData;
        }
        this.setState({ commDataList }, () => {
            let event = { target: { value: this.state.searchFilter } }
            this.handlesearchFilter(event);
        });
    }

    handleItemSelectAll(event: any, type: any) {
        this.setState({isLoader : true});
        if (type === "status") {
            let { statusFilter } = this.state;
            statusFilter.forEach((o: any) => o.isChecked = event.target.checked)
            this.setState({ statusFilter, statusFilterSelected: this.selectedStatus() }, () => {
                this.setStatusFilter();
            });
        } else if (type === "ehid") {
            let { ehidList, defehidList } = this.state;
            ehidList.forEach((item: any) => {
                item.isChecked = event.target.checked;
            });
            defehidList.forEach((o: any) => {
                ehidList.forEach((item: any) => {
                    if (o.id === item.id && item.checked) {
                        o.isChecked = true
                    };
                });
            });
            this.setState({ ehidList, defehidList }, () => {
                this.handleDropdownChange({ groupID: -1, groupName: "Select a Group", subGroups: [] }, false);
                this.GetFlaggedTransactions();
            })
        } else if (type === "type") {
            let { typeFilter } = this.state;
            typeFilter.forEach((o: any) => o.isChecked = event.target.checked)
            this.setState({ typeFilter, typeFilterSelected: this.selectedType() }, () => {
                this.setTypeFilter();
            });
        }
    }

    handleDropdownChange = (item: any, calledFromDidUpdate = false) => {
        const placeHolderName = +item?.groupID === -1 || +item?.groupID === 0 ? item?.groupName : item?.subGroupName;
        this.setState({ groupFilterSelected: placeHolderName === "Select a Group" ? "Groups" : placeHolderName }, () => {
            if (+item?.groupID === -1) {
                if (calledFromDidUpdate) {
                    this.resetSerchBox();
                    this.GetFlaggedTransactions();
                }
            } else {
                this.resetSerchBox();
                this.getHotelsByGroup(item);
            }
        });
    };

    resetSerchBox = () => {
        let { ehidList } = this.state;
        ehidList.forEach(o => o.isChecked = false);
        this.setState({ searchEhid: "", ehidList, defehidList: ehidList });
        const baseData = [...this.state.baseData];
        this.buildDropDownlist(baseData);
    };

    hideSlideOut(isSave) {
        if (isSave) {
            this.setState({ isSlideOut: false, isTransationSlideOut: false, isCreditCardSlideOut: false }, () => {
                this.resetSlideoutData();
                this.GetFlaggedTransactions();
            });
        } else {
            this.setState({ isSlideOut: false, isTransationSlideOut: false, isCreditCardSlideOut: false }, () => {
                this.resetSlideoutData();
            });
        }
    }

    rowEvents = {

        onClick: (e: any, row: any, rowIndex: any) => {
            //  if (row.ckno.toString().toLowerCase() !== "epay") {
            this.handleSlideout(e, row, rowIndex, "main");
            //}
        },
    };

    //handleSlideout = (item: any, rowType: any) => {
    handleSlideout = (e: any, item: any, rowIndex: any, rowType) => {
        let etype: any = '';
        item.etype = item.entrytype;
        etype = this.getEtype(item.etype, "main");

        const isWithdrawlOrDeposit = etype?.toLowerCase() == "deposit" || etype.toLowerCase() == "withdrawal" || etype.toLowerCase() == "drafttransaction";
        const IsCreditCardTransaction = item.transactionType.toLowerCase() == "creditcarddeposit" ||
            item.transactionType.toLowerCase() == "creditcardwithdrawal" || item.entrytype.toLowerCase() == "cc - debit" || item.entrytype.toLowerCase() == "cc - credit";

        if (isWithdrawlOrDeposit || IsCreditCardTransaction) {

            if (IsCreditCardTransaction) {
                if (item.transactionType.toLowerCase() == "creditcarddeposit" || item.entrytype.toLowerCase() == "cc - credit") {
                    etype = "CCCREDIT"
                }
                else if (item.transactionType.toLowerCase() == "creditcardwithdrawal" || item.entrytype.toLowerCase() == "cc - debit") {
                    etype = "CCDEBIT"
                }


            }

            let slideoutData: any = {};
            slideoutData.pageType = etype;
            slideoutData.accDetails = {
                acctType: item.accType,
                oprID: item.oprID,
                accountName: "",
                description: "",
                transDate: item.transDate,
                totalAmount: item.amount,
                isNewTransBankSync: ""
            };
            slideoutData.vid = item.vid;
            slideoutData.hidValue = item.backhid;
            slideoutData.hotelName = item.lettercode;
            slideoutData.uniqueNo = item.uniqueno;
            slideoutData.selectedRow = {
                pageType: etype,
                hid: item.hotelid,
                groupID: item.groupID,
                oprId: item.oprID,
                checkno: item.ckno,
                invPostFix: "",
                series: item.series ? item.series : "",
                isDraftUpdate: item.transactionType.toLowerCase() == "drafttransaction" ? "Yes" : "",
            };
            this.setState({
                pageType: etype,
                slideoutData,
                isSlideOut: false,
                isTransationSlideOut: IsCreditCardTransaction ? false : true,
                isCreditCardSlideOut: IsCreditCardTransaction ? true : false,
                isForComments: rowType === "Comments" ? true : false,
                callFrom: item.transactionType.toLowerCase() == "drafttransaction" ? "Draft" : "",
            })

        }
        else {
            this.setState({
                hidValue: (rowType === "child" ? item.hotelid : item.backhid),
                hotelName: item.lettercode,
                uniqueNo: item.uniqueno,
                pageType: (rowType === "child" ? "Invoice" : item.transactionType.toUpperCase() === "INCOMPLETEINVOICE" ? "INCOMPLETEINVOICE" : item.transactionType),
                uploadedDate: new Date(item.createdDate).toLocaleDateString(),
                uploadedBy: "",
                isApproved: (rowType === "child" ? this.state.isApproved : item.approved?.toLowerCase() === "app"),
                isSlideOut: true,
                isForComments: rowType === "Comments" ? true : false
            })
        }

    }

    resetSlideoutData = () => {
        this.setState({
            hidValue: "",
            hotelName: "",
            uniqueNo: "",
            pageType: "",
            uploadedDate: "",
            uploadedBy: "",
            isApproved: "",
            isForComments: false
        })
    }

    getEtype(mtype, rowType) {
        let etype = "";
        etype = registersevice.getEtype(mtype, rowType);
        return etype;
    }

    OpenInvoiceSlideOut = (e, row, rowIndex, rowType) => {
        if (rowType === "main") {
            let slideoutData: any = {};
            let etype = '';
            row.etype = row.entrytype;
            etype = this.getEtype(row.etype, "main");
            slideoutData.pageType = etype;
            slideoutData.accDetails = {
                acctType: etype,
                oprID: row.oprID,
                accountName: "",
                description: row.description,
                transDate: row.transDate,
                totalAmount: row.amount,
                isNewTransBankSync: ""
            };
            slideoutData.hidValue = this.state.hidValue;
            slideoutData.hotelName = this.state.hotelName;
            slideoutData.uniqueNo = row.uniqueno;
            slideoutData.selectedRow = {
                pageType: etype,
                hid: row.hotelid,
                groupID: row.groupID,
                oprId: row.oprID,
                checkno: row.ckno,
                invPostFix: "",
                series: row.series ? row.series : "",
            };
            this.setState({
                pageType: etype,
                slideoutData,
                isSlideOut: false,
                isTransationSlideOut: true
            })
        } else {
            //this.handleSlideout(row, rowType);
            this.handleSlideout(e, row, rowIndex, rowType);
        }
    };

    handleDDToggle = (type: any, value: any = "") => {
        let event = { target: { value: value } }
        this.handleSearchItem(event, type);
    }

    transationSlideoutSave = (saveReq, saveRes) => {
        const { entryType } = this.state;
        if (entryType === "edit") {
            this.setState({ entryType: "" });
            return;
        }
        let requestArray: any = [];
        let request: any = {};
        const { hidValue, accountNameValue, tenantID, AddTransationSyncID } = this.state;
        request.SyncId = AddTransationSyncID;
        request.HotelId = hidValue;
        request.Tenantid = tenantID;
        request.Uniqueno = saveRes.uniqueno;
        request.Oprid = accountNameValue;
        request.PageType = saveReq.pageType;
        request.UpdateTabale = "";
        requestArray.push(request);
        this.setState({ addTranReq: requestArray });
        bankSyncServices
            .SavePlaidTranSyncMatchingDetailsStatus(requestArray)
            .then((response) => {
                this.setState({ reconcileAfterSave: true }, () => {
                });
            })
            .catch((error) => {
                Utils.toastError(error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            });
    };

    handleRowToggle = (row: any, isToggle: boolean) => {
        let { AllcommData, commDataList } = this.state;
        commDataList.forEach((o: any) => o.uniqueno === row.uniqueno && (o.isRowToggle = !isToggle, o.CommentsLoading = true));
        AllcommData.forEach((o: any) => o.uniqueno === row.uniqueno && (o.isRowToggle = !isToggle));
        this.setState({ AllcommData, commDataList }, () => {
            isToggle = isToggle ? false : true;
            this.GetTransactionComments(row, isToggle)
        });
    }

    resetCommentForUniqueNo = (row: any) => {
        let { AllcommData, commDataList } = this.state;
        commDataList.forEach((o: any) => o.uniqueno === row.uniqueno && (o.TransactionComments = [], o.CommentsLoading = false));
        AllcommData.forEach((o: any) => o.uniqueno === row.uniqueno && (o.TransactionComments = []));
        this.setState({ AllcommData, commDataList });
    }

    GetTransactionComments = (row: any, isToggle: boolean) => {
        let { AllcommData, commDataList } = this.state;
        if (isToggle) {
            let request: any = {};
            request.uniqueno = row.uniqueno;
            request.transType = row.transactionType;
            registersevice.GetTransactionComments(request).then(async (result: any | null) => {
                if (result.length > 0) {
                    let mostRecent = _.maxBy(result, "id");
                    result.forEach((o: any) => o.isMostRecent = false)
                    result.forEach((o: any) => o.id === mostRecent.id && (o.isMostRecent = true))
                    commDataList.forEach((o: any) => o.uniqueno === row.uniqueno && (o.TransactionComments = result, o.CommentsLoading = false));
                    AllcommData.forEach((o: any) => o.uniqueno === row.uniqueno && (o.TransactionComments = result, o.CommentsLoading = false));
                    this.setState({ AllcommData, commDataList });
                } else {
                    this.resetCommentForUniqueNo(row);
                }
            }).catch((error) => {
                Utils.toastError(error, { position: toast.POSITION.BOTTOM_RIGHT });
                this.resetCommentForUniqueNo(row);
            });
        } else {
            this.resetCommentForUniqueNo(row);
        }
    }
    sortImage = (order) => {
        if (!order) return (
            <span className="ascending">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
                </svg>
            </span>
        );
        else if (order === 'asc') return (
            <span className="ascending">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
                </svg>
            </span>
        );
        else if (order === 'desc') return (
            <span className="descending">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
                </svg>
            </span>
        );
        return null;
    }

    handleSortByColumn = (field) => {
        this.setState({
            sortingField: field,
            sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
        }, () => {
            this.sortTableData(this.state.commDataList, this.state.sortingField);
        });
    }
    sortTableData = (data: any, fieldName) => {
        if (fieldName === "status") {
            if (this.state.sortingOrder === "asc") {
                data = _.orderBy(data, ['orderNo', "statusNumber"], ['asc', 'asc', 'asc', 'asc', 'asc'])
            } else if (this.state.sortingOrder === "desc") {
                data = _.orderBy(data, ['orderNo', "statusNumber"], ['asc', 'desc', 'desc', 'desc', 'desc'])
            }
        }
        else {
            if (this.state.sortingOrder === "asc") {
                data = _.orderBy(data, ['orderNo', fieldName], ['asc', 'asc', 'asc', 'asc', 'asc'])
            } else if (this.state.sortingOrder === "desc") {
                data = _.orderBy(data, ['orderNo', fieldName], ['asc', 'desc', 'desc', 'desc', 'desc'])
            }
        }



        this.setState({ commDataList: data,resetflag:false });
    }

    columnHeaderFormatter = (column, colIndex) => {
        let class1 = (this.state.sortingField === column.dataField ? 'active' : '')
        return (
            <>
                <div className="d-flex">
                    <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`}
                        onClick={() => this.handleSortByColumn(column.dataField)}>
                        {column.text}{this.sortImage(this.state.sortingOrder)}
                    </div>
                </div>
                <div className='border-btm'></div>
            </>
            
        )
    }

    selectedType = () => {
        let { typeFilter } = this.state;
        let checkedLen = typeFilter.filter((o: any) => o.isChecked);
        let isTypeSelected = (checkedLen.length > 1 ? `${checkedLen.length} Types Selected` : (checkedLen.length === 1 ? checkedLen[0].name : "Type"));
        return isTypeSelected;
    }

    setTypeFilter = () => {
        let { commDataList, typeFilter } = this.state;
        commDataList = [];
        if (typeFilter.some((st: any) => st.isChecked)) {
            this.state.AllcommData.forEach((o: any) => {
                typeFilter.forEach((st: any) => {
                    if (o.displayentrytype?.toLowerCase() === st.name?.toLowerCase() && st.isChecked) {
                        commDataList.push(o);
                    }
                });
            });
        } else {
            commDataList = this.state.AllcommData;
        }
        this.setState({ commDataList }, () => {
            let event = { target: { value: this.state.searchFilter } }
            this.handlesearchFilter(event);
        });
    }

    setTypePerTab = () => {
        let { typeFilter, typeFilterSelected, isActiveFlaggedbyMe } = this.state;
        typeFilter.forEach(o => o.isChecked = false);
        if (this.state.savedType.length > 0 && this.state.savedType.some((o: any) => o.id !== -1)) {
            typeFilter.forEach(o => o.isChecked = false);
            typeFilter.forEach(o => {
                this.state.savedType?.forEach(res => {
                    if (o?.name?.toString()?.toLowerCase() === res?.name?.toString()?.toLowerCase() && res.isChecked) {
                        o.isChecked = true;
                    }
                })
            })
        } else if (this.state.savedType.length === 1 && this.state.savedType[0].id === -1) {

            typeFilter.forEach((o: any) => {
                if (o.name === "Needs Review") {
                    o.isChecked = true
                }
            })

        }
        typeFilterSelected = this.selectedType();
        this.setState({ typeFilter, typeFilterSelected });
    }


    render() {
        const typeCheckLabel = (item: any, typeClass: any) => (<span>{item.name}</span>);
        const statusCheckLabel = (item: any, statusClass: any) => (<strong className={`${statusClass} tag`}>{item.name}</strong>);
        const ehidCheckLabel = (item: any) => (<><strong>{(item.name).split(" - ")[0]}</strong><span className="mr-1 ml-1">-</span><EllipsisWithTooltip placement="bottom">{(item.name).split(" - ")[1]}</EllipsisWithTooltip></>);
        let totalChecked: any = this.state.defehidList.filter(o => o.isChecked);
        let selectedAcc = (totalChecked?.length === 1 ? totalChecked[0]?.lattercode : totalChecked.length + " Accounts Selected");
        let isEHIDPlaceholder = (totalChecked?.length > 0 ? selectedAcc : "EHID");
        const isStatusClass = (status: any) => {
            let statusClass = "";
            if (status.toString().toLowerCase() === "needs review") {
                statusClass = "orange";
            } else if (status.toString().toLowerCase() === "reviewed") {
                statusClass = "blue";
            } else if (status.toString().toLowerCase() === "review complete") {
                statusClass = "green";
            } else if (status.toString().toLowerCase() === "flag for review") {
                statusClass = "light";
            } else if (status.toString().toLowerCase() === "complete review") {
                statusClass = "light";
            }
            return statusClass;
        }
        //const data = [{Type: 'Invoice H-352-37263', Status:'Needs Review', Vendor: 'Joe’s Plumbing', Account: 'CMC-OPR-8732', Amount: '$145.90', Ehid: 'DTMT', LastUpdate: '1 Hour Ago'}]
        const columns = [{
            dataField: 'displayentrytype',
            text: 'Type',
            headerClasses: "regColDate",
            headerFormatter: this.columnHeaderFormatter,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div className='txt-bold'><EllipsisWithTooltip placement="top">{row.displayentrytype + ' ' + row.invoiceno}</EllipsisWithTooltip></div>
                );
            }
        }, {
            dataField: 'status',
            text: 'Status',
            headerClasses: "regColDate",
            headerFormatter: this.columnHeaderFormatter,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (

                    <div className={`tag ${isStatusClass(row.status)}`}>
                        <EllipsisWithTooltip placement="top">{row.status}</EllipsisWithTooltip></div>
                );
            }
        }, {
            dataField: 'vendor',
            text: 'Vendor',
            headerClasses: "regColDate",
            headerFormatter: this.columnHeaderFormatter,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div><EllipsisWithTooltip placement="top">{row.vendor}</EllipsisWithTooltip></div>
                );
            }
        }, {
            dataField: 'acctName',
            text: 'Account',
            headerClasses: "regColDate",
            headerFormatter: this.columnHeaderFormatter,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div><EllipsisWithTooltip placement="top">{row.acctName}</EllipsisWithTooltip></div>
                );
            }
        }, {
            dataField: 'transTotal1',
            text: 'Amount',
            headerClasses: "regColDate",
            headerFormatter: this.columnHeaderFormatter,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div><EllipsisWithTooltip placement="top">{row.transTotal}</EllipsisWithTooltip></div>
                );
            }
        }, {
            dataField: 'lettercode',
            text: 'EHID',
            headerClasses: "regColDate",
            headerFormatter: this.columnHeaderFormatter,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div><EllipsisWithTooltip placement="top">{row.lettercode}</EllipsisWithTooltip></div>
                );
            }
        }, {
            dataField: 'defaultOrder',
            text: 'Last Update',
            headerClasses: "regColDate",
            headerFormatter: this.columnHeaderFormatter,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div><EllipsisWithTooltip placement="top">{row.lastUpdated}</EllipsisWithTooltip></div>
                );
            }
        }];


        return (
            <div className='communication-inbox'>
                {this.state.isTransationSlideOut && (
                    <TransactionSlideout
                        mainHidValue={this.state.hidValue}
                        pageType={Utils.CapitalizeFirstLetter(this.state.pageType)}
                        {...this.state.slideoutData}
                        hideSlideOut={this.hideSlideOut.bind(this)}
                        OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                        transationSlideoutSave={this.transationSlideoutSave.bind(this)}
                        callFrom={this.state.callFrom === "" ? "BankSync" : this.state.callFrom} //"BankSync"
                        loadFrom={this.state.isForComments ? this.state.pageType + "Comments" : ""}
                    />
                )}

                {this.state.isCreditCardSlideOut && (
                    <CreditCardSlideout
                        mainHidValue={this.state.hidValue}
                        pageType={this.state.pageType}
                        {...this.state.slideoutData}
                        hideCCSlideOut={this.hideSlideOut.bind(this)}
                        loadFrom={this.state.isForComments ? "Comments" : ""}
                        slideOutFrom="register-page-creditcard-slideout"
                        callFrom={this.state.callFrom}
                    />
                )}
                {this.state.isSlideOut && (
                    <InvoiceEntrySlideout
                        mainHidValue={this.state.hidValue}
                        hidValue={this.state.hidValue}
                        hotelName={this.state.hotelName}
                        uniqueNo={this.state.uniqueNo}
                        pageType={Utils.CapitalizeFirstLetter(this.state.pageType)}
                        hideSlideOut={this.hideSlideOut.bind(this)}
                        OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                        uploadedDate={this.state.uploadedDate}
                        uploadedBy={this.state.uploadedBy}
                        loadFrom={this.state.isForComments ? "InvoiceComments" : "PaymentPage"}
                        saveAction={this.state.isApproved ? "Payment" : "WaitingOnMe"}
                    />
                )}
                <ToastContainer autoClose={3000} containerId={"commInbox"} enableMultiContainer />
                <Container fluid className="body-sec">
                    <div className="page-heading d-flex">
                        <div className="mr-auto d-flex align-items-center">Communications Inbox</div>
                    </div>
                    <div>
                        <Tabs defaultActiveKey="flaggedItems" id="communicationList">
                            <Tab eventKey="flaggedItems" title={<div className="d-flex"><div>Flagged Items</div>
                                {/* <div className="count">{2}</div> */}
                            </div>}>
                                <div className='communication-bdy'>
                                    <div className='filter-area d-flex justify-content-center'>
                                        <div className="mr-auto d-flex">
                                            <Form.Control
                                                type="text"
                                                placeholder="Search..."
                                                className="comm-search-input"
                                                value={this.state.searchFilter}
                                                autoComplete="off"
                                                data-lpignore="true"
                                                onChange={(event: any) => this.handlesearchFilter(event)}
                                            />
                                            <div className='sec'>
                                                <Dropdown onToggle={() => this.handleDDToggle("group")} className="drop-down-select group-selector">
                                                    <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                                        <div className='d-flex'>
                                                            <div className='selected-val'><EllipsisWithTooltip placement="bottom">{this.state.groupFilterSelected}</EllipsisWithTooltip></div>
                                                            <div className='arrow ml-auto'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="SearchControl commSearchControl">
                                                            <Form.Control
                                                                type="text"
                                                                className="comm-search-input"
                                                                onChange={(e: any) => this.handleSearchItem(e, 'group')}
                                                                placeholder="Filter Group"
                                                                value={this.state.searchGroup}
                                                            />
                                                        </div>
                                                        <div className="list-area">
                                                            {this.state.groupList?.length === 0 && this.state.searchGroup && (
                                                                <div className="footer small p-2 pl-3">
                                                                    <span>No data found</span>
                                                                </div>
                                                            )}
                                                            {(this.state.groupList || []).map((item: any, idx: any) => (
                                                                <div className="heroGroup" key={item.groupID}>
                                                                    {item.subGroups?.length > 0 ? (
                                                                        <div className="dropdown-header divider">{item.groupName}</div>
                                                                    ) : (
                                                                        <Dropdown.Item className="border-0 d-flex"
                                                                            onClick={() => this.handleDropdownChange(item, true)}
                                                                        >
                                                                            <div className="subgroup-name"><EllipsisWithTooltip placement="bottom">{item.groupName}</EllipsisWithTooltip></div>

                                                                            <div className={`tick-mark ${this.state.groupFilterSelected === item.groupName ? "active" : ""}`}>
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                                                                                </svg>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    )}
                                                                    {item.subGroups?.length > 0 && (
                                                                        <div>{(item.subGroups || []).map((subGroup: any, idxx: any) => (
                                                                            <Dropdown.Item className="border-0 d-flex" key={idxx}
                                                                                onClick={() => this.handleDropdownChange(subGroup, true)}
                                                                            >
                                                                                <div className="subgroup-name"><EllipsisWithTooltip placement="bottom">{subGroup.subGroupName}{" "}</EllipsisWithTooltip></div>

                                                                                <div className="count ml-auto text-right">{subGroup?.noOfHotels}</div>

                                                                                <div className={`tick-mark ${this.state.groupFilterSelected === subGroup.subGroupName ? "active" : ""}`}>
                                                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                                                                                    </svg>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                        ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className='sec cusProcessDropdownWithSearch'>
                                                <Dropdown
                                                    onToggle={() => this.handleDDToggle("ehid")}
                                                    className="drop-down-select ehid-selector" onSelect={(event: any) => this.handleDropSelect(event, "ehid")}>
                                                    <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                                        <div className='d-flex'>
                                                            <div className='selected-val'><EllipsisWithTooltip placement="bottom">{isEHIDPlaceholder}</EllipsisWithTooltip></div>
                                                            <div className='arrow ml-auto'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{ "width": "250px" }}>
                                                        <div className="SearchControl commSearchControl">
                                                            <Form.Control type="text"
                                                                className="comm-search-input"
                                                                name="searchItems"
                                                                id="searchItemsFrom"
                                                                value={this.state.searchEhid}
                                                                autoComplete="off"
                                                                onChange={(e: any) => this.handleSearchItem(e, 'ehid')}
                                                                placeholder="Search..."
                                                            />
                                                        </div>
                                                        {this.state.ehidList?.length === 0 && this.state.searchEhid && (
                                                            <div className="footer small p-2 pl-3">
                                                                <span>No data found</span>
                                                            </div>
                                                        )}
                                                        <div className="ListItems ListWithMultiSelect">
                                                            {(this.state.ehidList || []).map((item: any, idx: any) => (
                                                                <>
                                                                    {(idx > 0 && item?.hotelType !== this.state.ehidList[idx - 1]?.hotelType) && (<Dropdown.Divider className="m-0" />)}
                                                                    <div className="dropdownItems border-0" key={item.id}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={`checkId_${item.id}`}
                                                                            name={item.name}
                                                                            label={ehidCheckLabel(item)}
                                                                            value={item.id}
                                                                            onChange={(event) => this.itemCheckChange(event, item, "ehid")}
                                                                            checked={item.isChecked}
                                                                        />
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                        {this.state.ehidList.length > 0 && (
                                                            <div className="selectClearAll isHideCheck dropdownItems">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`itemSelectAllEhid`}
                                                                    label={this.state.ehidList.some(o => o.isChecked === false) ? "Select All" : "Clear All"}
                                                                    value={this.state.ehidList.some(o => o.isChecked === false) ? "selectAll" : "clearAll"}
                                                                    onChange={(event) => this.handleItemSelectAll(event, "ehid")}
                                                                    checked={this.state.ehidList.some(o => o.isChecked === false) ? false : true}
                                                                />
                                                            </div>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className='sec cusProcessDropdownWithSearch'>
                                                <Dropdown className="drop-down-select status-selector">
                                                    <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                                        <div className='d-flex align-items-center'>
                                                            <div className='selected-val'><EllipsisWithTooltip placement="bottom">
                                                                {this.state.statusFilterSelected}
                                                            </EllipsisWithTooltip></div>
                                                            <div className='arrow ml-auto'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                </svg>
                                                            </div>
                                                        </div>

                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="ListItems ListWithMultiSelect">
                                                            {this.state.statusFilter.map((item: any, idx: any) => {
                                                                let statusClass = isStatusClass(item?.name);
                                                                return (
                                                                    <div className="dropdownItems border-0" key={item.id}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={`checkId_${item.id}`}
                                                                            name={item.name}
                                                                            label={statusCheckLabel(item, statusClass)}
                                                                            value={item.id}
                                                                            onChange={(event) => this.itemCheckChange(event, item, "status")}
                                                                            checked={item.isChecked}
                                                                        />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        {this.state.statusFilter.length > 0 && (
                                                            <div className="selectClearAll isHideCheck dropdownItems">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`itemSelectAllStatus`}
                                                                    label={this.state.statusFilter.some(o => o.isChecked === false) ? "Select All" : "Clear All"}
                                                                    value={this.state.statusFilter.some(o => o.isChecked === false) ? "selectAll" : "clearAll"}
                                                                    onChange={(event) => this.handleItemSelectAll(event, "status")}
                                                                    checked={this.state.statusFilter.some(o => o.isChecked === false) ? false : true}
                                                                />
                                                            </div>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                            <div className='sec cusProcessDropdownWithSearch'>
                                                <Dropdown className="drop-down-select status-selector">
                                                    <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                                        <div className='d-flex align-items-center'>
                                                            <div className='selected-val'><EllipsisWithTooltip placement="bottom">
                                                                {this.state.typeFilterSelected}
                                                            </EllipsisWithTooltip></div>
                                                            <div className='arrow ml-auto'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                </svg>
                                                            </div>
                                                        </div>

                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="ListItems ListWithMultiSelect">
                                                            {this.state.typeFilter.map((item: any, idx: any) => {
                                                                let typeClass = isStatusClass(item?.name);
                                                                return (
                                                                    <div className="dropdownItems border-0" key={item.id}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={`checkId_${item.id}`}
                                                                            name={item.name}
                                                                            label={typeCheckLabel(item, typeClass)}
                                                                            value={item.id}
                                                                            onChange={(event) => this.itemCheckChange(event, item, "type")}
                                                                            checked={item.isChecked}
                                                                        />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        {this.state.typeFilter.length > 0 && (
                                                            <div className="selectClearAll isHideCheck dropdownItems">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`itemSelectAllType`}
                                                                    label={this.state.typeFilter.some(o => o.isChecked === false) ? "Select All" : "Clear All"}
                                                                    value={this.state.typeFilter.some(o => o.isChecked === false) ? "selectAll" : "clearAll"}
                                                                    onChange={(event) => this.handleItemSelectAll(event, "type")}
                                                                    checked={this.state.typeFilter.some(o => o.isChecked === false) ? false : true}
                                                                />
                                                            </div>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                            <div className='sec'>
                                                <Dropdown className="d-none drop-down-select" onSelect={(event: any) => this.handleDropSelect(event, "dataType")}>
                                                    <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                                        <div className='d-flex'>
                                                            <div className='selected-val'><EllipsisWithTooltip placement="bottom">{this.state.dataTypeFilterSelected}</EllipsisWithTooltip></div>
                                                            <div className='arrow ml-auto'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                </svg>
                                                            </div>
                                                        </div>

                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {this.state.dataTypeFilter.map((item: any, idx: any) => (
                                                            <Dropdown.Item eventKey={item.id} key={item.id}>
                                                                <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                                                            </Dropdown.Item>
                                                        ))}

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="ml-auto d-flex">
                                            {false && (<div className='sec'>
                                                <Dropdown className="drop-down-select date-filter" onSelect={(event: any) => this.handleDropSelect(event, "date")}>
                                                    <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                                        <div className='d-flex'>
                                                            <div className='icn'>
                                                                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.10867 10.0843L2.89676 10.2967V1.42432C2.89676 1.16559 2.75872 0.926588 2.5347 0.797199C2.31068 0.667861 2.0346 0.667861 1.81058 0.797199C1.58655 0.926588 1.44851 1.16559 1.44851 1.42432V10.2967L1.23661 10.0843C1.05361 9.90133 0.786891 9.82987 0.536917 9.89686C0.286943 9.96384 0.0916808 10.1591 0.024701 10.4091C-0.0422801 10.6591 0.0291765 10.9258 0.212162 11.1088L1.66041 12.557C1.79623 12.6929 1.98048 12.7693 2.17262 12.7693C2.36477 12.7693 2.54901 12.6929 2.68484 12.557L4.13309 11.1088C4.31608 10.9258 4.38754 10.6591 4.32055 10.4091C4.25357 10.1591 4.05831 9.96384 3.80833 9.89686C3.55836 9.82988 3.29164 9.90134 3.10864 10.0843H3.10867Z" fill="#1D1E20" />
                                                                    <path d="M13.276 1.18311H5.55201C5.29328 1.18311 5.05427 1.32115 4.92488 1.54517C4.79555 1.76919 4.79555 2.04527 4.92488 2.26929C5.05427 2.49331 5.29328 2.63135 5.55201 2.63135H13.276C13.5347 2.63135 13.7737 2.49331 13.9031 2.26929C14.0324 2.04527 14.0324 1.76919 13.9031 1.54517C13.7737 1.32115 13.5347 1.18311 13.276 1.18311Z" fill="#1D1E20" />
                                                                    <path d="M11.345 4.56201H5.55201C5.29328 4.56201 5.05427 4.70005 4.92488 4.92407C4.79555 5.14809 4.79555 5.42418 4.92488 5.6482C5.05427 5.87222 5.29328 6.01026 5.55201 6.01026H11.345C11.6037 6.01026 11.8427 5.87222 11.9721 5.6482C12.1015 5.42418 12.1015 5.14809 11.9721 4.92407C11.8427 4.70005 11.6037 4.56201 11.345 4.56201Z" fill="#1D1E20" />
                                                                    <path d="M9.414 7.94141H5.55201C5.29328 7.94141 5.05427 8.07945 4.92488 8.30347C4.79555 8.52749 4.79555 8.80357 4.92488 9.02759C5.05427 9.25161 5.29328 9.38965 5.55201 9.38965H9.414C9.67273 9.38965 9.91173 9.25161 10.0411 9.02759C10.1705 8.80357 10.1705 8.52749 10.0411 8.30347C9.91173 8.07945 9.67273 7.94141 9.414 7.94141Z" fill="#1D1E20" />
                                                                    <path d="M7.483 11.3208H5.55201C5.29328 11.3208 5.05427 11.4588 4.92488 11.6829C4.79555 11.9069 4.79555 12.183 4.92488 12.407C5.05427 12.631 5.29328 12.769 5.55201 12.769H7.483C7.74173 12.769 7.98074 12.631 8.11013 12.407C8.23946 12.183 8.23946 11.9069 8.11013 11.6829C7.98074 11.4588 7.74173 11.3208 7.483 11.3208Z" fill="#1D1E20" />
                                                                </svg>
                                                            </div>
                                                            <div className='selected-val'><EllipsisWithTooltip placement="bottom">{this.state.dateFilterSelected}</EllipsisWithTooltip></div>
                                                            <div className='arrow'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                </svg>
                                                            </div>
                                                        </div>

                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {this.state.dateFilter.map((item: any, idx: any) => (
                                                            <Dropdown.Item className="d-flex" eventKey={item.id} key={item.id}>
                                                                <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                                                                {this.state.dateFilterSelected === item.name && (
                                                                    <div className="ml-auto">
                                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                                                                        </svg>
                                                                    </div>
                                                                )}
                                                            </Dropdown.Item>
                                                        ))}

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            )}
                                            <div className='sec'>
                                                <Dropdown className="drop-down-select setting" alignRight onSelect={(event: any) => this.handleDropSelect(event, "page")}>
                                                    <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                                        <div className='d-flex'>
                                                            <div className='selected-val'>
                                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="#1d1e20" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z"></path>
                                                                </svg>
                                                            </div>
                                                            <div className='arrow'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                </svg>
                                                            </div>
                                                        </div>

                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {this.state.pageSetting.map((item: any, idx: any) => (
                                                            <Dropdown.Item eventKey={item.id} key={item.id}>
                                                                <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                                                            </Dropdown.Item>
                                                        ))}

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='communication-list-sec list-sec d-flex align-items-stretch'>
                                        <div className='lft-sec'>
                                            <ul>
                                                {this.state.pageTabs.map((item: any, idx: any) => {
                                                    let isActive: any = (item.isActive ? "active" : "");
                                                    return (
                                                        <>
                                                            {!item.isHidden && (
                                                                <li role="button" onClick={() => this.handlePageTabs(item)} key={item.id} className={`d-flex ${isActive}`}>
                                                                    <div>{item.name}</div>
                                                                    {/* <div className='count ml-auto'>3</div> */}
                                                                </li>
                                                            )}
                                                        </>

                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className='right-sec'>
                                            <BootstrapTable
                                                keyField="id"
                                                data={this.state.commDataList}
                                                columns={columns}
                                                rowEvents={this.rowEvents}
                                                noDataIndication={'No record found.'}
                                            />
                                            {this.state.isLoader && (
                                                <div className="loader-spinner reconciliation-summary d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="success" />
                                                </div>
                                            )}
                                            {/* <div className='no-data d-flex justify-content-center align-items-center'>
                                                <div className='txt-center'>
                                                    <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="68" cy="68" r="68" fill="#DEFFF8" />
                                                        <circle cx="68" cy="68" r="50" fill="#B6FFEF" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M94.6217 41.0153L55.2307 79.1687L44.7778 67.9601C42.8523 66.138 39.8264 66.0276 37.6258 67.5736C35.4802 69.1748 34.8751 71.9908 36.1954 74.2546L48.5739 94.4632C49.7842 96.3405 51.8748 97.5 54.2405 97.5C56.4961 97.5 58.6417 96.3405 59.852 94.4632C61.8326 91.8681 99.6281 46.6472 99.6281 46.6472C104.58 41.5674 98.5829 37.095 94.6217 40.9601V41.0153Z" fill="#0E9074" />
                                                    </svg>
                                                    <div className='label'>Nothing to Review Yet</div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Container >
            </div>
        );
    }
}