import * as React from "react";
import { Container, Tab, Dropdown, Nav, Button, Form } from "react-bootstrap";
import { HidSelector } from "../../../Common/Components/HidSelector";
import "./payments.scss";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import { Register as registersevice } from "../../../Common/Services/Register";
import _ from "lodash";
import { PaymentsTable } from "./PaymentTable";
import { ProcessingTable } from "./ProcessingTable";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import { RoleSubPermissionSetting, IPageSubPermissionRole, } from "../../../Common/Services/RoleSubPermissionSetting";
import { Hotel } from "../../../Common/Services/Hotel";
import invoice_inv from "../../../Common/Assets/Images/invoice_inv.png";
import caught from "../../../Common/Assets/Images/icons/all-caught.png";
import DropdownItem from "react-bootstrap/DropdownItem";
import moment from "moment";
import { Utils } from "../../../Common/Utilis";
import { CSVLink } from "react-csv";

import { IHotelResponseWithDataDto } from "../../../Common/Contracts/IHotel";
import { FiChevronDown } from "react-icons/fi";
import { HIDWithData } from "../../../Common/Components/HIDWithData";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
let oldKey = "";
let exportDataSet: any = [];
export class Payments extends React.Component<any, any> {
    private PaymentsTable: any;
    private ProcessingTable: any;
    private accountName: any;
    private childHID: any;
    private hasPageWidth: any;
    constructor(props: any) {
        super(props);
        this.childHID = React.createRef();
        this.hasPageWidth = React.createRef();
        this.PaymentsTable = React.createRef();
        this.ProcessingTable = React.createRef();
        this.accountName = React.createRef();
        this.state = {
            tableData: [],
            filteredData: [],
            hidList: [],
            hidFilterList: [],
            hidValue: "Select",
            hotelName: "Select",
            key: "",
            accountName: "Select Account Name",
            accountNamelist: [],
            accountNames: [],
            sliderList: [],
            exportData: [],
            excelfileName: '',

            tabsArray: [
                { "name": "Checks", "count": 0, "isActive": true, "arrayList": [], "isShow": true, "sortNo": 1, "PaymentType": "unpaid" },
                { "name": "Manual ACH", "count": 0, "isActive": false, "arrayList": [], "isShow": true, "sortNo": 2, "PaymentType": "unpaid" },
                //{ "name": "Digital ACH", "count": 0, "isActive": false, "arrayList": [], "isShow": true, "sortNo": 3, "PaymentType": "unpaid" },
                { "name": "ePay", "count": 0, "isActive": false, "arrayList": [], "isShow": true, "sortNo": 4, "PaymentType": "unpaid" },
                { "name": "Credit Card", "count": 0, "isActive": false, "arrayList": [], "isShow": true, "sortNo": 1, "PaymentType": "unpaid" },
                { "name": "Checks & ACH", "count": 0, "isActive": false, "arrayList": [], "isShow": false, "sortNo": 5, "PaymentType": "unpaid" },
                { "name": "Confirm Payment", "count": 0, "isActive": true, "arrayList": [], "isShow": true, "sortNo": 1, "PaymentType": "paid" },
                { "name": "Confirm Print", "count": 0, "isActive": false, "arrayList": [], "isShow": true, "sortNo": 2, "PaymentType": "paid" },
                { "name": "Transfers", "count": 0, "isActive": false, "arrayList": [], "isShow": true, "sortNo": 6, "PaymentType": "unpaid" }
            ],
            isBoxActive: false,
            isSliderLoading: false,
            scrollItemList: 1,
            isAllPayment: false,
            tableLoading: false,
            isGroupUnpaid: false,
            accountBal: [],
            totalAcctBal: 0,
            selectedType: "",
            currentSelectedType: [],
            defaultDensityView: "",
            hasAlertSession: false,
            TransfersData: [],
            noDeletePermission: false,
            isFromAnotherPage: false,
            isFromOverview: false,
            hasMakePayments: false,
            hasViewPayments: false,
            defaultColumn: "",
            defaultSort: "",
            isDACH: false,
            hasUnApproved: false,
            voidPermission: "No",
            isDachEnabled: true,
            deleteInvPerm: false,
            editInvPerm: false,
            makeACHPerm: false,
            ePayFeatureFlag: "no",
            ePayaccountNames: [],
            isProcessingTab: false,
            tableLoadingProcess: true,
            isePayEnabled: false,
            epayEnabledForEHID: false,
            registerRequest: { hotelID: -1, lettercode: '', oprID: -1, selectType: "30", isDepositHide: "Yes", startDate: "", endDate: "", acctType: "" },
            accountNamePay: "Select Account Name",
            accountIdPay: -1,
            accountNamesPay: [],
            accountBalPay: "0.00",
            totalAcctBalPay: 0,
            isAccBalLoading: false
        };
    }

    componentDidMount() {
        let hotelId = this.getURLParamsValues()?.hotelId;
        if (hotelId) {
            hotelId = atob(hotelId);
            const menuList = JSON.parse(localStorage.headPermission);
            let isViewPermFound = menuList.some((o) => o.displayName === "View Payments");
            if (isViewPermFound) {
                this.loadHotelsDetails(hotelId, "");
                let Request = {} as any;
                Request.hotelID = hotelId;
                this.getRegisterNames(Request);
                this.setState({ isFromAnotherPage: true, isFromOverview: true, sliderList: [] })
            } else {
                window.location.href = "/unauthorize-access?Error=Page";
            }
        } else {
            this.loadHotelsDetails(-1, "");
        }
        this.CheckEpayEnabled();
        this.getWebTimeClockManagerPermission();
        this.check2FAalertSession();
    }

    CheckEpayEnabled = () => {
        InvoiceApprovalS.CheckEpayEnabled()
            .then(async (result: any) => {
                if (result?.message === "Success") {
                    this.setState({
                        isePayEnabled: result?.result?.isePayEnabled
                    });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                reject();
            });
    };

    loadHotelsDetails = (hid: any, type: any) => {
        const selectedHotel = JSON.parse(localStorage.getItem("hotelSelected")!);
        let Request = {} as any;
        Hotel.UserHotelAccessWithData("Invoice", "Payments").then(async (result: IHotelResponseWithDataDto[] | null) => {
            if (result != null && result.length > 0) {
                this.setState({ hidList: [] }, () => {
                    this.setState({ hidList: result, hidFilterList: result });
                })
                let hotelDetail: any;
                if (result.length > 1) {
                    if (hid !== -1) {
                        hotelDetail = result.find((r) => +r?.hotelID === +hid);
                        this.setState({ hotelName: hotelDetail.lettercode, hidValue: hotelDetail.hotelID }, () => {
                            type === "" && this.GetCountsForPayment(hotelDetail?.hotelID, "");
                            Request.hotelID = hotelDetail.hotelID;
                            this.getRegisterNames(Request);
                        });
                        localStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
                        sessionStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
                        localStorage.setItem("subMenu", "Payments");
                    } else if (selectedHotel !== null && hid === -1) {
                        this.setState({ hotelName: selectedHotel?.lettercode, hidValue: selectedHotel?.hotelID }, () => {
                            type === "" && this.GetCountsForPayment(selectedHotel?.hotelID, "");
                            Request.hotelID = selectedHotel.hotelID;
                            this.getRegisterNames(Request);
                        });
                    } else {
                        this.setState({ hotelName: "Select", hidValue: "Select" });
                    }
                } else {
                    hotelDetail = result[0];
                    this.setState({ hotelName: hotelDetail?.lettercode, hidValue: hotelDetail?.hotelID }, () => {
                        type === "" && this.GetCountsForPayment(hotelDetail?.hotelID, "");
                        Request.hotelID = hotelDetail.hotelID;
                        this.getRegisterNames(Request);
                    });
                }
            }
            resolve();
        }).catch((error) => {
            Utils.toastError(`Server Error: ${error}`, { position: toast.POSITION.BOTTOM_RIGHT });
            reject();
        });
    }


    loadHotelsDetailsDup = () => {
        let { hidList } = this.state;
        Hotel.UserHotelAccessWithData("Invoice", "Payments").then(async (result: IHotelResponseWithDataDto[] | null) => {
            if (result != null && result.length > 0) {
                result.forEach((rs: any) => {
                    hidList.forEach((sl: any) => {
                        if (sl?.hotelID === rs?.hotelID) {
                            sl.dataExists = rs.dataExists
                        }
                    })
                })
                this.setState({ hidList });
            }
            resolve();
        }).catch((error) => {
            Utils.toastError(`Server Error: ${error}`, { position: toast.POSITION.BOTTOM_RIGHT });
            reject();
        });
    }

    getURLParamsValues = (): any => {
        const paramObject: any = {
            hotelId: this.getParamValueByParamName("hotelId")
        }
        return paramObject;
    }

    getParamValueByParamName(paramName: string): any {
        const valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
        const item = valuesArray?.find(r => r?.includes(paramName));
        return item?.split('=')[1];
    }

    check2FAalertSession = () => {
        let hasV2Session = sessionStorage.getItem("V2Session");
        if (hasV2Session !== null) {
            sessionStorage.removeItem("V2Session");
            this.setState({ hasAlertSession: false })
        }
    }

    selectHotelDropDown = (hotelDetails: any) => {
        let { sliderList } = this.state;
        sliderList.forEach((element) => {
            element.isActive = false;
        });
        this.setState(
            {
                hotelName: hotelDetails.lettercode,
                hidValue: hotelDetails.hotelID,
                isBoxActive: false,
                isSliderLoading: true,
                sliderList,
                tableLoadingProcess: true
            }, () => {
                setTimeout(() => {
                    this.GetCountsForPayment(hotelDetails.hotelID, "");
                    //this.checkDACHEnabled(hotelDetails.hotelID)
                }, 10);

            });
    };

    checkDACHEnabled = (hid: any) => {
        //let { isDachEnabled } = this.state;
        //isDachEnabled = false;
        Hotel.GetEnableDigitalACHPaymentsHotel()
            .then(async (result: any[] | null) => {
                if (result != null) {
                    //isDachEnabled = result.some(item => item.hotelId === hid && item.digitalACS.toString().toLocaleLowerCase() === "enabled")
                    //this.setState({ isDachEnabled });
                }
            })
            .catch((err) => {
                Utils.toastError(`Server Error, ${err}`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            })
    }

    getWebTimeClockManagerPermission = () => {
        let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
        let requestObject = {} as IPageSubPermissionRole;
        requestObject.permissionID = 10088;
        requestObject.Module = "Accounting";
        requestObject.Type = "page";
        requestObject.RoleID = localStorageUser.rolId;
        requestObject.TenantID = localStorageUser.tenantID;
        RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
            .then(async (result: any | null) => {
                let { noDeletePermission } = this.state;
                if (result != null && result.length > 0) {
                    for (let index = 0; index < result.length; index++) {
                        if (result[index].displayName.toString().toLowerCase() === "Delete Transfers".toLocaleLowerCase() && result[index].moduleName === "Accounting") {
                            noDeletePermission = true;
                        }
                    }
                    this.setState({ noDeletePermission });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError(`Server Error, ${err}`, { position: toast.POSITION.BOTTOM_RIGHT });
                reject();
            });
    };

    GetCountsOnly = async (HID) => {
        let { sliderList } = this.state;
        InvoiceApprovalS.GetCountsForPayment(HID)
            .then(async (result: any | null) => {
                if (result != null) {
                    result.forEach((rs: any) => {
                        sliderList.forEach((sl: any) => {
                            if (sl?.type === rs?.type) {
                                sl.counts = rs.counts
                            }
                        })
                    })
                    this.setState({ sliderList });
                }
                resolve();
            })
            .catch((err: any) => {
                reject();
            });
    };

    GetCountsForPayment = async (HID, oldType) => {
        InvoiceApprovalS.GetCountsForPayment(HID)
            .then(async (result: any | null) => {
                if (result != null) {
                    let { hasViewPayments, hasMakePayments, isDACH, hasUnApproved, deleteInvPerm, editInvPerm, makeACHPerm, ePayFeatureFlag, isProcessingTab, epayEnabledForEHID } = this.state;
                    isProcessingTab = result.some((o: any) => o?.type?.toString().toLocaleLowerCase() === "processing" && o?.counts > 0);
                    this.setState({
                        sliderList: result,
                        isProcessingTab,
                        isSliderLoading: false
                    }, () => {
                        if (oldType !== "") {
                            const selectedItem = result.filter((o) => o.type?.toLowerCase() === oldType);
                            // if (selectedItem[0].counts == 0) {
                            //     oldType = "";
                            //     oldKey = "";
                            // } else {
                            //     this.optionBoxClick("", selectedItem[0]);
                            // }
                            this.optionBoxClick("", selectedItem[0]);
                        } else {
                            this.optionBoxClick("", result[0]);
                        }
                    });
                    if (this.state.isFromOverview) {
                        let obj = {} as any;
                        obj.counts = 3;
                        obj.id = 1;
                        obj.isActive = false;
                        obj.sortNo = 1;
                        obj.type = "Unpaid";
                        this.setState({ isSliderLoading: false })
                        this.optionBoxClick("", obj);
                    } else if (oldType === "") {
                        const selectedItem = result.filter((o) => o.counts > 0);
                        if (selectedItem.length > 0)
                            this.optionBoxClick("", selectedItem[0]);
                    }
                    hasViewPayments = result[0].viewPaymentPerm;
                    hasMakePayments = result[0].makePaymentPerm;
                    hasUnApproved = result[0].unApprovePerm;
                    deleteInvPerm = result[0].deleteInvPerm;
                    editInvPerm = result[0].editInvPerm;
                    makeACHPerm = result[0].makeACHPerm;
                    isDACH = result[0].isDACH === "Enabled" ? true : false;
                    ePayFeatureFlag = result[0]?.epayFeatureFlag?.toLowerCase();
                    epayEnabledForEHID = result[0]?.epayEnabled;
                    this.setState({ hasViewPayments, hasMakePayments, isDACH, hasUnApproved, deleteInvPerm, editInvPerm, makeACHPerm, ePayFeatureFlag, epayEnabledForEHID });
                }
                resolve();
            })
            .catch((err: any) => {
                this.setState({ isSliderLoading: false });
                Utils.toastError(`Server Error ${err}`);
                reject();
            });
    };

    sleep(msc) {
        return new Promise((resolve) => setTimeout(resolve, msc));
    }

    GetPaymentDetails = () => {
        InvoiceApprovalS.GetPaymentDetails(this.state.hidValue, this.state.selectedType)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (this.state.selectedType?.toLowerCase() === "processing") {
                        this.renderProcessTable(result);
                    } else {
                        result.map((item, index) => {
                            item["isSpinnerLoader"] = false
                            item["isSpinnerPP"] = false
                        });
                        let {tabsArray,sliderList}=this.state;
                        tabsArray.forEach(o=>o.count=0);
                        this.setState({tabsArray},()=>{
                            this.renderTabs(result, this.state.isGroupUnpaid);
                        })
                        let newResult = [] as any;
                        if (this.state.ePayFeatureFlag === "no" || !this.state.isePayEnabled || !this.state.epayEnabledForEHID) {
                            newResult = result.filter((o) => o?.displaypaymentmethod?.toLowerCase() != "epay");
                        } else {
                            newResult = result;
                        }
                        sliderList.filter((o) => o.type === this.state.selectedType)[0].counts = newResult.length;
                        this.setState({ sliderList });
                    }
                }
                resolve();
            })
            .catch((err: any) => {
                this.setState({ isSliderLoading: false, tableLoading: false });
                Utils.toastError(`Server Error ${err}`);
                reject();
            });
    };

    renderTabs = (result: any, isGrouped: boolean) => {
        if (result.length > 0) {
            this.setState({ voidPermission: result[0].voidPermission });
        }

        this.setState({ tableData: [] });

        let selectedKey = "checks";
        let selectetData;

        let { tabsArray, currentSelectedType } = this.state;
        if (currentSelectedType[0]?.type?.toLowerCase() === "unpaid") {
            let mergedArray;
            let CHKTableData = result.filter(
                (item) => item.etype.toLowerCase() === "check"
            );

            CHKTableData = CHKTableData.filter((item) => !item.company.toString().toLocaleLowerCase().includes("transfer from"));

            // const DACHTableData = result.filter(
            //     (item) => (item.etype.toLowerCase() === "ach" || item.etype.toLowerCase() === "d-ach") && item.isDigital === 1
            // );

            const EPAYTableData = result.filter(
                (item) => (item.etype.toLowerCase() === "ach" || item?.etype?.toLowerCase() === "epay") && item?.displaypaymentmethod?.toLowerCase() === "epay"
            );

            let MACHTableData = result.filter(
                (item) => (item.etype.toLowerCase() === "ach" || item.etype.toLowerCase() === "m-ach") && item.isDigital === 0 &&
                    item?.displaypaymentmethod?.toLowerCase() != "epay"
            );

            MACHTableData = MACHTableData.filter((item) => !item.company.toString().toLocaleLowerCase().includes("transfer from"));

            const CCTableData = result.filter(
                (item) => item.etype.toLowerCase() === "credit card"
            );
            const TransfersData = result.filter(
                (item) => item.company.toString().toLocaleLowerCase().includes("transfer from")
            );
            TransfersData.map(item => item["isTransferEntry"] = true);

            if (CHKTableData.length > 0) {
                selectedKey = "checks";
                selectetData = CHKTableData;
            }
            else if (MACHTableData.length > 0) {
                selectedKey = "manual ach";
                selectetData = MACHTableData;
            }
            // else if (DACHTableData.length > 0) {
            //     selectedKey = "digital ach";
            //     selectetData = DACHTableData;
            // }
            else if (EPAYTableData.length > 0 && this.state.ePayFeatureFlag === "yes" && this.state.isePayEnabled && this.state.epayEnabledForEHID) {
                selectedKey = "epay";
                selectetData = EPAYTableData;
            }
            else if (CCTableData.length > 0) {
                selectedKey = "credit card";
                selectetData = CCTableData;
            }
            else if (TransfersData.length > 0) {
                selectedKey = "transfers";
                selectetData = TransfersData;
            } else if (CHKTableData.length === 0 && MACHTableData.length === 0 /*&& DACHTableData.length === 0*/ && CCTableData.length === 0 && TransfersData.length === 0) {
                selectedKey = "checks";
                selectetData = CHKTableData;
            }

            if (isGrouped) {
                // if (this.state.isDACH)
                //     mergedArray = MACHTableData.concat(DACHTableData);
                // else
                mergedArray = MACHTableData;

                mergedArray = mergedArray.concat(CHKTableData);

                if (mergedArray.length > 0) {
                    selectedKey = "checks & ach";
                    selectetData = mergedArray;
                }

                Object.assign(tabsArray.filter((item) => item.name === "Checks")[0], { isShow: false, isActive: false });
                Object.assign(tabsArray.filter((item) => item.name === "Manual ACH")[0], { isShow: false, isActive: false });
                //Object.assign(tabsArray.filter((item) => item.name === "Digital ACH")[0], { isShow: false, isActive: false });
                Object.assign(tabsArray.filter((item) => item.name === "ePay")[0], { count: EPAYTableData.length, arrayList: EPAYTableData, isShow: this.state.ePayFeatureFlag === "yes" && this.state.isePayEnabled && this.state.epayEnabledForEHID, sortNo: 4, isActive: true });
                Object.assign(tabsArray.filter((item) => item.name === "Checks & ACH")[0], { count: mergedArray.length, arrayList: mergedArray, isShow: true, sortNo: 1, isActive: true });
            } else {
                Object.assign(tabsArray.filter((item) => item.name === "Checks")[0], { count: CHKTableData.length, arrayList: CHKTableData, isShow: true, sortNo: 1, isActive: true });
                Object.assign(tabsArray.filter((item) => item.name === "Manual ACH")[0], { count: MACHTableData.length, arrayList: MACHTableData, isShow: true, sortNo: 2, isActive: true });
                //Object.assign(tabsArray.filter((item) => item.name === "Digital ACH")[0], { count: DACHTableData.length, arrayList: DACHTableData, isShow: this.state.isDachEnabled, sortNo: 3, isActive: true });
                Object.assign(tabsArray.filter((item) => item.name === "ePay")[0], { count: EPAYTableData.length, arrayList: EPAYTableData, isShow: this.state.ePayFeatureFlag === "yes" && this.state.isePayEnabled && this.state.epayEnabledForEHID, sortNo: 4, isActive: true });
                Object.assign(tabsArray.filter((item) => item.name === "Checks & ACH")[0], { count: 0, arrayList: [], isShow: false, sortNo: 5, isActive: false });
            }

            Object.assign(tabsArray.filter((item) => item.name === "Credit Card")[0], { count: CCTableData.length, arrayList: CCTableData, isShow: true, sortNo: 4, isActive: true });
            Object.assign(tabsArray.filter((item) => item.name === "Transfers")[0], { count: TransfersData.length, arrayList: TransfersData, isShow: true, sortNo: 6, isActive: true });
            if (this.state.hidValue !== "Select") {
                if (isGrouped) {
                    if (currentSelectedType[0]?.type?.toLowerCase() === "unpaid") {
                        let unpaidFilter: any = [];
                        tabsArray.filter((item: any) => {
                            if (item.name === "Credit Card" ||
                                (this.state.isDachEnabled && item.name === "Digital ACH") ||
                                item.name === "Checks & ACH" ||
                                item.name === "Transfers") {
                                unpaidFilter.push(item)
                            }
                        })
                        if (unpaidFilter.some((o: any) => o.count > 0)) { } else {
                            selectedKey = "checks & ach";
                            selectetData = mergedArray;
                        }
                    }
                }
            }
            tabsArray = _.sortBy(tabsArray, "sortNo");

            let oldResult = [] as any;
            if (oldKey !== "") {
                oldResult = tabsArray.filter((item) => item.name?.toLowerCase() === oldKey)[0].arrayList;
            }
            this.setState({
                filteredData: oldKey !== "" ? oldResult : selectetData,
                tableData: result,
                isGroupUnpaid: isGrouped,
                tabsArray,
                key: oldKey !== "" ? oldKey : selectedKey,
                isFromOverview: false,
                tableLoading: false
            }, () => {
                setTimeout(() => { this.setState({ tableLoadingProcess: false }) }, 10);
                setTimeout(() => { oldKey = ""; }, 10);
            });
        } else if (currentSelectedType[0]?.type?.toLowerCase() === "paid") {
            const ConfPaymentTableData = result.filter(
                (item) => item.etype.toLowerCase() === "credit card" || item.etype.toLowerCase() === "ach"
            );
            const ConfPrintTableData = result.filter(
                (item) => item.etype.toLowerCase() === "check"
            );

            ConfPrintTableData.map(item => {
                if (item.company.toString().toLocaleLowerCase().includes("transfer from")) {
                    item["isTransferEntry"] = true
                } else {
                    item["isTransferEntry"] = false
                }
            })

            if (ConfPaymentTableData.length > 0) {
                selectedKey = "confirm payment";
                selectetData = ConfPaymentTableData;
            } else if (ConfPrintTableData.length > 0) {
                selectedKey = "confirm print";
                selectetData = ConfPrintTableData;
            } else {
                selectedKey = "confirm payment";
                selectetData = ConfPaymentTableData;
            }

            Object.assign(tabsArray.filter((item) => item.name === "Confirm Print")[0], { count: ConfPrintTableData.length, arrayList: ConfPrintTableData });
            Object.assign(tabsArray.filter((item) => item.name === "Confirm Payment")[0], { count: ConfPaymentTableData.length, arrayList: ConfPaymentTableData });
            let oldResult = [] as any;
            if (oldKey !== "") {
                oldResult = tabsArray.filter((item) => item.name?.toLowerCase() === oldKey)[0].arrayList;
            }
            this.setState({
                key: oldKey !== "" ? oldKey : selectedKey,
            }, () => {
                this.setState({
                    filteredData: oldKey !== "" ? oldResult : selectetData,
                    tableData: result,
                    tabsArray,
                    tableLoading: false,
                }, () => { 
                    setTimeout(() => { this.setState({ tableLoadingProcess: false }) }, 10); });
                    setTimeout(() => {oldKey = "";}, 10);
            });
        }
    }

    renderProcessTable = (result: any) => {
        this.setState({ tableLoading: false, filteredData: result }, () => { setTimeout(() => { this.setState({ tableLoadingProcess: false }) }, 10); });
    }

    refreshTable = (oldType, key) => {
        this.setState({ isSliderLoading: true, tableLoading: true });
        oldKey = key;
        this.GetCountsForPayment(this.state.hidValue, oldType);
        this.loadHotelsDetails(this.state.hidValue, "NoLoad");
    }

    selectHID = (hotelDetails: any) => {
        let { sliderList } = this.state;
        sliderList.forEach((element) => { element.isActive = false; element.counts = 0 });
        this.setState({
            hotelName: hotelDetails.lettercode,
            hidValue: hotelDetails.hotelID,
            isBoxActive: false,
            isSliderLoading: true,
            sliderList,
            tableLoadingProcess: true,
            accountNamePay: "Select Account Name",
            accountIdPay: -1,
        }, () => {
            setTimeout(() => {
                this.GetCountsForPayment(hotelDetails.hotelID, "");
                localStorage.setItem("hotelSelected", JSON.stringify(hotelDetails));
                sessionStorage.setItem("hotelSelected", JSON.stringify(hotelDetails));
                localStorage.setItem("subMenu", "Payments");
                this.loadHotelsDetailsDup()
                let Request: any = {};
                Request.hotelID = hotelDetails.hotelID;
                this.getRegisterNames(Request);
            }, 100);
        });
    };

    getRegisterNames = (Request: any) => {
        let selectedaccount = "";
        let selectedStatus = "";
        registersevice.RegisterAccountPayment(Request)
            .then(async (result: any[] | null) => {
                if (result !== null) {
                    let sharedHIDs: any[] = [];
                    let accountNames: any[] = [];

                    result.sort((a, b) =>
                        a.status !== b.status ? (a.status < b.status ? -1 : 1) : 0
                    );
                    result = result.filter((item) => item.status.toLowerCase() !== "inactive" /*&& item.acctType.toLowerCase() !== "m"*/);
                    result.forEach((element) => {
                        let hidCode: any = [];
                        let acctName: any = {};
                        let oprID = element.oprID;
                        let name = element.name;
                        acctName.id = oprID;
                        acctName.sort = element.orderBy;
                        acctName.name = name;
                        acctName.status = element.status;
                        acctName.hid = element.hid;
                        acctName.fundingSourceStatus = element.fundingSourceStatus;
                        acctName.acctType = element.acctType;
                        acctName.setDefault = element.setDefault;
                        acctName.ePayCustID = element.ePayCustID;
                        acctName.isEPayEnable = element.isEPayEnable;
                        accountNames.push(acctName);
                        // if (Number(this.state.selectedOprID) === Number(oprID)) {
                        //     selectedaccount = name;
                        //     selectedStatus = element.status;
                        // }
                    });
                    accountNames = _.sortBy(accountNames, "sort");
                    let ePayAccountList = [] as any;
                    accountNames = accountNames.filter((o) => o?.hid === this.state.hidValue?.toString());
                    ePayAccountList = accountNames.filter((o) => o?.ePayCustID !== "" && o?.hid === this.state.hidValue?.toString() && o?.isEPayEnable === 1);
                    accountNames = _.uniqBy(accountNames, 'name');
                    this.setState({
                        accountNamelist: result,
                        ePayaccountNames: ePayAccountList,
                    });
                    let { accountNamesPay } = this.state;
                    this.setState({ accountNamesPay: [], accountNames: [], accountNamePay: '', accountBalPay: 0, totalAcctBalPay: 0 })
                    accountNamesPay = accountNames.filter(item => item.acctType.toString().toLocaleLowerCase() !== "m");
                    if (accountNames.length === 1) {
                        let findDefault = accountNamesPay.filter((o: any) => o.setDefault.toString().toLocaleLowerCase() === "yes");
                        this.setState({
                            accountNamePay: findDefault.length > 0 ? findDefault[0].name : "Select Account Name",
                            accountIdPay: findDefault.length > 0 ? findDefault[0].id : -1,
                            accountNames: accountNames,
                            accountNamesPay
                        }, () => {
                            findDefault.length > 0 && this.isRegisterPayBalance(findDefault[0]);
                        });
                    } else {
                        let findDefault = accountNamesPay.filter((o: any) => o.setDefault.toString().toLocaleLowerCase() === "yes");
                        this.setState({
                            accountNamePay: findDefault.length > 0 ? findDefault[0].name : "Select Account Name",
                            accountIdPay: findDefault.length > 0 ? findDefault[0].id : -1,
                            accountNames: accountNames,
                            accountNamesPay
                        }, () => {
                            findDefault.length > 0 && this.isRegisterPayBalance(findDefault[0]);
                        });
                    }
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };

    isRegisterPayBalance = (arr: any) => {
        let { registerRequest } = this.state;
        registerRequest.oprID = arr.id;
        registerRequest.acctType = arr.acctType;
        registerRequest.hotelID = this.state.hidValue;
        registerRequest.hotelName = this.state.hotelName;
        registerRequest.accountName = arr.name;
        registerRequest.selectType = "30";
        this.setState({ registerRequest }, () => {
            this.getRegisterBal(registerRequest);
        });
    }

    handleaccountNameSelectedItem = (controlID: any, arr: any) => {
        let isName = "";
        let isId = -1;
        this.state.accountNamelist.filter(x => x.oprID === arr.id).forEach(element => {
            isName = element.name;
            isId = element.oprID;
        });
        this.setState({ accountNamePay: isName, accountIdPay: isId }, () => {
            this.isRegisterPayBalance(arr);
        });
    };

    getRegisterBal = (request: any) => {
        this.setState({ isAccBalLoading: true });
        registersevice.RegisterAccountBal(request)
            .then(async (result: any[] | null) => {
                if (result !== null) {
                    // this.setState({ accountBalPay: result, /*totalAcctBal: result[0].accountBal,*/ totalAcctBalPay: result[0].accountBal, isAccBalLoading: false });
                    this.setState({ accountBalPay: result, totalAcctBalPay: result[0].accountBal, isAccBalLoading: false });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isAccBalLoading: false });
                reject();
            });
    };

    optionBoxClick = (e: any, item: any) => {
        let { sliderList, currentSelectedType } = this.state;
        currentSelectedType = [];
        currentSelectedType.push(item)
        this.setState({ isBoxActive: false, tableLoading: true, selectedType: item.type, currentSelectedType });
        sliderList.filter(
            (itemList: { type: any }) => itemList.type === item.type
        )[0].isActive = true;

        sliderList.forEach((element) => {
            if (element.type !== item.type) {
                element.isActive = false;
            }
        });

        this.setState({ sliderList, isBoxActive: true }, () => {
            if (oldKey === "") {
                this.getDefaultView(item.type);
                this.GetCountsOnly(this.state.hidValue);
            } else {
                this.GetPaymentDetails();
            }
        });
    };

    getDefaultView(type) {
        laborPerformance.getDefaultViewProperties(type.toLowerCase())
            .then(async (response: any | null) => {
                if (response?.length > 0) {
                    let defaultView = response.find(
                        (item) => item.fieldName === "view"
                    )?.fieldValue;

                    if (defaultView === undefined || defaultView === null)
                        defaultView = "densityView_Narrow_No";

                    let defaultColumn = response.find(
                        (item) => item.fieldName === "column"
                    )?.fieldValue;

                    if (defaultColumn === undefined || defaultColumn === null)
                        defaultColumn = "company,invoiceNo,letterCode,accper,billDate,transTotal,etype,tabStatus,dueDate,checkNo";

                    let defaultSort = response.find(
                        (item) => item.fieldName === "sort"
                    )?.fieldValue;

                    if (defaultSort === undefined || defaultSort === null)
                        defaultSort = "company";

                    this.setState({
                        isGroupUnpaid: defaultView?.split(',')[1] === "Yes" ? true : false,
                        defaultDensityView: defaultView?.split(',')[0],
                        defaultColumn: defaultColumn,
                        defaultSort: defaultSort
                    }, () => {
                        this.GetPaymentDetails();
                    });
                } else {
                    this.setState({
                        isGroupUnpaid: false,
                        defaultDensityView: "densityView_Narrow",
                        defaultSort: "company",
                        defaultColumn: "company,invoiceNo,letterCode,accper,billDate,transTotal,etype,tabStatus,dueDate,checkNo",
                    }, () => {
                        this.GetPaymentDetails();
                    })
                }
            })
            .catch((error) => {
                this.setState({ isGroupUnpaid: false, defaultDensityView: "densityView_Narrow" }, () => {
                    this.GetPaymentDetails();
                });
            });
    }

    handleDensityView = (viewName) => {
        this.setState({ defaultDensityView: viewName });
    }

    handleSessionAlert = (value) => {
        this.setState({ hasAlertSession: value })
    }

    groupedACHCheck = (isGrouped) => {
        this.setState({ tableLoading: true, isGroupUnpaid: isGrouped });
        this.renderTabs(this.state.tableData, isGrouped)
    }

    handleNoEvent = () => { }

    handleEndSecureSession = () => {
        let hasV2Session = sessionStorage.getItem("V2Session");
        if (hasV2Session !== null) {
            sessionStorage.removeItem("V2Session");
            this.handleSessionAlert(false)
        }
    }
    runReportClicked = (event) => {
        const location = {
            pathname: "/account-settings/viewer",
            search: "",
        };

        let {
            hidValue
        } = this.state;

        const hotelId = window.btoa(hidValue);
        const url = this.props.history.createHref(location) +
            `?hotelId=${hotelId}`;

        window.open(url, "_blank");


    };

    sortreviewstatus = (table: any) => {
        const customOrder = ["Needs Review", "Reviewed", "Review Complete", ""];
        table = _.orderBy(table, [(obj) => {
            const index = customOrder.indexOf(obj.reviewstatus);
            return index === -1 ? customOrder.length : index;
        }], ['asc']);
        return table;
    }

    getExport = () => {

        let defaultsetting: any = {};
        defaultsetting = this.PaymentsTable?.current?.getColumns();

        if (defaultsetting != null) {
            let columnList = defaultsetting.tableColumnList;
            let sortby = defaultsetting.sortBy;

            let copyTableColumnList: any;
            copyTableColumnList = defaultsetting.copyTableColumnList.split(',');


            if (columnList != null) {
                exportDataSet = [];
                new Date().getDate()
                new Date().getDay()
                new Date().getFullYear()
                new Date().getHours()
                new Date().getMilliseconds()
                new Date().getMinutes()
                new Date().getMonth()
                new Date().getSeconds()
                new Date().getTime()

                let dateObj = new Date();

                let objmonth = (dateObj.getMonth() + 1) < 10 ? "0" + (dateObj.getMonth() + 1).toString() : (dateObj.getMonth() + 1).toString();
                let objdate = (dateObj.getUTCDate()) < 10 ? "0" + (dateObj.getUTCDate()).toString() : (dateObj.getUTCDate()).toString();
                let objHr = (dateObj.getHours()) < 10 ? "0" + (dateObj.getHours()).toString() : (dateObj.getHours()).toString();
                let objMM = (dateObj.getMinutes()) < 10 ? "0" + (dateObj.getMinutes()).toString() : (dateObj.getMinutes()).toString();
                let objSS = (dateObj.getSeconds()) < 10 ? "0" + (dateObj.getSeconds()).toString() : (dateObj.getSeconds()).toString();


                let excelfileName = "Unpaid-Payments-" + (dateObj.getUTCFullYear().toString().substring(2, 4))
                    + "-" + objmonth + "" + objdate
                    + " " + objHr.toString() + "." + objMM.toString() + "." + objSS.toString();


                let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
                let isvendor = columnList.filter(item => item.id === "company" && item.isChecked === true).length > 0;
                let isaccountPeriod = columnList.filter(item => item.id === "accper" && item.isChecked === true).length > 0;
                let isinvoicedate = columnList.filter(item => item.id === "billDate" && item.isChecked === true).length > 0;
                let isamount = columnList.filter(item => item.id === "transTotal" && item.isChecked === true).length > 0;
                let ismethod = columnList.filter(item => item.id === "etype" && item.isChecked === true).length > 0;
                let isstatus = columnList.filter(item => item.id === "tabStatus" && item.isChecked === true).length > 0;
                let isreviewstatus = columnList.filter(item => item.id === "reviewstatus" && item.isChecked === true).length > 0;
                let isnotes = columnList.filter(item => item.id === "internalNotes" && item.isChecked === true).length > 0;

                let isNumber: boolean;
                let isduteedate: boolean;
                let iscustomer: boolean;
                if (this.state.key.toLowerCase() === "transfers") {
                    isNumber = copyTableColumnList.filter(item => item === "invoiceNo").length > 0;
                    isduteedate = copyTableColumnList.filter(item => item === "dueDate").length > 0;
                    iscustomer = copyTableColumnList.filter(item => item === "addContract").length > 0;
                }
                else {
                    isNumber = columnList.filter(item => item.id === "invoiceNo" && item.isChecked === true).length > 0;
                    isduteedate = columnList.filter(item => item.id === "dueDate" && item.isChecked === true).length > 0;
                    iscustomer = columnList.filter(item => item.id === "addContract" && item.isChecked === true).length > 0;
                }

                tableData.map(item => item.etype = (item.etype.toLowerCase() === "ach" && item?.displaypaymentmethod?.toLowerCase() === "epay") ? "ePay" :
                    (item.etype.toLowerCase() === "ach" && item.isDigital === 0) ? "M-ACH" : item.etype);
                let exportCheck = tableData.filter(item => item.etype.toLowerCase() === "check" && !item.company.toString().toLocaleLowerCase().includes("transfer from"));
                let exportMACH = tableData.filter(item => (item.etype.toLowerCase() === "ach" || item.etype.toLowerCase() === "m-ach") && item.isDigital === 0
                    && !item.company.toString().toLocaleLowerCase().includes("transfer from"));
                let exportDACH = tableData.filter(item => (item.etype.toLowerCase() === "ach" || item.etype.toLowerCase() === "epay") && item?.displaypaymentmethod?.toLowerCase() === "epay");
                let exportCredit = tableData.filter(item => item.etype.toLowerCase() === "credit card");
                let exportTransfer = tableData.filter(item => item.company.toString().toLocaleLowerCase().includes("transfer from"));

                exportTransfer.map(item => item["isTransferEntry"] = true);
                exportTransfer.map(item => item.dueDate = (item.entryType.toLowerCase() === "transfer" ? "" : moment(item.dueDate).format("DD-MMM-YY")));
                exportTransfer.map(item => item.invoiceNo = (item.entryType.toLowerCase() === "transfer" ? "" : item.invoiceNo));
                exportTransfer.map(item => item.addContract = item.entryType.toLowerCase() === "transfer" ? "" : item.addContract);

                if (sortby.toString().toLowerCase() === "vendor") {
                    exportCheck = [...exportCheck].sort((a, b) => a.company > b.company ? 1 : -1);
                    exportMACH = [...exportMACH].sort((a, b) => a.company > b.company ? 1 : -1);
                    exportDACH = [...exportDACH].sort((a, b) => a.company > b.company ? 1 : -1);
                    exportCredit = [...exportCredit].sort((a, b) => a.company > b.company ? 1 : -1);
                    exportTransfer = [...exportTransfer].sort((a, b) => a.ttFvendor > b.ttFvendor ? 1 : -1);

                }

                if (sortby.toString().toLowerCase() === "number") {
                    exportCheck = [...exportCheck].sort((a, b) => a.invoiceNo > b.invoiceNo ? 1 : -1);
                    exportMACH = [...exportMACH].sort((a, b) => a.invoiceNo > b.invoiceNo ? 1 : -1);
                    exportDACH = [...exportDACH].sort((a, b) => a.invoiceNo > b.invoiceNo ? 1 : -1);
                    exportCredit = [...exportCredit].sort((a, b) => a.invoiceNo > b.invoiceNo ? 1 : -1);
                    exportTransfer = [...exportTransfer].sort((a, b) => a.invoiceNo > b.invoiceNo ? 1 : -1);
                }
                if (sortby.toString().toLowerCase() === "accounting period") {
                    exportCheck = [...exportCheck].sort((a, b) => a.accper.split('/').reverse().join().localeCompare(b.accper.split('/').reverse().join()));
                    exportMACH = [...exportMACH].sort((a, b) => a.accper.split('/').reverse().join().localeCompare(b.accper.split('/').reverse().join()));
                    exportDACH = [...exportDACH].sort((a, b) => a.accper.split('/').reverse().join().localeCompare(b.accper.split('/').reverse().join()));
                    exportCredit = [...exportCredit].sort((a, b) => a.accper.split('/').reverse().join().localeCompare(b.accper.split('/').reverse().join()));
                    exportTransfer = [...exportTransfer].sort((a, b) => a.accper.split('/').reverse().join().localeCompare(b.accper.split('/').reverse().join()));
                }

                if (sortby.toString().toLowerCase() === "invoice date") {
                    exportCheck = [...exportCheck].sort((a, b) => a.billDate.localeCompare(b.billDate) || a.billDate.localeCompare(b.billDate));
                    exportMACH = [...exportMACH].sort((a, b) => a.billDate.localeCompare(b.billDate) || a.billDate.localeCompare(b.billDate));
                    exportDACH = [...exportDACH].sort((a, b) => a.billDate.localeCompare(b.billDate) || a.billDate.localeCompare(b.billDate));
                    exportCredit = [...exportCredit].sort((a, b) => a.billDate.localeCompare(b.billDate) || a.billDate.localeCompare(b.billDate));
                    exportTransfer = [...exportTransfer].sort((a, b) => a.billDate.localeCompare(b.billDate) || a.billDate.localeCompare(b.billDate));
                }
                if (sortby.toString().toLowerCase() === "due date") {
                    exportCheck = [...exportCheck].sort((a, b) => a.dueDate.localeCompare(b.dueDate) || a.dueDate.localeCompare(b.dueDate));
                    exportMACH = [...exportMACH].sort((a, b) => a.dueDate.localeCompare(b.dueDate) || a.dueDate.localeCompare(b.dueDate));
                    exportDACH = [...exportDACH].sort((a, b) => a.dueDate.localeCompare(b.dueDate) || a.dueDate.localeCompare(b.dueDate));
                    exportCredit = [...exportCredit].sort((a, b) => a.dueDate.localeCompare(b.dueDate) || a.dueDate.localeCompare(b.dueDate));
                    exportTransfer = [...exportTransfer].sort((a, b) => a.dueDate.localeCompare(b.dueDate) || a.dueDate.localeCompare(b.dueDate));
                }
                if (sortby.toString().toLowerCase() === "amount") {
                    exportCheck = [...exportCheck].sort((a, b) => a.transTotal - b.transTotal);
                    exportMACH = [...exportMACH].sort((a, b) => a.transTotal - b.transTotal);
                    exportDACH = [...exportDACH].sort((a, b) => a.transTotal - b.transTotal);
                    exportCredit = [...exportCredit].sort((a, b) => a.transTotal - b.transTotal);
                    exportTransfer = [...exportTransfer].sort((a, b) => a.transTotal - b.transTotal);
                }

                if (sortby.toString().toLowerCase() === "method") {
                    exportCheck = [...exportCheck].sort((a, b) => a.etype > b.etype ? 1 : -1);
                    exportMACH = [...exportMACH].sort((a, b) => a.etype > b.etype ? 1 : -1);
                    exportDACH = [...exportDACH].sort((a, b) => a.etype > b.etype ? 1 : -1);
                    exportCredit = [...exportCredit].sort((a, b) => a.etype > b.etype ? 1 : -1);
                    exportTransfer = [...exportTransfer].sort((a, b) => a.etype > b.etype ? 1 : -1);
                }

                if (sortby.toString().toLowerCase() === "customer") {
                    exportCheck = [...exportCheck].sort((a, b) => a.addContract > b.addContract ? 1 : -1);
                    exportMACH = [...exportMACH].sort((a, b) => a.addContract > b.addContract ? 1 : -1);
                    exportDACH = [...exportDACH].sort((a, b) => a.addContract > b.addContract ? 1 : -1);
                    exportCredit = [...exportCredit].sort((a, b) => a.addContract > b.addContract ? 1 : -1);
                    exportTransfer = [...exportTransfer].sort((a, b) => a.addContract > b.addContract ? 1 : -1);
                }

                if (sortby.toString().toLowerCase() === "notes") {
                    exportCheck = [...exportCheck].sort((a, b) => a.internalNotes > b.internalNotes ? 1 : -1);
                    exportMACH = [...exportMACH].sort((a, b) => a.internalNotes > b.internalNotes ? 1 : -1);
                    exportDACH = [...exportDACH].sort((a, b) => a.internalNotes > b.internalNotes ? 1 : -1);
                    exportCredit = [...exportCredit].sort((a, b) => a.internalNotes > b.internalNotes ? 1 : -1);
                    exportTransfer = [...exportTransfer].sort((a, b) => a.internalNotes > b.internalNotes ? 1 : -1);
                }


                if (sortby.toString().toLowerCase() === "flagged") {
                    exportCheck = this.sortreviewstatus([...exportCheck]);
                    exportMACH = this.sortreviewstatus([...exportMACH]);
                    exportDACH = this.sortreviewstatus([...exportDACH]);
                    exportCredit = this.sortreviewstatus([...exportCredit]);
                    exportTransfer = this.sortreviewstatus([...exportTransfer]);
                }

                let tableDataFinal = [...exportCheck, ...exportMACH, ...exportDACH, ...exportCredit, ...exportTransfer];
                let datefromat = "MM/DD/YY";//"DD-MMM-YY"
                tableDataFinal.forEach(item => {
                    let row: any = {};
                    isvendor && (row["Vendor"] = (item.isTransferEntry !== undefined && item.isTransferEntry) ? item.ttFvendor : item.company);
                    isNumber && (row["Number"] = item.entryType.toLowerCase() === "transfer" ? "" : item.invoiceNo);
                    isaccountPeriod && (row["AP"] = moment(item.accper1).format(datefromat));
                    isinvoicedate && (row["Invoice/Transaction Date"] = moment(item.billDate).format(datefromat));
                    isduteedate && (row["Due Date"] = item.entryType.toLowerCase() === "transfer" ? "" : moment(item.dueDate).format(datefromat));
                    isamount && (row["Amount"] = Utils.currencyFormatWithOutdoller(item.transTotal));
                    ismethod && (row["Method"] = (item.etype.toLowerCase() === "ach" && item.isDigital === 1) ? "D-ACH" :
                        (item.etype.toLowerCase() === "ach" && item.isDigital === 0) ? "M-ACH" : item.etype);
                    isstatus && (row["Status"] =
                        (item.etype?.toLowerCase() === 'd-ach' && row.digitAchStatus?.toLowerCase()?.includes("fail") ? "Failed" : "Ready for Payment"));
                    (isreviewstatus && tableData[0]?.isbookingkeeping.toString().toLowerCase() === "yes") && (row["Flagged"] = item.reviewstatus);
                    iscustomer && (row["Customer"] = item.entryType.toLowerCase() === "transfer" ? "" : item.addContract);
                    isnotes && (row["Notes"] = item.internalNotes);
                    exportDataSet.push(row);
                });
                this.setState({ exportData: exportDataSet, excelfileName: excelfileName }, () => {
                    let tempLink = document.getElementById("btnexport2") as any;
                    tempLink.click();
                });
            }
        }
    }

    render() {
        const handleTabSelect = (eventKey: any) => {
            this.setState({ tableLoading: true });
            this.setState({ key: eventKey }, () => {
                let filterDataTable = this.state.tabsArray.filter((item) => item.name.toLowerCase() === eventKey)[0].arrayList;
                this.setState({ tableLoading: false }, () => {
                    this.setState({ filteredData: filterDataTable, }, () => { setTimeout(() => { this.setState({ tableLoadingProcess: false }) }, 10); });
                });
            });
        };

        // const zeroCount = this.state.sliderList.filter((o) => o.counts === 0).length;
        // let isCountGreater = this.state.sliderList.some(item => item.isActive && item.counts > 0);
        const isPaidCounterZero = this.state.sliderList.some((o) => o.type.toString().toLocaleLowerCase() === "unpaid" && o.counts === 0);
        return (
            <>
                <Container fluid className={this.state.hasAlertSession ? "hasAlertBox vt5_posRel body-sec PaymentPage" : "vt5_posRel body-sec PaymentPage"}>
                    {this.state.hasAlertSession && (
                        <div className="notificationBannersPay blue-bg">
                            <div className="mr-auto">
                                <span className="bold">
                                    You are currently in a secure session.  Inactivity, refreshing, or leaving this page will end this session.</span>
                            </div>
                            <div className="hasOTPLink">
                                <span className="cusSpanLink" onClick={this.handleEndSecureSession}>End Secure Session</span>
                            </div>
                        </div>
                    )}
                    <div className="page-heading underline d-flex">
                        {this.state.hidList.length > 0 ? (
                            <HIDWithData
                                hotelName={this.state.hotelName}
                                hidList={this.state.hidList}
                                selectHID={this.selectHID}
                                hidFilterList={this.state.hidFilterList}
                            />
                        ) : (
                            <Dropdown className="hid-select disabled-area">
                                <Dropdown.Toggle id="dropdown-hid" disabled={this.state.loaderTrue}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        {this.state.hotelName}
                                        <div className="drop-arrow d-flex"><FiChevronDown /></div>
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        )}
                        {this.state.hidValue !== "Select" && (
                            <>
                                <div className="mr-auto d-flex align-items-center">Payments</div>
                                {this.state.currentSelectedType[0]?.type?.toLowerCase() === "unpaid" ? (
                                    <div className="action-group d-flex">
                                        <Dropdown className="more-action" alignRight>
                                            <Dropdown.Toggle
                                                className="btn-outline-primary btn btn-primary more"
                                                id="dropdown-more"
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                </svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* <Dropdown.Item eventKey="export" key="export" onClick={this.runReportClicked}>
                                              Payment Register Report
                                            </Dropdown.Item> */}
                                                {/* {this.state.currentSelectedType[0]?.type?.toLowerCase() === "unpaid" && */}
                                                <>
                                                    {isPaidCounterZero ? (
                                                        <Dropdown.Item className="disabled-area">Export Unpaid</Dropdown.Item>
                                                    ) : (
                                                        <Dropdown.Item eventKey="export1" key="export1" onClick={this.getExport}>Export Unpaid</Dropdown.Item>
                                                    )}
                                                    <div>
                                                        <Button className="without-border margin-right-15" variant="primary" style={{ display: "none" }}>
                                                            <CSVLink id="btnexport2" data={exportDataSet} filename={this.state.excelfileName + ".csv"}>Export</CSVLink>
                                                        </Button>
                                                    </div>
                                                </>
                                                {/* } */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                ) : (<div style={{ "height": "40px" }}></div>)}
                            </>
                        )}
                    </div>
                    {this.state.hidValue !== "Select" && (
                        <div className="d-flex">
                            <div className="PaymentAlertBox">
                                <div className="Box-Option">
                                    <div className="optionBoxCont d-flex">
                                        {this.state.isSliderLoading ? (
                                            <ReactPageLoader useas={"laborDashboardTopBox"} />
                                        ) : (<>
                                            {this.state.sliderList.map((item) => (
                                                <div className={`${this.state.isProcessingTab ? "maxSmall3Screen" : "maxSmall2Screen"} scroll-area`}>
                                                    {(item.type.toString().toLocaleLowerCase() === "processing" ? this.state.isProcessingTab : true) && (
                                                        <div className={`slick-slider slider variable-width slick-initialized
                                                    ${item.type.toString().toLocaleLowerCase() === "processing" && this.state.tableLoadingProcess && "disabledforProcess"}
                                                    `}>
                                                            <div className="slick-list">
                                                                <div className="slick-track">
                                                                    <div className="slick-slide slick-active slick-current" aria-hidden="false"
                                                                        onClick={(e) => { this.state.tableLoading ? this.handleNoEvent() : this.optionBoxClick(e, item) }}>
                                                                        <div className={`optionBox d-flex align-items-center ${item.isActive ? "active" : ""}`}>
                                                                            <div className="circle d-flex align-items-center justify-content-center">{item.counts}</div>
                                                                            <div className="label-sec">{item.type}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            {/* {zeroCount === this.state.sliderList.length && (
                                            <div className="vt5allCaughtUp d-flex align-items-center justify-content-center">
                                                <img src={invoice_inv} alt="You’re all caught up!" />
                                                <div className="label-sec"> You’re all caught up on your payments. <br />Nice Work
                                                    <img src={caught} alt="You’re all caught up!" />
                                                </div>
                                            </div>
                                        )} */}
                                        </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="ml-auto">
                                {this.state.isSliderLoading ? (<div className="single-loader accbalanceloaderLarge loadingAnimation"></div>) : (
                                    <div className="paymentRightControl">
                                        {this.state.accountNamesPay.length > 0 && (
                                            <>
                                                <div className={`${this.state.isProcessingTab ? "accSearchDDListWithIcon3" : ""} accSearchDDListWithIcon`}>
                                                    <SingleSearchDropdownList
                                                        id={"tableAccountName"}
                                                        ref={this.accountName}
                                                        itemList={this.state.accountNamesPay}
                                                        handleSelectedItem={this.handleaccountNameSelectedItem.bind(this, 1)}
                                                        defaultItem={this.state.HIDToNM}
                                                        defaultText={"Search..."}
                                                        defaultName={this.state.accountNamePay}
                                                        calHomeIcon={true}
                                                        currentChecked={this.state.accountNamePay}
                                                        controlID="2"
                                                        pageType={"Register"}
                                                        isStatusbadge={true}
                                                    />
                                                </div>
                                                <div className="accbalance">
                                                    <div className="balTitle">Inn-Flow Balance</div>
                                                    {this.state.isAccBalLoading ? (<div className="single-loader accbalanceloader loadingAnimation"></div>) : (
                                                        <div className="balMain">{Utils.currencyFormat(this.state.totalAcctBalPay)}</div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                    )}

                    {this.state.hidValue !== "Select" && this.state.isBoxActive && (
                        <>
                            {this.state.tableLoading || this.state.tableLoadingProcess ? (
                                <ReactPageLoader useas={"accounts"} />
                            ) : (
                                this.state.selectedType?.toLowerCase() === "processing" ? (
                                    <ProcessingTable
                                        parentState={this.state}
                                        ref={this.ProcessingTable}
                                        hidValue={this.state.hidValue}
                                        hotelName={this.state.hotelName}
                                        filteredData={this.state.filteredData}
                                        handleDensityView={this.handleDensityView}
                                        refreshTable={this.refreshTable}
                                    />
                                ) :
                                    <Tab.Container
                                        activeKey={this.state.key}
                                        onSelect={handleTabSelect}
                                        id="left-tabs-example"
                                    >
                                        <div>
                                            <Nav className="nav-tabs flex-row">
                                                {this.state.tabsArray.map(
                                                    (data: any, key: any) => (
                                                        <>
                                                            {data.isShow && this.state.currentSelectedType[0]?.type?.toLowerCase() === data.PaymentType?.toLowerCase() && (
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey={data.name.toLowerCase()}>
                                                                        {data.name}{" "}
                                                                        {(data.count === 0 || this.state.tableLoadingProcess) ? "" : <span className="tab-badge color-blue">{data.count}</span>}
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                            </Nav>
                                        </div>
                                        <div>
                                            <Tab.Content className="paymentTabContent">
                                                {this.state.tabsArray.map(
                                                    (data: any, key: any) => (
                                                        <>
                                                            {/* {isCountGreater && ()} */}
                                                            <>
                                                                {this.state.key === data.name.toLowerCase() && data.isShow && (
                                                                    <Tab.Pane eventKey={data.name.toLowerCase()}>
                                                                        {this.state.isBoxActive && (
                                                                            <PaymentsTable
                                                                                parentState={this.state}
                                                                                ref={this.PaymentsTable}
                                                                                hidValue={this.state.hidValue}
                                                                                hotelName={this.state.hotelName}
                                                                                filteredData={this.state.filteredData}
                                                                                groupedACHCheck={this.groupedACHCheck}
                                                                                isGroupUnpaid={this.state.isGroupUnpaid}
                                                                                handleDensityView={this.handleDensityView}
                                                                                handleSessionAlert={this.handleSessionAlert}
                                                                                refreshTable={this.refreshTable}
                                                                                updateColumns={this.updateColumns}
                                                                                updateSort={this.updateSort}
                                                                                voidPermission={this.state.voidPermission}
                                                                            />
                                                                        )}
                                                                    </Tab.Pane>
                                                                )}
                                                            </>

                                                        </>

                                                    )
                                                )}
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>
                            )}
                        </>
                    )
                    }
                </Container >
            </>
        );
    }
    updateColumns = (columnsHeader: any, defaultSort: any) => {
        let oldDefaultColumnList = this.state.defaultColumn.split(',');
        if (!columnsHeader.isChecked)
            oldDefaultColumnList = oldDefaultColumnList.filter((o) => o !== columnsHeader.id);
        else
            oldDefaultColumnList.push(columnsHeader.id)

        this.setState({ defaultColumn: oldDefaultColumnList.toString(), defaultSort: defaultSort });
    }

    updateSort = (defaultSort: any) => {
        this.setState({ defaultSort: defaultSort })
    }
}
