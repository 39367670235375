import React from "react";
import { Container, Dropdown, Spinner, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "../Settings/EntityManagement/pmsImport.scss"
import { ModuleReports } from "./../../Common/Services/ModuleReports";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import "../../Common/Assets/Styles/Modules/DetailsReportDrilldown.scss";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../Common/Utilis";
import { Register as registersevice } from "../../Common/Services/Register";
import { TransactionSlideout } from "../Register/TransactionSlideout";
import { InvoiceEntrySlideout } from "../Register/InvoiceEntrySlideout";
import { TransferSlideout } from "../Register/TransferSlideout";
import { TransactionSlideout as JournalSlideOut } from '../Accounting/JournalEntry/transactionSlideout';
import { CreditCardSlideout } from '../Register/CreditCardSlideout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import {
    RoleSubPermissionSetting,
    IPageSubPermissionRole,
} from "../../Common/Services/RoleSubPermissionSetting";
export class DetailsReportDrilldown extends React.Component<any, any> {
    private linkRef: any;
    private scheduleUserViewRef: any;
    constructor(props: any) {
        super(props);
        this.scheduleUserViewRef = React.createRef();
        this.linkRef = React.createRef();
        this.state = {
            tableData: [],
            loader: false,
            isSlideOut: false,
            pageNumber: 1,
            isLoading: false,
            densityName: "compact",
            reportName: "",
            coaName: "",
            arrData: [],
            queryData: [],
            hasAddPermission: false,
        };
    }

    componentDidMount() {
        this.getWebTimeClockManagerPermission();
        if (window.location.search !== '') {
            this.loadReportEncrypt(1);
            this.loadDataEncrypt();
        } else {
            this.getDataFromQueryString();
        }
    }

    getWebTimeClockManagerPermission() {
        let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
        let requestObject = {} as IPageSubPermissionRole;
        requestObject.permissionID = 148;
        requestObject.Module = "Accounting";
        requestObject.Type = "page";
        requestObject.RoleID = localStorageUser.rolId;
        requestObject.TenantID = localStorageUser.tenantID;
        RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
            .then(async (result: any | null) => {
                let { hasAddPermission } = this.state;
                if (result != null && result.length > 0) {
                    for (let index of result) {
                        if (index?.displayName.toString().toLowerCase() === "Add".toLocaleLowerCase() && index?.moduleName === "Accounting") {
                            hasAddPermission = true;
                        }
                    }
                    this.setState({ hasAddPermission });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError(`Server Error, ${err}`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    };

    getDataFromQueryString() {
        const location = this.props.location;
        if (location.state !== undefined) {
            const stateLength = Object.keys(location.state).length;
            if (stateLength > 0) {
                this.setState({ queryData: location.state }, () => {
                    this.loadReport(1);
                    this.loadData();
                })
            }
        }
    }

    loadReport(pageNumber) {
        let { StartDate, EndDate, SplitHID, COA, UniqueNo, Ctype, Type, GroupID, SubGroupID, SubGroupID2, AccountType, IsTotal, OprID, reportId, Report, ReportType, RowType, SelectPL, IsCOS, IsMOD, IsFB, LineGroup, IsPending } = this.state.queryData;
        let reportParam = {} as any;
        const storage = JSON.parse(localStorage.getItem("storage")!);
        reportParam.username = storage === null ? 0 : (storage.userName as any);
        reportParam.tenantId = storage === null ? 0 : (storage.tenantID as any);
        reportParam.StartDate = StartDate;
        reportParam.EndDate = EndDate;
        reportParam.SplitHID = SplitHID;
        reportParam.COA = COA;
        reportParam.UniqueNo = UniqueNo;
        reportParam.Type = Type;
        reportParam.Ctype = Ctype;
        reportParam.GroupID = GroupID;
        reportParam.SubGroupID = SubGroupID;
        reportParam.SubGroupID2 = SubGroupID2;
        reportParam.AccountType = AccountType;
        reportParam.IsTotal = IsTotal;
        reportParam.Oprid = OprID;
        reportParam.Reportid = reportId;
        reportParam.PageNumber = pageNumber;
        reportParam.ExportType = "";
        reportParam.Report = Report;
        reportParam.ReportType = ReportType;
        reportParam.RowType = RowType;
        reportParam.SelectPL = SelectPL;
        reportParam.IsCOS = IsCOS;
        reportParam.IsMOD = IsMOD;
        reportParam.IsFB = IsFB;
        reportParam.LineGroup = LineGroup;

        reportParam.PageNumber = pageNumber;
        reportParam.ExportType = "";
        reportParam.Userid = "";
        reportParam.IsPending = IsPending;

        this.setState({ loader: pageNumber === 1 ? true : false })
        try {
            ModuleReports.GetDrillDownData(reportParam)
                .then(async (result: any | null) => {
                    if (result != null && result.length > 0) {
                        if (pageNumber === 1) {
                            this.setState({ tableData: result }, () => {
                                this.setState({ loader: false, isLoading: false });
                            });
                        } else {
                            this.setState((prevState) => ({
                                tableData: [...prevState.tableData, ...result], loader: false, isLoading: false
                            }));
                        }
                    } else {
                        this.setState({ loader: false })
                    }
                    resolve();
                })
        } catch (error) {
            Utils.toastError(`Server Error: ${error}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            reject();
        }

    }

    loadData() {
        let { ReportType, reportId, OprID, COA, IsPending } = this.state.queryData;
        let reportParam = {} as any;
        const storage = JSON.parse(localStorage.getItem("storage")!);
        reportParam.tenantid = storage === null ? 0 : (storage.tenantID as any);
        reportParam.Report = ReportType;
        reportParam.reportid = reportId;
        reportParam.Oprid = OprID;
        reportParam.coa = COA;
        reportParam.IsPending = IsPending;
        try {
            ModuleReports.GetHeaderDetails(reportParam)
                .then(async (result: any | null) => {
                    if (result != null) {
                        this.setState({
                            reportName: result.reportname,
                            coaName: result.coaname,
                        })
                    } else {

                    }
                    resolve();
                })
        } catch (error) {
            Utils.toastError(`Server Error: ${error}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            reject();
        }
    }

    getParamValueByParamName(paramName: string): any {
        let pramValue = new URLSearchParams(window.location.search).get(paramName)
        return window.atob(pramValue === null ? "" : pramValue);
    }

    loadReportEncrypt(pageNumber) {
        let reportParam = {} as any;
        const storage = JSON.parse(localStorage.getItem("storage")!);
        reportParam.username = storage === null ? 0 : (storage.userName as any);
        reportParam.tenantId = storage === null ? 0 : (storage.tenantID as any);
        reportParam.StartDate = this.getParamValueByParamName("StartDate");
        reportParam.EndDate = this.getParamValueByParamName("EndDate");
        reportParam.SplitHID = this.getParamValueByParamName("SplitHID");
        reportParam.COA = this.getParamValueByParamName("COA");
        reportParam.UniqueNo = this.getParamValueByParamName("UniqueNo");
        reportParam.Type = this.getParamValueByParamName("Type");
        reportParam.GroupID = this.getParamValueByParamName("GroupID");
        reportParam.SubGroupID = this.getParamValueByParamName("SubGroupID");
        reportParam.SubGroupID2 = this.getParamValueByParamName("SubGroupID2");
        reportParam.AccountType = this.getParamValueByParamName("AccountType");
        reportParam.IsTotal = this.getParamValueByParamName("IsTotal");
        reportParam.Oprid = this.getParamValueByParamName("OprID");
        reportParam.Reportid = this.getParamValueByParamName("reportId");
        reportParam.Report = this.getParamValueByParamName("Report");
        reportParam.ReportType = this.getParamValueByParamName("ReportType");
        reportParam.RowType = this.getParamValueByParamName("RowType");
        reportParam.Ctype = this.getParamValueByParamName("Ctype");
        reportParam.SelectPL = this.getParamValueByParamName("SelectPL");
        reportParam.IsCOS = this.getParamValueByParamName("IsCOS");
        reportParam.IsMOD = this.getParamValueByParamName("IsMOD");
        reportParam.IsFB = this.getParamValueByParamName("IsFB");
        reportParam.LineGroup = this.getParamValueByParamName("LineGroup");
        reportParam.IsPending = this.getParamValueByParamName("IsPending");
        reportParam.PageNumber = pageNumber;
        reportParam.ExportType = "";
        reportParam.Userid = "";

        this.setState({ loader: pageNumber === 1 ? true : false })
        try {
            ModuleReports.GetDrillDownData(reportParam)
                .then(async (result: any | null) => {
                    if (result != null && result.length > 0) {
                        if (pageNumber === 1) {
                            this.setState({ tableData: result }, () => {
                                this.setState({ loader: false, isLoading: false });
                            });
                        } else {
                            this.setState((prevState) => ({
                                tableData: [...prevState.tableData, ...result], loader: false, isLoading: false
                            }));
                        }
                    } else {
                        this.setState({ loader: false })
                    }
                    resolve();
                })
        } catch (error) {
            Utils.toastError(`Server Error: ${error}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            reject();
        }

    }

    loadDataEncrypt() {
        let reportParam = {} as any;
        const storage = JSON.parse(localStorage.getItem("storage")!);
        reportParam.tenantid = storage === null ? 0 : (storage.tenantID as any);
        reportParam.Report = this.getParamValueByParamName("Report");
        reportParam.reportid = this.getParamValueByParamName("reportId");
        reportParam.Oprid = this.getParamValueByParamName("OprID");
        reportParam.coa = this.getParamValueByParamName("COA");
        reportParam.IsPending = this.getParamValueByParamName("IsPending");
        try {
            ModuleReports.GetHeaderDetails(reportParam)
                .then(async (result: any | null) => {
                    if (result != null) {
                        this.setState({
                            reportName: result.reportname,
                            coaName: result.coaname,
                        })
                    } else {

                    }
                    resolve();
                })
        } catch (error) {
            Utils.toastError(`Server Error: ${error}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            reject();
        }
    }

    rowEvents = {
        onClick: (e: any, newRow: any, rowIndex: any) => {
            this.setState({ isSlideOut: false, isTransferSlideOut: false, isTRSideOut: false, isCCSlideout: false, })
            const uniqueNo = newRow.uniqueNo;
            const etype = newRow.etype;
            let backhid = newRow.backhid;
            let pageType = this.getEtype(etype, 'child');
            let acctType1 = this.getAccttype(etype);
            let acctType = newRow.accountType === "Operating" ? "O" : newRow.accountType === "Payroll" ? "P" : newRow.accountType === "Miscellaneous" ? "M" : "";
            let registerRequest1 = { ...this.state.registerRequest };
            registerRequest1.oprID = Number(newRow.oprid);

            if (etype.toLowerCase() === 'Invoice'.toLowerCase()) {
                let registerRequest = {} as any;
                registerRequest.UniqueNo = uniqueNo;
                registerRequest.oprID = Number(newRow.oprid);
                registerRequest.acctType = acctType1;
                this.setState({
                    trUniqueNo: uniqueNo, pageType: pageType, sildeoutHid: Number(backhid),
                    registerRequest
                }, () => {
                    this.setState({ isSlideOut: true });
                });

            } else if (etype.toLowerCase() === 'Deposit'.toLowerCase()
                || etype.toLowerCase() === 'Misc'.toLowerCase()
                || etype.toLowerCase() === 'MiscDeposit'.toLowerCase()
                || etype.toLowerCase() === 'PayrollDeposit'.toLowerCase()
                || etype.toLowerCase() === 'PayrollWithdrawal'.toLowerCase()
                || etype.toLowerCase() === 'WithDrawal'.toLowerCase()
            ) {

                let registerRequest = {} as any;
                registerRequest.UniqueNo = uniqueNo;
                registerRequest.oprID = Number(newRow.oprid);
                registerRequest.acctType = acctType1;
                this.setState({
                    trUniqueNo: uniqueNo, pageType: pageType, sildeoutHid: Number(backhid),
                    registerRequest
                }, () => {
                    this.setState({ isSlideOut: true });
                });

            } else if (etype.toLowerCase() === 'Journal'.toLowerCase() && newRow.vendor.toLowerCase() !== "XXX COA-WISE PAYROLL IMPORT XXX".toLowerCase()) {
                let JERequest = {} as any;
                JERequest.UniqueNo = uniqueNo;
                this.setState({
                    JERequest, pageType: "Journal", sildeoutHid: Number(backhid), isTRSideOut: true
                })

            } else if (etype.toLowerCase() === 'MiscTransfer'.toLowerCase()
                || etype.toLowerCase() === 'PayrollTransfer'.toLowerCase()
                || etype.toLowerCase() === 'Transfer'.toLowerCase()
            ) {
                let transferRequest = {} as any;
                transferRequest.acctType = acctType;
                transferRequest.hasRecouncil = false;
                this.setState({
                    trUniqueNo: uniqueNo, registerRequest: registerRequest1, transferRequest,
                    isTransferSlideOut: true, sildeoutHid: backhid, sildeoutCode: Number(backhid)
                })
            } else if (etype.toLowerCase() === 'CreditCardWithdrawal'.toLowerCase()
                || etype.toLowerCase() === 'CreditCardDeposit'.toLowerCase()) {
                let cctype = etype.toLowerCase() === 'CreditCardWithdrawal'.toLowerCase() ? "CCDEBIT" : "CCCREDIT";
                this.setState({
                    selectedRow: {}, ccSlideType: cctype, trUniqueNo: uniqueNo, registerRequest: registerRequest1,
                    vid: "0", oprId: Number(newRow.oprid), isCCSlideout: true, sildeoutHid: backhid, sildeoutCode: ""
                })
            } else if (etype.toLowerCase() === "DepositEntry".toLowerCase()) {
                Utils.toastError("This transaction was posted from Daily Activity Entry. Details for this transaction cannot be opened.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (etype.toLowerCase() === "Journal".toLowerCase() && newRow.vendor.toLowerCase() === "XXX COA-WISE PAYROLL IMPORT XXX".toLowerCase()) {
                Utils.toastError("Payroll entries cannot be opened.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (etype.toLowerCase() === "NewPayroll".toLowerCase() || etype.toLowerCase() === "PayrollCheckManual".toLowerCase()) {
                Utils.toastError("Payroll entries cannot be opened.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                Utils.toastError("These entries cannot be opened.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
    }

    getAccttype(mtype) {
        let accttype = '';
        accttype = registersevice.getAccttype(mtype);
        return accttype;
    }



    handleTripleDotsMenu = (eventKey: any) => {

        let reportParam = {} as any;
        const storage = JSON.parse(localStorage.getItem("storage")!);
        reportParam.username = storage === null ? 0 : (storage.userName as any);
        reportParam.tenantId = storage === null ? 0 : (storage.tenantID as any);
        if (window.location.search !== '') {
            reportParam.StartDate = this.getParamValueByParamName("StartDate");
            reportParam.EndDate = this.getParamValueByParamName("EndDate");
            reportParam.SplitHID = this.getParamValueByParamName("SplitHID");
            reportParam.COA = this.getParamValueByParamName("COA");
            reportParam.UniqueNo = this.getParamValueByParamName("UniqueNo");
            reportParam.Type = this.getParamValueByParamName("Type");
            reportParam.GroupID = this.getParamValueByParamName("GroupID");
            reportParam.SubGroupID = this.getParamValueByParamName("SubGroupID");
            reportParam.SubGroupID2 = this.getParamValueByParamName("SubGroupID2");
            reportParam.AccountType = this.getParamValueByParamName("AccountType");
            reportParam.IsTotal = this.getParamValueByParamName("IsTotal");
            reportParam.Oprid = this.getParamValueByParamName("OprID");
            reportParam.Reportid = this.getParamValueByParamName("reportId");
            reportParam.Report = this.getParamValueByParamName("Report");
            reportParam.ReportType = this.getParamValueByParamName("ReportType");
            reportParam.RowType = this.getParamValueByParamName("RowType");
            reportParam.Ctype = this.getParamValueByParamName("Ctype");
            reportParam.SelectPL = this.getParamValueByParamName("SelectPL");
            reportParam.IsCOS = this.getParamValueByParamName("IsCOS");
            reportParam.IsMOD = this.getParamValueByParamName("IsMOD");
            reportParam.IsFB = this.getParamValueByParamName("IsFB");
            reportParam.LineGroup = this.getParamValueByParamName("LineGroup");
        } else {
            let { StartDate, EndDate, SplitHID, COA, UniqueNo, Ctype, Type, GroupID, SubGroupID, SubGroupID2, AccountType, IsTotal, OprID, reportId, Report, ReportType, RowType, SelectPL, IsCOS, IsMOD, IsFB, LineGroup } = this.state.queryData;
            reportParam.StartDate = StartDate;
            reportParam.EndDate = EndDate;
            reportParam.SplitHID = SplitHID;
            reportParam.COA = COA;
            reportParam.UniqueNo = UniqueNo;
            reportParam.Type = Type;
            reportParam.Ctype = Ctype;
            reportParam.GroupID = GroupID;
            reportParam.SubGroupID = SubGroupID;
            reportParam.SubGroupID2 = SubGroupID2;
            reportParam.AccountType = AccountType;
            reportParam.IsTotal = IsTotal;
            reportParam.Oprid = OprID;
            reportParam.Reportid = reportId;
            reportParam.Report = Report;
            reportParam.ReportType = ReportType;
            reportParam.RowType = RowType;
            reportParam.SelectPL = SelectPL;
            reportParam.IsCOS = IsCOS;
            reportParam.IsMOD = IsMOD;
            reportParam.IsFB = IsFB;
            reportParam.LineGroup = LineGroup;
        }

        reportParam.PageNumber = 0;
        reportParam.ExportType = eventKey;
        reportParam.Userid = "";
        let currDate = new Date()
        let fileName = "Details-" + (currDate.getMonth() + 1) + "_" + currDate.getDate() + "_" + currDate.getFullYear() + "_" + currDate.getHours() + "_" + currDate.getMinutes() + "_" + currDate.getSeconds();
        reportParam.fileName = eventKey === "pdf" ? fileName + ".pdf" : fileName + ".xlsx";
        try {
            ModuleReports.GetDrillDownDataExport(reportParam)
                .then(async (result: any | null) => {
                    if (result != null && result.length > 0) {

                    } else {

                    }
                    resolve();
                })
        } catch (error) {
            Utils.toastError(`Server Error: ${error}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            reject();
        }
    }

    OpenInvoiceSlideOut = (e, newrow, rowIndex, rowType) => {
        if (!this.state.isBgBlue) {
            let row: any = {};
            row.hid = newrow.hotelid;
            row.etype = "Invoice";
            row.vendor = newrow.vendor;
            row.backHIDCode = newrow.lettercode;
            row.vid = newrow.vid;
            row.oprId = newrow.oprID;
            row.uniqueno = newrow.uniqueno;
            row.checkno = newrow.ckno;
            row.series = newrow.series;
            row.invPostFix = newrow.postFix;
            row.groupID = newrow.groupID;
            this.hideSlideOut(false);
            this.handlerowEvents(e, row, rowIndex, rowType);
        }
    }

    hideSlideOut = (isSave) => {
        this.setState({
            isSlideOut: false,
            isTransferSlideOut: false,
            isTRSideOut: false
        })
    }

    hideTrSlideOut(hasEvent) {
        this.setState({
            isTransferSlideOut: false
        })
    }

    hideCCSlideOut = (isSave) => {
        this.setState({ isCCSlideout: false });
    }

    handlerowEvents = (e: any, row: any, rowIndex: any, rowType) => {
        let registerRequest1 = { ...this.state.registerRequest }
        let etype = '';
        etype = this.getEtype(row.etype, rowType);
        this.setState({
            selectedRow: row, pageType: etype,
            trUniqueNo: row.uniqueno, registerRequest: registerRequest1, vid: row.vid, oprId: row.oprId,
            isSlideOut: true, sildeoutHid: row.hid, sildeoutCode: row.backHIDCode
        })
    }

    getEtype(mtype, rowType) {
        let etype = '';
        etype = registersevice.getEtype(mtype, rowType);
        return etype;
    }

    loadMore = () => {
        this.setState((prevState) => ({
            pageNumber: prevState.pageNumber + 1, isLoading: true
        }), () => {
            if (window.location.search !== '') {
                this.loadReportEncrypt(this.state.pageNumber);
            } else {
                this.loadReport(this.state.pageNumber);
            }
        });
    };

    render() {
        let { tableData, isLoading, loader, reportName, coaName } = this.state;
        const columns = [
            {
                dataField: "lettercode",
                text: "EHID",
                headerClasses: 'ehid',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">
                            {row.lettercode}
                        </EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "date",
                text: "Date",
                headerClasses: 'date',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">
                            {row.date}
                        </EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "period",
                text: "Period",
                headerClasses: 'period',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">
                            {row.period}
                        </EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "invoiceNo",
                text: "Inv. No",
                headerClasses: 'invoiceNo',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">
                            {row.invoiceNo}
                        </EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "date1",
                text: "Inv. Date",
                headerClasses: 'invoiceDate',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">
                            {row.date1}
                        </EllipsisWithTooltip>
                    )
                }

            }, {
                dataField: "vendor",
                text: "Vendor",
                headerClasses: 'vendor',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">
                            {row.vendor}
                        </EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "street",
                text: "Street",
                headerClasses: 'street',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.street}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "city",
                text: "City",
                headerClasses: 'city',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.city}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "description",
                text: "Description",
                headerClasses: 'description',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.description}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "internalnotes",
                text: "Notes",
                headerClasses: 'Notes',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.internalnotes}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "coa",
                text: "COA",
                headerClasses: 'coa',
                classes: 'coa',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.coa}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "ckno",
                text: "CkNo",
                headerClasses: 'ckno',
                classes: 'ckno',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.ckno}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "amount",
                text: "Amount",
                headerClasses: 'amount',
                classes: 'amount',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(Number(row.amount))}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "cl",
                text: "CL",
                headerClasses: 'cl',
                classes: 'cl',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.cl}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "",
                text: "",
                headerClasses: 'inv',
                classes: 'cl',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <>
                            {row.filename1.length > 0 && (
                                <div><FontAwesomeIcon icon={faPaperclip} /></div>
                            )}
                        </>
                    )
                }
            }
        ];

        return (
            <>
                {(tableData.length > 0 && Number(tableData[0].multipleCOA) === -100) ?
                    <Container fluid>
                        <Row className="screen-action">
                            <Col>
                                <div className="screen-heading text-center">
                                    You do not have permission to view the details. Please contact your administrator.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <div className="drilldown">
                        <Container fluid className="body-sec pms-import">
                            <ToastContainer
                                autoClose={3000}
                                containerId={"ddldata"}
                                enableMultiContainer
                            />
                            {this.state.isSlideOut && (
                                <>
                                    {(this.state.pageType === "Invoice" || this.state.pageType === "Incompleteinvoice") ?
                                        <InvoiceEntrySlideout
                                            hidValue={this.state.sildeoutHid}
                                            hotelName={""}
                                            uniqueNo={this.state.trUniqueNo}
                                            pageType={this.state.pageType}
                                            oprId={this.state.oprId}
                                            vid={this.state.vid}
                                            hideSlideOut={this.hideSlideOut.bind(this)}
                                            OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                                            selectedRow={this.state.selectedRow}
                                        />
                                        :
                                        <TransactionSlideout
                                            accDetails={this.state.registerRequest}
                                            hidValue={this.state.sildeoutHid}
                                            hotelName={""}
                                            uniqueNo={this.state.trUniqueNo}
                                            pageType={this.state.pageType}
                                            hideSlideOut={this.hideSlideOut.bind(this)}
                                            OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                                            selectedRow={this.state.selectedRow}
                                        />
                                    }
                                </>

                            )}
                            {this.state.isTransferSlideOut && (
                                <TransferSlideout
                                    accDetails={this.state.registerRequest}
                                    hidValue={this.state.sildeoutHid}
                                    hotelName={""}
                                    uniqueNo={this.state.trUniqueNo}
                                    pageType={this.state.pageType}
                                    transferRequest={this.state.transferRequest}
                                    hideTrSlideOut={this.hideTrSlideOut.bind(this)}
                                />
                            )}

                            {this.state.isTRSideOut && (
                                <JournalSlideOut
                                    JERequest={this.state.JERequest}
                                    hidValue={this.state.sildeoutHid}
                                    hotelName={""}
                                    hideSlideOut={this.hideSlideOut.bind(this)}
                                    newRequest={true}
                                    hasAddPermission={this.state.hasAddPermission}
                                    isFromRepeating={false}
                                />

                            )}

                            {this.state.isCCSlideout && (
                                <CreditCardSlideout
                                    accDetails={this.state.registerRequest}
                                    hidValue={this.state.sildeoutHid}
                                    hotelName={this.state.hotelName}
                                    uniqueNo={this.state.trUniqueNo}
                                    oprId={this.state.oprId}
                                    vid={this.state.vid}
                                    pageType={this.state.ccSlideType}
                                    hideCCSlideOut={this.hideCCSlideOut.bind(this)}
                                />
                            )}
                            <div className="page-heading underline d-flex">
                                <div className="mr-auto align-items-center">
                                    {reportName}
                                    <div className="tag-line">{coaName}</div>
                                </div>
                                <div className="action-group d-flex">
                                    <div className="Accbalance">
                                        <span className="balTitle">Grand Total Amount</span>
                                        <strong className="balMain">{tableData.length > 0 ? Utils.currencyFormat(Number(tableData[0].totalAmount)) : "$0.00"}</strong>
                                    </div>
                                    <Dropdown className="more-action three-dot" alignRight onSelect={this.handleTripleDotsMenu}>
                                        <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey={"pdf"}>Export PDF</Dropdown.Item>
                                            <Dropdown.Item eventKey={"excel"}>Export Excel</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className={loader ? "bdy-sec loader-active pos-relative" : "bdy-sec pos-relative"}>
                                <div className="main-Page">
                                    <div className="result-area">
                                        <div className={`scroll-area ${this.state.densityName}`}>
                                            {!loader && (
                                                <BootstrapTable keyField="rowNo" data={tableData} columns={columns} rowEvents={this.rowEvents} />
                                            )}
                                            {tableData.length > 0 && tableData[0].totalPage > 1 && tableData[0].totalPage > this.state.pageNumber && (
                                                <div className="loadMoreBtn">
                                                    <button type="button" onClick={() => this.loadMore()} disabled={isLoading}
                                                        className="btn btn-ghost btn btn-primary">{isLoading ? "Loading..." : "Load More..."}
                                                    </button>
                                                </div>
                                            )}
                                            {loader && (
                                                <div className="loader-spinner d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="success" />
                                                </div>
                                            )}
                                        </div>
                                        {tableData.length > 0 && (
                                            <div className="count-show">Showing 1 - {tableData.length} of {tableData[0].totalEntries} Entries</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                }
            </>
        );
    }
}
