import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { SingleDropdownListWithoutSearch } from "../../../Common/Components/SingleDropdownListWithoutSearch";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";

import { HidSelector } from "../../../Common/Components/HidSelector";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { Register as registersevice } from "../../../Common/Services/Register";
import { Repeating as repeatingservice } from "../../../Common/Services/Repeating";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { TransactionSlideout } from "../../Register/TransactionSlideout";
import { InvoiceEntrySlideout } from "../../Register/InvoiceEntrySlideout";
import "bootstrap-daterangepicker/daterangepicker.css";
import "../../Register/register.scss";
import { ToastContainer, toast } from "react-toastify";
import { TransferSlideout } from "../../Register/TransferSlideout";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import TableSearch from "../../../Common/Components/TableSearch";
import { TransactionLogHistory } from "../../Register/TransactionLogHistory";
import { BankAccountSlideout } from "../../Accounting/BankAccountSlideout";
import { CreditCardAccountSlideout } from "../../Accounting/CreditCardAccountSlideout";
import $ from "jquery";
import { User } from "../../../Common/Services/User";
import { RegisterExport } from "../../Register/RegisterExportModal";
import Spinner from "react-bootstrap/Spinner";
import { TransactionSlideout as JourTransactionSlideout } from "../JournalEntry/transactionSlideout";
import _ from "lodash";
import { Utils } from "../../../Common/Utilis";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import { CreditCardSlideout } from '../../Register/CreditCardSlideout';

let openRow: any = {};
let isEnterPriseEntry = false;
let isHide = false;
export class Repeating extends React.Component<any, any> {
  private tableSearch: any;
  private childHID: any;
  private accountName: any;
  private dayCount: any;
  private exportModal: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.accountName = React.createRef();
    this.dayCount = React.createRef();
    this.tableSearch = React.createRef();
    this.exportModal = React.createRef();
    this.state = {
      selectedaccount: "",
      selectedOprID: "",
      backPage: "register",
      isShowSlideout: false,
      isCCShowSlideout: false,
      inNew: true,
      isBgBlue: false,
      selectedRow: {},
      hidValue: "Select",
      hotelName: "Select",
      customDate: false,
      repeatingModal: false,
      hotelList: [],
      showChangeLogModal: false,
      moreAction: [
        { name: "Repeating(0)", eventKey: "repeating" },
        { name: "Change Log", eventKey: "changeLog" },
        { name: "Export", eventKey: "Export" },
      ],
      addTransactionAction: [
        { name: "Deposit", eventKey: "deposit", toShow: 0 },
        { name: "Withdrawal", eventKey: "withdrawal", toShow: 0 },
        { name: "Add Debit", eventKey: "AddDebit", toShow: 1 },
        { name: "Add Credit", eventKey: "AddCredit", toShow: 1 },
        { name: "Add Payment", eventKey: "AddPayment", toShow: 1 },
        { name: "Transfer", eventKey: "transfer", toShow: 0 },
      ],
      isCCAcc: false,
      istableloader: false,
      repeatlist: [],
      regularlist: [],
      splitlist: [],
      tableData: [],
      tableData2: [],
      accountBal: [],
      accountNamelist: [],
      viewType: "regular",
      registerRequest: {
        hotelID: -1,
        lettercode: "",
        oprID: -1,
        selectType: "30",
        isDepositHide: "Yes",
        startDate: "",
        endDate: "",
        acctType: "",
      },

      accountNames: [],
      accountName: "All",
      accountNameValue: -1,
      dayFilter: [
        { id: 1, name: "Next 30 Days" },
        { id: 2, name: "Next 60 Days" },
        { id: 3, name: "Next 90 Days" },
        { id: 4, name: "Custom" },
      ],
      dayText: "Next 30 Days",
      dayValue: 1,
      totalAcctBal: 0,
      totalsharedHID: [],
      totalsharedHIDTemp: 0,
      totalsharedHIDCount: 0,
      sharedcode: "",
      dateRange: "",
      start: Utils.getHotalCurrentDate(),
      end: Utils.getHotalCurrentDate(),
      isSelect: true,
      ehidSected: 0,
      isFilter: false,
      isloader: true,
      isSlideOut: false,
      trUniqueNo: 0,
      slideOutRequest: {},
      sildeoutHid: -1,
      sildeoutCode: "",
      pageType: "",
      oprId: -1,
      vid: 0,
      isTransferSlideOut: false,
      isExpand: false,
      densityView: [
        { id: 1, densityType: "densityView_1", name: "WIDE" },
        { id: 2, densityType: "densityView_3", name: "NARROW" },
      ],
      defaultDensityView: "densityView_3",
      expandedList: [],
      expList: [],
      valuesArray: false,
      transferRequest: { hasRecouncil: false, acctType: "" },
      creditCardStatementModal: false,
      CCPaymentShow: false,
      CardUniqueNo: -1,
      permissionlist: [],
      exportModal: false,
      isExportRun: false,

      sortingOrder: "desc",
      isCCSlideout: false,
      ccSlideType: "",
      isTRSideOut: false,
      JERequest: {
        UniqueNo: 0,
      },
    };
    this.handleConcile = this.handleConcile.bind(this);
  }

  componentDidMount() {
    this.getDefaultView();
    this.loadFromAccount();
  }

  getParamValueByParamName(paramName: string): any {
    let valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    window.history.replaceState(null, "");
    return valuesArray;
  }

  loadFromAccount() {
    const location = this.props.location;
    if (location.state !== undefined) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0) {
        let { oprid } = location.state;
        this.setState({ selectedOprID: oprid });
      }
    }
  }

  selectHotelDropDown = (hotelDetails: any) => {
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      return;
    }

    let currentDate: any = Utils.getHotalCurrentDate();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      currentDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    currentDate.setDate(currentDate.getDate());
    this.setState({
      hotelName: hotelDetails.lettercode,
      hidValue: hotelDetails.hotelID,
    });
    let repeatlist = [],
      regularlist = [],
      splitlist = [],
      tableData = [],
      accountBal = [],
      viewType = "regular";
    let registerRequest: any = {
      hotelID: -1,
      oprID: -1,
      selectType: "30",
      isDepositHide: "Yes",
      startDate: "",
      endDate: "",
      acctType: "M",
    };
    let dayFilter: any[] = [
      { id: 1, name: "Next 30 Days" },
      { id: 2, name: "Next 60 Days" },
      { id: 3, name: "Next 90 Days" },
      { id: 4, name: "Custom" },
    ];
    let dayText = "Next 30 Days",
      dayValue = 1,
      totalAcctBal = 0,
      totalsharedHIDCount = 0,
      totalsharedHIDTemp = 0;
    let totalsharedHID: any[] = [];
    let sharedcode = "";
    let accountNamelist: any[] = [];
    let accountNames: any[] = [],
      accountName = "All";
    let accountNameValue = -1;

    this.setState(
      {
        repeatlist,
        regularlist,
        splitlist,
        tableData,
        accountBal,
        viewType,
        registerRequest,
        dayFilter,
        dayText,
        dayValue,
        totalAcctBal,
        totalsharedHID,
        totalsharedHIDTemp,
        totalsharedHIDCount,
        sharedcode,
        accountNamelist,
        accountNames,
        accountName,
        accountNameValue,
        dateRange: "",
        start: Utils.getHotalCurrentDate(),
        end: Utils.getHotalCurrentDate(),
        isSelect: true,
        ehidSected: 0,
        isFilter: false,
      },
      () => {
        let registerRequest1 = { ...this.state.registerRequest };
        registerRequest1.hotelID = hotelDetails.hotelID;
        registerRequest1.lettercode = hotelDetails.lettercode;
        this.getRegisterNames(registerRequest1);

        const valuesArray = this.getParamValueByParamName("");
        if (valuesArray.length > 1) {
          // Do this
        } else {
          this.setState({ registerRequest: registerRequest1 });
        }
        this.CleanSearch();
      }
    );
    if (this.childHID.current !== null) {
      let hidList = this.childHID.current.handleHIDList();
      this.setState({ hotelList: hidList });
    }
  };

  getRepeatlist = (registerRequest: any) => {
    this.setState({ istableloader: true, tableData: [] });
    let regularlist: any = [];
    let exList: any = [];
    repeatingservice
      .Repeatinglist(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          if (result.length > 0) {
            regularlist = result;
            let expandList = result.filter((item) => item.viewType === 1);
            expandList.forEach((item, i) => {
              item.id = i + 1;
            });
            for (let i = 1; i <= expandList.length; i++) {
              exList.push(i);
            }
            this.setState(
              {
                expandedList: [],
                expList: exList,
                registerList: result,
                tableData2: regularlist,
                tableData: regularlist,
                istableloader: false,
                isloader: false,
              },
              () => {
                this.CleanSearch();
              }
            );
          } else {
            this.setState({ istableloader: false }, () => {
              this.CleanSearch();
            });
            this.setState({ isloader: false });
          }
        } else {
          this.setState({ istableloader: false, isloader: false }, () => {
            this.CleanSearch();
          });
        }
        resolve();
      })
      .catch((error) => {
        toast.error(error.message, {
        });
        this.setState({ istableloader: false, isloader: false }, () => {
          this.CleanSearch();
        });
        reject();
      });
  };

  getRegisterPermission = () => {
    let permissionRequest: any = {};
    permissionRequest.hotelID = this.state.registerRequest.hotelID;
    permissionRequest.oprID = this.state.registerRequest.oprID;
    permissionRequest.pageName = "Register";

    registersevice
      .getRegisterPermission(permissionRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          this.setState({ permissionlist: result });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  getRegisterNames = (registerRequest: any) => {
    let selectedaccount = "";
    registersevice
      .RegisterAccount(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          let accountNames: any[] = [];
          result = result.filter(
            (item) =>
              // item.acctType.toLowerCase() !== "c" &&
              item.status.toLowerCase() === "active"
          );
          let resultLength = 0;
          resultLength = result.length;
          if (result.length > 0) {
            let acctName: any = {};
            acctName.id = "All";
            acctName.name = "All";
            acctName.oprID = "All";
            acctName.sort = 0;
            acctName.orderBy = 0;
            acctName.status = "Active";
            result.splice(0, 0, acctName);
            this.setState({ selectedOprID: "All" });
          }

          result.forEach((element) => {
            let acctName: any = {};
            let oprID = element.oprID;
            let name = element.name;
            acctName.id = oprID;
            acctName.sort = element.orderBy;
            acctName.name = name;
            acctName.status = element.status;
            accountNames.push(acctName);
            if (String(this.state.selectedOprID) === String(oprID)) {
              selectedaccount = name;
            }
          });
          accountNames = _.sortBy(accountNames, "sort");

          let allPermList = JSON.parse(localStorage.headPermission);

          let isJourPagePerm = _.some(allPermList, function (el) {
            return el.displayName.toLowerCase() === "journal entry";
          });

          if (isJourPagePerm) {
            let acctName: any = {};
            acctName.id = "-1";
            acctName.name = "JOURNAL";
            acctName.acctType = "J";
            acctName.oprID = "-1";
            acctName.sort = resultLength + 1;
            acctName.status = "Active";
            accountNames.push(acctName);
            result.push(acctName);
          }

          if (resultLength === 1 && !isJourPagePerm) {
            accountNames = accountNames.filter((item) => item.name !== "All");
            result = result.filter((item) => item.name !== "All");
          }

          accountNames = _.sortBy(accountNames, "sort");
          const location = this.props.location;
          if (location.state !== undefined) {
            const stateLength = Object.keys(location.state).length;
            if (stateLength > 0) {
              this.setState(
                {
                  accountNamelist: result,
                  accountNames: accountNames,
                  accountName: selectedaccount,
                },
                () => {
                  let arr: any = {};
                  arr.id = location.state.oprid;
                  arr.name = location.state.acntName;
                  arr.acctType = location.state.acctType;
                  arr.status = "Active";
                  setTimeout(() => {
                    this.handleaccountNameSelectedItem(1, arr);
                  }, 1000);
                }
              );
            }
          } else {
            console.log(accountNames);
            this.setState(
              { accountNamelist: result, accountNames: accountNames },
              () => {
                let jourArray: any = result;
                setTimeout(() => {
                  let acctName: any = {};
                  acctName.id = "All";
                  acctName.name = "All";
                  acctName.oprID = "All";
                  acctName.status = "Active";
                  if (resultLength === 0 && isJourPagePerm)
                    this.handleaccountNameSelectedItem("-1", jourArray[0]);
                  else if (resultLength === 1)
                    this.handleaccountNameSelectedItem(1, accountNames[0]);
                  else if (resultLength > 0)
                    this.handleaccountNameSelectedItem("All", acctName);
                }, 0);
              }
            );
          }
        }

        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  handleaccountNameSelectedItem = (controlID: any, arr: any) => {
    this.setState({
      istableloader: true,
      isloader: true,
      accountStatus: arr.status,
    });
    this.setState({ customDate: false });
    let sharedHIDs: any[] = [];
    let totalsharedHIDCount = 0;
    let acctType = "";
    let sharedcode = "";
    let name = "";
    let isCCA = false;
    if (arr.id === "-1") {
      this.state.accountNamelist
        .filter((x) => x.oprID === arr.id)
        .forEach((element) => {
          acctType = element.acctType;
          name = element.name;
        });
    } else if (arr.id === "All") {
      this.state.accountNamelist
        .filter((x) => x.oprID === arr.id)
        .forEach((element) => {
          acctType = element.acctType;
          name = element.name;
        });
    } else {
      this.state.accountNamelist
        .filter((x) => x.oprID === arr.id)
        .forEach((element) => {
          let hidCode: any = [];
          hidCode = element.sharedHID.split("[");
          totalsharedHIDCount = element.hidCount;
          acctType = element.acctType;
          sharedcode = element.sharedcode;
          name = element.name;
          isCCA = element.acctType.toString().toLocaleLowerCase() === "c";
          hidCode.forEach((item) => {
            let sharedHID: any = {};
            let lettercodes: any = [];
            lettercodes = item.split("_");
            let splitHid = lettercodes[0];
            let splitcode = lettercodes[1];
            sharedHID.hid = splitHid;
            sharedHID.lettercode = splitcode;
            sharedHID.isChecked = false;
            sharedHIDs.push(sharedHID);
          });
        });
    }

    let registerRequest = { ...this.state.registerRequest };
    registerRequest.oprID = String(arr.id);
    registerRequest.acctType = acctType;
    registerRequest.hotelID = this.state.hidValue;
    registerRequest.hotelName = this.state.hotelName;
    registerRequest.accountName = name;
    registerRequest.selectType = "30";

    this.setState(
      {
        CardUniqueNo: arr.id,
        isCCAcc: isCCA,
        accountNameValue: arr.id,
        totalsharedHIDCount: totalsharedHIDCount,
        dayText: "Next 30 Days",
        accountName: name,
        totalsharedHID: sharedHIDs,
        totalsharedHIDTemp: sharedHIDs,
        registerRequest: registerRequest,
        sharedcode: sharedcode,
        tableData: [],
        dateRange: "",
        start: Utils.getHotalCurrentDate(),
        end: Utils.getHotalCurrentDate(),
        isSelect: true,
        ehidSected: 0,
        isFilter: false,
        viewType: "regular",
      },
      () => {
        this.getRepeatlist(registerRequest);
        this.getRegisterPermission();
      }
    );
  };

  handleSelectedItem = (controlID: any, id: any) => {
    let selectType;
    let dayText: any;
    this.setState({ predayValue: this.state.dayValue });
    if (id === 1) {
      selectType = "30";
      dayText = "Next 30 Days";
    } else if (id === 2) {
      selectType = "60";
      dayText = "Next 60 Days";
    } else if (id === 3) {
      selectType = "90";
      dayText = "Next 90 Days";
    } else if (id === 4) {
      let { dateRange } = this.state;
      selectType = "DateRange";
      dayText = "Custom";
      this.setState({ customDate: true });
      let startDate;
      let endDate;

      if (dateRange === "") {
        let now = Utils.getHotalCurrentDate();
        let pre30days;
        if (this.state.dayValue === 1) {
          pre30days = new Date(now.setDate(now.getDate() + 30));
        } else if (this.state.dayValue === 2) {
          pre30days = new Date(now.setDate(now.getDate() + 60));
        } else if (this.state.dayValue === 3) {
          pre30days = new Date(now.setDate(now.getDate() + 90));
        }

        let calenderYear = pre30days.getFullYear();
        let calenderDate =
          pre30days.getDate() > 9
            ? pre30days.getDate()
            : 0 + "" + pre30days.getDate();
        let calenderMonth =
          pre30days.getMonth() + 1 > 9
            ? pre30days.getMonth() + 1
            : 0 + "" + (pre30days.getMonth() + 1);
        endDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

        let datee = Utils.getHotalCurrentDate();
        let calenderYeare = datee.getFullYear();
        let calenderDatee =
          datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
        let calenderMonthe =
          datee.getMonth() + 1 > 9
            ? datee.getMonth() + 1
            : 0 + "" + (datee.getMonth() + 1);
        startDate = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;
      } else {
        startDate = this.state.dateRange.split("-")[0].trim();
        endDate = this.state.dateRange.split("-")[1].trim();
      }
      this.setState({
        dateRange: startDate + " - " + endDate,
        start: startDate,
        end: endDate,
        dayText,
        selectType,
      });
      setTimeout(() => {
        $("#dtrange").trigger("click");
      }, 100);
    }
    if (id !== 4) {
      this.setState({ istableloader: true, tableData: [] }, () => {
        let registerRequest = { ...this.state.registerRequest };
        registerRequest.selectType = selectType;
        this.getRepeatlist(registerRequest);
        this.setState({
          registerRequest,
          dateRange: "",
          customDate: false,
          dayValue: id,
          dayText,
        });
      });
    }
    this.handleClearAll("");
    this.setState({ isFilter: false });
  };

  getJournalRepeatList = () => {
    this.handleSelectedItem(0, this.state.dayValue);
  };

  hideBankSlideOut(mode) {
    if (mode === 1) {
      let registerRequest1: any = {};
      registerRequest1.hotelID = this.state.hidValue;
      registerRequest1.lettercode = this.state.hotelName;
      this.selectHotelDropDown(registerRequest1);
    }
    this.setState({ isShowSlideout: false, isCCShowSlideout: false });
  }

  handleApplyEvent = (event, picker) => {
    let startDate = new Date(picker.startDate._d).toLocaleDateString();
    let endDate = new Date(picker.endDate._d).toLocaleDateString();
    let registerRequest = { ...this.state.registerRequest };
    registerRequest.selectType = "DateRange";
    registerRequest.startDate = startDate;
    registerRequest.endDate = endDate;
    this.getRepeatlist(registerRequest);
    const newStartDate =
      startDate.split("/")[1] +
      "/" +
      startDate.split("/")[0] +
      "/" +
      startDate.split("/")[2];
    const newEndDate =
      endDate.split("/")[1] +
      "/" +
      endDate.split("/")[0] +
      "/" +
      endDate.split("/")[2];
    this.setState({
      registerRequest,
      dateRange: newStartDate + " - " + newEndDate,
    });
  };

  handleClearAll = (e: any) => {
    let allItemList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.totalsharedHID))
    );
    allItemList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ totalsharedHID: allItemList, isSelect: true });
  };

  resetFilter = (e) => {
    this.handleClearAll(e);
    this.setState({ isFilter: false });
    this.setState({ tableData: [] }, () => {
      this.setState({ tableData: this.state.tableData2 });
    });
  };

  hideTrSlideOut(hasEvent) {
    if (this.state.isBgBlue) {
      let win = window.open("about:blank", "_self");
      win?.close();
    } else {
      if (hasEvent) {
        let registerRequest = this.state.registerRequest;
        if (registerRequest.accountName === "All")
          registerRequest.oprID = "All";
        this.setState(
          {
            isTransferSlideOut: false,
            registerList: [],
            tableData: [],
            transferRequest: { hasRecouncil: false, acctType: "" },
          },
          () => {
            this.getRepeatlist(registerRequest);
          }
        );
      } else {
        this.setState({
          isTransferSlideOut: false,
          transferRequest: { hasRecouncil: false, acctType: "" },
        });
      }
    }
  }

  hideSlideOut(isSave) {
    if (this.state.isBgBlue) {
      let win = window.open("about:blank", "_self");
      win?.close();
    } else {
      this.setState({
        isSlideOut: false,
        isTransferSlideOut: false,
      });

      if (isSave) {
        let registerRequest = this.state.registerRequest;
        if (registerRequest.accountName === "All")
          registerRequest.oprID = "All";

        this.setState(
          {
            registerList: [],
            tableData: [],
          },
          () => {
            this.getRepeatlist(registerRequest);
          }
        );
      }
    }
  }

  notesIcon() {
    return (
      <svg
        stroke="currentColor"
        fill="#1d1e20"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1.2em"
        width="1.2em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19,4h-3V2h-2v2h-4V2H8v2H5C3.897,4,3,4.897,3,6v14c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V6C21,4.897,20.103,4,19,4z M5,20V7h3h2h4h2h3V6l0.002,14H5z"></path>
        <path d="M7 9H17V11H7zM7 13H12V15H7z"></path>
      </svg>
    );
  }

  handleDensityView = (viewName) => {
    this.setState({ defaultDensityView: viewName });
  };

  getEtype(mtype, rowType) {
    let etype = "";
    etype = registersevice.getEtype(mtype, rowType);
    return etype;
  }

  OpenInvoiceSlideOut = (e, newrow, rowIndex, rowType) => {
    if (!this.state.isBgBlue) {
      let row: any = {};
      row.hid = newrow.hotelid;
      row.etype = "Invoice";
      row.vendor = newrow.vendor;
      row.backHIDCode = newrow.lettercode;
      row.vid = newrow.vid;
      row.oprId = newrow.oprID;
      row.uniqueno = newrow.uniqueno;
      row.checkno = newrow.ckno;
      row.series = newrow.series;
      row.invPostFix = newrow.postFix;
      this.hideSlideOut(false);
      this.handlerowEvents(e, row, rowIndex, rowType);
    }
  };

  actTypeName = (e) => {
    let acctType = "";
    e === "O"
      ? (acctType = "Operating")
      : e === "P"
        ? (acctType = "Payroll")
        : e === "M" && (acctType = "Miscellaneous");
    return acctType;
  };

  handlerowEvents = (e: any, row: any, rowIndex: any, rowType) => {
    if (row.ckno === "DEP") {
      toast.error("Daily entry can not be open here.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (
      row.etype.toLowerCase() === "newpayroll" ||
      row.etype.toLowerCase() === "payroll" ||
      row.etype.toLowerCase() === "payrollcheckmanual"
    ) {
      toast.error("Payroll entry can not be open here.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (row.etype.toLowerCase() === "journal") {
      this.getJournalEntry(row);
    } else if (row.etype.toString().toLocaleLowerCase().includes('cc')) {
      let registerRequest1 = { ...this.state.registerRequest };
      registerRequest1.acctType = row.etype;
      registerRequest1.oprID = row.oprId
      this.setState({
        registerRequest: registerRequest1,
        pageType: row.etype,
        oprId: row.oprId,
        sildeoutHid: row.hid,
        sildeoutCode: row.backHIDCode,
        trUniqueNo: row.uniqueno,
        isCCSlideout: true
      })
    } else {
      let isHIDPermission = this.state.hotelList.filter(
        (e) => e.hotelID === row.hid
      );
      if (isHIDPermission.length > 0) {
        let registerRequest1 = { ...this.state.registerRequest };
        let transferRequest = { ...this.state.transferRequest };

        if (this.state.accountName === "All") {
          let acctType = "";
          let oprID = "";
          this.state.accountNamelist
            .filter((x) => x.oprID === row.oprId)
            .forEach((element) => {
              acctType = element.acctType;
              oprID = element.oprID;
            });
          registerRequest1.acctType = acctType;
          registerRequest1.oprID = oprID;
        }

        let etype = "";
        etype = this.getEtype(row.etype, rowType);
        this.setState({ pageType: etype });
        if (
          row.etype
            .toString()
            .toLowerCase()
            .includes("Transfer".toLocaleLowerCase())
        ) {
          let accountTo = "";
          let accName = row.vendor.split("-")[1].trim().charAt(0);
          let accountFrom = row.vendor.split("To")[0].split("-")[1].trim();
          if (this.state.accountName === "All")
            accountTo = row.accountName.split("-")[1].trim(); //selectedAcc
          else accountTo = registerRequest1.accountName.split("-")[1].trim(); //selectedAcc

          if (this.state.hotelName !== row.backHIDCode) {
            let errMessage = `This transaction was entered into ${row.backHIDCode}. You need to change the EHID menu to match this EHID in order to view the transaction details.`;
            toast.error(errMessage, { position: toast.POSITION.BOTTOM_RIGHT });
          } else if (
            this.state.hotelName === row.backHIDCode &&
            accountFrom !== accountTo
          ) {
            let errMessage = `${this.actTypeName(
              accName
            )} transfer entry can't be open.`;
            toast.error(errMessage, { position: toast.POSITION.BOTTOM_RIGHT });
          } else {
            transferRequest.acctType = accName;
            transferRequest.hasRecouncil = row.cl !== null && row.cl !== "";
            this.setState({
              trUniqueNo: row.uniqueno,
              registerRequest: registerRequest1,
              transferRequest,
              isTransferSlideOut: true,
              sildeoutHid: row.hid,
              sildeoutCode: row.backHIDCode,
            });
          }
        } else {
          this.setState({
            selectedRow: row,
            trUniqueNo: row.uniqueno,
            registerRequest: registerRequest1,
            vid: row.vid,
            oprId: row.oprId,
            isSlideOut: true,
            sildeoutHid: row.hid,
            sildeoutCode: row.backHIDCode,
          });
        }
      } else {
        toast.error("User does not have permission to edit.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };
  getJournalEntry = (row) => {
    if (
      row.vendor.toString().toLocaleLowerCase() ===
      "Payroll".toLocaleLowerCase()
    ) {
      toast.error(`Payroll entries can not be updated.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      let JERequest = { ...this.state.JERequest };
      JERequest.UniqueNo = row.uniqueno;
      this.setState({ JERequest, isTRSideOut: true });
    }
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.handlerowEvents(e, row, rowIndex, "main");
    },
  };

  rowChildEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.handlerowEvents(e, row, rowIndex, "child");
    },
  };

  handleBtnClick = (type) => {
    let { expList } = this.state;
    if (type === "Collapse_All") {
      this.setState(() => ({
        expandedList: [],
      }));
    } else {
      this.setState(() => ({
        expandedList: expList,
      }));
    }
  };

  CleanSearch = () => {
    if (this.tableSearch.current !== null) {
      this.tableSearch.current.CleanSearch();
    }
  };

  showHideExportModal = () => {
    this.setState({
      exportModal: !this.state.exportModal,
    });
  };

  handleConcile = (queryStringparm) => {
    User.GenerateToken()
      .then(async (result: any | null) => {
        if (result) {
          let mergeURL = result.result.mergeURL;
          let tokenID = result.result.tokenID;
          let pageURL,
            queryStringKey,
            queryStringValue,
            functionName = "";
          queryStringKey = "PageName";
          queryStringValue = queryStringparm;
          functionName = this.state.registerRequest.acctType;
          let hotelID = this.state.registerRequest.hotelID;
          let hCode = this.state.registerRequest.hotelName;
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let userName = storage.userName;
          const serverTimeResponse = await User.GetServerTime();
          let currentTimeUTC = serverTimeResponse ? serverTimeResponse : null;

          if (!currentTimeUTC) {
              throw new Error("Failed to retrieve server time from API.");
          }
          window.open(
            mergeURL +
            // "http://localhost:50491/login.aspx" +
            "?logInUser=" +
            Utils.encryptString(`${userName}|${currentTimeUTC}`) +
            "&tokenID=" +
            btoa(tokenID) +
            "&module=" +
            btoa("Accounting") +
            "&logInType=2&PageUrl=" +
            pageURL +
            "&Key=" +
            btoa(queryStringKey) +
            "&Value=" +
            btoa(queryStringValue) +
            "&function=" +
            btoa(functionName) +
            "&hid=" +
            hotelID +
            "&HCode=" +
            hCode +
            "&AcctID=" +
            this.state.registerRequest.oprID
          );
        }

        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  showHideChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
    });
  };

  hideJournalSlideOut = (hasEvent) => {
    if (hasEvent) {
      this.setState({ isTRSideOut: false, tableData: [] }, () => {
        this.getJournalRepeatList();
      });
    } else {
      this.setState({ isTRSideOut: false }, () => {
        this.getJournalRepeatList();
      });
    }
  };

  hideCCSlideOut = (isSave) => {
    if (isSave) {
      this.setState({ isCCSlideout: false, tableData: [] }, () => {
        let acctName: any = {};
        acctName.id = "All";
        acctName.name = "All";
        acctName.oprID = "All";
        acctName.status = "Active";
        let currentAcc = this.state.accountNamelist.filter((x) => x.oprID === this.state.CardUniqueNo);
        if (currentAcc.length > 0) {
          currentAcc.forEach(item => item["id"] = item.oprID);
          this.handleaccountNameSelectedItem(1, currentAcc[0]);
        } else {
          this.handleaccountNameSelectedItem("All", acctName);
        }
      });
    }
    this.setState({ isCCSlideout: false });
  }

  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: any[] = [];
    laborSaveAsDefaultdtos.push({
      fieldName: "sort",
      fieldValue: this.state.defaultDensityView,
      pageName: "Repeating",
    });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch((error) => {
        toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({ showLoader: false });
      });
  };

  getDefaultView() {
    laborPerformance
      .getDefaultViewProperties("Repeating")
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          this.setState({
            defaultDensityView: response[0].fieldValue,
          });
        } else {
          this.setState({
            defaultDensityView: this.state.densityView[1].densityType,
          });
        }
      })
      .catch((error) => {
        this.setState({
          defaultDensityView: this.state.densityView[1].densityType,
        });
        toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }

  render() {

    const columns = [
      {
        dataField: "date1",
        text: "",
        sort: true,
        order: "desc",
        sortCaret: (order, column) => {
          if (!order)
            return (
              <div className="d-flex descending">
                <div>Next Post Date</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "asc")
            return (
              <div className="d-flex ascending">
                <div>Next Post Date</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "desc")
            return (
              <div className="d-flex descending">
                <div>Next Post Date</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          return null;
        },
        onSort: (field, order) => {
          if (order === "asc") {
            this.setState({ sortingOrder: "asc" });
          } else if (order === "desc") {
            this.setState({ sortingOrder: "desc" });
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.date}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "accountName",
        text: "Account Name",
        hidden: this.state.accountName === "All" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.accountName}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "backHIDCode",
        text: "EHID",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              {row.codeCount > 1 ? (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id={"ehid-list"}>{row.splitCode}</Tooltip>}
                >
                  <span>Multiple</span>
                </OverlayTrigger>
              ) : (
                <div>{row.splitCode}</div>
              )}
            </>
          );
        },
      },
      {
        dataField: "vendor",
        text: "Vendor",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.vendor}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "internalnotes",
        text: "Notes",
        searchable: false,
        hidden: true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {this.notesIcon()}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "ckno",
        text: "Type",
        hidden: true,
      },
      {
        dataField: this.state.isCCAcc ? "debits" : "payment",
        text: this.state.isCCAcc ? "Debits" : "Payment",
        headerAlign: "right",
        hidden: true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="text-right">
              <EllipsisWithTooltip placement="bottom">
                {row.payment}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: this.state.isCCAcc ? "credits" : "deposit",
        text: this.state.isCCAcc ? "Credits" : "Deposit",
        headerAlign: "right",
        hidden: true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="text-right">
              <EllipsisWithTooltip placement="bottom">
                {row.deposit}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "endDateType",
        text: "Repeat Thru",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>
              <EllipsisWithTooltip placement="bottom">
                {row.endDateType}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "transTotal",
        headerAlign: "right",
        text: "Trans. Total",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="text-right">
              <EllipsisWithTooltip placement="bottom">
                {row.transTotal}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "cl",
        text: "CL",
        hidden: true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="text-center">
              <EllipsisWithTooltip placement="bottom">
                {row.cl}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "",
        hidden: true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item key={rowIndex}>Dummy</Dropdown.Item>
              <Dropdown.Item key={rowIndex}>Dummy</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
      {
        dataField: "invoiceNo",
        text: "",
        hidden: true,
      },
      {
        dataField: "coa",
        text: "",
        hidden: true,
      },
      {
        dataField: "coaname",
        text: "",
        hidden: true,
      },
      {
        dataField: "coaname1",
        text: "",
        hidden: true,
      },
      {
        dataField: "payment1",
        text: "",
        hidden: true,
      },
      {
        dataField: "deposit1",
        text: "",
        hidden: true,
      },
      {
        dataField: "runningTotal1",
        text: "",
        hidden: true,
      },
      {
        dataField: "date",
        text: "",
        hidden: true,
      },
      {
        dataField: "period",
        text: "",
        hidden: true,
      },
      {
        dataField: "splitCode",
        text: "",
        hidden: true,
      },
    ];

    const handleOnExpand = (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        this.setState(() => ({
          expandedList: [...this.state.expandedList, row.id],
        }));
      } else {
        this.setState(() => ({
          expandedList: this.state.expandedList.filter((x) => x !== row.id),
        }));
      }
    };

    const expandRow = {
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderChild(row),
      expanded: this.state.expandedList,
      showExpandColumn: true,
      expandByColumnOnly: true,
      onExpand: handleOnExpand,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const getRenderChild = (registerList, row) => {
      return registerList.filter(
        (splist) =>
          splist.viewType === 2 &&
          (splist.uniqueno ===
            row.uniqueno /* && (splist.payment === row.payment)*/ ||
            (row.uniqueno === "-1" &&
              splist.vid === row.vid &&
              splist.date1 === row.date1 &&
              splist.ckno === row.ckno))
      );
    };
    const getRenderSplitHID = (registerList, row) => {
      return registerList.filter(
        (splist) =>
          splist.viewType === 2 &&
          (splist.uniqueno ===
            row.uniqueno /* && (splist.payment === row.payment)*/ ||
            (row.uniqueno === "-1" &&
              splist.vid === row.vid &&
              splist.date1 === row.date1 &&
              splist.ckno === row.ckno))
      );
    };

    const renderChild = (row) => {
      let { registerList } = this.state;
      let splitlist = [];
      splitlist = getRenderChild(registerList, row);
      openRow = row;
      if (row.isEnterPrise) isEnterPriseEntry = true;
      else isEnterPriseEntry = false;

      if (row.etype === "Journal") {
        columnChild[6].hidden = true;
        columnChild[7].hidden = false;
        columnChild[8].hidden = false;
      } else {
        columnChild[6].hidden = false;
        columnChild[7].hidden = true;
        columnChild[8].hidden = true;
      }
      return (
        <>
          <ToolkitProvider
            keyField="Child_rNo"
            data={splitlist}
            columns={columnChild}
            search
          >
            {(props) => (
              <>
                <div
                  className={
                    this.state.registerRequest.acctType === "J" ||
                      this.state.registerRequest.acctType === undefined
                      ? "forRePeaatingChildTableJour"
                      : "forRePeaatingChildTable"
                  }
                >
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="id"
                    rowEvents={this.rowChildEvents}
                    hover
                    noDataIndication={
                      "No activity for the selected time period"
                    }
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </>
      );
    };

    const columnChild = [
      {
        headerFormatter: (column: any, colIndex: any) => {
          let { registerList } = this.state;
          let splitlist: any = []; //openRow
          splitlist = splitlist = getRenderChild(registerList, openRow);
          let invoceArray: any = [];
          for (let i = 0; i < splitlist.length; i++) {
            invoceArray.push(splitlist[i].invoiceNo);
          }
          const uniqueInvoceArray: any = new Set(invoceArray);
          const setUnique = [...uniqueInvoceArray];
          if (setUnique.length <= 0) {
            return <span></span>;
          } else if (setUnique[0] !== "") {
            return <span>{column.text}</span>;
          } else {
            return <span></span>;
          }
        },
        dataField: "invoiceNo",
        text: "INVOICE",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="hasInvoice">
              <strong>
                <EllipsisWithTooltip placement="bottom">
                  {row.invoiceNo}
                </EllipsisWithTooltip>
              </strong>
              {row.invoiceNo !== "" && this.notesIcon()}
            </div>
          );
        },
      },
      {
        headerFormatter: (column: any, colIndex: any) => {
          let { registerList } = this.state;
          let splitlist: any = [];
          splitlist = splitlist = getRenderSplitHID(registerList, openRow);
          let splitHIDArray: any = [];
          for (let i = 0; i < splitlist.length; i++) {
            if (openRow.isEnterPrise)
              splitHIDArray.push(splitlist[i].splitCode);
          }

          const uniqueInvoceArray: any = new Set(splitHIDArray);
          const setUnique = [...uniqueInvoceArray];
          if (setUnique.length <= 0) {
            return <span></span>;
          } else if (setUnique[0] !== "") {
            return <span>{column.text}</span>;
          } else {
            return <span></span>;
          }
        },
        dataField: "",
        text: "EHID",
        formatter: (cell: any, row: any, rowIndex: any) => {
          let dis = isEnterPriseEntry ? "block" : "none";
          return <div style={{ display: dis }}>{row.splitCode}</div>;
        },
      },
      {
        dataField: "coa",
        text: "COA #",
      },
      {
        dataField: "coaname",
        text: "COA NAME",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.coaname}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "description",
        text: "DESCRIPTION",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.etype.toString().toLowerCase() === "transfer"
                ? row.internalnotes
                : row.description}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "period",
        text: "A/C PERIOD",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.period}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "transTotal",
        text: "AMOUNT",
        headerAlign: "right",
        hidden: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="text-right">
              <EllipsisWithTooltip placement="bottom">
                {row.transTotal}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "dr",
        text: "DEBIT",
        headerAlign: "right",
        hidden: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="text-right">
              <EllipsisWithTooltip placement="bottom">
                {row.dr}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "cr",
        text: "CREDIT",
        headerAlign: "right",
        hidden: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="text-right">
              <EllipsisWithTooltip placement="bottom">
                {row.cr}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "",
        hidden: true,
      },
    ];

    return (
      <React.Fragment>
        {this.state.isExportRun && (
          <div className="matrixSpinnerCenter">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
        <div className={this.state.isBgBlue ? "bg-blue-static" : ""}>
          <ToastContainer autoClose={3000} enableMultiContainer />
          {this.state.showChangeLogModal && (
            <TransactionLogHistory
              payPeriodStartDate={""}
              payPeriodEndDate={""}
              accType={"All"}
              showChangeLogModal={this.state.showChangeLogModal}
              showHideChangeLogModal={this.showHideChangeLogModal}
              hotelName={""}
              hotelId={0}
              hideCrossAndClearFilters={false}
              shiftId={0}
              shiftDate={""}
            />
          )}
          {this.state.isSlideOut && (
            <>
              {this.state.pageType === "Invoice" ? (
                <InvoiceEntrySlideout
                  hidValue={this.state.sildeoutHid}
                  hotelName={this.state.sildeoutCode}
                  uniqueNo={this.state.trUniqueNo}
                  pageType={this.state.pageType}
                  oprId={this.state.oprId}
                  vid={this.state.vid}
                  hideSlideOut={this.hideSlideOut.bind(this)}
                  OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                  selectedRow={this.state.selectedRow}
                />
              ) : (
                <TransactionSlideout
                  accDetails={this.state.registerRequest}
                  hidValue={this.state.sildeoutHid}
                  hotelName={this.state.sildeoutCode}
                  uniqueNo={this.state.trUniqueNo}
                  pageType={this.state.pageType}
                  hideSlideOut={this.hideSlideOut.bind(this)}
                  OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                  selectedRow={this.state.selectedRow}
                  isFromRepeating={true}
                  pageFrom={"repeatingPage"}
                />
              )}
            </>
          )}
          {this.state.isTRSideOut && (
            <JourTransactionSlideout
              JERequest={this.state.JERequest}
              hidValue={this.state.hidValue}
              hotelName={this.state.hotelName}
              hideSlideOut={this.hideJournalSlideOut}
              isFromRepeating={true}
              pageFrom={"repeatingPage"}
            //getJournalRepeatList={this.getJournalRepeatList}
            />
          )}

          {this.state.isTransferSlideOut && (
            <TransferSlideout
              accDetails={this.state.registerRequest}
              hidValue={this.state.sildeoutHid}
              hotelName={this.state.sildeoutCode}
              uniqueNo={this.state.trUniqueNo}
              pageType={this.state.pageType}
              valuesArray={this.state.valuesArray}
              transferRequest={this.state.transferRequest}
              hideTrSlideOut={this.hideTrSlideOut.bind(this)}
              isFromRepeating={true}
              pageFrom={"repeatingPage"}
            />
          )}
          {this.state.isCCSlideout && (
            <CreditCardSlideout
              accDetails={this.state.registerRequest}
              hidValue={this.state.sildeoutHid}
              hotelName={this.state.sildeoutCode}
              uniqueNo={this.state.trUniqueNo}
              oprId={this.state.oprId}
              pageType={this.state.pageType}
              vid={this.state.vid}
              hideCCSlideOut={this.hideCCSlideOut.bind(this)}
              isFromRepeating={true}
              pageFrom={"repeatingPage"}
            // slideOutFrom="register-page-creditcard-slideout"
            />
          )}
          <div className="register">
            <Container fluid className="body-sec">
              <div className="page-heading underline d-flex">
                <HidSelector
                  onClickEvent={this.selectHotelDropDown}
                  ref={this.childHID}
                  modulename={"Accounting"}
                />
                {this.state.accountNameValue !== -1 && (
                  <div className="mr-auto d-flex align-items-center">
                    Repeating
                  </div>
                )}
              </div>

              {this.state.hidValue !== "Select" && (
                <div className="main-Page">
                  <div className="bdy-sec">
                    <ToolkitProvider
                      keyField="Modal_rNo"
                      data={this.state.tableData.filter(
                        (x) => x.viewType === 1
                      )}
                      columns={columns}
                      search
                      rowEvents={this.rowEvents}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          {this.state.accountNames.length > 0 && (
                            <div className="search-header registerHeaderWithoutSearch d-flex">
                              <div className="filter-section">
                                <div className="singleSearchDropdownListWithIcon">
                                  <SingleSearchDropdownList
                                    id={"tableAccountName"}
                                    ref={this.accountName}
                                    key={Math.floor(Math.random() * 90 + 10)}
                                    itemList={this.state.accountNames}
                                    handleSelectedItem={this.handleaccountNameSelectedItem.bind(
                                      this,
                                      1
                                    )}
                                    defaultItem={this.state.HIDToNM}
                                    defaultText={"Search..."}
                                    defaultName={this.state.accountName}
                                    calHomeIcon={true}
                                    currentChecked={this.state.accountName}
                                    controlID="2"
                                    pageType={"Register"}
                                    isStatusbadge={true}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="search-header registerSearchHeader d-flex">
                            {this.state.accountNameValue !== -1 && (
                              <>
                                <>
                                  {!this.state.isloader ? (
                                    <div className="registerSearch">
                                      <TableSearch
                                        id="tableSearch"
                                        key="tableSearch"
                                        ref={this.tableSearch}
                                        {...props.searchProps}
                                        CleanSearch={this.CleanSearch.bind(
                                          this
                                        )}
                                        placeholder={"Filter Transactions"}
                                      />
                                    </div>
                                  ) : (
                                    <div className="single-loader loadingAnimation"></div>
                                  )}
                                </>
                                <div
                                  className={
                                    this.state.customDate
                                      ? "filter-section cusDateMargin d-flex align-items-center"
                                      : "filter-section cusDateMargin d-flex align-items-center mr-auto"
                                  }
                                >
                                  {!this.state.isloader ? (
                                    <>
                                      {this.state.accountNamelist.length >
                                        0 && (
                                          <>
                                            <SingleDropdownListWithoutSearch
                                              id={"tabledayCount"}
                                              ref={this.dayCount}
                                              key={Math.floor(
                                                Math.random() * 90 + 10
                                              )}
                                              itemList={this.state.dayFilter}
                                              handleSelectedItem={this.handleSelectedItem.bind(
                                                this,
                                                1
                                              )}
                                              defaultText={"Search day Status"}
                                              defaultName={this.state.dayText}
                                              controlID="1"
                                              calIcon={true}
                                            />
                                          </>
                                        )}
                                    </>
                                  ) : (
                                    <div className="single-loader loadingAnimation"></div>
                                  )}
                                </div>
                                {!this.state.isloader ? (
                                  <>
                                    {this.state.customDate && (
                                      <div
                                        className={
                                          this.state.customDate
                                            ? "dateRangePicker hideDisableDate mr-auto"
                                            : "dateRangePicker hideDisableDate"
                                        }
                                      >
                                        <DateRangePicker
                                          onApply={this.handleApplyEvent}
                                          initialSettings={{
                                            startDate: new Date(
                                              this.state.start
                                            ),
                                            endDate: new Date(this.state.end),
                                          }}
                                        >
                                          <div className="d-flex align-items-center info">
                                            <div
                                              className="shared-list"
                                              id="dtrange"
                                            >
                                              {this.state.dateRange}
                                            </div>
                                          </div>
                                        </DateRangePicker>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className="single-loader loadingAnimation"></div>
                                )}

                                <div className="regCollapseButton">
                                  {!this.state.isloader ? (
                                    <button
                                      type="button"
                                      onClick={(e: any) =>
                                        this.handleBtnClick(
                                          this.state.expandedList.length > 0
                                            ? "Collapse_All"
                                            : "Expend_All"
                                        )
                                      }
                                      className="btn-outline-primary btn btn-primary"
                                      disabled={
                                        this.state.istableloader ||
                                        this.state.tableData.length === 0
                                      }
                                    >
                                      {this.state.expandedList.length > 0
                                        ? "Collapse All"
                                        : "Expand All"}
                                    </button>
                                  ) : (
                                    <div className="single-loader loadingAnimation"></div>
                                  )}
                                </div>
                                <div className="filter-section ml-1 hasGearIcon">
                                  {!this.state.isloader ? (
                                    <Dropdown className="more-action bg-blue dropdown single-select-image">
                                      <Dropdown.Toggle id="rolesList">
                                        <div className="d-flex align-items-center">
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                                          </svg>
                                          <div className="drop-arrow ml-1 d-flex">
                                            <FiChevronDown />
                                          </div>
                                        </div>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <div className="gearIconHeader headerTop">
                                          <strong>Density View Control</strong>
                                        </div>
                                        {this.state.densityView.map(
                                          (items, index) => (
                                            <div
                                              className={
                                                this.state
                                                  .defaultDensityView ===
                                                  items.densityType
                                                  ? "dropdownItem active"
                                                  : "dropdownItem"
                                              }
                                              key={items.id}
                                              onClick={(e: any) =>
                                                this.handleDensityView(
                                                  items.densityType
                                                )
                                              }
                                            >
                                              {items.name}
                                              {this.state.defaultDensityView ===
                                                items.densityType && (
                                                  <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                                      fill="#2CAF92"
                                                    ></path>
                                                  </svg>
                                                )}
                                            </div>
                                          )
                                        )}
                                        <div className="m-0 dropdown-divider"></div>
                                        <Dropdown.Item
                                          className="saveDefaultBtn"
                                          onClick={() => this.saveDefaultView()}
                                        >
                                          Save as Default View
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    <div className="single-loader loadingAnimation"></div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {this.state.isFilter && (
                            <div className="info-badges exported-date">
                              <div className="d-flex">
                                <div>
                                  This is a partial view and transactions are
                                  hidden, to view all{" "}
                                </div>
                                <div>
                                  <a
                                    className="d-flex"
                                    onClick={(e: any) => this.resetFilter(e)}
                                  >
                                    {" "}
                                    click here
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                          {this.state.accountNameValue !== -1 && (
                            <>
                              {!this.state.istableloader && (
                                <div
                                  className={
                                    this.state.accountName === "All"
                                      ? `${this.state.defaultDensityView} fixHeader100 forRePeatParentTableAll tableToggleRow regular rePeatScrollArea`
                                      : `${this.state.defaultDensityView} fixHeader100 forRePeatParentTable tableToggleRow regular rePeatScrollArea`
                                  }
                                >
                                  <BootstrapTable
                                    {...props.baseProps}
                                    keyField="id"
                                    hover
                                    rowEvents={this.rowEvents}
                                    expandRow={expandRow}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </ToolkitProvider>

                    {this.state.istableloader && (
                      <div className="loader-responsive register">
                        <div className="performanceList">
                          <div className="tableList">
                            <ul>
                              <li className="d-flex">
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Container>

            {this.state.isShowSlideout && (
              <BankAccountSlideout
                hidValue={this.state.hidValue}
                hotelName={this.state.hotelName}
                oprID={this.state.registerRequest.oprID}
                acctType={this.state.registerRequest.acctType}
                actionType={"edit"}
                hideSlideOut={this.hideBankSlideOut.bind(this)}
              ></BankAccountSlideout>
            )}

            {this.state.isCCShowSlideout && (
              <CreditCardAccountSlideout
                hidValue={this.state.hidValue}
                hotelName={this.state.hotelName}
                oprID={this.state.registerRequest.oprID}
                acctType={this.state.registerRequest.acctType}
                actionType={"edit"}
                hideCreditCardSlideOut={this.hideBankSlideOut.bind(this)}
              ></CreditCardAccountSlideout>
            )}

            {this.state.exportModal && (
              <RegisterExport
                ref={this.exportModal}
                parentState={this.state}
                registerExoprtModalClose={this.showHideExportModal}
                exportToExcel={this.exportToExcel}
              ></RegisterExport>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  exportToExcel = (type: any, startDate: any, endDate: any) => {
    let req = { ...this.state.registerRequest };
    if (req.acctType !== "O") {
      this.setState({ isExportRun: true });
    }
    let registerExportReq: any = {};
    registerExportReq.hotelID = this.state.hidValue;
    registerExportReq.Type = type;
    registerExportReq.startDate =
      req.acctType === "O" ? startDate : req.startDate;
    registerExportReq.endDate = req.acctType === "O" ? endDate : req.startDate;
    registerExportReq.OprID = req.oprID;
    registerExportReq.SelectType = req.acctType === "O" ? "" : req.selectType;
    registerExportReq.IsDepositHide =
      req.acctType === "O" ? "" : req.isDepositHide;
    registerExportReq.AccountType = req.acctType;
    registerExportReq.IsInvoiceShow = "No";
    registerExportReq.SortOrder = this.state.sortingOrder;
    registersevice
      .ExportRegister(registerExportReq)
      .then(async (result: any) => {
        this.setState({ isExportRun: false });
        if (req.acctType === "O") this.showHideExportModal();
        this.exportModal?.current?.stopLoader();
        resolve();
      })
      .catch((error) => {
        this.setState({ isExportRun: false });
        this.exportModal?.current?.stopLoader();
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };
}
