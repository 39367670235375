import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import filterFactory from "react-bootstrap-table2-filter";
import TableSearch from "../../../Common/Components/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Button, Dropdown, Form, Row, Col, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { Utils } from "../../../Common/Utilis";
import { InvoiceEntrySlideout } from "../../Register/InvoiceEntrySlideout";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import Spinner from "react-bootstrap/Spinner";
import { Register as registersevice } from "../../../Common/Services/Register";
import cellEditFactory from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import { ForgotPassword as PwdService } from "../../../Common/Services/ForgotPasswordService";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { TransferSlideout } from "../../../Modules/Register/TransferSlideout";
import { UnApproveModal } from '../../../Common/Components/UnApproveModal';
import { SingleDropdownListWithoutSearch } from "../../../Common/Components/SingleDropdownListWithoutSearch";
import OutsideClickHandler from 'react-outside-click-handler';
import { PartialPayment } from '../../../Common/Components/PartialPaymentModal';
import { OtpValidator } from "../../../Common/Components/OtpValidator"
import moment from "moment";
import "../../../Modules/Accounting/Payments/otpPay.scss";

let isEnterPriseFound = false;
let sesstionResetTimer: any;
let NegativeorLessVendor = [] as any;
export class PaymentsTable extends React.Component<any, any> {
    private child: any;
    private wom_vendorWidth: any;
    private scrollArea: any;
    private accountName: any;
    private trdate: any;
    public scrollRef;
    private ChildOTP: any;
    private ChildRePrintOTP: any;

    iconFormatter = () => {
        return (
            <div className="wom_hasGearIcon">
                <Dropdown className="more-action bg-blue rolePermissiondropdown dropdown wom_dropdown" onToggle={this.handleToggle}>
                    <Dropdown.Toggle id="dropdown-ptoType" className="pl-1 pr-1" disabled={this.state.tableData.length === 0}>
                        <div className="d-flex align-items-center wom_svg">
                            <div className="d-flex align-items-center wom_svg">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.3914 2H3.60861C2.72146 2 2 2.79733 2 3.77778V16.2222C2 17.2027 2.72146 18 3.60861 18H16.3914C17.2785 18 18 17.2027 18 16.2222V6.44356V5.55467V4.66578V3.77778V3.77689C17.9992 2.79644 17.2769 2 16.3914 2ZM6.82582 16.2222H3.60861V6.44356H6.82582V16.2222ZM11.6516 16.2222H8.43442V6.44356H11.6516V16.2222ZM13.2602 16.2222V6.44356H16.3914L16.3922 16.2222H13.2602Z" />
                                </svg>
                                <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                </div>
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="wom_tableColumnListSelector-Pay" id="colList3">
                        <div className="header">Columns</div>
                        <div
                            className={`${this.state.bulkSelect ? "paymentColumSelFoot" : this.props.parentState.hasAlertSession ? "paymentColumh170" : "paymentColumSel"} dropdownItems`}
                        //className="dropdownItems paymentColumSel"
                        >
                            {this.state.tableColumnList.map((items, index) => (
                                <>
                                    {!items.isHide && (
                                        <div className="dropdown-item" key={index}>
                                            <EllipsisWithTooltip placement="bottom">
                                                <div className="wom_formCheck wom_formCheckHasPadd">
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={`list_${items.id}`}
                                                        id={`list_${items.id}`}
                                                        name={items.name.toLowerCase()}
                                                        label={items.name === "AP" ? "Accounting Period" : items.name}
                                                        value={items.id}
                                                        onChange={(event) =>
                                                            this.onColumnChange(event, items, index)
                                                        }
                                                        checked={items.isChecked}
                                                        disabled={items.disabled}
                                                    />
                                                </div>
                                            </EllipsisWithTooltip>
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                        <div className="m-0 dropdown-divider"></div>
                        <Dropdown.Item className="wom_saveDefaultBtn" onClick={this.saveDefaultView}>Save as Default View</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div >
        );
    }

    handleToggle = (event) => {
        if (event === false && this.state.isColumnChange) {
            let defaultWidth = this.scrollArea.current.getBoundingClientRect().width;
            let currentWidth = this.scrollArea.current.childNodes[0].childNodes[0].childNodes[0].getBoundingClientRect().width;
            if (defaultWidth < currentWidth) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        this.setState({ isColumnChange: false });
                        return (
                            <div className='viom_CustomConfirmAlert'>
                                <h1 className="viom_alertTitle">Note:</h1>
                                <p className="viom_alertMessage">Your column selection may cause horizontal scrolling due to the number selected.  To eliminate scrolling, limit the number of columns you select.</p>
                                <div className="viom_alertButtonGroup">
                                    <button className="viom_alertButton viom_success" onClick={onClose}>Ok</button>
                                </div>
                            </div>
                        );
                    }
                })
            }
        }
    }

    hoverOutEllipsis = () => {
        $(".tooltip").removeClass("show");
    };

    columnsHeader = [{
        dataField: "company",
        text: "Vendor",
        headerFormatter: (column, colIndex) => {
            return (
                <div ref={this.wom_vendorWidth}>{column.text}</div>
            );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
            let isTransferEntry = row.isTransferEntry !== undefined && row.isTransferEntry;
            if (isTransferEntry) {
                return (<div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="top">{row.ttFvendor}</EllipsisWithTooltip>
                </div>)
            } else {
                return (<div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="top">{row.company}</EllipsisWithTooltip>
                </div>)
            }
        },
        headerClasses: "wom_colVendor",
        classes: "stickey-vendor",
    }, {
        dataField: "invoiceNo",
        text: "Number",
        headerStyle: (column, colIndex) => {
            return {
                left: this.state.wom_vendorWidthCol
            };
        },
        style: (cell, row, rowIndex, colIndex) => {
            return {
                left: row.wom_vendorWidthCol
            };
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="top">{row.invoiceNo}</EllipsisWithTooltip>
                </div>)
        },
        headerClasses: "wom_colNumber",
        classes: "stickey-number",
    }, {
        dataField: "letterCode",
        text: "EHID",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="top">{row.letterCode}</EllipsisWithTooltip>
                </div>)
        },
        headerClasses: "wom_colEhid hasSortCol",
        classes: "colRemovable-Pay",
    }, {
        dataField: "accper",
        text: "AP",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="top">{row.accper}</EllipsisWithTooltip>
                </div>
            )
        },
        headerClasses: "wom_colAccPeriod hasSortCol",
        classes: "colRemovable-Pay",
    }, {
        dataField: "billDate",
        text: "Invoice Date",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (<div onClick={() => { this.rowClickEvents(row) }}>
                <EllipsisWithTooltip placement="top">{row.billDate}</EllipsisWithTooltip>
            </div>)
        },
        headerClasses: "wom_colInvoiceDate hasSortCol",
        classes: "colRemovable-Pay",
    }, {
        dataField: "dueDate",
        text: "Due Date",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (<div onClick={() => { this.rowClickEvents(row) }}>
                <EllipsisWithTooltip placement="top">{row.dueDate}</EllipsisWithTooltip>
            </div>)
        },
        headerClasses: "wom_colDueDate hasSortCol",
        hidden: true,
        classes: "colRemovable-Pay",
    }, {
        dataField: "transTotal",
        text: "Amount",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="top">{Utils.currencyFormatWithOutdoller(row.transTotal)}</EllipsisWithTooltip>
                </div>
            )
        },
        headerClasses: "wom_colAmount hasSortCol",
        classes: "text-right colRemovable-Pay",
    }, {
        dataField: "checkNo",
        text: "Check Number",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="top">{row.checkNo}</EllipsisWithTooltip>
                </div>
            )
        },
        headerClasses: "hasSortCol",
        classes: "colRemovable-Pay",
    }, {
        dataField: "etype",
        text: "Method",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="top">{row.etype}</EllipsisWithTooltip>
                </div>
            )
        },
        headerClasses: "wom_colMethod hasSortCol",
        classes: "colRemovable-Pay",
    }, {
        dataField: "tabStatus",
        text: "Status",
        headerClasses: "wom_colStatus hasSortCol",
        classes: "colRemovable-Pay",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }} onWheel={() => this.hoverOutEllipsis()}>
                    {this.state.parentType === "unpaid" ?
                        <span className={`ml-0 badge ${row.etype?.toLowerCase() === 'd-ach' && row.digitAchStatus?.toLowerCase()?.includes("fail") ? "badgeInfoPaymentFailed" : "badgeGray"}`}>{row.etype?.toLowerCase() === 'd-ach' && row.digitAchStatus?.toLowerCase()?.includes("fail") ? "Failed" : row.tabStatus}</span>
                        : this.props.parentState.key.toLowerCase() === "confirm payment" ? <span className="ml-0 badge badgeInfoPayment">{row.tabStatus}</span> :
                            <span className="ml-0 badge badgeInfoPrint">{row.tabStatus}</span>}

                </div>
            )
        },
    }, {
        dataField: "reviewstatus",
        text: "Flagged",
        hidden: true,
        headerClasses: "wom_colStatus hasSortCol",
        classes: "colRemovable-Pay",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div>
                    <span className={row.reviewstatus.toLowerCase() === "Needs Review".toLowerCase() ? "ml-0 badge badgeOrange" : row.reviewstatus.toLowerCase() === "Review Complete".toLowerCase() ? "ml-0 badge green-bg" : "ml-0 badge blue-bg"}>{row.reviewstatus}</span>
                </div>
            )
        },
    }, {
        dataField: "addContract",
        text: "Customer",
        headerClasses: "wom_colContract hasSortCol",
        classes: "colRemovable-Pay toolTipNotesContract",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }} onWheel={() => this.hoverOutEllipsis()}>
                    <EllipsisWithTooltip placement="top">
                        {row.addContract}
                    </EllipsisWithTooltip>
                </div>
            )
        },
    }, {
        dataField: "internalNotes",
        text: "Notes",
        headerClasses: "wom_colNotes hasSortCol",
        classes: "colRemovable-Pay toolTipNotesContract",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }} onWheel={() => this.hoverOutEllipsis()}>
                    <EllipsisWithTooltip placement="top">
                        {row.internalNotes}
                    </EllipsisWithTooltip>
                </div>
            )
        },
    },
    {
        dataField: "",
        text: "",
        classes: (cell: any, row: any, rowIndex: any) => `pay_colDrop pay_colBtn text-right ${row.isDropShow ? "setZindex" : ""}`,
        headerClasses: "pay_colBtn text-right",
        // headerFormatter: this.iconFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
            let isTransferEntry = row.isTransferEntry !== undefined && row.isTransferEntry;
            let isEtypeCheck = isTransferEntry && row.etype.toString().toLocaleLowerCase() === "check";
            let isDeleteTrPermission = row.pActivate.toString().toLocaleLowerCase() === "active";
            let isDeleteInvPermission = row.pActivate.toString().toLocaleLowerCase() === "active" && (row.papClosed.toString().toLocaleLowerCase() === "active" || row.papClosed.toString().toLocaleLowerCase() === "ap closed");
            let partialPermission = false;
            if (this.props.parentState.key?.toLowerCase() === "checks" || this.props.parentState.key?.toLowerCase() === "epay") {
                partialPermission = this.state.hasMakePayments;
            } else if (this.props.parentState.key?.toLowerCase() === "checks & ach") {
                if (row?.etype?.toLowerCase() === "checks")
                    partialPermission = this.state.hasMakePayments;
                else
                    partialPermission = row.paymentPerm === "Yes";
            } else {
                partialPermission = this.props.parentState.makeACHPerm;
            }
            return (
                <div className="d-flex">
                    <div className="ml-auto">
                        {isTransferEntry ?
                            (
                                <>
                                    {row.paymentPerm === "Yes" ? (
                                        <Button variant="primary" type="button"
                                            className="space btn btn-primary btn-discard mr-0"
                                            onClick={(e) => { this.rowClickSubmit(e, row, this.state.parentType) }}>
                                            {this.state.parentType === "unpaid" ? isEtypeCheck ? "Print" : "Pay" :
                                                this.state.parentType === "paid" ? "Confirm" : "Confirm"}
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) :
                            (
                                <>
                                    {(row?.etype?.toLowerCase() === "check" || row?.etype?.toLowerCase() === "epay") && (
                                        <>
                                            {this.state.hasMakePayments ? (
                                                <Button variant="primary" type="button"
                                                    className="space btn btn-primary btn-discard mr-0"
                                                    onClick={(e) => { this.rowClickSubmit(e, row, this.state.parentType) }}>
                                                    {this.state.parentType === "unpaid" ? "Pay"
                                                        : "Confirm"
                                                    }
                                                </Button>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                    {(row?.etype?.toLowerCase() === "ach" || row?.etype?.toLowerCase() === "m-ach" || row?.etype?.toLowerCase() === "d-ach" || row?.etype?.toLowerCase() === "credit card") && (
                                        <>
                                            {row.paymentPerm === "Yes" ? (
                                                <Button variant="primary" type="button"
                                                    className="space btn btn-primary btn-discard mr-0"
                                                    onClick={(e) => { this.rowClickSubmit(e, row, this.state.parentType) }}>
                                                    {this.state.parentType === "unpaid" ? "Pay"
                                                        : "Confirm"
                                                    }
                                                </Button>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                    </div>
                    <div className="ml-auto">
                        {this.state.parentType !== "paid" && isTransferEntry ? (
                            <>
                                {(isDeleteTrPermission && this.state.noDeletePermission && row.isRemDeletePermission === "Yes") ? (
                                    <Dropdown id="more-action" onToggle={(e) => { this.setZindex(e, row) }} className="more-action" alignRight>
                                        <Dropdown.Toggle className="btn-outline-primary btn btn-primary btn-ghost more" id="dropdown-more">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {isDeleteInvPermission && (
                                                <>
                                                    {this.props.parentState.key?.toLowerCase() !== "confirm print" && (
                                                        <Dropdown.Item eventKey={"Delete"} onClick={(e) => { e.stopPropagation(); this.handleBtnEvent(e, row, 'single') }} key={rowIndex}>
                                                            Delete
                                                        </Dropdown.Item>
                                                    )}
                                                </>
                                            )}
                                            {/* <Dropdown.Item eventKey={"Delete"}
                                                onClick={(e) => { e.stopPropagation(); this.handleBtnEvent(e, row, 'single') }}
                                                key={rowIndex}>Delete
                                            </Dropdown.Item> */}
                                            {this.props.parentState.key?.toLowerCase() === "confirm print" && this.state.hasMakePayments && (
                                                <React.Fragment>
                                                    <Dropdown.Item eventKey={"Confirm Print"} onClick={(e) => { e.stopPropagation(); this.rowClickSubmit(e, row, this.state.parentType) }} key={rowIndex}>Confirm Print</Dropdown.Item>
                                                    {Number(row.transTotal) > 0 && (
                                                        <Dropdown.Item eventKey={"Reprint Check"} onClick={(e) => { e.stopPropagation(); this.reprintSingleCheckPaid(row) }} key={rowIndex}>Reprint Check</Dropdown.Item>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {this.props.parentState.key?.toLowerCase() === "confirm print" && row.voidPermission === "Yes" && row.fromPer === "Yes" && (
                                                <Dropdown.Item eventKey={"handleBtnEventVoid"} onClick={(e) => { e.stopPropagation(); this.handleBtnEvent(e, row, 'single') }} key={rowIndex}>Void</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (<div className="nonDelBtn"></div>)}
                            </>) : (
                            <Dropdown id="more-action" onToggle={(e) => { this.setZindex(e, row) }} className="more-action" alignRight>
                                <Dropdown.Toggle className="btn-outline-primary btn btn-primary btn-ghost more" id="dropdown-more">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>

                                {row.isSpinnerLoader && (
                                    <OutsideClickHandler onOutsideClick={() => this.hideUnApprovalModal(false)}>
                                        <div className="PaymentUnApproveModal">
                                            <UnApproveModal
                                                modalUniqueNos={row.uniqueno}
                                                modalClose={this.hideUnApprovalModal}
                                            />
                                        </div>
                                    </OutsideClickHandler>
                                )}

                                <Dropdown.Menu>
                                    {!row.isSpinnerPP && (
                                        <>
                                            {this.state.parentType === "unpaid" && (
                                                <React.Fragment>
                                                    {partialPermission ? (
                                                        <>
                                                            {row.isCreditCard?.toLowerCase() !== "yes" && (
                                                                <>
                                                                    {row.isSpinnerPP ? (
                                                                        <span className='dropdown-item disabled-area'>
                                                                            <Spinner size="sm" className="disabled-area" animation="border" /> Partial Payment
                                                                        </span>
                                                                    ) : (
                                                                        <>
                                                                            {!row.transTotal?.toString()?.includes("-") && row.transTotal !== 0 && (
                                                                                <span className='dropdown-item' onClick={(e) => { e.stopPropagation(); this.handleBtnEvent(e, row, 'single') }} key={rowIndex}>
                                                                                    Partial Payment
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                        // <Dropdown.Item eventKey={"Partial Payment"} key={rowIndex}>Partial Payment</Dropdown.Item>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {this.state.hasUnApproved && (
                                                        <>
                                                            {row.isSpinnerLoader ? (
                                                                <span className='dropdown-item disabled-area'>
                                                                    <Spinner size="sm" className="disabled-area" animation="border" /> Unapprove
                                                                </span>
                                                            ) : (
                                                                <span className='dropdown-item' onClick={(e) => { e.stopPropagation(); this.handleBtnEvent(e, row, 'single') }} key={rowIndex}>
                                                                    Unapprove
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {this.state.parentType === "paid" && (
                                                <React.Fragment>
                                                    {this.props.parentState.key?.toLowerCase() === "confirm payment" && (
                                                        <>
                                                            {this.props.parentState.editInvPerm && (
                                                                <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.handleBtnEvent(e, row, 'single') }} eventKey={"Mark as Unpaid"} key={rowIndex}>Mark as Unpaid</Dropdown.Item>
                                                            )}
                                                            {this.state.hasUnApproved && (
                                                                <>
                                                                    {row.isSpinnerLoader ? (
                                                                        <span className='dropdown-item disabled-area'>
                                                                            <Spinner size="sm" className="disabled-area" animation="border" /> Unapprove
                                                                        </span>
                                                                    ) : (
                                                                        <span className='dropdown-item' onClick={(e) => { e.stopPropagation(); this.handleBtnEvent(e, row, 'single') }} key={rowIndex}>
                                                                            Unapprove
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}

                                                        </>
                                                    )}

                                                    {this.props.parentState.key?.toLowerCase() === "confirm print" && (
                                                        <>
                                                            {this.state.hasMakePayments && (
                                                                <React.Fragment>
                                                                    <Dropdown.Item eventKey={"Confirm Print"} onClick={(e) => { e.stopPropagation(); this.rowClickSubmit(e, row, this.state.parentType) }} key={rowIndex}>Confirm Print</Dropdown.Item>
                                                                    {Number(row.transTotal) > 0 && (
                                                                        <Dropdown.Item eventKey={"Reprint Check"} onClick={(e) => { e.stopPropagation(); this.reprintSingleCheckPaid(row) }} key={rowIndex}>Reprint Check</Dropdown.Item>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {isDeleteInvPermission && this.props.parentState.deleteInvPerm && (
                                                <>
                                                    {this.props.parentState.key?.toLowerCase() !== "confirm print" && (
                                                        <Dropdown.Item eventKey={"Delete"} onClick={(e) => { e.stopPropagation(); this.handleBtnEvent(e, row, 'single') }} key={rowIndex}>Delete
                                                        </Dropdown.Item>
                                                    )}
                                                </>
                                            )}
                                            {this.props.parentState.key?.toLowerCase() === "confirm print" && row.voidPermission === "Yes" && row.fromPer === "Yes" && (
                                                <Dropdown.Item eventKey={"handleBtnEventVoid"} onClick={(e) => { e.stopPropagation(); this.handleBtnEvent(e, row, 'single') }} key={rowIndex}>Void</Dropdown.Item>

                                            )}
                                        </>
                                    )}

                                    {row.isSpinnerPP && (
                                        <OutsideClickHandler onOutsideClick={() => this.hidePPModal(false)}>
                                            <div className="PaymentUnApproveModal_duplicate">
                                                <PartialPayment
                                                    uniqueNo={row.uniqueno}
                                                    invoiceNo={row.invoiceNo}
                                                    amount={row.transTotal}
                                                    modalClose={this.hidePPModal}
                                                    HotelID={this.props.hidValue}
                                                    savePartialPayment={this.savePartialPayment}
                                                />
                                            </div>
                                        </OutsideClickHandler>
                                    )}
                                </Dropdown.Menu>


                            </Dropdown>
                        )}
                    </div>
                </div >
            );
        }
    }, {
        dataField: "extra",
        text: "",
        classes: 'd-none',
        headerClasses: "d-none",
        hidden: true,
    }, {
        dataField: "transTotal1",
        text: "",
        classes: 'd-none',
        headerClasses: "d-none",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div>
                    <EllipsisWithTooltip placement="top">{row.transTotal1}</EllipsisWithTooltip>
                </div>
            )
        },
    }];

    constructor(props: any) {
        super(props);
        this.child = React.createRef();
        this.wom_vendorWidth = React.createRef();
        this.scrollArea = React.createRef();
        this.accountName = React.createRef();
        this.trdate = React.createRef();
        this.scrollRef = React.createRef();
        this.ChildOTP = React.createRef();
        this.ChildRePrintOTP = React.createRef();

        this.state = {
            wom_vendorWidthCol: 0,
            hidValue: this.props.hidValue,
            hotelName: "",
            bulkSelect: false,
            bulkSelectRow: false,
            tableData: [],
            searchResult: [],
            resetTableData: [],
            isSpinner: true,
            modalTypeBulk: false,
            isBulkSelected: false,
            isSlideOut: false,
            uniqueNo: 0,
            sildeoutCode: '',
            sildeoutValue: '',
            selectedRows: [],
            densityView: [
                { id: 1, densityType: "densityView_Wide", name: "WIDE" },
                { id: 2, densityType: "densityView_Narrow", name: "NARROW" },
                { id: 3, densityType: "groupUnpaid", name: "Group Unpaid" }
            ],
            defaultDensityView: this.props.parentState.defaultDensityView,
            tableColumnList: [
                { id: "company", name: "Vendor", isChecked: true, disabled: true, isHide: false },
                { id: "invoiceNo", name: "Number", isChecked: true, disabled: true, isHide: false },
                { id: "letterCode", name: "EHID", isChecked: true, disabled: false, isHide: true },
                { id: "accper", name: "AP", isChecked: true, disabled: false, isHide: false },
                { id: "billDate", name: "Invoice Date", isChecked: true, disabled: false, isHide: false },
                { id: "dueDate", name: "Due Date", isChecked: false, disabled: false, isHide: false },
                { id: "transTotal", name: "Amount", isChecked: true, disabled: false, isHide: false },
                { id: "checkNo", name: "Check Number", isChecked: true, disabled: false, isHide: true },
                { id: "etype", name: "Method", isChecked: true, disabled: false, isHide: true },
                { id: "tabStatus", name: "Status", isChecked: true, disabled: false, isHide: false },
                { id: "reviewstatus", name: "Flagged", isChecked: false, disabled: false, isHide: true },
                { id: "addContract", name: "Customer", isChecked: false, disabled: false, isHide: false },
                { id: "internalNotes", name: "Notes", isChecked: false, disabled: false, isHide: false },
                { id: "extra", name: "extra", isChecked: false, disabled: false, isHide: true },
            ],
            columnsHeader: this.columnsHeader,
            columnSortingList: [
                { id: "company", name: "Vendor", isActive: true, isHidden: false },
                { id: "invoiceNo", name: "Number", isActive: false, isHidden: false },
                { id: "letterCode", name: "EHID", isActive: false, isHidden: true },
                { id: "accper", name: "Accounting Period", isActive: false, isHidden: false },
                { id: "billDate", name: "Invoice Date", isActive: false, isHidden: false },
                { id: "dueDate", name: "Due Date", isActive: false, isHidden: true },
                { id: "transTotal", name: "Amount", isActive: false, isHidden: false },
                { id: "checkNo", name: "Check Number", isActive: false, isHidden: false },
                { id: "etype", name: "Method", isActive: false, isHidden: false },
                { id: "tabStatus", name: "Status", isActive: false, isHidden: false },
                { id: "reviewstatus", name: "Flagged", isActive: false, isHidden: true },
                { id: "addContract", name: "Customer", isActive: false, isHidden: false },
                { id: "internalNotes", name: "Notes", isActive: false, isHidden: false },
                { id: "extra", name: "extra", isActive: false, isHidden: true },
            ],
            SortBy: { id: "company", name: "Vendor" },
            defSortBy: { id: "company", name: "Vendor" },
            paymentTypeFilter: [],
            paymentType: "Payment Type",
            batchTotal: 0,
            batchTotalReview: 0,
            reviewPayModalShow: false,
            reviewTable: [],
            reviewTableUnMapped: [],
            reviewProcessStart: false,
            accountBal: this.props.parentState.accountBal,
            totalAcctBal: this.props.parentState.totalAcctBal,
            balanceLoader: false,
            isSingleRow: false,
            isColumnChange: false,
            parentType: this.props.parentState?.currentSelectedType[0]?.type?.toLowerCase(),
            loaderTrue: false,

            isShowPaymentmodal: false,
            isShowOTPmodal: false,
            paymentRow: {},
            isSubmitPayment: false,
            userMobile: '',
            isTransferSlideOut: false,
            registerRequest: { oprID: -1 },
            transferRequest: { acctType: '', glArray: [] },
            isShowReviewPrintConfirmationModal: false,
            isShowReviewPrintConfirmationCloseBtnModal: false,
            oprIDForCkGenerate: 0,
            invoiceCHKData: [],
            transferCheck: false,
            noDeletePermission: this.props.parentState?.noDeletePermission,
            hasMakePayments: this.props.parentState?.hasMakePayments,
            hasViewPayments: this.props.parentState?.hasViewPayments,
            isRefresh: false,
            showVoidModal: false,
            voidNotes: "",
            transcationvoidDate: null,
            voidPeriodList: [],
            selectedVoid: [],
            copyIds: [],
            voidPeriod: "",
            defaultvoidPeriod: "Accounting Period",
            selectdefaultperiod: "Accounting Period",
            associatedPayment: [],
            chkvoidDate: "",
            transferVoidModal: false,
            transferRowData: {},
            transferVoidReason: "",
            transferVoidAccountingPeriod: "Accounting Period",
            isVoidStart: false,
            hasUnApproved: this.props.parentState?.hasUnApproved,
            pvrno: 0,
            confirmAlldisabled: false,
            isSubmitPaymentBtnDisabled: false,
            isValidtotalAmount: true,
            ePayCustID: "",
            blfirstLoad: false,
            isbookingkeeping: false
        };
        this.handleVoidAction = this.handleVoidAction.bind(this);
    }
    
    componentDidMount(): void {
        if (this.props.filteredData !== undefined || this.state.tableData.length === 0) {
            this.paymentDetails(this.props.filteredData);
        } else {
            this.setState({ isSpinner: false });
        }
    } 
    componentWillReceiveProps(nextProps: { filteredData: any }) {
        if (!this.state.reviewPayModalShow) {
            if (nextProps.filteredData !== undefined) {
                if (this.props.filteredData !== nextProps.filteredData || this.state.tableData.length === 0) {
                    this.paymentDetails(nextProps.filteredData);
                } else {
                    this.setState({ isSpinner: false });
                }
            } else {
                this.setState({ isSpinner: false });
            }
        } else {
            this.setState({ isSpinner: false });
        }
    }

    savePartialPayment = (amount: any, uniqueNo: any) => {
        let request: any = {};
        request.Uniqueno = uniqueNo;
        request.PartAmt = Utils.removecurrencyFormat(amount)?.replace(',', '')?.trim();
        request.HotelID = this.props.hidValue;
        InvoiceApprovalS.savePartialPayment(request)
            .then(async (result: any) => {
                if (result?.result?.saveStatus?.toLowerCase() === "success") {
                    toast.success(`Partial payment created successfully.`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.hidePPModal(true);
                } else {
                    this.hidePPModal(true);
                    Utils.toastError(result?.result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.hidePPModal(true);
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    setZindex = (e: any, row: any) => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            if (items.rowNo === row.rowNo) {
                if (items.isSpinnerLoader || items.isSpinnerPP) {
                    return { ...items, isDropShow: true };
                } else {
                    return { ...items, isDropShow: e };
                }
            } else {
                return items;
            }
        });
        this.setState({ tableData, showDropDown: e });
    }

    paymentDetails = (result: any) => {
        this.setState({ isSpinner: true, tableData: [], resetTableData: [] });
        result.map((items) => (
            items["tabStatus"] =
            this.state.parentType === "unpaid" ? "Ready for Payment"
                : this.props.parentState.key.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()),
            items["wom_vendorWidthCol"] = "",
            items["isDropShow"] = false,
            items["etype"] = (items.etype.toLowerCase() === "ach" && items.isDigital === 1) ? "D-ACH" :
                (items.etype.toLowerCase() === "ach" && items?.displaypaymentmethod?.toLowerCase() === "epay") ? "ePay" :
                    (items.etype.toLowerCase() === "ach" && items.isDigital === 0) ? "M-ACH" : items.etype
        ));
        result = _.sortBy(result, "company");
        let filteredAccountList = [] as any;
        if (this.props.parentState.key === "credit card")
            filteredAccountList = this.props.parentState.accountNames.filter((o) => o?.acctType?.toLowerCase() === "c" && o?.acctType.toLowerCase() !== "m");
        else
            filteredAccountList = this.props.parentState.accountNames.filter((o) => o?.acctType?.toLowerCase() !== "c" && o?.acctType.toLowerCase() !== "m");

        // if (this.props.parentState.key === "digital ach")
        //     filteredAccountList = filteredAccountList.filter((o) => o?.fundingSourceStatus?.toLowerCase() === "verified");
        if (this.props.parentState.key === "epay") {
            filteredAccountList = this.props.parentState.ePayaccountNames;
        }
        let { tableColumnList, isbookingkeeping } = this.state;
        isbookingkeeping = result[0]?.isbookingkeeping?.toString()?.toLowerCase() === "yes";
        tableColumnList.forEach((o: any) => {
            if (this.state.parentType === "unpaid" && isbookingkeeping) {
                if (o.id === 'reviewstatus') {
                    o.isHide = false;
                }
            }
        });
        let { tableData } = this.state;        
        tableData = result.slice(0, 1000);
        this.setState({
            tableData: result.slice(0, 1000),
            isbookingkeeping,
            resetTableData: result,
            nextCount: 1000,
            tableColumnList,
            accountName: this.props.parentState.accountName,
            accountNamelist: this.props.parentState.accountNamelist,
            accountNames: filteredAccountList
        }, () => {
            this.setDefaultColumn(this.props.parentState.defaultColumn);
            this.getUserGeneralInfo();
            if (this.state.tableData.length === 0)
                $('#serachInput').prop('disabled', true);
            else
                $('#serachInput').prop('disabled', false);
        });
    }

    formatPhoneNumber = (phoneNumberString) => {
        let cleaned = phoneNumberString.replace(/\D/g, "").slice(-10);
        let match = cleaned.match(/^(91|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            let intlCode = match[1] ? "+1 " : "";
            return [intlCode, "(", "•••", ") ", "•••", "-", match[4]].join("");
        }
        return null;
    };

    getUserGeneralInfo = () => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let user_UniqueId = storage === null ? 0 : (storage.user_UniqueID as any);
        UserManagement.GetUserGeneralInfo(user_UniqueId)
            .then(async (response: any | null) => {
                if (response !== null) {

                    this.setState({ userMobile: this.formatPhoneNumber(response.phone1) });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ userMobile: "" });
                reject();
            });
    }

    GetAccountingPeriod = (hidValue: any, etype) => {
        this.setState({ isSpinner: true });
        let requestlist: any = {};
        requestlist.hotelID = hidValue;
        requestlist.etype = etype;
        let accPeriod: any = [];
        registersevice.GetAccountingPeriod(requestlist)
            .then(async (result: any | null) => {
                if (result !== null) {
                    result.forEach(element => {
                        let item: any = {};
                        item.id = element.accperiod;
                        item.name = element.accperiod;
                        accPeriod.push(item);
                    });
                    if (accPeriod.length > 0) {
                        let defaultper = result[0].defaultPeriod;
                        this.setState({
                            voidPeriodList: accPeriod,
                            defaultvoidPeriod: defaultper.length === 0 ? "Accounting Period" : defaultper,
                            voidPeriod: defaultper,
                            selectdefaultperiod: defaultper,
                        }, () => { this.setState({ isSpinner: false }) })
                    }
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isSpinner: false })
                reject();
            });
    };

    getPaymentDetails(row) {
        this.setState({ isSpinner: true })
        let selectedRow: any = {};
        let paymentrequest: any = {};
        selectedRow = row;

        paymentrequest.hotelID = selectedRow.backHID;
        paymentrequest.uniqueNo = selectedRow.uniqueno;
        paymentrequest.oprID = selectedRow.oprid;
        paymentrequest.CkNo = selectedRow.checkNo;
        // paymentrequest.CkNo = selectedRow.checkNo.toUpperCase() === "ACH" ? 'ACH' : selectedRow.checkNo.toUpperCase() === "Manual ACH" ? 'ACH' :
        //     selectedRow.checkNo.toUpperCase() === "Credit Card".toUpperCase() ? "Credit Card" : selectedRow.checkNo.toUpperCase().includes("ACH") ? "ACH" : selectedRow.checkNo;

        paymentrequest.series = selectedRow.series;
        paymentrequest.postFix = "";


        registersevice.GetAssociatedPayment(paymentrequest)
            .then(async (result: any | null) => {
                if (result !== null) {
                    this.setState({
                        associatedPayment: result,
                        transcationvoidDate: result[0].ckDate,
                        chkvoidDate: result[0].ckDate,
                    }, () => { this.setState({ isSpinner: false }); });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });

    }

    CleanSearch() {
        this.child.current.CleanSearch();
    };

    handleConfirmPrint = (row: any) => {
        this.setState({ loaderTrue: true })
        let request: any = {};
        request.hotelID = row.backHID;
        request.oprID = row.etype.toLowerCase() === 'credit card' ? row.cardUniqueno : row.oprid;
        request.CheckType = row.entryType === "Transfer" ? "T" : "I";;
        request.pvrno = 0;
        request.CheckNo = row.checkNo;
        request.Checkdate = "";
        request.Downloadcopy = "DownloadCheck";
        request.Series = row.series;
        request.isCopyCheck = "Not";
        request.browserName = "Chrome";
        request.vid = 0;
        request.aid = 0;
        request.Uniquenos = row.uniqueno;


        InvoiceApprovalS.PrintActualCheck(request)
            .then(async (result: any | null) => {
                this.setState({ loaderTrue: false })
                resolve();
            })
            .catch((error) => {
                Utils.toastError("Something went wrong. " + error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });

    }

    rowClickEvents = (row: any) => {
        let isConfirmPrint = this.props.parentState.key?.toLowerCase() === "confirm print";
        if (row.isTransferEntry) {
            let { registerRequest, transferRequest } = this.state;
            registerRequest.oprID = row.oprid;
            transferRequest.acctType = row.company.split(" - ")[1].trim().charAt(0);
            this.setState({ uniqueNo: row.uniqueno, sildeoutCode: row.letterCode, sildeoutValue: row.hid, registerRequest, transferRequest }, () => {
                this.setState({ isTransferSlideOut: true })
            });
        } else if (isConfirmPrint) {
            Number(row.transTotal) > 0 && this.handleConfirmPrint(row);
        } else {
            this.setState({ uniqueNo: row.uniqueno, sildeoutCode: row.letterCode, sildeoutValue: row.hid }, () => {
                this.setState({ isSlideOut: true })
            });
        }
    }

    handleDensityView = (e: any, viewName) => {
        if (viewName === "groupUnpaid") {
            let isChecked = e.target.checked;
            this.setState({ isSpinner: true }, () => {
                this.props.groupedACHCheck(isChecked);
            });
        } else {
            this.setState({ defaultDensityView: viewName }, () => {
                this.props?.handleDensityView(viewName);
            });
        }
    }

    getColumns() {
        let defaultsetting: any = {};
        defaultsetting.tableColumnList = this.state.tableColumnList;
        defaultsetting.sortBy = this.state.SortBy.name;
        defaultsetting.copyTableColumnList = this.state.CopyTableColumnList;
        return defaultsetting;
    }

    saveDefaultView = (): void => {
        const laborSaveAsDefaultdtos: any[] = [];
        let eventKey = this.props.parentState.key;
        let tableColumnList = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        let selectedColumns: any = [];
        tableColumnList.map(item => {
            if (eventKey === "transfers") {// || item.id === "dueDate" || item.id === "addContract" /// can be use latter
                item.isChecked = item.id === "invoiceNo" ? item.isChecked = true : item.isChecked
            }
            if (item.isChecked) {
                selectedColumns.push(item.id)
            }
        })
        let isGrouped = this.props.isGroupUnpaid ? "Yes" : "No";
        laborSaveAsDefaultdtos.push({
            fieldName: "view",
            fieldValue: this.state.defaultDensityView + ',' + isGrouped,
            pageName: this.state.parentType,
        });
        laborSaveAsDefaultdtos.push({
            fieldName: "column",
            fieldValue: (selectedColumns).toString(),
            pageName: this.state.parentType,
        });
        laborSaveAsDefaultdtos.push({
            fieldName: "sort",
            fieldValue: this.state.SortBy.id,
            pageName: this.state.parentType,
        });
        laborPerformance.saveDefaultViewProperties(laborSaveAsDefaultdtos)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.message === "Success") {
                        toast.success(result?.result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ showLoader: false });
            });
    };

    setDefaultColumn = (columns: any) => {
        let { tableColumnList, columnsHeader, columnSortingList } = this.state;
        let eventKey = this.props.parentState.key;
        this.setState({ CopyTableColumnList: columns });
        let isAll = this.props.hidValue.toString().toLocaleLowerCase() === "select" || this.props.hidValue.toString().toLocaleLowerCase() === "all";
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        if (columns?.length > 0) {
            let resData = columns.split(",");
            tableColumnList.map(item => { item.isChecked = false })
            columnSortingList.map(item => { item.isHidden = true })
            columnsHeader.map(item => {
                if (
                    item.dataField === 'company' ||
                    item.dataField === 'invoiceNo' ||
                    item.dataField === 'letterCode' ||
                    item.dataField === 'accper' ||
                    item.dataField === 'billDate' ||
                    item.dataField === 'dueDate' ||
                    item.dataField === 'transTotal' ||
                    item.dataField === 'etype' ||
                    item.dataField === 'tabStatus' ||
                    item.dataField === 'reviewstatus' ||
                    item.dataField === 'addContract' ||
                    item.dataField === 'checkNo' ||
                    item.dataField === 'internalNotes' ||
                    item.dataField === 'extra'
                ) {
                    item.hidden = true;
                    item.searchable = false
                }
            });

            tableColumnList.map(item => {
                if (item.id === "etype") {
                    if (this.state.parentType === "unpaid") {
                        item.isHide = false;
                    } else if (this.state.parentType === "paid") {
                        if (this.props.parentState.key === "confirm print") {
                            item.isHide = true;
                        }
                        else {
                            item.isHide = false;
                        }
                    }
                }
                if (item.id === "checkNo") {
                    if (this.state.parentType === "unpaid") {
                        item.isHide = true;
                    } else {
                        if (this.props.parentState.key === "confirm print") {
                            item.isHide = false;
                        }
                        else {
                            item.isHide = true;
                        }
                    }
                }

            });
            resData.forEach(ritm => {
                columnsHeader.map(item => {
                    if (item.dataField === ritm) {
                        if (item.dataField === "letterCode") {
                            item.hidden = !isAll;
                            item.searchable = isAll;
                        } else if (item.dataField === "extra") {
                            item.hidden = true;
                            item.searchable = false;
                        } else if (item.dataField === "checkNo") {
                            if (this.state.parentType === "unpaid") {
                                item.hidden = true;
                                item.searchable = false;
                            } else if (this.state.parentType === "paid") {
                                if (this.props.parentState.key === "confirm print") {
                                    item.hidden = false;
                                    item.searchable = true;
                                }
                                else {
                                    item.hidden = true;
                                    item.searchable = false;
                                }
                            }
                        } else if (item.dataField === "etype") {
                            if (this.state.parentType === "unpaid") {
                                item.hidden = false;
                                item.searchable = true;
                            } else if (this.state.parentType === "paid") {
                                if (this.props.parentState.key === "confirm print") {
                                    item.hidden = true;
                                    item.searchable = false;
                                }
                                else {
                                    item.hidden = false;
                                    item.searchable = true;
                                }
                            }
                        } else if (item.dataField === "reviewstatus") {
                            if (this.state.parentType === "unpaid") {
                                if (!this.state.isbookingkeeping) {
                                    item.hidden = true;
                                    item.searchable = false;
                                } else {
                                    item.hidden = false;
                                    item.searchable = true;
                                }
                            } else {
                                item.hidden = true;
                                item.searchable = false;
                            }


                        } else {
                            item.hidden = false;
                            item.searchable = true;
                        }
                    }
                    if (eventKey === "transfers") {
                        if (item.dataField === "billDate") { item.text = "Transaction Date" }
                        if (item.dataField === "invoiceNo" || item.dataField === "dueDate" || item.dataField === "addContract") {
                            item.hidden = true
                        }
                    }
                })
                tableColumnList.map(item => {
                    if (item.id === ritm) {
                        if (item.id === "letterCode") {
                            item.disabled = isAll; //? false : true;
                            item.isChecked = isAll; //? true : false;
                            item.isHide = true;
                        } else if (item.id === "etype") {
                            if (this.state.parentType === "unpaid") {
                                item.isHide = false;
                                item.isChecked = true;
                            } else if (this.state.parentType === "paid") {
                                if (this.props.parentState.key === "confirm print") {
                                    item.isHide = true;
                                    item.isChecked = false;
                                }
                                else {
                                    item.isHide = false;
                                    item.isChecked = true;
                                }
                            }
                        } else if (item.id === "checkNo") {
                            if (this.state.parentType === "unpaid") {
                                item.isHide = true;
                            } else {
                                if (this.props.parentState.key === "confirm print") {
                                    item.isHide = false;
                                    item.isChecked = true;
                                }
                                else {
                                    item.isHide = true;
                                }
                            }
                        } else if (item.id === "reviewstatus") {
                            if (this.state.parentType === "unpaid") {
                                if (!this.state.isbookingkeeping) {
                                    item.isHide = true;
                                    item.isChecked = false;
                                } else {
                                    item.isHide = false;
                                    item.isChecked = true;
                                }
                            } else {
                                item.isHide = true;
                            }
                        } else {
                            item.isHide = false;
                            item.isChecked = true;
                        }
                    }
                    if (eventKey === "transfers") {
                        if (item.id === "billDate") { item.name = "Transaction Date" }
                        if (item.id === "invoiceNo" || item.id === "dueDate" || item.id === "addContract") {
                            item.disabled = true;
                            item.isHide = true;
                            item.isChecked = false;
                        }
                    }

                })
                columnSortingList.map(item => {
                    if (item.id === ritm) {
                        if (item.id === "checkNo") {
                            if (this.state.parentType === "unpaid") {
                                item.isHidden = true;
                            } else {
                                if (this.props.parentState.key === "confirm print") {
                                    item.isHidden = false;
                                }
                                else {
                                    item.isHidden = true;
                                }
                            }
                        } else if (item.id === "etype") {
                            if (this.state.parentType === "unpaid") {
                                item.isHidden = false;
                            } else {
                                if (this.props.parentState.key === "confirm print") {
                                    item.isHidden = true;
                                }
                                else {
                                    item.isHidden = false;
                                }
                            }
                        } else if (item.id === "reviewstatus") {
                            if (this.state.parentType === "unpaid") {
                                if (!this.state.isbookingkeeping) {
                                    item.isHidden = true;
                                } else {
                                    item.isHidden = false;
                                }
                            } else {
                                item.isHidden = false;
                            }
                        } else {
                            item.isHidden = false;
                        }
                    } if (eventKey === "transfers") {
                        if (item.id === "billDate") { item.name = "Transaction Date" }
                        if (item.id === "invoiceNo" || item.id === "dueDate" || item.id === "addContract") {
                            item.isHidden = true;
                            item.isActive = false
                        }
                    }
                });
            })
            this.setState(() => ({ columnsHeader, tableColumnList, columnSortingList }), () => {
                this.setDefaultFilter(this.props.parentState.defaultSort);
            });
        } else {
            columnsHeader.map(item => {
                if (
                    item.dataField === 'dueDate' ||
                    item.dataField === 'addContract' ||
                    item.dataField === 'internalNotes' ||
                    item.dataField === 'extra' ||
                    item.dataField === 'letterCode'
                ) {
                    item.hidden = true;
                    item.searchable = false;
                } else if (item.dataField === "checkNo") {
                    if (this.state.parentType === "unpaid") {
                        item.hidden = true
                        item.searchable = false;
                    } else {
                        if (this.props.parentState.key === "confirm print") {
                            item.hidden = false;
                            item.searchable = true;
                        }
                        else {
                            item.hidden = true;
                            item.searchable = false;
                        }
                    }
                } else if (item.dataField === "letterCode") {
                    item.hidden = !isAll;
                    item.searchable = !isAll;
                } else if (item.dataField === "etype") {
                    if (this.state.parentType === "unpaid") {
                        item.hidden = false;
                        item.searchable = true;
                    } else if (this.state.parentType === "paid") {
                        if (this.props.parentState.key === "confirm print") {
                            item.hidden = true;
                            item.searchable = false;
                        }
                        else {
                            item.hidden = false;
                            item.searchable = true;
                        }
                    }
                } else if (item.dataField === "accper") {
                    if (this.state.parentType === "unpaid") {
                        item.hidden = false;
                        item.searchable = true;
                    } else {
                        item.hidden = true;
                        item.searchable = false;
                    }
                }
                if (eventKey === "transfers") {
                    if (item.dataField === "billDate") { item.text = "Transaction Date" }
                    if (item.dataField === "invoiceNo" || item.dataField === "dueDate" || item.dataField === "addContract") {
                        item.hidden = true
                    }
                }
            });
            columnSortingList.map(item => {
                if (item.id === "checkNo") {
                    if (this.state.parentType === "unpaid") {
                        item.isHidden = true;
                    } else {
                        if (this.props.parentState.key === "confirm print") {
                            item.isHidden = false;
                        }
                        else {
                            item.isHidden = true;
                        }
                    }
                } else if (item.id === "etype") {
                    if (this.state.parentType === "unpaid") {
                        item.isHidden = false;
                    } else {
                        if (this.props.parentState.key === "confirm print") {
                            item.isHidden = true;
                        }
                        else {
                            item.isHidden = false;
                        }
                    }
                } else if (item.id === "letterCode") {
                    item.isHidden = !isAll; //? false : true;
                } else {
                    if (this.state.parentType === "unpaid") {
                        if (item.id === "addContract" || item.id === "internalNotes" || item.id === "dueDate") {
                            item.isHidden = true;
                        }
                    } else {
                        if (item.id === "etype" || item.id === "accper" || item.id === "addContract" || item.id === "internalNotes" || item.id === "dueDate") {
                            item.isHidden = true;
                        }
                    }
                }
                if (eventKey === "transfers") {
                    if (item.id === "billDate") { item.name = "Transaction Date" }
                    if (item.id === "invoiceNo" || item.id === "dueDate" || item.id === "addContract") {
                        item.isHidden = true;
                        item.isActive = false
                    }
                }
            });
            tableColumnList.map(item => {
                if (item.id === "letterCode") {
                    item.disabled = isAll;
                    item.isChecked = isAll;
                    item.isHide = !isAll;
                } else if (item.id === "checkNo") {
                    if (this.state.parentType === "unpaid") {
                        item.isHide = true;
                    } else {
                        if (this.props.parentState.key === "confirm print") {
                            item.isHide = false;
                        }
                        else {
                            item.isHide = true;
                        }
                    }
                } else if (item.id === "etype") {
                    if (this.state.parentType === "unpaid") {
                        item.isHide = false;
                    } else {
                        if (this.props.parentState.key === "confirm print") {
                            item.isHide = true;
                        }
                        else {
                            item.isHide = false;
                        }
                    }
                } else if (item.id === "accper") {
                    if (this.state.parentType === "unpaid") {
                        item.isChecked = true;
                    } else {
                        item.isChecked = false;
                    }
                }
                if (eventKey === "transfers") {
                    if (item.id === "billDate") { item.name = "Transaction Date" }
                    if (item.id === "invoiceNo" || item.id === "dueDate" || item.id === "addContract") {
                        item.disabled = true;
                        item.isChecked = false;
                    }
                }
            })
            this.setState({ tableColumnList, columnsHeader, columnSortingList }, () => {
                this.setDefaultFilter(this.props.parentState.defaultSort);
            })
        }
    }

    setDefaultFilter = (defaultFilter: any) => {
        let { SortBy, columnSortingList } = this.state;
        if (defaultFilter?.length > 0) {
            let currentRow = columnSortingList.filter(item => item.id === defaultFilter && item.isHidden === false);
            if (currentRow.length > 0) {
                SortBy.name = currentRow[0].name;
                SortBy.id = currentRow[0].id;
            } else {
                SortBy = this.state.defSortBy
            }
            this.setState({ SortBy }, () => {
                this.handleSortTable(this.state.SortBy.id, 'load');
                setTimeout(() => {
                    this.updateColumns();
                }, 100)
            });
        } else {
            this.setState({ SortBy: this.state.defSortBy }, () => {
                this.handleSortTable(this.state.SortBy.id, 'load');
                setTimeout(() => {
                    this.updateColumns();
                }, 100)
            })
        }
    }

    updateColumns = () => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            return { ...items, wom_vendorWidthCol: (Number(this.wom_vendorWidth?.current?.getBoundingClientRect().width + 40 + 14).toFixed(2)).toString() + "px" };
        });
        this.setState((curr: any) => ({
            ...curr,
            tableData: tableData,
            loaderTrue: false,
            wom_vendorWidthCol: (Number(this.wom_vendorWidth?.current?.getBoundingClientRect().width + 40 + 14).toFixed(2)).toString() + "px"
        }));
    }

    onColumnChange = (e: any, items: any, index: any) => {
        this.setState({ loaderTrue: true });
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        data[index].isChecked = e.target.checked;
        setTimeout(() => {
            let { columnsHeader, columnSortingList, SortBy } = this.state;
            columnSortingList[index].isHidden = data[index].isChecked ? false : true;
            if (columnSortingList[index].isActive === true && columnSortingList[index].isHidden === true) {
                columnSortingList[index].isActive = false;
                if (columnSortingList[index + 1] !== undefined && columnSortingList[index + 1].isHidden !== true) {
                    columnSortingList[index + 1].isActive = true
                    SortBy.name = columnSortingList[index + 1].name;
                    SortBy.id = columnSortingList[index + 1].id;
                } else {
                    columnSortingList[0].isActive = true;
                    SortBy.name = "Vendor";
                    SortBy.id = "company";
                }
            }
            columnsHeader[index].hidden = data[index].isChecked ? false : true;
            columnsHeader[index].searchable = data[index].isChecked;
            if (items.id === "transTotal") {
                columnsHeader[14].searchable = data[index].isChecked;
            }
            this.setState(() => ({ tableColumnList: data, columnsHeader, columnSortingList, SortBy, isColumnChange: true }), () => {
                this.updateColumns();
                this.props.updateColumns(data[index], SortBy.id);
            })
        }, 0);
    }

    handleOnSelect = (row, isSelect) => {
        let isTransferEntry = this.props.parentState.key === "transfers";
        setTimeout(() => {
            let { tableData, selectedRows, bulkSelectRow, bulkSelect, batchTotal, reviewTable, reviewTableUnMapped, batchTotalReview } = this.state;
            let tableLen = tableData;
            if (isSelect) {
                let arr = {} as any;
                arr.rowNo = row.rowNo;
                arr.rowID = row.rowNo;
                arr.uniqueno = row.uniqueno;
                arr.backHID = row.backHID;
                arr.letterCode = row.letterCode;
                arr.oprid = row.oprid;
                arr.company = isTransferEntry ? row.ttFvendor : row.company;
                arr.addContract = row.addContract;
                arr.aid = row.aid;
                arr.vid = row.vid;
                arr.etype = row.etype;
                arr.etypeOld = row.etype;
                arr.isCreditCard = row?.isCreditCard;
                arr.billDate = row?.isCreditCard?.toLowerCase() === "yes" ? new Date() : isTransferEntry ? row.billDate : row.etype?.toLowerCase() === "check" || row.etype?.toLowerCase() === "credit card" || row.etype?.toLowerCase() === "m-ach" || row.etype?.toLowerCase() === "epay" ? "" : row.billDate;
                //this.state.parentType === 'unpaid' && isTransferEntry && row.etype?.toLowerCase() === "m-ach" ? row.billDate : this.state.parentType === 'unpaid' ? "" : row.billDate;
                arr.transTotal = row.transTotal; //Utils.currencyFormatWithOutdoller(row.transTotal);
                arr.internalNotes = ''; //row.internalNotes;
                arr.isGrouped = "No";
                arr.cardUniqueno = row.cardUniqueno;
                arr.gdate = row.gdate;
                arr.paymentNotes = row.paymentNotes;
                arr.isEnterPrise = row.isEnterPrise;
                arr.isVendorGroup = row.isVendorGroup;
                arr.mdGroupingFeature = row.mdGroupingFeature;
                arr.isTransferEntry = row.isTransferEntry !== undefined ? true : false;
                arr.entryType = row.entryType;
                arr.invoiceNo = row.invoiceNo;
                arr.pActivate = row.pActivate;
                arr.papClosed = row.papClosed;
                arr.isDateErr = false;
                arr.isRemDeletePermission = row.isRemDeletePermission;
                arr.paymentPerm = row.paymentPerm;
                reviewTable.push(arr);
                batchTotal += row.transTotal;
                batchTotalReview += row.transTotal;
            }
            else {
                reviewTable = reviewTable.filter((item) => item.uniqueno !== row.uniqueno);
                batchTotal -= row.transTotal;
                batchTotalReview -= row.transTotal;
            }

            if (isSelect) {
                selectedRows = [...this.state.selectedRows, row.rowNo]
            } else {
                selectedRows = this.state.selectedRows.filter(x => x !== row.rowNo);
            }
            if (selectedRows.length === 0) {
                bulkSelectRow = false;
                bulkSelect = false;
                $("#footerBulkSelect").prop("checked", false).prop("indeterminate", false);
            } else if (selectedRows.length > 0 && selectedRows.length < tableLen.length) {
                bulkSelectRow = false;
                bulkSelect = true;
                $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
            } else if (selectedRows.length === tableLen.length) {
                bulkSelectRow = true;
                bulkSelect = true;
                $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
            }
            this.setState({
                selectedRows, bulkSelectRow,
                bulkSelect, batchTotal,
                reviewTable,
                reviewTableUnMapped: reviewTable,
                batchTotalReview
            }, () => {
                let erPermission = reviewTable.filter(x => x.isRemDeletePermission === "Yes");
                if (erPermission.length > 0) {
                    //this.setState({ noDeletePermission: true });
                } else {
                    //this.setState({ noDeletePermission: false });
                }
            });
        }, 0);
    }

    handleOnSelectAll = (isSelect, rows) => {
        this.setState({ reviewTable: [], reviewTableUnMapped: [] });
        let isTransferEntry = this.props.parentState.key === "transfers";
        setTimeout(() => {
            let { selectedRows, batchTotal, reviewTable, reviewTableUnMapped, resetTableData, batchTotalReview } = this.state;
            let rPT = [] as any;
            if (isSelect) {
                rows.forEach((item, id) => {
                    let arr = {} as any;
                    arr.uniqueno = item.uniqueno;
                    arr.rowNo = item.rowNo;
                    arr.rowID = id;
                    arr.backHID = item.backHID;
                    arr.oprid = item.oprid;
                    arr.company = isTransferEntry ? item.ttFvendor : item.company;
                    arr.addContract = item.addContract;
                    arr.aid = item.aid;
                    arr.vid = item.vid;
                    arr.etype = item.etype;
                    arr.etypeOld = item.etype;
                    arr.isCreditCard = item?.isCreditCard;
                    arr.billDate = item?.isCreditCard?.toLowerCase() === "yes" ? new Date() : isTransferEntry ? item.billDate : item.etype?.toLowerCase() === "check" || item.etype?.toLowerCase() === "credit card" || item.etype?.toLowerCase() === "m-ach" || item.etype?.toLowerCase() === "epay" ? "" : item.billDate;
                    //this.state.parentType === 'unpaid' && isTransferEntry && item.etype?.toLowerCase() === "m-ach" ? item.billDate : this.state.parentType === 'unpaid' ? "" : item.billDate;
                    arr.transTotal = item.transTotal; //Utils.currencyFormatWithOutdoller(item.transTotal);
                    arr.internalNotes = '';//item.internalNotes;
                    arr.isGrouped = "No";
                    arr.cardUniqueno = item.cardUniqueno;
                    arr.gdate = item.gdate;
                    arr.paymentNotes = item.paymentNotes;
                    arr.isEnterPrise = item.isEnterPrise;
                    arr.isVendorGroup = item.isVendorGroup;
                    arr.mdGroupingFeature = item.mdGroupingFeature;
                    arr.isTransferEntry = item.isTransferEntry !== undefined ? true : false;
                    arr.entryType = item.entryType;
                    arr.invoiceNo = item.invoiceNo;
                    arr.pActivate = item.pActivate;
                    arr.papClosed = item.papClosed;
                    arr.isDateErr = false;
                    arr.paymentPerm = item.paymentPerm;
                    reviewTable.push(arr);
                    batchTotal += item.transTotal;
                    batchTotalReview += item.transTotal;
                });
            }
            else {
                reviewTable = [];
                batchTotal = 0;
                batchTotalReview = 0;
            }
            if (rows.length > 0) {
                if (isSelect) {
                    rows.forEach(item => selectedRows.push(item.rowNo));
                    $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
                    if (resetTableData.length > rows.length) {
                        Utils.toastError(`${rows.length} rows selected out of ${resetTableData.length}.`, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } else {
                    selectedRows = [];
                    this.footerBulkClose();
                }
                this.setState(() => ({
                    selectedRows, bulkSelectRow: isSelect, bulkSelect: isSelect,
                    batchTotal, reviewTable,
                    reviewTableUnMapped: reviewTable,
                    isSpinner: false, footerLoader: false,
                    batchTotalReview
                }));
            }
        }, 0);
    }

    footerBulkSelectAll = (event) => {
        let isTransferEntry = this.props.parentState.key === "transfers";
        let { tableData, selectedRows, searchResult, batchTotal, reviewTable, resetTableData, batchTotalReview } = this.state;
        selectedRows = [];
        let tableLen = tableData;
        let checked = event.target.checked;
        if (checked) {
            reviewTable = [];
            batchTotal = 0;
            batchTotalReview = 0;
            searchResult.filter((item, i) => selectedRows.push(item.rowNo));
            searchResult.forEach((item, id) => {
                let arr = {} as any;
                arr.uniqueno = item.uniqueno;
                arr.rowNo = item.rowNo;
                arr.rowID = id;
                arr.backHID = item.backHID;
                arr.oprid = item.backHID;
                arr.company = isTransferEntry ? item.ttFvendor : item.company;
                arr.addContract = item.addContract;
                arr.aid = item.aid;
                arr.vid = item.vid;
                arr.etype = item.etype;
                arr.etypeOld = item.etype;
                arr.isCreditCard = item?.isCreditCard;
                arr.billDate = item?.isCreditCard?.toLowerCase() === "yes" ? new Date() : isTransferEntry ? item.billDate : item.etype?.toLowerCase() === "check" || item.etype?.toLowerCase() === "credit card" || item.etype?.toLowerCase() === "m-ach" || item.etype?.toLowerCase() === "epay" ? "" : item.billDate;
                //this.state.parentType === 'unpaid' && isTransferEntry && item.etype?.toLowerCase() === "m-ach" ? item.billDate : this.state.parentType === 'unpaid' ? "" : item.billDate;
                arr.transTotal = item.transTotal; //Utils.currencyFormatWithOutdoller(item.transTotal);
                arr.internalNotes = ''; //item.internalNotes;
                arr.isGrouped = "No";
                arr.cardUniqueno = item.cardUniqueno;
                arr.gdate = item.gdate;
                arr.paymentNotes = item.paymentNotes;
                arr.isEnterPrise = item.isEnterPrise;
                arr.isVendorGroup = item.isVendorGroup;
                arr.mdGroupingFeature = item.mdGroupingFeature;
                arr.isTransferEntry = item.isTransferEntry !== undefined ? true : false;
                arr.entryType = item.entryType;
                arr.invoiceNo = item.invoiceNo;
                arr.pActivate = item.pActivate;
                arr.papClosed = item.papClosed;
                arr.isDateErr = false;
                arr.paymentPerm = item.paymentPerm;
                reviewTable.push(arr);
                batchTotal += item.transTotal;
                batchTotalReview += item.transTotal;
            });
        } else {
            selectedRows = [];
            reviewTable = [];
            batchTotal = 0;
            batchTotalReview = 0;
            this.footerBulkClose();
        }
        if (selectedRows.length > 0 && selectedRows.length < tableLen.length) {
            $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
        } else if (selectedRows.length === tableLen.length) {
            $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
        }
        if (checked)
            if (resetTableData.length > searchResult.length) {
                Utils.toastError(`${searchResult.length} rows selected out of ${resetTableData.length}.`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        this.setState({ bulkSelectRow: checked, selectedRows, bulkSelect: checked, batchTotal, reviewTable, reviewTableUnMapped: reviewTable, batchTotalReview });
    }

    footerBulkClose = () => {
        this.setState({ selectedRows: [], bulkSelect: false, bulkSelectRow: false, batchTotal: 0, reviewTable: [], reviewTableUnMapped: [], batchTotalReview: 0 }, () => {
            $("#footerBulkSelect").prop("checked", false).prop("indeterminate", false);
        })
    }

    searchResult = (resultAfterSearch: any) => {
        let { searchResult, tableData } = this.state;
        searchResult = [];
        let afterSearchUniqueno: any = [];
        resultAfterSearch.forEach(item => afterSearchUniqueno.push(item.rowNo));
        afterSearchUniqueno.forEach(uNo => {
            tableData.filter(item => {
                if (item.rowNo === uNo) {
                    searchResult.push(item);
                }
            })
        });
        this.setState({ bulkSelectRow: false, selectedRows: [], searchResult }, () => {
            this.footerBulkClose();
            this.updateColumns();
        });
    }

    loadBackData() {
        this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
    }

    hideSlideOut(isSave) {
        if (isSave) {
            this.setState({ isSlideOut: false }, () => {
                this.props?.refreshTable(this.state.parentType, this.props?.parentState.key);
            });
        } else {
            this.setState({ isSlideOut: false });
        }
    }

    handleSortTable = (eventKey: any, eventType) => {
        let { columnSortingList, tableData, SortBy } = this.state;
        columnSortingList.map(item => item.id === eventKey ? (item.isActive = true) : (item.isActive = false))
        let isRow = columnSortingList.filter(item => item.id === eventKey)
        SortBy.name = isRow[0].name;
        SortBy.id = isRow[0].id;
        //tableData = _.sortBy(tableData, eventKey);
        //Second Criteria as due date
        tableData.map(
            (item, index) => (
                (item["dueDate1"] = new Date(moment(item.dueDate).format("YYYY-MM-DD"))),
                (item["company1"] = item.company?.toUpperCase()),
                (item["invoiceNo1"] = item.invoiceNo?.toUpperCase()),
                (item["billDate1"] = new Date(moment(item.billDate).format("YYYY-MM-DD")))
            )
        );
        if (eventKey === "company") {
            tableData = _.orderBy(tableData, ["company1", "dueDate1"], ['asc', 'asc']);
            //_.orderBy(tableData, [(obj) => obj?.company?.toUpperCase()], [(obj) => obj.dueDate1], ['asc']);
        } else if (eventKey === "invoiceNo") {
            tableData = _.orderBy(tableData, ["invoiceNo1", "dueDate1"], ['asc', 'asc']);
            //_.orderBy(tableData, [(obj) => obj?.invoiceNo?.toUpperCase()], [(obj) => new Date(obj.dueDate)], ['asc']);
        } else if (eventKey === "dueDate") {
            tableData = _.orderBy(tableData, [(obj) => new Date(obj.dueDate)], ['asc']);
        } else if (eventKey === "billDate") {
            tableData = _.orderBy(tableData, ["billDate1", "dueDate1"], ['asc', 'asc']);
            //_.orderBy(_.orderBy(tableData, [(obj) => new Date(obj.billDate)], ['asc']), [(obj) => new Date(obj.dueDate)], ['asc']);
        } else if (eventKey === "accper") {
            tableData = _.orderBy(tableData, ["accper1", "dueDate1"], ['asc', 'asc']);
            //_.orderBy(tableData, [(obj) => new Date(obj.accper1)], [(obj) => new Date(obj.dueDate1)], ['asc']);
            //_.orderBy(_.orderBy(tableData, [(obj) => new Date(obj.accper1)], ['asc']), [(obj) => new Date(obj.dueDate)], ['asc']);
        } else if (eventKey === "reviewstatus") {
            const customOrder = ["Needs Review", "Reviewed", "Review Complete", ""];
            tableData = _.orderBy(tableData, [(obj) => {
                const index = customOrder.indexOf(obj.reviewstatus);
                return index === -1 ? customOrder.length : index;
            }], ['asc']);
        } else {
            tableData = _.sortBy((_.orderBy(tableData, [(obj) => new Date(obj.dueDate1)], ['asc'])), eventKey);
        }
        this.setState({ SortBy, tableData, columnSortingList, isSpinner: false }, () => {
            if (eventType === 'change')
                this.props.updateSort(SortBy.id);
        });
    }

    memo = (cell, row, rowIndex) => {
        if (row.etype?.toLowerCase() === 'check') {
            return (
                <div className={row.etype?.toLowerCase() === 'check' ? "inputBorderPay" : ""}>
                    <EllipsisWithTooltip placement="top">{row.internalNotes}</EllipsisWithTooltip>
                </div>
            );
        } else {
            return (<></>)
        }
    };

    difference(first, sec) {
        return Math.abs(first - sec);
    }

    handleScroll = (e) => {
        const isPPModalShow = this.state.tableData.some((o) => o.isSpinnerPP === true);
        const scrollTop = this.scrollArea?.current?.scrollTop;
        const clientHeight = this.scrollArea?.current?.clientHeight;
        const scrollHeight = this.scrollArea?.current?.scrollHeight;

        if (this.state.showDropDown && !isPPModalShow) {
            $('#more-action').click();
        }

        let sum = scrollTop + clientHeight;
        var diff = this.difference(sum, scrollHeight);
        let reviewTableCopy = [...this.state.reviewTable];
        if (diff < 5) {
            if (this.state.resetTableData.length > this.state.tableData.length) {
                this.setState({ footerLoader: true, isSpinner: true, reviewTable: [], selectedRows: [], batchTotal: 0 });
                let nextCount = this.state.nextCount + 1000;
                setTimeout(() => {
                    let newTableData = this.state.resetTableData.slice(0, nextCount);
                    newTableData.map((items) => (
                        items["wom_vendorWidthCol"] = this.state.wom_vendorWidthCol
                    ));
                    this.setState({
                        tableData: this.state.resetTableData.slice(0, nextCount),
                        searchResult: this.state.resetTableData.slice(0, nextCount),
                        nextCount: nextCount,
                    }, () => {
                        if (reviewTableCopy.length > 0) {
                            reviewTableCopy.forEach(element => {
                                this.handleOnSelect(element, true)
                            });
                            this.setState({ isSpinner: false, footerLoader: false });
                        } else {
                            this.setState({ isSpinner: false, footerLoader: false });
                        }
                    });
                }, 1500);
            } else {
                this.setState({ isSpinner: false, footerLoader: false });
            }

        }
    };

    handleDateValidation = (event, row, FieldType) => {
        let { reviewTable } = this.state;
        let isTransferEntry = this.props.parentState.key === "transfers";
        if (FieldType === "trDate") {
            let isDate = event === null ? Utils.getHotalCurrentDate() : event.currentTarget.value;
            let today = new Date(isDate);

            let currentDate = new Date();
            let Cmonth: any =
                currentDate.getMonth() + 1 <= 9
                    ? "0" + (currentDate.getMonth() + 1)
                    : currentDate.getMonth() + 1;
            let Cday: any = currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate();
            let Cyear: any = currentDate.getFullYear().toString(); //.substr(-2);

            if (isTransferEntry && row.etype?.toLowerCase() === "check") {
                if (today < new Date(`${Cmonth}/${Cday}/${Cyear}`)) {
                    Utils.toastError("You can't select past date.");
                    return false;
                }
            }
            let month: any =
                today.getMonth() + 1 <= 9
                    ? "0" + (today.getMonth() + 1)
                    : today.getMonth() + 1;
            let day: any = today.getDate() <= 9 ? "0" + today.getDate() : today.getDate();
            let year: any = today.getFullYear().toString(); //.substr(-2);
            if (isNaN(month) || isNaN(day) || isNaN(year)) {
                Utils.toastError("Invalid date format.");
                return false;
            }
            let dateNew = `${month}/${day}/${year}`;
            reviewTable = reviewTable.map((items) => {
                if (items.uniqueno === row.uniqueno) {
                    return { ...items, billDate: dateNew };
                } else {
                    return items;
                }
            });
        }
        this.setState((curr: any) => ({
            ...curr,
            reviewTable,
        }));
    }

    handleBulkUnapprove = () => {
        confirmAlert({
            title: "Confirm Unapprove",
            message: "This unapprove action will affect all approval rules.  Are you sure you would like to proceed?",
            buttons: [
                { label: "Confirm", className: "confirmAlert btnSuccess", onClick: () => { this.bulkUnapproveAction(); reject() } },
                { label: "Cancel", className: "confirmAlert btnDiscard", onClick: () => reject() },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    bulkUnapproveAction = () => {
        this.setState({ loaderTrue: true });
        let { reviewTable } = this.state;
        let isUniqueNo: any = [];
        let requestArray: any = {};
        reviewTable.forEach((item: any) => isUniqueNo.push(item.uniqueno));
        requestArray.InvoiceUniqueNo = isUniqueNo.toString()
        requestArray.RuleIds = "";
        let isTextPerCount = reviewTable.length > 1 ? "Invoices" : "Invoice";
        let isErrorMessage: any = "";
        InvoiceApprovalS.UnApproveInvoiceBulk(requestArray)
            .then(async (result: any) => {
                if (result.success) {
                    if (result.result.result.toString().toLocaleLowerCase() === "success") {
                        toast.success(`${isTextPerCount} unapproved successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
                        this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                    } else if (result.result.result.toString().toLocaleLowerCase() === "validationinvoice") {
                        isErrorMessage = reviewTable.length > 1 ?
                            "One or more invoices are no longer present in the system as someone else has taken another action." :
                            "The current invoice is no longer present in the system as someone else has taken another action."
                    } else if (result.result.result.toString().toLocaleLowerCase() === "validationpaid") {
                        isErrorMessage = reviewTable.length > 1 ?
                            "One or more invoices are already paid and changes are no longer allowed in this session. Please reopen the invoice and make any necessary changes." :
                            "This invoice is already paid and changes are no longer allowed in this session. Please reopen the invoice and make any necessary changes."
                    } else if (result.result.result.toString().toLocaleLowerCase() === "apclosed" || result.result?.message.toString().toLocaleLowerCase() === "closed") {
                        isErrorMessage = reviewTable.length > 1 ?
                            "One or more invoices cannot be unapproved as Accounting period has been closed for this invoice." :
                            "Invoice cannot be unapproved as Accounting period has been closed for this invoice."
                    } else {
                        isErrorMessage = "Something went wrong.";
                    }
                    this.setState({ loaderTrue: false }, () => {
                        if (isErrorMessage !== "") {
                            Utils.toastError(isErrorMessage, { position: toast.POSITION.BOTTOM_RIGHT });
                        }
                    });
                } else {
                    if (result.result === "Validation") {
                        isErrorMessage = reviewTable.length > 1 ?
                            "One or more invoices are no longer present in the system as someone else has taken another action." :
                            "The current invoice is no longer present in the system as someone else has taken another action."
                    } else {
                        isErrorMessage = "Something went wrong.";
                    }
                    this.setState({ loaderTrue: false }, () => {
                        if (isErrorMessage !== "") {
                            Utils.toastError(isErrorMessage, { position: toast.POSITION.BOTTOM_RIGHT });
                        }
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ loaderTrue: false });
                Utils.toastError(`Error: ${error}`, { position: toast.POSITION.BOTTOM_RIGHT });
                reject();
            });
    }

    render() {
        const bulkResponsiveList = () => {
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        const footerLoader = () => {
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        const sortSvg = () => {
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
                </svg>
            )
        }

        const gearSvg = () => {
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
                </svg>)
        }

        const checkSvg = () => {
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                </svg>
            )
        }

        const returnGroupedCount = (cell: any, row: any, rowIndex: any) => {
            if (row.isGrouped === "Yes") {
                return (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="groupdCount">{`${row.groupedInvoiceCount} Invoices`}</Tooltip>}
                    >
                        <div className="desc grey PaymentInvCount">
                            <span className="red-badges gray-bg">{row.groupedInvoiceCount}</span>
                        </div>
                    </OverlayTrigger>
                );
            } else {
                return (
                    <></>
                )
            }
        }

        const ReviewPaymentColumns = [
            {
                dataField: "company",
                text: "Vendor",
                editable: false,
                headerClasses: "defaultColWidth-PAY",
                formatter: (
                    cell: any,
                    row: any,
                    rowIndex: any
                ) => {
                    return (
                        <div>
                            <div className="title">
                                <div className="d-flex vendorPayment">
                                    <EllipsisWithTooltip placement="top">
                                        {row.company}
                                    </EllipsisWithTooltip>
                                    {returnGroupedCount(cell, row, rowIndex)}
                                </div>
                            </div>
                        </div>
                    )
                },
            },
            {
                dataField: "etype",
                text: "Method",
                editable: false,
                headerClasses: "defaultColWidth-PAY",
            },
            {
                dataField: "billDate",
                text: "Date",
                editable: false,
                headerClasses: "defaultColWidth-PAY",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="date-picker calender-picker importJourDropdSmall">
                            <DatePicker
                                id={`trdate_${rowIndex}`}
                                ref={this.trdate}
                                className={`form-control ${row.isDateErr ? "border-danger" : ""}`}
                                placeholderText={"MM/DD/YY"}
                                dateFormat="MM/dd/yy"
                                autoComplete="off"
                                //minDate={row.etype?.toLowerCase() === 'check' ? new Date() : ""}
                                selected={row.billDate === "" ? new Date() : new Date(row.billDate)}
                                onSelect={(event: any) =>
                                    this.handleChange(event, row, "trDate")
                                }
                                //onBlur={(e: any) => this.handleDateValidation(e, row, "trDate")}
                                disabled={row.etype?.toLowerCase() === "d-ach" || row.etype?.toLowerCase() === "ach"}
                            />
                        </div>
                    );
                },
            },
            {
                dataField: "transTotal",
                text: "Total",
                editable: false,
                headerClasses: "defaultColWidth-PAY",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            {Utils.currencyFormatWithOutdoller(row.transTotal)}
                        </div>
                    )
                }

            },
            {
                dataField: "internalNotes",
                text: this.props.parentState.key === "credit card" ? "Note" : "Memo",
                headerClasses: "defaultColWidth-PAY",
                formatter: this.memo,
                hidden: this.state.reviewTable.some((o) => o.etype?.toLowerCase() === 'check') ? false : true,
                editable: (cell: any, row: any, rowIndex: any) => {
                    if (row.etype?.toLowerCase() === 'check') {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            {
                dataField: "action",
                text: "",
                editable: false,
                headerClasses: "lastChildColWidth reviewLastChild",
                classes: "lastChildColWidth reviewLastChild",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <div className="reviewLastChildContainer">
                        <Dropdown
                            // onClick={(e) => {
                            //     e.stopPropagation();
                            // }}
                            className="more-action"
                            alignRight
                        >
                            <Dropdown.Toggle
                                className="btn-outline-primary btn btn-primary btn-ghost more"
                                id="dropdown-more"
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {row.isGrouped.toLowerCase() === "yes" && this.props.parentState.key !== "transfers" && (
                                    <Dropdown.Item key={rowIndex} onClick={(e) => { this.ungroupInvoice(e, row) }}>Ungroup</Dropdown.Item>
                                )}
                                <Dropdown.Item key={rowIndex} onClick={(e) => { this.deleteCurrentRow(row) }}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ),
            },
        ];


        const associatedInvoicesColumns = [{
            dataField: 'date',
            text: 'Date'
        }, {
            dataField: 'invoiceNo',
            text: 'Number',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="top">
                        {row.invoiceNo}
                    </EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'addContract',
            text: 'Customer Num',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="top">
                        {row.addContract}
                    </EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'transTotal',
            text: 'Amount',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (<div>

                    {Utils.amountFormatted(Number(row.transTotal).toFixed(2))}

                </div>)
            }
        }, {
            dataField: 'paymentType',
            text: 'Status',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (<div className="paid-status">{row.paymentType}</div>)
            }
        }];
        const selectVoidRow = {
            mode: "checkbox",
            clickToSelect: true,
            selected: this.state.selectedVoid,
            onSelect: this.handleOnVoidSelect,
            onSelectAll: this.handleOnVoidSelectAll,
            selectionHeaderRenderer: ({ mode, ...rest }) => (
                <Form.Check type={mode} {...rest} label="" custom />
            ),
            selectionRenderer: ({ mode, ...rest }) => (

                <>
                    {/* <input type={ mode } { ...rest } /> */}
                    <Form.Check type={mode} {...rest} label="" custom />
                </>



            )
        };

        const reviewPrintConfirmationColumns = [
            {
                dataField: "vendor",
                text: "Vendor",
                editable: false,
                headerClasses: "defaultColWidth-RTPCM",
                formatter: (
                    cell: any,
                    row: any,
                    rowIndex: any
                ) => {
                    return (
                        <div>
                            <div className="title">
                                <div className="d-flex vendorPayment">
                                    <EllipsisWithTooltip placement="top">
                                        {row.vendor}
                                    </EllipsisWithTooltip>
                                    {returnGroupedCount(cell, row, rowIndex)}
                                </div>
                            </div>
                        </div>
                    )
                },
            },
            {
                dataField: "chkno",
                text: "Check No.",
                editable: false,
                headerClasses: "defaultColWidth-RTPCM",
            },
            {
                dataField: "amount",
                text: "Check Total",
                editable: false,
                headerClasses: "defaultColWidth-RTPCM",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            {Utils.currencyFormatWithOutdoller(row.amount)}
                        </div>
                    )
                }

            },
            {
                dataField: "memo",
                text: "Memo",
                headerClasses: "defaultColWidth-RTPCM",
                formatter: (cell, row, rowIndex) => {
                    return (
                        <div className={row.etype?.toLowerCase() === 'check' ? "inputBorderPay" : ""}>
                            <EllipsisWithTooltip placement="top">{row.memo}</EllipsisWithTooltip>
                        </div>
                    );
                },
                editable: (cell: any, row: any, rowIndex: any) => {
                    if (row.etype?.toLowerCase() === 'check') {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            {
                dataField: "",
                text: "",
                headerClasses: "wom_colBtn text-right",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex">
                            <div className="ml-auto">
                                <Button variant="primary" type="button"
                                    className={!row.isPrintConfirmed ? `space btn btn-primary btn-discard mr-0` : `space btn confirm-btn-review-print-confirm`}
                                    onClick={(e) => { this.onConfirnPrintClick(rowIndex) }}>
                                    {!row.isPrintConfirmed ? "Confirm Print"
                                        : "Confirmed"
                                    }
                                </Button>
                            </div>
                        </div>
                    );
                }
            },
            {
                dataField: "",
                text: "",
                editable: false,
                headerClasses: "lastChildColWidth",
                classes: "lastChildColWidth",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <Dropdown
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className="more-action"
                        alignRight
                    >
                        <Dropdown.Toggle
                            className="btn-outline-primary btn btn-primary btn-ghost more"
                            id="dropdown-more"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {(this.state.hasMakePayments && (row.etype?.toLowerCase() === 'check' || row.etype?.toLowerCase() === "transfercheck" ? Number(row.amount) !== 0 ? true : false : true)) && (
                                <Dropdown.Item key={rowIndex} onClick={(e) => { this.rePrintConfirm(row) }}>Reprint</Dropdown.Item>
                            )}

                            {this.props.voidPermission === "Yes" && (
                                <Dropdown.Item key={rowIndex} onClick={(e) => { this.printConfirmationVoidBtnClicked(row) }}>Void</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                ),
            },
        ];



        let { selectedRows, tableData, defaultDensityView } = this.state;
        let densityView = defaultDensityView;
        let className;
        let tableLen = tableData;
        let isLenLess = selectedRows.length > 0 && selectedRows.length < tableLen.length;
        className = isLenLess ? "showTip" : "";
        const isTransferEntry = this.props.parentState.key === "transfers";

        const selectRow = {
            mode: 'checkbox',
            selected: this.state.selectedRows,
            headerClasses: "selectionHeader",
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
        };


        if (this.props.parentState?.key === "checks") {
            isEnterPriseFound = this.state.reviewTableUnMapped?.some(
                (item) => item.isEnterPrise?.toLowerCase() === "no"
            );
        }
        const isInvalidAccountName = this.state.accountName === "" || this.state.accountName === undefined || this.state.accountName === null || this.state.accountName === "Select Account Name"
        const isInvalidDate = this.state.reviewTable?.some((o) => o.isDateErr === true);

        const isSubmitBtnDisable = (
            this.state.reviewTable.length === 0 ? true :
                ((this.state.reviewProcessStart ||
                    this.state.isSubmitPayment ||
                    !isTransferEntry) && (isInvalidAccountName ||
                        this.state.balanceLoader)) ||
                isInvalidDate || this.state.isSubmitPayment
        );

        let { parentType, hasUnApproved } = this.state;
        let isparentState = this.props.parentState.key.toLowerCase();
        let isFooterUnApprove: boolean = false;
        isFooterUnApprove = (hasUnApproved && ((parentType === "paid" && isparentState === "confirm payment") || (parentType === "unpaid" && !isTransferEntry)))

        return (
            <>
                {this.state.loaderTrue && (
                    <div className="matrixSpinnerCenter">
                        <Spinner animation="border" variant="secondary" />
                    </div>
                )}
                {this.state.isSlideOut && (
                    <InvoiceEntrySlideout
                        mainHidValue={this.props.hidValue}
                        hidValue={this.state.hidValue}
                        hotelName={this.state.sildeoutCode}
                        uniqueNo={this.state.uniqueNo}
                        pageType={"Invoice"}
                        saveAction={"Payment"}
                        oprId={this.state.oprId}
                        vid={this.state.vid}
                        hideSlideOut={this.hideSlideOut.bind(this)}
                        loadBackData={this.loadBackData.bind(this)}
                        loadFrom={this.props.parentState.key?.toLowerCase() === "confirm print" ? "confirmPrint" : "PaymentPage"}
                    />
                )}
                {this.state.isShowPaymentmodal && (
                    // <PrintConfirmationModal
                    //     {...this.state}
                    //     hidePaymentModal={this.hidePaymentModal}
                    //     sendOTPText={this.sendOTPText}
                    //     submitPayments={this.submitPayments}
                    //     isUserInSession={this.isUserInSession}
                    // />
                    <OtpValidator
                        ref={this.ChildOTP}
                        closePopup={this.hidePaymentModal.bind(this)}
                        ValidateOTP={this.ValidateOTP.bind(this)}
                        PageName={"Print Checks"}
                        Source={1}
                    ></OtpValidator>

                )}
                {this.state.isShowOTPmodal && (
                    // <PrintConfirmationModal
                    //     {...this.state}
                    //     hidePaymentModal={this.hidePaymentModal}
                    //     sendOTPText={this.sendOTPReprint}
                    //     submitPayments={this.reprintPaymentsDetail}
                    //     isUserInSession={this.isUserInSession}
                    // />
                    <OtpValidator
                        ref={this.ChildRePrintOTP}
                        closePopup={this.hidePaymentModal.bind(this)}
                        ValidateOTP={this.ValidateOTP.bind(this)}
                        PageName={"RePrintPaymentChecks"}
                        Source={1}
                    ></OtpValidator>
                )}
                {this.state.isTransferSlideOut && (
                    <TransferSlideout
                        accDetails={this.state.registerRequest}
                        hidValue={this.state.sildeoutValue}
                        hotelName={this.state.sildeoutCode}
                        uniqueNo={this.state.uniqueNo}
                        //pageType={this.state.pageType}
                        //valuesArray={this.state.valuesArray}
                        transferRequest={this.state.transferRequest}
                        hideTrSlideOut={this.hideTrSlideOut.bind(this)}
                        accountStatus={"active"}
                        isfromSearch={true}
                    />
                )}

                <ToolkitProvider
                    keyField="rowNo"
                    data={tableData}
                    columns={this.columnsHeader}
                    search={{ afterSearch: (newResult) => this.searchResult(newResult) }}
                >
                    {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                    }) => (
                        <>
                            <div className="InnerTabPanel-Pay">
                                <div className='TabBody-Pay'>
                                    <div className="search-header d-flex">
                                        <div className="search-filter">
                                            <label id="labeltableSearch" className="search-label">
                                                <TableSearch id="tableSearch" key="tableSearch" ref={this.child}
                                                    {...props.searchProps} CleanSearch={this.CleanSearch.bind(this)}
                                                    placeholder={"Filter"}
                                                />
                                            </label>
                                        </div>
                                        <div className="d-flex ml-auto">
                                            <div className={`${tableData.length === 0 ? "disabled-area" : ""} hasShortingBtn wom_hasGearIcon mr-2`}>
                                                <Dropdown className="pos-static more-action bg-blue dropdown wom_dropdown" alignRight>
                                                    <Dropdown.Toggle id="dropdown-ptoType" disabled={tableData.length === 0 || this.state.isSpinner}>
                                                        <div className="d-flex align-items-center wom_svg">
                                                            <div className="d-flex align-items-center wom_svg">
                                                                {sortSvg()}
                                                                {this.state.SortBy.name}
                                                                <div className="drop-arrow d-flex ml-1">
                                                                    <FiChevronDown />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="wom_DencityMenu">
                                                        <div className="wom_MenuOverlap">
                                                            {this.state.columnSortingList.map((item, index) => {
                                                                if (item.isHidden) {
                                                                    return (<></>)
                                                                } else {
                                                                    return (
                                                                        <div key={index}
                                                                            className={item.isActive ? "active wom_dropdownItem" : "wom_dropdownItem"}
                                                                            onClick={(e) => this.handleSortTable(item.id, 'change')}>
                                                                            <div className="d-flex">
                                                                                <div className="mr-auto">{item.name}</div>
                                                                                <div className="ml-auto">{item.isActive && checkSvg()}</div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                        <div className="m-0 dropdown-divider"></div>
                                                        <Dropdown.Item className="wom_saveDefaultBtn" onClick={() => this.saveDefaultView()} >Save as Default View</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="ml-1 wom_hasGearIcon">
                                                <Dropdown className="more-action bg-blue dropdown wom_dropdown">
                                                    <Dropdown.Toggle id="dropdown-ptoType" disabled={tableData.length === 0}>
                                                        <div className="d-flex align-items-center wom_svg">
                                                            {gearSvg()}
                                                            <div className="drop-arrow d-flex">
                                                                <FiChevronDown />
                                                            </div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="wom_DencityMenu">
                                                        <>
                                                            <div className="wom_gearIconHeader wom_headerTop"><strong>Density View Control</strong></div>
                                                            {this.state.densityView.map((items, index) => (

                                                                <>
                                                                    {items.densityType !== "groupUnpaid" && (
                                                                        <div className={densityView === items.densityType ? 'wom_dropdownItem wom_ddactive' : 'wom_dropdownItem'}
                                                                            key={items.id}
                                                                            onClick={(e: any) => this.handleDensityView(e, items.densityType)}>{items.name}
                                                                            {densityView === items.densityType && (<>{checkSvg()}</>)}
                                                                        </div>
                                                                    )}
                                                                    {items.densityType === "groupUnpaid" && this.state.parentType === 'unpaid' && !isTransferEntry && (
                                                                        <div className="wom_dropdownItem d-flex"
                                                                            key={items.id}>{items.name}
                                                                            <Form.Check type="switch" style={{ margin: "-8px 0px 0px 65px" }} id="custom-switch" label=""
                                                                                checked={this.props.isGroupUnpaid}
                                                                                onChange={(e: any) => this.handleDensityView(e, items.densityType)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ))}
                                                            <div className="m-0 dropdown-divider"></div>
                                                            <Dropdown.Item className="wom_saveDefaultBtn" onClick={() => this.saveDefaultView()} >Save as Default View</Dropdown.Item>
                                                        </>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="CombinedTable-Pay-with-icon-formatter">
                                        <div className="has-icon">{this.iconFormatter()}</div>
                                        <div id="CombinedTable-Pay" onScroll={(e) => this.handleScroll(e)}
                                            className={`${this.state.bulkSelect && this.state.reviewTable.length > 0 ? "CombinedTable-Pay payHashasFooter" : "CombinedTable-Pay payHasNoFooter"} 
                                    ${className} ${this.props.parentState.hasAlertSession ? "hasAlertBox" : ""}`}
                                            ref={this.scrollArea}>

                                            {(this.state.isSpinner || this.props?.parentState?.tableLoadingProcess) ? (
                                                <div className="bulkImportList mt-0">
                                                    <div className="bulkImportList1 d-flex">
                                                        <div className="single-loader loadingAnimation"></div>
                                                    </div>
                                                    <>{bulkResponsiveList()}</>
                                                    <>{bulkResponsiveList()}</>
                                                    <>{bulkResponsiveList()}</>
                                                </div>
                                            ) : (
                                                <div className={this.state.isBulkSelected ? `${densityView} disabled-area stickey` : `${densityView} stickey`}>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        keyField="rowNo"
                                                        hover
                                                        noDataIndication={`There are no ${isTransferEntry ? "Transfers" : "Invoices"} with this payment method.`}
                                                        selectRow={selectRow}
                                                        filter={filterFactory()}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {this.state.footerLoader ?
                                        (<>{footerLoader()}</>) :
                                        <div className={this.state.bulkSelect && this.state.reviewTable.length > 0 ? "TabFooter-Pay" : "d-none TabFooter-Pay"}>
                                            <div className="bulkSelectFooter">
                                                <Form.Check type="checkbox" label=""
                                                    id="footerBulkSelect"
                                                    checked={this.state.bulkSelectRow}
                                                    onClick={(e: any) => this.footerBulkSelectAll(e)} />
                                                <span className="shift-selected-count">{this.state.selectedRows.length}
                                                    {" "}Selected</span>
                                                <div className="FooterHorLine"></div>
                                                <span className="shift-selected-count">Batch Total: {Utils.currencyFormatWithOutdoller(this.state.batchTotal)}</span>
                                                <div className="action-group d-flex flex-row mr-auto">

                                                    {this.renderPayConfirmButton()}
                                                    {isFooterUnApprove && (<Button type="button" onClick={this.handleBulkUnapprove} className="btn-outline-primary btnHeight">Unapprove</Button>)}
                                                    {this.state.parentType === "paid" && (
                                                        <>
                                                            {this.props.parentState.key.toLowerCase() === "confirm payment" ?
                                                                <>{this.props.parentState.editInvPerm && (
                                                                    <Button type="button"
                                                                        className="btn-outline-primary btnHeight"
                                                                        onClick={(e) => { this.handleBtnEvent(e, "", 'bulk') }}
                                                                    >Mark as Unpaid</Button>
                                                                )}
                                                                </> :
                                                                <>{(this.state.hasMakePayments && Number(Number(this.state.batchTotal).toFixed(2)) > 0) && (
                                                                    <Button type="button"
                                                                        className="btn-outline-primary btnHeight"
                                                                        onClick={(e) => { this.reprintSelectedCheckPaid() }}
                                                                    > Reprint check
                                                                    </Button>
                                                                )}</>
                                                            }
                                                        </>
                                                    )}
                                                    {this.props.parentState.key?.toLowerCase() !== "confirm print" && this.props.parentState.deleteInvPerm && (
                                                        <Button type="button"
                                                            onClick={(e: any) => this.handleBtnEvent(e, "", "bulk")}
                                                            className="btn-outline-primary btnHeight"
                                                            disabled={isTransferEntry ? this.state.noDeletePermission ? false : true : false}
                                                        >Delete</Button>
                                                    )}
                                                </div>
                                                <Button style={{ color: "#fff" }} className="closeButton btn-ghost-light" onClick={() => this.footerBulkClose()}
                                                >&times;</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    )}
                </ToolkitProvider>
                <Modal
                    className="review-payments"
                    centered
                    show={this.state.reviewPayModalShow}
                    onHide={this.reviewPayModalHide}
                    backdrop="static"
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    {this.state.reviewProcessStart && (
                        <div className="matrixSpinnerCenter">
                            <Spinner animation="border" variant="secondary" />
                        </div>
                    )}
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="sub-heading-Pay">Review Payments</div>
                        </Modal.Title>
                    </Modal.Header>
                    {this.props.parentState.hasAlertSession && (
                        <div className="notificationBannersPay blue-bg">
                            <div className="mr-auto">
                                <span className="bold">
                                    You are currently in a secure session.  Inactivity, refreshing, or leaving this page will end this session.</span>
                            </div>
                        </div>
                    )}
                    <Modal.Body>
                        <div className="search-header-PY registerHeaderWithoutSearch d-flex PaymentModal">
                            {!isTransferEntry && (
                                <div className="filter-section-PAY">

                                    {this.state.reviewTable.length > 0 ? (
                                        <div
                                            //className="singleSearchDropdownListWithIcon"
                                            className={this.state.balanceLoader ? "disabled-area singleSearchDropdownListWithIcon" : "singleSearchDropdownListWithIcon"}
                                        >
                                            <SingleSearchDropdownList
                                                id={"tableAccountName"}
                                                ref={this.accountName}
                                                itemList={this.state.accountNames}
                                                handleSelectedItem={this.handleSelectedItem.bind(this, 1)}
                                                defaultItem={this.state.HIDToNM}
                                                defaultText={"Search..."}
                                                defaultName={this.state.accountName}
                                                calHomeIcon={true}
                                                currentChecked={this.state.accountName}
                                                controlID="2"
                                                pageType={"Register"}
                                                isStatusbadge={true}
                                                isEnterPriseFound={isEnterPriseFound}
                                                isRefresh={this.state.isRefresh}
                                            //isdisable={this.state.balanceLoader}
                                            />
                                        </div>
                                    ) :
                                        <Dropdown className="more-action bg-blue single-select-image">
                                            <Dropdown.Toggle id="Modal-hid" className="filter-btn" disabled={true}>
                                                <div className="d-flex align-items-center">
                                                    <div className="txt-area">
                                                        <EllipsisWithTooltip placement="bottom">
                                                            Select account name
                                                        </EllipsisWithTooltip>
                                                    </div>
                                                    <div className="drop-arrow ml-0 d-flex">
                                                        <FiChevronDown />
                                                    </div>
                                                </div>
                                                <div className="cal-icon housekeeping-standalone">
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0)">
                                                            <path d="M1.3689 6.31577V9.19367H2.05895V13.5087H1.3689V15.6666H2.05895H4.12913H5.50924H7.57942H8.95953H11.0297H12.4098L14.48 15.6673V15.6666H15.1701V13.5087H14.48V9.19367H15.1701V6.31577L8.26947 2L1.3689 6.31577ZM4.12913 13.5087V9.19367H5.50924V13.5087H4.12913ZM7.57942 13.5087V9.19367H8.95953V13.5087H7.57942ZM12.4098 13.5087H11.0297V9.19367H12.4098V13.5087ZM9.64959 6.31577C9.64959 7.10987 9.0313 7.75436 8.26947 7.75436C7.50765 7.75436 6.88936 7.10987 6.88936 6.31577C6.88936 5.52167 7.50765 4.87718 8.26947 4.87718C9.0313 4.87718 9.64959 5.52167 9.64959 6.31577Z" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect width="15.4059" height="16" fill="white" transform="translate(0.406006 0.5)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </div>
                            )}
                            <div className="ml-auto">
                                <div className="Accbalance">
                                    <span className="balTitle">Batch Total</span>
                                    <strong className="balMain">{Utils.currencyFormatWithOutdoller(this.state.batchTotalReview)}</strong>
                                </div>
                            </div>
                            {!isTransferEntry && (<>
                                <div className="borderHorz"></div>
                                <div className="newBalance">
                                    <div className="Accbalance">
                                        <span className="balTitle">New Balance</span>
                                        <strong className="balMain">
                                            {this.state.balanceLoader && (
                                                <>
                                                    <Spinner style={{ color: "#2caf92", alignItems: 'center' }} size="sm" animation="border" />{" "}
                                                </>
                                            )}
                                            {!this.state.balanceLoader && (
                                                <>
                                                    {this.state.totalAcctBal === 0 ? (
                                                        <>-</>
                                                    ) :
                                                        <>{Utils.currencyFormatWithOutdoller(this.state.totalAcctBal)}</>
                                                    }

                                                </>
                                            )}
                                        </strong>
                                    </div>
                                </div>
                            </>
                            )}
                        </div>
                        <ToolkitProvider
                            keyField="uniqueno"
                            data={this.state.reviewTable}
                            columns={ReviewPaymentColumns}
                        >
                            {(props: {
                                searchProps: JSX.IntrinsicAttributes;
                                baseProps: JSX.IntrinsicAttributes;
                            }) => (
                                <div>
                                    <div className="JournalNavTabs PaymentNavTabs">
                                        <div className="grid-sec cusGridSec2 cusGridSecPay">
                                            <div className={`${densityView} scroll-area reviewTable-PAY table-responsive`}>
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    key={Math.floor(Math.random() * 90 + 10)}
                                                    keyField="uniqueno"
                                                    hover
                                                    noDataIndication={this.props.parentState.key === "transfers" ? "No transfer selected." : "No invoice selected."}
                                                    cellEdit={this.tableCellEdit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ToolkitProvider>
                    </Modal.Body>

                    <Modal.Footer className="Footer-Pay">
                        <Button
                            variant="primary"
                            type="button"
                            className="space btn btn-primary btn-discard"
                            onClick={this.reviewPayModalHide}
                            disabled={this.state.reviewProcessStart}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="primary"
                            id="btnSubmitPay"
                            type="button"
                            onClick={this.CheckACPeriodStatusByDate}
                            //onClick={this.ValidatedSession}
                            //onClick={this.submitPayments}
                            disabled={isSubmitBtnDisable || this.state.isSubmitPaymentBtnDisabled}
                        >
                            Submit Payments
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal className="move-invoice-modal" show={this.state.showVoidModal} dialogClassName="modal-70w">
                    <Modal.Header>
                        <Modal.Title>Void Check</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="filter-sec">
                            <div className="d-flex">
                                <Form.Group controlId="vendorName">
                                    <Form.Label>Accounting Period</Form.Label>
                                    <SingleDropdownListWithoutSearch
                                        id={"voidPeriod"}
                                        itemList={this.state.voidPeriodList}
                                        handleSelectedItem={this.handleVoidSelectedItem.bind(this, 11 + '_' + 0)}
                                        defaultItem={this.state.defaultvoidPeriod}
                                        defaultText={"Accouting Period"}
                                        defaultName={this.state.defaultvoidPeriod}
                                        controlID="11" />
                                </Form.Group>

                                <Form.Group className="rm-bottom-margin" controlId="amount">
                                    <Form.Label>Void Date</Form.Label>
                                    <div className="date-picker calender-picker">
                                        <div className="drop-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                            </svg>
                                        </div>
                                        <DatePicker
                                            id="trvoiddate"
                                            tabIndex={0}
                                            className="form-control"
                                            placeholderText={"MM/DD/YY"}
                                            dateFormat="MM/dd/yy"
                                            selected={this.state.transcationvoidDate === null ? "" : new Date(this.state.transcationvoidDate)}
                                            onChange={(event: any) => this.handleVoidChange(event, 'trvoidDate')}
                                            onKeyDown={(e: any) => this.onTAB('trvoiddate-1', e)}
                                            autoComplete='off'
                                        />
                                    </div>
                                </Form.Group>

                            </div>
                            <div className="d-flex">
                                <Form.Group controlId="voidReason">
                                    <Form.Label>Reason</Form.Label>
                                    <Form.Control className={"form-control"} id="txtvoidNotes" as="textarea" wrap="hard" rows={3} value={this.state.voidNotes}
                                        maxLength={200} onChange={(event: any) => this.handleVoidChange(event, 'voidnotes')}
                                        autoComplete="off"

                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className="associated-invoices">
                            <div className="sec-ttl"> Select the invoices below that should be recreated</div>
                            <BootstrapTable
                                keyField='id'
                                data={this.state.associatedPayment.filter(x => x.viewType === 1)}
                                columns={associatedInvoicesColumns}
                                // rowEvents={this.rowEvents}
                                selectRow={selectVoidRow}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-outline-primary" onClick={this.hideVoidModal}>Close</Button>
                        <Button className="btn-primary" onClick={this.handleVoidAction}>Void</Button>
                    </Modal.Footer>
                    {this.state.isSpinner && (
                        <div className="loader-spinner d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="success" />
                        </div>
                    )}
                </Modal>

                <Modal
                    backdrop="static"
                    keyboard={false}
                    show={this.state.transferVoidModal}
                    className="transferVoidModal"
                    onHide={this.transferVoidModalHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Void Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="textmsgV">The offset for the selected Check would be created under</p>
                        <p className="labelTextV">Original Transaction</p>
                        <div className="OldTrans">
                            <Row>
                                <Col sm="6">
                                    <Form.Group>
                                        <Form.Label>
                                            Check Date
                                        </Form.Label>
                                        <Form.Control
                                            id="checkdate"
                                            value={this.state.transferRowData.gdate}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm="6">
                                    <Form.Group>
                                        <Form.Label>
                                            Accounting Period
                                        </Form.Label>
                                        <Form.Control
                                            id="state"
                                            value={this.state.transferRowData.accper}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <p className="labelTextV">Void Transaction</p>
                        <div className="OldTrans">
                            <Row>
                                <Col sm="6">
                                    <Form.Group>
                                        <Form.Label>
                                            Date
                                        </Form.Label>
                                        <div className="date-picker calender-picker">
                                            <DatePicker
                                                tabIndex={0}
                                                className="form-control voiddatePicker"
                                                placeholderText={"MM/DD/YY"}
                                                dateFormat="MM/dd/yy"
                                                selected={this.state.transferRowData.billDate === null ? "" : new Date(this.state.transferRowData.billDate)}
                                                onChange={(event: any) => this.handleTransferVoidChange(event, 'VoidDate')}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col sm="6">
                                    <Form.Group>
                                        <Form.Label>
                                            Accounting Period
                                        </Form.Label>
                                        <div className="rm-bottom-margin form-group">
                                            <div className="single-select">
                                                <SingleDropdownListWithoutSearch
                                                    id={"accountingPeriod"}
                                                    key={Math.floor(Math.random() * 90 + 10)}
                                                    itemList={this.state.voidPeriodList}
                                                    handleSelectedItem={(event: any) => this.handleTransferVoidChange(event, "voidAccPer")}
                                                    defaultItem={this.state.transferVoidAccountingPeriod}
                                                    defaultText={"Accouting Period"}
                                                    defaultName={this.state.transferVoidAccountingPeriod}
                                                    controlID="6"
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <Form.Group>
                                        <Form.Label>
                                            Reason
                                        </Form.Label>
                                        <Form.Control
                                            id="VoidReason"
                                            as="textarea"
                                            value={this.state.transferVoidReason}
                                            placeholder="Enter reason"
                                            onChange={(e) => { this.handleTransferVoidChange(e, 'voidreason') }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-outline-primary" disabled={this.state.isVoidStart} onClick={this.transferVoidModalHide}>
                            Cancel
                        </Button>
                        <Button variant="primary" disabled={this.state.isVoidStart} onClick={this.createVoidEntry}>
                            {this.state.isVoidStart && (
                                <>
                                    <Spinner size="sm" animation="border" />{" "}
                                </>
                            )}{" "}
                            Void
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    centered
                    show={this.state.isShowReviewPrintConfirmationModal}
                    onHide={this.reviewPrintConfirmationModalHide}
                    backdrop="static"
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    {this.state.reviewProcessStart && (
                        <div className="matrixSpinnerCenter">
                            <Spinner animation="border" variant="secondary" />
                        </div>
                    )}
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="sub-heading-Pay">Print Confirmation</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="notificationBannersPay blue-bg bttm-mrgn">
                            <div className="mr-auto">
                                <span className="bold">
                                    Confirm that each check printed successfully.</span>
                            </div>
                        </div>
                        <ToolkitProvider
                            keyField="id"
                            data={this.state.invoiceCHKData}
                            columns={reviewPrintConfirmationColumns}
                        >
                            {(props: {
                                searchProps: JSX.IntrinsicAttributes;
                                baseProps: JSX.IntrinsicAttributes;
                            }) => (
                                <div>
                                    <div className="JournalNavTabs PaymentNavTabs">
                                        <div className="grid-sec cusGridSec2 cusGridSecPay">
                                            <div className={`${densityView} scroll-area reviewTable-payment-confirm-modal table-responsive reviewTable-PAY`}>
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    key={Math.floor(Math.random() * 90 + 10)}
                                                    keyField="id"
                                                    hover
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ToolkitProvider>
                    </Modal.Body>

                    <Modal.Footer className="Footer-review-print-confirm">
                        <div className="action-group d-flex flex-row mr-auto">
                            {(this.state.hasMakePayments && this.state.isValidtotalAmount) && (
                                <Button
                                    variant="primary"
                                    type="button"
                                    className="space btn btn-primary btn-discard"
                                    onClick={() => this.rePrintAllConfirmation()}>
                                    Reprint All
                                </Button>
                            )}
                        </div>
                        <div >
                            <Button
                                variant="primary"
                                type="button"
                                className="space btn btn-primary btn-discard"
                                onClick={this.reviewPrintConfirmationModalHide}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="primary"
                                id="btnSubmitPay"
                                type="button"
                                disabled={this.state.confirmAlldisabled}
                                onClick={this.onConfirnAllPrintedClick}
                            >
                                Confirm All Printed
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>


                <Modal
                    centered
                    show={this.state.isShowReviewPrintConfirmationCloseBtnModal}
                    onHide={this.reviewPrintConfirmationCloseBtnModalHide}
                    backdrop="static"
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header className="header-review-payment-confirm-close-modal" closeButton>
                        <Modal.Title>
                            <div className="sub-header-review-payment-confirm-close-modal">Confirm Check Prints Later</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="body-review-payment-confirm-close-modal  ">
                            <div className="mr-auto">
                                <span className="">
                                    Are you sure you want to leave? There are </span>
                                <span className="">printed checks that need to be confirmed.</span>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="footer-review-payment-confirm-close-modal  " >
                        <Button
                            variant="primary"
                            type="button"
                            className="space btn btn-primary btn-discard mr-0"
                            size="sm"
                            onClick={this.onConfirmLaterClick}
                        >
                            Confirm Later
                        </Button>

                        <Button
                            variant="primary"
                            id="btnSubmitPay"
                            type="button"
                            size="sm"
                            onClick={this.reviewPrintConfirmationCloseBtnModalHide}
                        >
                            Confirm Now
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div id="main"></div>
            </>
        )
    }

    renderPayConfirmButton = () => {
        let { reviewTable } = this.state;
        let isBtnVisible = false;
        if ((this.props.parentState.key.toLowerCase() === "checks" || this.props.parentState.key.toLowerCase() === "confirm print" || this.props.parentState.key.toLowerCase() === "epay")) {
            isBtnVisible = this.state.hasMakePayments;
        } else if ((this.props.parentState.key.toLowerCase() === "confirm payment" || this.props.parentState.key.toLowerCase() === "manual ach" || this.props.parentState.key.toLowerCase() === "credit card")) {
            isBtnVisible = this.props.parentState.makeACHPerm;
        } else if (this.props.parentState.key.toLowerCase() === "checks & ach") {
            if (this.state.hasMakePayments || this.props.parentState.makeACHPerm)
                isBtnVisible = true;
            if (!this.props.parentState.makeACHPerm) {
                let hasmachEntry = reviewTable?.filter((o) => (o.etype?.toLowerCase() === 'm-ach' || o.etype?.toLowerCase() === 'd-ach'));
                if (hasmachEntry?.length === reviewTable?.length) {
                    isBtnVisible = false;
                } else {
                    isBtnVisible = true;
                }
            }
            if (!this.state.hasMakePayments) {
                let hasCheckEntry = reviewTable?.filter((o) => o.etype?.toLowerCase() === 'check');
                if (hasCheckEntry?.length === reviewTable?.length) {
                    isBtnVisible = false;
                } else {
                    isBtnVisible = true;
                }
            }
            if (!this.state.hasMakePayments && !this.props.parentState.makeACHPerm) {
                isBtnVisible = false;
            }
        } else if (this.props.parentState.key.toLowerCase() === "transfers") {
            if (this.state.hasMakePayments || this.props.parentState.makeACHPerm)
                isBtnVisible = true;
            if (!this.props.parentState.makeACHPerm) {
                let hasmachEntry = reviewTable?.filter((o) => (o.etype?.toLowerCase() === 'm-ach' || o.etype?.toLowerCase() === 'd-ach'));
                if (hasmachEntry?.length === reviewTable?.length) {
                    isBtnVisible = false;
                } else {
                    isBtnVisible = true;
                }
            }
            if (!this.state.hasMakePayments) {
                let hasCheckEntry = reviewTable?.filter((o) => o.etype?.toLowerCase() === 'check');
                if (hasCheckEntry?.length === reviewTable?.length) {
                    isBtnVisible = false;
                } else {
                    isBtnVisible = true;
                }
            }
            if (!this.state.hasMakePayments && !this.props.parentState.makeACHPerm) {
                isBtnVisible = false;
            }
        }

        return (
            <>
                {isBtnVisible && (
                    <Button type="button"
                        onClick={this.paymentBasedAction}
                        className="btnHeight"
                    >
                        {this.state.parentType === "unpaid" ? "Pay"
                            : this.props.parentState.key.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())
                        }</Button>
                )}
            </>
        );
    }

    hideTrSlideOut = (isSave) => {
        if (isSave) {
            this.setState({ isTransferSlideOut: false }, () => {
                this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
            });
        } else {
            this.setState({ isTransferSlideOut: false });
        }
    }

    paymentBasedAction = () => {
        if (this.state.parentType === "unpaid") {
            this.reviewPayModalShow();
        } else if (this.state.parentType === "paid") {
            if (this.props.parentState.key.toLowerCase() === "confirm payment")
                // this.handlePayment();
                this.submitPayments();
            else if (this.props.parentState.key.toLowerCase() === "confirm print")
                this.confirmPrintCheck()
        }
    }

    submitPayments = () => {
        if (this.state.parentType === 'unpaid') {
            this.setState({ reviewProcessStart: true });
            let { reviewTable } = this.state;
            let reviewTableData = reviewTable;
            let isTransferEntry = this.props.parentState.key === "transfers" ? true : false;
            if (isTransferEntry) {

                let hasmachEntry = reviewTableData.some((o) => o.etype?.toLowerCase() === 'm-ach');
                let hasCheckEntry = reviewTableData.some((o) => o.etype?.toLowerCase() === 'check');
                if (hasmachEntry) {
                    this.transferEntryPayment(reviewTableData);
                }
                if (hasCheckEntry) {
                    let checkData = reviewTable.filter((o) => o.etype?.toLowerCase() === "check");
                    this.setState({ isShowReviewPrintConfirmationModal: true, reviewPayModalShow: false }, () => {
                        let storage = JSON.parse(localStorage.getItem("storage")!);
                        let tenantID = storage === null ? 0 : storage.tenantID as any;
                        let reqArray: any = [];
                        let { hidValue } = this.state;
                        checkData.forEach((item, index) => {
                            let request: any = {};
                            request.TenantID = tenantID;
                            request.uniqueNos = item.uniqueno;
                            request.Hid = hidValue;
                            request.Oprid = -1;
                            request.memo = item.internalNotes;
                            request.CkDate = item.billDate === "" ? new Date() : item.billDate;
                            request.etype = "TransferCheck";
                            request.pvrno = 0;
                            request.isGrouped = item.isGrouped;
                            reqArray.push(request);
                        });
                        this.TwoFAContinueBtnClick(reqArray);
                    });
                }
            } else {
                let checkData = reviewTable.filter((o) => o.etype?.toLowerCase() === "check");
                if (checkData.length > 0) {
                    this.setState({ isShowReviewPrintConfirmationModal: true, reviewPayModalShow: false }, () => {
                        let reqArray: any = [];
                        let { hidValue, oprIDForCkGenerate } = this.state;
                        let storage = JSON.parse(localStorage.getItem("storage")!);
                        let tenantID = storage === null ? 0 : storage.tenantID as any;
                        checkData.forEach((item, index) => {
                            let request: any = {};
                            request.TenantID = tenantID;
                            request.uniqueNos = item.uniqueno;
                            request.Hid = hidValue;
                            request.Oprid = oprIDForCkGenerate;
                            request.memo = item.internalNotes;
                            request.CkDate = item.billDate === "" ? new Date() : item.billDate;
                            request.etype = "";
                            request.pvrno = 0;
                            request.isGrouped = item.isGrouped;
                            reqArray.push(request);
                        });
                        this.TwoFAContinueBtnClick(reqArray);
                    });
                }

                let ePAYData = reviewTable.filter((o) => o.etype?.toLowerCase() === 'epay');
                if (ePAYData.length > 0) {
                    let storage = JSON.parse(localStorage.getItem("storage")!);
                    let tenantID = storage === null ? 0 : storage.tenantID as any;
                    let reqArray: any = {};
                    let vendorArr: any = [];
                    let invoicesArr: any = [];
                    let { hidValue, oprID, reviewTableUnMapped } = this.state;
                    reqArray.TenantID = tenantID;
                    reqArray.OPRID = oprID;
                    reqArray.custId = this.state.ePayCustID;
                    reqArray.HID = hidValue;
                    reqArray.EPaySubmitDate = moment(new Date()).format("YYYY-MM-DD");
                    reqArray.GroupID = "";//`${tenantID}_${hidValue}_${oprID}_${moment(new Date()).format("DDMMYYYY")}`
                    ePAYData.forEach((item, index) => {
                        let request: any = {};
                        request.VID = item?.vid;
                        request.AID = item?.aid;
                        request.VendorName = item?.company;
                        request.vendorNumber = `${hidValue}_${item?.vid}` //`${item?.vid}_${hidValue}`;
                        request.TotalAmount = item?.transTotal.toFixed(2);
                        request.invoiceUniqueNos = item?.uniqueno;
                        request.AccountNo = item.addContract;
                        let unGroupedUniqueNo = item?.uniqueno?.toString()?.split(',');
                        invoicesArr = [];
                        for (let n = 0; n < unGroupedUniqueNo.length; n++) {
                            let requestInvoice: any = {};
                            let unGroupedRow = reviewTableUnMapped.filter((d) => d.uniqueno === parseInt(unGroupedUniqueNo[n]));
                            requestInvoice.netAmount = unGroupedRow[0]?.transTotal.toFixed(2);
                            requestInvoice.invoiceNumber = unGroupedRow[0]?.invoiceNo;
                            requestInvoice.invoiceDate = moment(unGroupedRow[0]?.billDate === "" ? new Date() : unGroupedRow[0]?.billDate).format("YYYY-MM-DD");
                            invoicesArr.push(requestInvoice);
                        }
                        request.invoices = invoicesArr;
                        vendorArr.push(request);
                    });
                    reqArray.vendor = vendorArr;
                    console.log(reqArray)
                    this.makeEpayInvoicePayment(reqArray)
                    //this.digitalAchPayment(reqArray);
                } else {
                    reviewTable = reviewTable.filter((o) => o.etype?.toLowerCase() !== 'check' && o.etype?.toLowerCase() !== 'd-ach');
                    if (reviewTable.length > 0) {
                        this.mACHInvoicePayment(reviewTable);
                    }
                }

            }
        } else if (this.state.parentType === 'paid') {
            if (this.props.parentState.key.toLowerCase() === "confirm payment") {
                this.setState({ loaderTrue: true });
                let { reviewTableUnMapped } = this.state;
                let requestArray: any = [];
                reviewTableUnMapped.forEach((items) => {
                    let request: any = {};
                    request.uniqueNo = items.uniqueno;
                    request.hotelId = items.backHID;
                    request.oprId = items.etype.toLowerCase() === 'credit card' ? items.cardUniqueno : items.oprid;
                    request.paymentNotes = '';
                    request.Gdate = items.billDate === "" ? new Date() : items.billDate;
                    requestArray.push(request);
                });
                InvoiceApprovalS.ConfirmInvoicePayment(requestArray)
                    .then(async (result: any) => {
                        if (result.success) {
                            if (result?.result?.messageCode === "UnapproveInvoice") {
                                this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                                Utils.toastError("Some invoice are unapproved.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                                return;
                            } else if (result?.result?.messageCode === "DateGraterFromAccount") {
                                this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                                Utils.toastError("Entered date should be greater than Account Opening Date.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                                return;
                            } else if (result?.result?.messageCode === "InvoiceNotExist") {
                                this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                                Utils.toastError("The current invoice is no longer present in the system as someone else has taken another action.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                                return;
                            } else {
                                toast.success(`${reviewTableUnMapped.length} ${reviewTableUnMapped.length > 1 ? 'invoices' : 'invoice'} paid successfully.`, {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                                this.setState({
                                    loaderTrue: false,
                                    reviewTableUnMapped: [],
                                    isSingleRow: false,
                                }, () => {
                                    this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                                });
                            }
                        } else {
                            this.setState({ reviewProcessStart: false });
                            Utils.toastError("Something went wrong.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                        resolve();
                    })
                    .catch((error) => {
                        this.setState({ reviewProcessStart: false });
                        Utils.toastError("Something went wrong.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        reject();
                    });
            }
        }
    };

    makeEpayInvoicePayment = (reqArray: any) => {
        InvoiceApprovalS.MakeEpayInvoicePayment(reqArray)
            .then(async (result: any) => {
                if (result.success) {
                    if (result?.result?.message?.toLowerCase() === "success" || result?.result?.message?.toLowerCase() === "partialsubmit") {
                        let msg = "";
                        if (result?.result?.message?.toLowerCase() === "success")
                            msg = "Invoice(s) successfully sent for payment.";
                        else
                            msg = "One or more invoice had a problem and have been moved back to the Unpaid section.";

                        toast.success(msg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({
                            reviewProcessStart: false,
                            reviewPayModalShow: false,
                            reviewTable: [],
                            reviewTableUnMapped: [],
                            isSingleRow: false,
                            accountName: "Select Account Name",
                            totalAcctBal: 0,
                            batchTotal: 0,
                            selectedRows: []
                        }, () => {
                            this.isUserInSession(true);
                            this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                        });
                    } else if (result?.result?.message?.toLowerCase() === "submitfail") {
                        this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                        Utils.toastError("None of the invoices could be sent for payment.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        return;
                    } else if (result?.result?.message?.toLowerCase() === "paidsingleinv" || result?.result?.message?.toLowerCase() === "invpaid" || result?.result?.message?.toLowerCase() === "validation") {
                        this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                        Utils.toastError(result?.result?.result, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        return;
                    } else if (result?.result?.message?.toLowerCase() === "accountnonotexist") {
                        this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                        Utils.toastError(result?.result?.result, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        return;
                    } else if (result?.result?.message?.toLowerCase() === "vendornotexist") {
                        this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                        Utils.toastError(result?.result?.result, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        return;
                    } else {
                        this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                        Utils.toastError("Something went wrong.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        return;
                    }
                } else {
                    this.setState({ reviewProcessStart: false });
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ reviewProcessStart: false });
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    transferEntryPayment = (reviewTable) => {
        let hasCheckEntry = reviewTable.some((o) => o.etype?.toLowerCase() === 'check');
        let isMachEntry: any = [];
        isMachEntry = reviewTable.filter((o) => o.etype?.toLowerCase() === 'm-ach');
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let requestArray: any = [];
        isMachEntry.forEach((items) => {
            let request: any = {};
            request.uniqueNo = items.uniqueno;
            request.TransferType = items.invoiceNo;
            request.TransferDate = items.billDate;
            request.HID = items.backHID;
            request.TenantID = tenantID;
            requestArray.push(request);
        });
        InvoiceApprovalS.ConfirmTransferPayment(requestArray)
            .then(async (result: any) => {
                if (result.success) {
                    if (!hasCheckEntry) {
                        if (result.result.messageCode === 'APClosed') {
                            Utils.toastError(`Accounting period is closed.`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                            return;

                        } else if (result.result.messageCode === 'Payment failed.') {
                            Utils.toastError(`Something wen wrong.`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                            return;
                        }
                        else {
                            toast.success(`${reviewTable.length} ${reviewTable.length > 1 ? 'transfers' : 'transfer'} paid successfully.`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            this.setState({
                                reviewProcessStart: false,
                                reviewPayModalShow: false,
                                reviewTable: [],
                                reviewTableUnMapped: [],
                                isSingleRow: false,
                                accountName: "Select Account Name",
                                totalAcctBal: 0,
                                batchTotal: 0,
                                selectedRows: []
                            }, () => {
                                this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                            });
                        }
                    }
                } else {
                    this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ reviewProcessStart: false });
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    sesstionTimer = () => {
        let hasV2Session = sessionStorage.getItem("V2Session");
        if (hasV2Session !== null) {
            if (new Date(hasV2Session) < new Date()) {
                sessionStorage.removeItem("V2Session");
                this.props.handleSessionAlert(false);
            }
        }
    };

    startSesstionTimer() {
        let isTransferEntry = this.props.parentState.key === "transfers" ? true : false;
        let isCheckEntry = this.state.reviewTable.some((o) => o.etype?.toLowerCase() === 'check');
        if (isTransferEntry && isCheckEntry) {
            this.setState({ reviewProcessStart: false, isSubmitPayment: false });
        }
        clearInterval(sesstionResetTimer);
        sesstionResetTimer = setInterval(this.sesstionTimer, 1000);
    }

    confirmPrintCheck = () => {
        this.setState({ loaderTrue: true });
        let { reviewTableUnMapped } = this.state;
        let request: any = {};
        let uniqueNoList = "";
        reviewTableUnMapped.forEach((items) => {
            uniqueNoList += items.uniqueno + ",";
        });
        request.uniqueNos = uniqueNoList.replace(/,(?=\s*$)/, '');
        InvoiceApprovalS.confirmPrintCheck(request)
            .then(async (result: any) => {
                if (result.success) {
                    toast.success(`${reviewTableUnMapped.length} ${reviewTableUnMapped.length > 1 ? 'invoices' : 'invoice'} confirmed successfully.`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.setState({
                        loaderTrue: false,
                        reviewTableUnMapped: [],
                        isSingleRow: false,
                    }, () => {
                        this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                    });
                } else {
                    this.setState({ reviewProcessStart: false });
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ reviewProcessStart: false });
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    rowClickSubmit = (e, row, type) => {
        let isTransferEntry = this.props.parentState.key === "transfers";
        if (type === "unpaid") {
            this.setState({ reviewTable: [], reviewTableUnMapped: [], isSingleRow: true }, () => {
                this.footerBulkClose();
            });
            setTimeout(() => {
                let { batchTotal, reviewTable, reviewTableUnMapped, batchTotalReview } = this.state;
                let arr = {} as any;
                arr.rowNo = row.rowNo;
                arr.uniqueno = row.uniqueno;
                arr.backHID = row.backHID;
                arr.oprid = row.oprid;
                arr.cardUniqueno = row.cardUniqueno;
                arr.company = isTransferEntry ? row.ttFvendor : row.company;
                arr.etype = row.etype;
                arr.isCreditCard = row?.isCreditCard;
                arr.billDate = row?.isCreditCard?.toLowerCase() === "yes" ? new Date() : isTransferEntry ? row.billDate : row.etype?.toLowerCase() === "check" || row.etype?.toLowerCase() === "credit card" || row.etype?.toLowerCase() === "m-ach" || row.etype?.toLowerCase() === "epay" ? "" : row.billDate;
                //this.state.parentType === 'unpaid' && isTransferEntry && row.etype?.toLowerCase() === "m-ach" ? row.billDate : this.state.parentType === 'unpaid' ? "" : row.billDate;
                //this.state.parentType === 'unpaid' && isTransferEntry ? row.billDate : "";//row.billDate;
                arr.transTotal = row.transTotal;
                arr.internalNotes = '';//row.internalNotes;
                arr.isGrouped = "No";
                arr.isEnterPrise = row.isEnterPrise;
                arr.isTransferEntry = row.isTransferEntry !== undefined ? true : false;
                arr.entryType = row.entryType;
                arr.invoiceNo = row.invoiceNo;
                arr.isDateErr = false;
                arr.vid = row.vid;
                arr.aid = row.aid;
                arr.paymentPerm = row.paymentPerm;
                reviewTable.push(arr);
                batchTotal = row.transTotal;
                batchTotalReview = row.transTotal;
                arr.addContract = row?.addContract;

                this.setState(() => ({ reviewPayModalShow: true, batchTotal, reviewTable, reviewTableUnMapped: reviewTable, batchTotalReview }), () => {
                    if (row.etype.toString().toLocaleLowerCase() === "epay") {
                        this.addDefaultAccount("epay");
                    } else if (row.etype.toString().toLocaleLowerCase() === "check" || row.etype.toString().toLocaleLowerCase() === "m-ach") {
                        this.addDefaultAccount("other");
                    }
                });
            }, 100);
        } else if (type === "paid") {
            //if (this.props.parentState.key?.toLowerCase() === "confirm payment") {
            let { reviewTableUnMapped } = this.state;
            reviewTableUnMapped = [];
            this.footerBulkClose();
            let arr = {} as any;
            arr.rowNo = row.rowNo;
            arr.uniqueno = row.uniqueno;
            arr.backHID = row.backHID;
            arr.oprid = row.oprid;
            arr.cardUniqueno = row.cardUniqueno;
            arr.company = isTransferEntry ? row.ttFvendor : row.company;
            arr.etype = row.etype;
            arr.billDate = row.billDate;
            arr.transTotal = row.transTotal;
            arr.internalNotes = '';//row.internalNotes;
            arr.isTransferEntry = row.isTransferEntry !== undefined ? true : false;
            arr.entryType = row.entryType;
            arr.invoiceNo = row.invoiceNo;
            arr.isGrouped = "No";
            reviewTableUnMapped.push(arr);
            this.setState({ reviewTableUnMapped: reviewTableUnMapped }, () => {
                if (this.props.parentState.key?.toLowerCase() === "confirm payment")
                    this.submitPayments();
                else if (this.props.parentState.key?.toLowerCase() === "confirm print")
                    this.confirmPrintCheck();
            });
        }
    }

    reprintPaymentsDetail = () => {

        let request: any = {};
        if (this.state.isMultiselect) {
            let { reviewTableUnMapped } = this.state;

            let uniqueNoList = "";
            reviewTableUnMapped.forEach((items) => {
                uniqueNoList += items.uniqueno + ",";
            });
            request.hotelID = reviewTableUnMapped[0].backHID;
            request.oprID = -1;
            request.CheckType = "I"//reviewTableUnMapped[0].entryType === "Transfer" ? "T" : "I";
            request.pvrno = 0;
            request.CheckNo = "";
            request.Checkdate = "";
            request.Downloadcopy = this.state.parentType === "paid" ? "Print" : "Reprint";
            request.Series = "";
            request.isCopyCheck = this.state.parentType === "paid" ? "Not" : "";
            request.browserName = "Chrome";
            request.vid = 0;
            request.aid = 0;
            request.Uniquenos = uniqueNoList.replace(/,(?=\s*$)/, '');
        } else {
            let row = this.state.reprintRoW;
            request.hotelID = row.backHID;
            request.oprID = row.etype.toLowerCase() === 'credit card' ? row.cardUniqueno : row.oprid;
            request.CheckType = row.entryType === "Transfer" ? "T" : "I";;
            request.pvrno = 0;
            request.CheckNo = row.checkNo;
            request.Checkdate = "";
            request.Downloadcopy = this.state.parentType === "paid" ? "Print" : "Reprint";
            request.Series = row.series;
            request.isCopyCheck = this.state.parentType === "paid" ? "Not" : "";
            request.browserName = "Chrome";
            request.vid = 0;
            request.aid = 0;
            request.Uniquenos = "";
        }

        InvoiceApprovalS.PrintActualCheck(request)
            .then(async (result: any | null) => {
                resolve();
                this.isUserInSession(true);
            })
            .catch((error) => {
                Utils.toastError("Something went wrong. " + error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    checkSessionReprint = () => {
        let hasV2Session = sessionStorage.getItem("V2Session");
        if (hasV2Session !== null) {
            if (new Date(hasV2Session) < new Date(new Date().getTime())) {
                sessionStorage.removeItem("V2Session");
                this.sendOTPReprint();
            } else {
                this.reprintPaymentsDetail()
            }
        } else {
            this.sendOTPReprint();
        }
    }

    sendOTPReprint = () => {
        this.setState({ isShowOTPmodal: true });
    }

    sendOTPReprintOld = () => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        PwdService.GeneratePassCodeByPage(userName, 0, "sms", "Authenticate2FA", 0, "Payment", "Reprint Checks")
            .then(async (response: any | null) => {
                if (response.passcode !== "") {
                    toast.success("OTP Sent Successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ isShowOTPmodal: true })
                } else {
                    this.setState({ passCode: 0, resetMode: "sms", logAuditId: response.logAuditId, enterCodeShow: false, loaderMain: false });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    reprintSelectedCheckPaid = () => {
        this.setState({ isMultiselect: true }, () => {
            this.checkSessionReprint();
        })
    }

    reprintSingleCheckPaid = (row) => {
        this.setState({ isMultiselect: false, reprintRoW: row }, () => {
            this.checkSessionReprint();
        })
    }

    hidePaymentModal = () => {
        this.setState({ isShowPaymentmodal: false, isShowOTPmodal: false, isSubmitPayment: false });
    }

    isUserInSession = (value) => {
        if (value) {
            let sessionTime = new Date(new Date().getTime() + 30 * 60000).toString();
            sessionStorage.setItem("V2Session", sessionTime);
            this.props.handleSessionAlert(true);
            this.startSesstionTimer();
        }
    }

    mACHInvoicePayment = (reviewTable) => {
        let requestArray: any = [];
        reviewTable.forEach((items) => {
            let request: any = {};
            request.uniqueNo = items.uniqueno;
            request.hotelId = items.backHID;
            request.oprId = this.state.oprID;
            request.paymentNotes = items.internalNotes?.substring(0, 200).trim();
            request.Gdate = items.billDate === "" ? new Date() : items.billDate;
            requestArray.push(request);
        });
        InvoiceApprovalS.ConfirmInvoicePayment(requestArray)
            .then(async (result: any) => {
                if (result.success) {
                    if (result?.result?.messageCode === "UnapproveInvoice") {
                        this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                        Utils.toastError("Some invoice are unapproved.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        return;
                    } else if (result?.result?.messageCode === "DateGraterFromAccount") {
                        this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                        Utils.toastError("Entered date should be greater than Account Opening Date.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        return;
                    } else if (result?.result?.messageCode === "InvoiceNotExist") {
                        this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                        Utils.toastError("The current invoice is no longer present in the system as someone else has taken another action.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        return;
                    } else {
                        toast.success(`${reviewTable.length} ${reviewTable.length > 1 ? 'invoices' : 'invoice'} ${'paid successfully.'}`, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        let checkData = this.state.reviewTable.filter((o) => o.etype?.toLowerCase() === "check");
                        if (checkData.length === 0) {
                            this.setState({
                                reviewProcessStart: false,
                                reviewPayModalShow: false,
                                reviewTable: [],
                                reviewTableUnMapped: [],
                                isSingleRow: false,
                                accountName: "Select Account Name",
                                totalAcctBal: 0,
                                batchTotal: 0,
                                batchTotalReview: 0,
                                selectedRows: []
                            }, () => {
                                this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                            });
                        }
                    }
                } else {
                    this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ reviewProcessStart: false });
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    TwoFAContinueBtnClick = (reqArray) => {
        this.setState({ invoiceCHKData: [] })
        InvoiceApprovalS.InvoiceCkGenerate(reqArray)
            .then(async (result: any | null) => {

                if (result.success) {
                    if (result.result !== null) {
                        if (result.result.length > 0) {
                            result.result.map((items) => ((items["isPrintConfirmed"] = false)));
                            this.setState({ invoiceCHKData: result.result, pvrno: result.result[0].pvrno }, () => {
                                this.isUserInSession(true);
                                let totalAmount = 0;
                                result.result.forEach((items) => {
                                    totalAmount = Number(totalAmount) + Number(items.amount)
                                })
                                if (Number(totalAmount) !== 0) {
                                    this.setState({ isValidtotalAmount: true }, () => {
                                        this.genratePDFPrintPreview();  /// Print preview of checks
                                    })
                                } else {
                                    this.setState({ isValidtotalAmount: false })
                                }
                            })
                        } else {
                            Utils.toastError("No records found.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                    } else {
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        return false;
                    }
                    this.setState({ reviewProcessStart: false });
                } else {
                    this.setState({ reviewProcessStart: false, isShowReviewPrintConfirmationModal: false, reviewPayModalShow: true });
                    Utils.toastError(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ reviewProcessStart: false });
                Utils.toastError("Something went wrong. " + error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }


    digitalAchPayment = (reqArray) => {
        InvoiceApprovalS.InvoiceDAchPayment(reqArray)
            .then(async (result: any | null) => {
                if (result.success) {
                    if (result.result !== null) {
                        if (result.result.length > 0) {
                            toast.success(`${reqArray.length} ${reqArray.length > 1 ? 'invoices' : 'invoice'} ${'paid successfully.'}`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });

                            let checkData = this.state.reviewTable.filter((o) => o.etype?.toLowerCase() === "check");
                            if (checkData.length === 0) {
                                this.setState({
                                    reviewProcessStart: false,
                                    reviewPayModalShow: false,
                                    reviewTable: [],
                                    reviewTableUnMapped: [],
                                    isSingleRow: false,
                                    accountName: "Select Account Name",
                                    totalAcctBal: 0,
                                    batchTotal: 0,
                                    batchTotalReview: 0,
                                    selectedRows: []
                                }, () => {
                                    this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                                });
                            }

                        } else {
                            Utils.toastError("No records found.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                    } else {
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    this.setState({ reviewProcessStart: false });
                } else {
                    this.setState({ reviewProcessStart: false });
                    Utils.toastError(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ reviewProcessStart: false });
                Utils.toastError("Something went wrong. " + error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });

    }

    genratePDFPrintPreview = () => {
        let { hidValue, oprIDForCkGenerate } = this.state;

        let invoiceCHKData = this.state.invoiceCHKData;

        if (invoiceCHKData.length > 0) {
            let request: any = {};
            request.hotelID = hidValue;
            request.oprID = this.props.parentState.key === "transfers" ? -1 : oprIDForCkGenerate;
            request.CheckType = this.props.parentState.key === "transfers" ? "T" : "I";
            request.pvrno = invoiceCHKData[0].pvrno;
            request.CheckNo = "";
            request.Checkdate = "";
            request.Downloadcopy = "";
            request.Series = "";
            request.isCopyCheck = "Not";
            request.browserName = "Chrome";
            request.vid = 0;
            request.aid = 0;
            request.Uniquenos = "";
            InvoiceApprovalS.PrintActualCheck(request)
                .then(async (result: any | null) => {
                    resolve();
                })
                .catch((error) => {
                    Utils.toastError("Something went wrong. " + error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    reject();
                });
        }

    }


    ValidatedSession = () => {
        let isTransferEntry = this.props.parentState.key === "transfers" ? true : false;
        const isCheck = this.state.reviewTable.some(item => item.etype?.toLocaleLowerCase() === "check");
        //const isDACH = this.state.reviewTable.some(item => item.etype?.toLocaleLowerCase() === "d-ach");
        const isEPAY = this.state.reviewTable.some(item => item.etype?.toLocaleLowerCase() === "epay");
        const isMACH = this.state.reviewTable.some(item => item.etype?.toLocaleLowerCase() === "m-ach");
        const isCC = this.state.reviewTable.some(item => item.etype?.toLocaleLowerCase() === "credit card");
        if (isCheck /*|| isEPAY */ || isMACH || isCC) {
            const negativeEntry = this.state.reviewTable?.filter(
                o => {
                    return (
                        (o.transTotal < 0 && (o.etype?.toLowerCase() === 'check' /*|| o.etype?.toLowerCase() === 'm-ach' || o.etype?.toLowerCase() === 'credit card'*/))
                    )
                }
            );
            const negativeOrZeroEntry = this.state.reviewTable?.filter(
                o => {
                    return (
                        (o.transTotal < 0 || o.transTotal === 0) && (o.etype?.toLowerCase() === 'epay')
                    )
                }
            );
            if (negativeEntry.length > 0 || negativeOrZeroEntry.length > 0) {
                const type = negativeEntry[0]?.etype?.toLowerCase() === 'check' ? "Check" : "Invoice";
                if (negativeEntry.length > 0)
                    Utils.toastError(`${type} for ${negativeEntry[0].company} is an invalid amount.`);
                else
                    Utils.toastError(`Invoice for ${negativeOrZeroEntry[0].company} is an invalid amount.`);

                this.setState({ isSubmitPayment: false });
                return;
            }
        }
        if (isTransferEntry) {
            this.handlePayment()
        } else {

            if (this.state.accountName === "" || this.state.accountName === undefined || this.state.accountName === null || this.state.accountName === "Select Account Name") {
                Utils.toastError('Please select account.');
                return;
            } else if (isEPAY) {
                this.checkAmount();
                //this.handlePayment();
            } else {
                this.handlePayment();
            }
        }
    }

    checkAmount = () => {
        let { reviewTable, hidValue, oprID } = this.state;
        let ePAYData = reviewTable.filter((o) => o.etype?.toLowerCase() === 'epay');
        NegativeorLessVendor = [];
        const ifNegativeOrLess = ePAYData.some(item => item.transTotal < 1.50);
        //debugger
        if (ifNegativeOrLess) {
            const NegativeOrLess = ePAYData.filter(item => item.transTotal < 1.50);
            NegativeOrLess.forEach((item, index) => {
                NegativeorLessVendor.push(item?.company);
            });
            this.stopPaymentWithAlert();
            // if (ePAYData.length > 0) {
            //     const vidArr = _.uniqBy(ePAYData, 'addContract');
            //     let request = {} as any;
            //     request.OPRID = oprID;
            //     request.HID = hidValue;
            //     request.VID = _.uniqBy(vidArr, 'vid').map(x => x.vid).join(",");
            //     request.AccountNo = vidArr.map(x => x.addContract).join(",");
            //     InvoiceApprovalS.CheckAmountInGroup(request)
            //         .then(async (result: any | null) => {
            //             if (result?.message?.toLowerCase() === "success") {
            //                 const NegativeOrLess = ePAYData.filter(item => item.transTotal < 1.50);

            //                 if (result?.result[0]?.result === "Approved Group") {
            //                     Utils.toastError("You can't make transactions because this group is already approved.", {
            //                         position: toast.POSITION.BOTTOM_RIGHT,
            //                     });
            //                 } else if (result?.result[0]?.result === "Not Exist") {
            //                     if (NegativeOrLess?.length > 0) {
            //                         NegativeOrLess.forEach((item, index) => {
            //                             NegativeorLessVendor.push(item?.company);
            //                         });
            //                     }
            //                 }
            //                 else {
            //                     const CheckAmountArray = result?.result;
            //                     NegativeOrLess.forEach((item, index) => {
            //                         const GroupTransTotal = CheckAmountArray.filter((n) => n.accountNo?.toLowerCase() === item?.addContract?.toLowerCase() && n.vid === item?.vid);
            //                         if (GroupTransTotal?.length > 0) {
            //                             if ((parseFloat(GroupTransTotal[0]?.totalAmount) + parseFloat(item?.transTotal)) < 1.50) {
            //                                 NegativeorLessVendor.push(item?.company);
            //                             }
            //                         } else {
            //                             NegativeorLessVendor.push(item?.company);
            //                         }
            //                     });
            //                 }
            //             } else {
            //                 Utils.toastError(result?.message, {
            //                     position: toast.POSITION.BOTTOM_RIGHT,
            //                 });
            //                 return false;
            //             }
            //             if (NegativeorLessVendor.length > 0)
            //                 this.stopPaymentWithAlert();
            //             else
            //                 this.handlePayment();

            //             resolve();
            //         })
            //         .catch((error) => {
            //             Utils.toastError(error, {
            //                 position: toast.POSITION.BOTTOM_RIGHT,
            //             });
            //             reject();
            //         });
            // }
        }
        else {
            this.handlePayment();
        }
    }

    stopPaymentWithAlert = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                reject();
                this.setState({ reviewProcessStart: false, isSubmitPayment: false });
                return (
                    <div className='viom_CustomConfirmAlert'>
                        <h1 className="viom_alertTitle">Alert!</h1>
                        <p className="viom_alertMessage">ePay payments made to vendor
                            <strong> {NegativeorLessVendor.map(x => x).join(", ")} </strong>
                            must be greater than or equal to $1.50</p>
                        <div className="viom_alertButtonGroup">
                            <button className="viom_alertButton viom_danger_payment" onClick={onClose}>Ok</button>
                        </div>
                    </div>
                );
            }
        });
    }

    handlePayment = () => {
        let { reviewTable } = this.state;
        let isTransferEntry = this.props.parentState.key === "transfers" ? true : false;
        let isCreditCard = false;
        let isEpay = false;
        let isCheck = false;
        let isMAch = false;
        this.setState({ isSubmitPayment: true });
        if (isTransferEntry) {
            isCheck = reviewTable.some(item => item.etype?.toLocaleLowerCase() === "check");
            if (isCheck) {
                this.checkSession();
            } else {
                this.submitPayments()
            }
        } else {
            if (reviewTable.length > 1) {
                isMAch = reviewTable.some(item => item.etype.toString().toLocaleLowerCase() === "m-ach");
                isCheck = reviewTable.some(item => item.etype.toString().toLocaleLowerCase() === "check");
                isEpay = reviewTable.some(item => item.etype.toString().toLocaleLowerCase() === "epay");
                let isGrouped = this.props.isGroupUnpaid;
                if (isGrouped && isCheck) {
                    this.checkSession();
                } else {
                    if (isCheck || isEpay) {
                        this.checkSession();
                    } else {
                        this.submitPayments()
                    }
                }
            } else {
                isCreditCard = reviewTable.some(item => item.etype.toString().toLocaleLowerCase() === "credit card");
                isMAch = reviewTable.some(item => item.etype.toString().toLocaleLowerCase() === "m-ach");
                if (isCreditCard || isMAch) {
                    this.submitPayments()
                } else {
                    this.checkSession();
                }
            }
        }
    }

    checkSession = () => {
        let hasV2Session = sessionStorage.getItem("V2Session");
        if (hasV2Session !== null) {
            if (new Date(hasV2Session) < new Date(new Date().getTime())) {
                sessionStorage.removeItem("V2Session");
                this.sendOTPText();
            } else {
                this.submitPayments()
            }
        } else {
            this.sendOTPText();
        }
    }

    sendOTPText = () => {
        this.setState({ isShowPaymentmodal: true })
    }

    ValidateOTP = (flag, pageName) => {
        //debugger
        if (pageName === "RePrintPaymentChecks") {
            this.hidePaymentModal();
            this.reprintPaymentsDetail();
            this.isUserInSession(true)
        }
        if (pageName === "Print Checks") {
            this.hidePaymentModal();
            this.submitPayments();
            this.isUserInSession(true)
        }

    }

    sendOTPTextOld = () => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        PwdService.GeneratePassCodeByPage(userName, 0, "sms", "Authenticate2FA", 0, "Payment", "Print Checks")
            .then(async (response: any | null) => {
                if (response.passcode !== "") {
                    toast.success("OTP Sent Successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ isShowPaymentmodal: true })
                } else {
                    this.setState({ passCode: 0, resetMode: "sms", logAuditId: response.logAuditId, enterCodeShow: false, loaderMain: false });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    checkSharedAccountExist(request, controlID, arr) {

        let Invoicelist: any = "";
        registersevice.CheckSharedAccountExist(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.saveStatus === "Success") {

                        Invoicelist = result.message;
                        this.handleBankAccount(controlID, arr, Invoicelist);
                    }
                }
                else {
                    Invoicelist = "";
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });



    }

    handleBankAccount = (controlID, arr, SharedAccountInvoice) => {

        let hasMACH = this.state.reviewTable.some((o) => o.etype?.toLowerCase() === 'epay');


        if (hasMACH && SharedAccountInvoice === "") {
            this.setState({ isSubmitPaymentBtnDisabled: false });
            //if (arr.fundingSourceStatus?.toLowerCase() === "verified") {
            this.handleaccountNameSelectedItem(controlID, arr);
            // }
            // else {
            //     this.setState({ accountName: 'Select Account Name', oprID: '', isRefresh: !this.state.isRefresh }, () => {
            //         confirmAlert({
            //             title: "Alert",
            //             message: "The selected account is not enabled for Digital ACH.  Please select a different account or remove the Digital ACH transactions to proceed.",
            //             buttons: [
            //                 {
            //                     label: "Ok",
            //                     onClick: () => reject()
            //                 }
            //             ],
            //         });
            //     });
            // }
        }
        else if (SharedAccountInvoice != "") {
            this.handleaccountNameSelectedItem(controlID, arr);
            this.setState({ isSubmitPaymentBtnDisabled: true, isRefresh: !this.state.isRefresh }, () => {
                confirmAlert({
                    title: "Alert",
                    message: SharedAccountInvoice,
                    buttons: [
                        {
                            label: "Ok",
                            onClick: () => reject()
                        }
                    ],
                });
            });
        }
        else {
            this.setState({ isSubmitPaymentBtnDisabled: false });
            this.handleaccountNameSelectedItem(controlID, arr);
        }

    }

    handleSelectedItem = (controlID: any, arr: any) => {


        let { reviewTable } = this.state;
        let request: any = {};
        let columns: any = [];
        reviewTable.forEach(element => {
            columns.push(element.uniqueno)
        });

        request.UserUniqueno = columns.join(',');
        request.OprID = arr.id;
        request.Type = "Invoice";

        this.checkSharedAccountExist(request, controlID, arr);

        //this.handleaccountNameSelectedItem(controlID, arr);
    };


    handleaccountNameSelectedItem = (controlID: any, arr: any) => {
        this.setState({ accountStatus: arr.status });
        this.setState({ customDate: false })
        this.setState({ oprIDForCkGenerate: arr.id })
        let isEtypeEPAY = this.state.reviewTable.some((o: any) => o.etype.toString().toLocaleLowerCase() === "epay");
        let isEtypeMACHorCheck = this.state.reviewTable.some((o: any) => (o.etype.toString().toLocaleLowerCase() === "m-ach" || o.etype.toString().toLocaleLowerCase() === "check"));
        let sharedHIDs: any[] = [];
        let totalsharedHIDCount = 0;
        let acctType = "";
        let sharedcode = "";
        let name = "";
        let isCCA = false;
        this.state.accountNamelist.filter(x => x.oprID === arr.id).forEach(element => {
            let hidCode: any = [];
            hidCode = element.sharedHID.split("[")
            totalsharedHIDCount = element.hidCount;
            acctType = element.acctType;
            sharedcode = element.sharedcode;
            name = element.name;
            isCCA = element.acctType.toString().toLocaleLowerCase() === "c";
            hidCode.forEach(item => {
                let sharedHID: any = {};
                let lettercodes: any = [];
                lettercodes = item.split("_");
                let splitHid = lettercodes[0];
                let splitcode = lettercodes[1];
                sharedHID.hid = splitHid;
                sharedHID.lettercode = splitcode;
                sharedHID.isChecked = false;
                sharedHIDs.push(sharedHID);
            });
        });
        let registerRequest = {} as any;
        registerRequest.oprID = arr.id;
        registerRequest.acctType = acctType;
        registerRequest.hotelID = this.props.parentState.hidValue === "All" ? arr.hid : this.state.hidValue;
        this.setState({ accountName: name, oprID: arr.id, ePayCustID: arr.ePayCustID }, () => {
            this.getRegisterBal(registerRequest);
        });
        if (isEtypeEPAY || isEtypeMACHorCheck) {
            const accountList = Object.assign([], JSON.parse(JSON.stringify(this.state.reviewTable)));
            this.setState({ reviewTable: [] }, () => {
                this.setState({
                    reviewTable: accountList,
                    accountName: name, oprID: arr.id
                }, () => {
                    this.getRegisterBal(registerRequest);
                });
            })
        }
    };

    getRegisterBal = (registerRequest: any) => {
        // if (!this.state.blfirstLoad) {
        this.setState({ balanceLoader: true, totalAcctBal: 0 });
        // }
        registersevice.RegisterAccountBal(registerRequest)
            .then(async (result: any[] | null) => {
                if (result !== null) {
                    let totalBalance = result[0].accountBal;
                    let batchTotal = this.state.batchTotal;
                    let batchTotalReview = this.state.batchTotalReview;
                    let newBalance = 0;
                    if (this.props.parentState?.key?.toLowerCase() === 'credit card')
                        newBalance = parseFloat(totalBalance?.replaceAll(',', '')) + parseFloat(batchTotal);
                    else
                        newBalance = parseFloat(totalBalance?.replaceAll(',', '')) - parseFloat(batchTotal);

                    this.setState({ accountBal: result, totalAcctBal: newBalance, balanceLoader: false/*, blfirstLoad: false*/ });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };

    tableCellEdit = cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            if (oldValue !== newValue) {
                let { reviewTable } = this.state;
                let isTrEntry = this.props.parentState.key === "transfers";
                let dataField = column.dataField;
                if (isTrEntry) {
                    reviewTable.map((items) => items.rowNo === row.rowNo && (items[dataField] = newValue?.substring(0, 50).trim()));
                } else {
                    reviewTable.map((items) => items.rowNo === row.rowNo && (items[dataField] = newValue?.substring(0, 200).trim()));
                }
                this.setState((curr: any) => ({
                    ...curr,
                    reviewTable: reviewTable,
                }));
            }
        },
        onStartEdit: (row, column, rowIndex, columnIndex) => {
            $('.edit-text').attr('maxlength', '50');
        }
    });

    groupAndSum(arr, groupKeys, sumKeys, rowNo, uniqueno) {
        return Object.values(
            arr.reduce((acc, curr) => {
                const group = groupKeys.map(k => curr[k]).join('-');
                acc[group] = acc[group] || Object.fromEntries(groupKeys.map(k => [k, curr[k]]).concat(sumKeys.map(k => [k, 0])));
                sumKeys.forEach(k => acc[group][k] += curr[k]);
                uniqueno.forEach(k => acc[group][k] += curr.uniqueno + ',');
                rowNo.forEach(k => acc[group][k] += curr.rowNo + ',');
                return acc;
            }, {})
        );
    }

    reviewPayModalShow = () => {
        this.clearError();
        this.setState({ reviewPayModalShow: !this.state.reviewPayModalShow }, () => {
            if (this.props.parentState.key !== "credit card" && this.props.parentState.key !== "transfers") {

                let { reviewTable, reviewTableUnMapped, batchTotalReview } = this.state;
                reviewTable = [];
                let newGroupedArr = [] as any;

                const mdGroupingFeature = reviewTableUnMapped.some((o) => o.mdGroupingFeature?.toLowerCase() === "yes");

                let withGroupVendList = [] as any;

                let withOutGroupVendList = reviewTableUnMapped.filter((o) => o.isVendorGroup === "Yes");
                withGroupVendList = reviewTableUnMapped.filter((o) => o.isVendorGroup === "No");

                if (!mdGroupingFeature && withGroupVendList.length > 0) {
                    withGroupVendList = withGroupVendList.filter((o) => o.etype !== "D-ACH" && o.etype !== "M-ACH");
                    let allMACHDACHData = reviewTableUnMapped.filter((o) => o.etype === "D-ACH" || o.etype === "M-ACH");
                    withOutGroupVendList = withOutGroupVendList.concat(allMACHDACHData);
                    let remDupList: any[] = [];
                    const map = new Map();
                    for (const item of withOutGroupVendList) {
                        if (!map.has(item.uniqueno)) {
                            map.set(item.uniqueno, true);
                            remDupList.push(item);
                        }
                    }
                    withOutGroupVendList = [];
                    withOutGroupVendList = remDupList;
                }
                //withGroupVendList = [...withGroupVendListNew];
                // if (mdGroupingFeature && this.props.isGroupUnpaid) {
                //     for (let o = 0; o < withGroupVendList.length; o++) {
                //         if (withGroupVendList[o].etype === "D-ACH" || withGroupVendList[o].etype === "M-ACH") {
                //             withGroupVendList[o].etype = "ACH";
                //         }
                //     }
                // }

                newGroupedArr = this.groupAndSum(withGroupVendList, ['vid', 'addContract', 'aid', 'etype'], ['transTotal'], ['rowNo'], ['uniqueno']);

                for (let r = 0; r < newGroupedArr.length; r++) {
                    let groupedUniqueNo = newGroupedArr[r].uniqueno.replace('undefined', '').replace(/,(?=\s*$)/, '').split(',');
                    newGroupedArr[r].rowNo = newGroupedArr[r].rowNo.replace('undefined', '').replace(/,(?=\s*$)/, '');
                    newGroupedArr[r].uniqueno = newGroupedArr[r].uniqueno.replace('undefined', '').replace(/,(?=\s*$)/, '');
                    newGroupedArr[r].transTotal = newGroupedArr[r].transTotal; //Utils.currencyFormatWithOutdoller((newGroupedArr[r].transTotal));

                    const arrayObj = reviewTableUnMapped.filter((o) => o.uniqueno === parseInt(groupedUniqueNo[0]));
                    newGroupedArr[r].company = arrayObj[0].company;
                    newGroupedArr[r].billDate = arrayObj[0]?.isCreditCard?.toLowerCase() === "yes" ? arrayObj[0].billDate : "";
                    newGroupedArr[r].backHID = arrayObj[0].backHID;
                    newGroupedArr[r].internalNotes = ''; //arrayObj[0].internalNotes;
                    newGroupedArr[r].groupedInvoiceCount = groupedUniqueNo.length;
                    if (groupedUniqueNo.length > 1) {
                        newGroupedArr[r].isGrouped = "Yes";
                    } else {
                        newGroupedArr[r].etype = arrayObj[0].etypeOld;
                        newGroupedArr[r].isGrouped = "No";
                    }
                    withOutGroupVendList.push(newGroupedArr[r]);
                }

                withOutGroupVendList = _.sortBy(withOutGroupVendList, 'company');

                withOutGroupVendList.map((items, ID) => (
                    items["rowID"] = (ID + 1)
                ));
                let badgeTotal = batchTotalReview;

                if (!this.props?.parentState?.makeACHPerm) {
                    badgeTotal = 0;
                    withOutGroupVendList = withOutGroupVendList.filter((o) => o?.etype.toUpperCase() !== "M-ACH" && o?.etype.toUpperCase() !== "EPAY");
                    withOutGroupVendList.forEach((item, index) => {
                        badgeTotal += item.transTotal;
                    });
                }
                if (!this.props?.parentState?.hasMakePayments) {
                    badgeTotal = 0;
                    withOutGroupVendList = withOutGroupVendList.filter((o) => o?.etype.toUpperCase() !== "CHECK");
                    withOutGroupVendList.forEach((item, index) => {
                        badgeTotal += item.transTotal;
                    });
                } else {
                    withGroupVendList = [] as any;

                    withOutGroupVendList = reviewTableUnMapped.filter((o) => o.isVendorGroup === "Yes");
                    withGroupVendList = reviewTableUnMapped.filter((o) => o.isVendorGroup === "No");

                    if (!mdGroupingFeature && withGroupVendList.length > 0) {
                        withGroupVendList = withGroupVendList.filter((o) => o.etype !== "D-ACH" && o.etype !== "M-ACH");
                        let allMACHDACHData = reviewTableUnMapped.filter((o) => o.etype === "D-ACH" || o.etype === "M-ACH");
                        withOutGroupVendList = withOutGroupVendList.concat(allMACHDACHData);
                        let remDupList: any[] = [];
                        const map = new Map();
                        for (const item of withOutGroupVendList) {
                            if (!map.has(item.uniqueno)) {
                                map.set(item.uniqueno, true);
                                remDupList.push(item);
                            }
                        }
                        withOutGroupVendList = [];
                        withOutGroupVendList = remDupList;
                    }
                    //withGroupVendList = [...withGroupVendListNew];
                    // if (mdGroupingFeature && this.props.isGroupUnpaid) {
                    //     for (let o = 0; o < withGroupVendList.length; o++) {
                    //         if (withGroupVendList[o].etype === "D-ACH" || withGroupVendList[o].etype === "M-ACH") {
                    //             withGroupVendList[o].etype = "ACH";
                    //         }
                    //     }
                    // }

                    newGroupedArr = this.groupAndSum(withGroupVendList, ['vid', 'addContract', 'aid', 'etype'], ['transTotal'], ['rowNo'], ['uniqueno']);

                    for (let r = 0; r < newGroupedArr.length; r++) {
                        let groupedUniqueNo = newGroupedArr[r].uniqueno.replace('undefined', '').replace(/,(?=\s*$)/, '').split(',');
                        newGroupedArr[r].rowNo = newGroupedArr[r].rowNo.replace('undefined', '').replace(/,(?=\s*$)/, '');
                        newGroupedArr[r].uniqueno = newGroupedArr[r].uniqueno.replace('undefined', '').replace(/,(?=\s*$)/, '');
                        newGroupedArr[r].transTotal = newGroupedArr[r].transTotal; //Utils.currencyFormatWithOutdoller((newGroupedArr[r].transTotal));

                        const arrayObj = reviewTableUnMapped.filter((o) => o.uniqueno === parseInt(groupedUniqueNo[0]));
                        newGroupedArr[r].company = arrayObj[0].company;
                        newGroupedArr[r].billDate = arrayObj[0]?.isCreditCard?.toLowerCase() === "yes" ? arrayObj[0].billDate : "";
                        newGroupedArr[r].backHID = arrayObj[0].backHID;
                        newGroupedArr[r].internalNotes = ''; //arrayObj[0].internalNotes;
                        newGroupedArr[r].groupedInvoiceCount = groupedUniqueNo.length;
                        if (groupedUniqueNo.length > 1) {
                            newGroupedArr[r].isGrouped = "Yes";
                        } else {
                            newGroupedArr[r].etype = arrayObj[0].etypeOld;
                            newGroupedArr[r].isGrouped = "No";
                        }
                        withOutGroupVendList.push(newGroupedArr[r]);
                    }

                    withOutGroupVendList = _.sortBy(withOutGroupVendList, 'company');

                    withOutGroupVendList.map((items, ID) => (
                        items["rowID"] = (ID + 1)
                    ));
                    badgeTotal = batchTotalReview;

                    if (!this.props?.parentState?.makeACHPerm) {
                        badgeTotal = 0;
                        withOutGroupVendList = withOutGroupVendList.filter((o) => o?.etype.toUpperCase() !== "M-ACH" && o?.etype.toUpperCase() !== "EPAY");
                        withOutGroupVendList.forEach((item, index) => {
                            badgeTotal += item.transTotal;
                        });
                    }
                    if (!this.props?.parentState?.hasMakePayments) {
                        badgeTotal = 0;
                        withOutGroupVendList = withOutGroupVendList.filter((o) => o?.etype.toUpperCase() !== "CHECK");
                        withOutGroupVendList.forEach((item, index) => {
                            badgeTotal += item.transTotal;
                        });
                    }
                }
                this.setState({ reviewTable: withOutGroupVendList, batchTotalReview: badgeTotal });
                let isEtypeDACH = this.state.reviewTable.some((o: any) => o.etype.toString().toLocaleLowerCase() === "epay");
                let isEtypeMACHOrCheck: any[] = [];
                this.state.reviewTable.some((o: any) => {
                    let eType = o.etype.toString().toLocaleLowerCase();
                    if (eType === "m-ach" || eType === "check") {
                        isEtypeMACHOrCheck.push(o);
                    }
                });
                if (isEtypeDACH) {
                    this.addDefaultAccount("epay");
                } else if (isEtypeMACHOrCheck) {
                    this.addDefaultAccount("other");
                }
            }
            let { accountNames } = this.state;
            if (accountNames.length == 1) {
                let arr = {} as any;
                arr.acctType = accountNames[0].acctType;
                arr.fundingSourceStatus = accountNames[0].fundingSourceStatus;
                arr.hid = this.state.hidValue;
                arr.id = accountNames[0].id;
                arr.name = accountNames[0].name;
                arr.setDefault = accountNames[0].setDefault;
                arr.sort = accountNames[0].sort;
                arr.status = accountNames[0].status;
                arr.ePayCustID = accountNames[0].ePayCustID;
                this.setState({ isRefresh: !this.state.isRefresh, accountName: accountNames[0].name }, () => {
                    this.handleSelectedItem(1, arr);
                })
            }
            if (this.props.parentState.key === "credit card") {
                let isPayid = this.props?.parentState?.accountIdPay;
                let accountListFull = Object.assign([], JSON.parse(JSON.stringify(this.props.parentState.accountNames)));
                if (isPayid !== -1 && isPayid !== undefined) {
                    let filterAccList = accountListFull.filter((item: any) => item.id === isPayid && item.acctType.toString().toLocaleLowerCase() === "c");
                    if (filterAccList.length > 0) {
                        //this.updateFromPayment();
                        this.setState({ isRefresh: !this.state.isRefresh, accountName: filterAccList[0].name, }, () => {
                            this.handleSelectedItem(1, filterAccList[0]);
                        })
                    }
                }
            } else if (this.props.parentState.key === "epay") {
                this.addDefaultAccount("epay");
            }
        });
    };

    updateFromPayment = () => {
        let totalBalance = this.props?.parentState.accountBalPay[0].accountBal;
        let batchTotal = this.state.batchTotal;
        let newBalance = 0;
        if (this.props.parentState?.key?.toLowerCase() === 'credit card') {
            newBalance = parseFloat(totalBalance?.replaceAll(',', '')) + parseFloat(batchTotal);
        } else {
            newBalance = parseFloat(totalBalance?.replaceAll(',', '')) - parseFloat(batchTotal);
        }
        this.setState({ totalAcctBal: newBalance, blfirstLoad: true });
    }

    addDefaultAccount = (type: any) => {
        let accountListFull = [] as any;
        if (type === "epay") {
            accountListFull = Object.assign([], JSON.parse(JSON.stringify(this.state.accountNames)));
        } else {
            accountListFull = Object.assign([], JSON.parse(JSON.stringify(this.props.parentState.accountNames)));
        }
        let accVerified: any[] = [];
        // if (type === "d-ach") {
        //     accountListFull.filter((item: any) => {
        //         if (item.setDefault.toString().toLocaleLowerCase() === "yes" && item.fundingSourceStatus.toString().toLocaleLowerCase().includes("verified")) {
        //             accVerified.push(item);
        //         }
        //     });
        // } 
        let isPayid = this.props?.parentState?.accountIdPay;
        if (type === "epay") {
            if (isPayid !== -1 && isPayid !== undefined) {
                let filterAccList = accountListFull.filter((item: any) => item.id === isPayid && item.acctType.toString().toLocaleLowerCase() !== "c" && item.ePayCustID !== "");
                if (filterAccList.length > 0) {
                    accVerified = filterAccList;
                    // this.updateFromPayment()
                } else {
                    accountListFull.filter((item: any) => {
                        if (item.setDefault.toString().toLocaleLowerCase() === "yes" && item.ePayCustID !== "") {
                            accVerified.push(item);
                        }
                    });
                }
            } else {
                accountListFull.filter((item: any) => {
                    if (item.setDefault.toString().toLocaleLowerCase() === "yes" && item.ePayCustID !== "") {
                        accVerified.push(item);
                    }
                });
            }
        } else {
            if (isPayid !== -1 && isPayid !== undefined) {
                let filterAccList = accountListFull.filter((item: any) => item.id === isPayid && item.acctType.toString().toLocaleLowerCase() !== "c");
                if (filterAccList.length > 0) {
                    accVerified = filterAccList;
                    // this.updateFromPayment()
                } else {
                    accountListFull.filter((item: any) => {
                        if (item.setDefault.toString().toLocaleLowerCase() === "yes") {
                            accVerified.push(item);
                        }
                    });
                }
            } else {
                accountListFull.filter((item: any) => {
                    if (item.setDefault.toString().toLocaleLowerCase() === "yes") {
                        accVerified.push(item);
                    }
                });
            }
        }
        setTimeout(() => {
            if (accVerified.length > 0) {
                let objRow = Object.assign({}, accVerified[0]);
                let isTransferEntry = this.props.parentState.key === "transfers";
                if (!isTransferEntry) {
                    this.handleSelectedItem(1, objRow);
                }
            }
        }, 500)
    }


    reviewPrintConfirmationModalHide = () => {
        if (this.state.invoiceCHKData.some(e => e.isPrintConfirmed === false)) {
            this.setState({ isShowReviewPrintConfirmationCloseBtnModal: true });
        } else {
            this.setState({ isShowReviewPrintConfirmationCloseBtnModal: false, isShowReviewPrintConfirmationModal: false });
            this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
        }

    }

    reviewPrintConfirmationCloseBtnModalHide = () => {
        this.setState({ isShowReviewPrintConfirmationCloseBtnModal: false });
    }

    onConfirmLaterClick = () => {
        this.setState({ isShowReviewPrintConfirmationCloseBtnModal: false, isShowReviewPrintConfirmationModal: false });
        this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
    }

    onConfirnPrintClick = (id) => {
        let invoiceCHKData = this.state.invoiceCHKData;
        if (invoiceCHKData.length > 0) {
            if (!invoiceCHKData[id].isPrintConfirmed) {
                this.printConfirmation(invoiceCHKData[id].chkno, this.state.invoiceCHKData[id].pvrno, id);
            }
        }
    }

    onConfirnAllPrintedClick = () => {
        let isAnyPendingForConfirm = false;
        //let chknoList = "";
        this.state.invoiceCHKData.map((data, index) => {
            if (!data.isPrintConfirmed) {
                //chknoList += data.chkno + ",";
                isAnyPendingForConfirm = true;
            }
        })


        if (isAnyPendingForConfirm) {
            //let chks = chknoList.replace(/,(?=\s*$)/, '');
            this.printConfirmation("", this.state.invoiceCHKData[0].pvrno, -1);
        }
    }

    rePrintConfirm = (row) => {
        //debugger;
        let { hidValue, oprIDForCkGenerate } = this.state;
        let request: any = {};
        request.hotelID = hidValue;
        request.oprID = row.oprID;//this.props.parentState.key === "transfers" ? -1 : oprIDForCkGenerate;
        request.CheckType = this.props.parentState.key === "transfers" ? "T" : "I";
        request.pvrno = 0;
        request.CheckNo = row.chkno;
        request.Checkdate = "";
        request.Downloadcopy = "Print";
        request.Series = row.series;
        request.isCopyCheck = "Not";
        request.browserName = "Chrome";
        request.vid = 0;
        request.aid = 0;
        request.Uniquenos = "";
        InvoiceApprovalS.PrintActualCheck(request)
            .then(async (result: any | null) => {
                resolve();
            })
            .catch((error) => {
                Utils.toastError("Something went wrong. " + error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    rePrintAllConfirmation = () => {
        this.genratePDFPrintPreview();
    }

    printConfirmation(checkNo, pvrNo, tblindex) {
        //this.setState({ confirmAlldisabled: true })
        let { hidValue, oprIDForCkGenerate } = this.state;
        let request: any = {};
        request.CheckNos = checkNo;
        request.Hid = hidValue;
        request.Oprid = oprIDForCkGenerate;
        request.Pvrno = pvrNo;
        InvoiceApprovalS.UpdatePrintConfirm(request)
            .then(async (result: any | null) => {
                if (result.success) {
                    toast.success(result.result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    if (checkNo !== "") {
                        let invoiceCHKData = this.state.invoiceCHKData;
                        if (!invoiceCHKData[tblindex].isPrintConfirmed) {
                            invoiceCHKData[tblindex].isPrintConfirmed = true;
                            this.setState({ invoiceCHKData });
                        }
                    } else {
                        let invoiceCHKData = this.state.invoiceCHKData.map((data, index) => {
                            if (!data.isPrintConfirmed) {
                                return {
                                    ...data,
                                    isPrintConfirmed: true,
                                }
                            }
                            else {
                                return data;
                            }
                        })

                        this.setState({ confirmAlldisabled: false, invoiceCHKData: invoiceCHKData, isShowReviewPrintConfirmationCloseBtnModal: false, isShowReviewPrintConfirmationModal: false });
                        this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                    }
                } else {
                    this.setState({ confirmAlldisabled: false })
                    Utils.toastError(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ confirmAlldisabled: false })
                Utils.toastError("Something went wrong. " + error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }


    reviewPayModalHide = () => {
        if (this.state.transferCheck) {
            this.setState({ reviewPayModalShow: false, transferCheck: false, isSpinner: true }, () => {
                this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
            })
        } else {
            this.setState({ reviewPayModalShow: false }, () => {
                if (this.state.isSingleRow) {
                    setTimeout(() => {
                        this.setState({
                            reviewTable: [],
                            reviewTableUnMapped: [],
                            isSingleRow: false,
                            accountName: "Select Account Name",
                            totalAcctBal: 0,
                            batchTotal: 0,
                            batchTotalReview: 0,
                            selectedRows: [],
                            isSubmitPayment: false
                        });
                    }, 100);
                } else {
                    setTimeout(() => {
                        this.setState({
                            reviewTable: this.state.reviewTableUnMapped,
                            accountName: "Select Account Name",
                            totalAcctBal: 0,
                            isSubmitPayment: false,
                        });
                    }, 100);
                }
            })
        }
    }

    deleteCurrentRow = (row) => {
        let { reviewTable, reviewTableUnMapped, selectedRows } = this.state;
        let isTrEntry = this.props.parentState.key === "transfers";
        confirmAlert({
            title: "Alert",
            message: "Are you sure you want to delete this row?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        if (this.props.parentState.key !== "credit card" && this.props.parentState.key !== "transfers")
                            reviewTable = reviewTable.filter((item) => item.rowID !== row.rowID);
                        else
                            reviewTable = reviewTable.filter((item) => item.rowNo !== row.rowNo);

                        if (row.isGrouped === "No") {
                            reviewTableUnMapped = reviewTableUnMapped.filter(
                                (item) => item.uniqueno !== parseInt(row.uniqueno)
                            );
                            selectedRows = selectedRows.filter(x => x !== parseInt(row.rowNo));
                        } else if (row.isGrouped === "Yes") {
                            let groupUniqueNoList = row.uniqueno.split(',');
                            let groupRowNo = row.rowNo.split(',');
                            for (let d = 0; d < groupUniqueNoList.length; d++) {
                                reviewTableUnMapped = reviewTableUnMapped.filter(
                                    (item) => item.uniqueno !== parseInt(groupUniqueNoList[d])
                                );
                                selectedRows = selectedRows.filter(
                                    (item) => item !== parseInt(groupRowNo[d])
                                );
                            }
                        }
                        let batchTotal = parseFloat(this.state.batchTotal) - parseFloat(row.transTotal);
                        let batchTotalReview = parseFloat(this.state.batchTotalReview) - parseFloat(row.transTotal);
                        let newUpdatedBal = 0;
                        if (this.state.totalAcctBal !== 0) {
                            if (this.props.parentState?.key?.toLowerCase() === 'credit card') {
                                newUpdatedBal = parseFloat(this.state.totalAcctBal) - parseFloat(row.transTotal);
                            } else {
                                newUpdatedBal = parseFloat(this.state.totalAcctBal) + parseFloat(row.transTotal);
                            }
                        }
                        if (isTrEntry) {
                            selectedRows = selectedRows.filter(x => x !== parseInt(row.rowNo));
                            newUpdatedBal = parseFloat(this.state.totalAcctBal) + parseFloat(row.transTotal);
                        }
                        setTimeout(() => {
                            this.setState({
                                reviewTable,
                                batchTotal: reviewTable.length === 0 ? 0 : batchTotal,
                                batchTotalReview: reviewTable.length === 0 ? 0 : batchTotalReview,
                                selectedRows,
                                reviewTableUnMapped,
                                totalAcctBal: reviewTable.length === 0 ? 0 : newUpdatedBal
                            });
                        }, 0);
                    },
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
        });
    };

    ungroupInvoice = (e: any, row: any) => {
        confirmAlert({
            title: "Ungroup Invoice",
            message: "Are you sure you want to ungroup?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        this.ungroupInvoiceYes(row);
                    },
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
        });
    }

    ungroupInvoiceYes = (row: any) => {
        let { reviewTable, reviewTableUnMapped } = this.state;
        const mdGroupingFeature = reviewTableUnMapped.some((o) => o.mdGroupingFeature?.toLowerCase() === "yes");
        var maxRowID = Math.max.apply(Math, reviewTable.map(function (o) { return o.rowID; }));
        reviewTable = reviewTable.filter(
            (item) => item.rowNo !== row.rowNo
        );
        let unGroupedUniqueNo = row.uniqueno.split(',');
        for (let n = 0; n < unGroupedUniqueNo.length; n++) {
            let unGroupedRow = reviewTableUnMapped.filter((d) => d.uniqueno === parseInt(unGroupedUniqueNo[n]));
            unGroupedRow[0].isGrouped = "No";
            unGroupedRow[0].groupedInvoiceCount = 0;
            unGroupedRow[0].rowNo = unGroupedRow[0].rowNo;
            unGroupedRow[0].transTotal = unGroupedRow[0].transTotal;
            // if (mdGroupingFeature && this.props.isGroupUnpaid)
            //     unGroupedRow[0].etype = unGroupedRow[0].etypeOld;
            maxRowID = (maxRowID + 1)
            unGroupedRow[0].rowID = maxRowID;
            Array.prototype.push.apply(reviewTable, unGroupedRow);
        }
        setTimeout(() => {
            this.setState((curr: any) => ({
                ...curr,
                reviewTable: reviewTable
            }));
        }, 100);
    }

    handleChange = (event: any, row: any, FieldType: string) => {
        let { reviewTable } = this.state;
        if (FieldType === "trDate") {
            let isDate = event === null ? Utils.getHotalCurrentDate() : event;
            let today = new Date(isDate);
            let month =
                today.getMonth() + 1 <= 9
                    ? "0" + (today.getMonth() + 1)
                    : today.getMonth() + 1;
            let day = today.getDate() <= 9 ? "0" + today.getDate() : today.getDate();
            let year = today.getFullYear().toString().substr(-2);
            let dateNew = `${month}/${day}/${year}`;
            reviewTable = reviewTable.map((items) => {
                if (items.uniqueno === row.uniqueno) {
                    return { ...items, billDate: dateNew, isDateErr: false };
                } else {
                    return items;
                }
            });
        }
        this.setState((curr: any) => ({
            ...curr,
            reviewTable,
        }));
    };

    handleVoidChange = (event: any, FieldType: string) => {
        if (FieldType === "trvoidDate") {

            let today = new Date(event);
            let dateNew = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();

            this.setState({ transcationvoidDate: dateNew })
        }
        else if (FieldType === "voidnotes") {
            this.setState({ voidNotes: event.target.value })
        }
    }

    onTAB = (control, event) => {

    }

    hideVoidModal = (event: any) => {
        let voidPeriodLST = Object.assign([], JSON.parse(JSON.stringify(this.state.voidPeriodList)));
        this.setState({
            selectedVoid: [],
            copyIds: [],
            showVoidModal: false,
            transcationvoidDate: this.state.chkvoidDate,
            voidNotes: "",
            voidPeriodList: [],
            defaultvoidPeriod: this.state.selectdefaultperiod.length === 0 ? "Accounting Period" : this.state.selectdefaultperiod,
            voidPeriod: this.state.selectdefaultperiod,
        }, () => {
            this.setState({ voidPeriodList: voidPeriodLST })
        });
    }

    handleVoidAction() {
        if (this.state.voidPeriod.length === 0) {
            Utils.toastError("Please select accounting period.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }

        else if (this.state.transcationvoidDate === null) {
            Utils.toastError("Please select Transaction Date.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;

        }
        let VoidIds: any = [];
        let Voidrequest: any = [];
        const storage = JSON.parse(localStorage.getItem("storage")!);
        const tenantId = storage === null ? 0 : (storage.tenantID as any);

        VoidIds = this.state.associatedPayment.filter(x => x.viewType === 1);
        VoidIds.forEach(element => {
            let voidItem: any = {};
            voidItem.voidUniqueno = element.uniqueno;
            voidItem.voidaccperiod = this.state.voidPeriod;
            voidItem.voidDate = this.state.transcationvoidDate;
            voidItem.voidReason = this.state.voidNotes;
            voidItem.copyUniqueno = this.state.copyIds.filter(x => x === element.uniqueno).length > 0 ? "Yes" : "No";
            voidItem.hotelID = element.hotelid;
            voidItem.tenantID = tenantId;
            Voidrequest.push(voidItem);
        });

        this.SaveInvoiceVoid(Voidrequest);
    }


    SaveInvoiceVoid = (Voidrequest) => {
        this.setState({ isSpinner: true })
        registersevice.SaveInvoiceVoid(Voidrequest)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.saveStatus === "Success") {
                        toast.success("Check entry voided successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        setTimeout(() => {
                            this.setState({ isSpinner: false }, () => {
                                this.hideVoidModal("");
                                if (this.state.isShowReviewPrintConfirmationModal) {
                                    this.voidSuccess("Invoice");
                                }
                                else {
                                    this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                                }
                                // this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                            })
                        }, 3000);
                    }
                    else {
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isSpinner: false })
                    }
                }

                resolve();
            })
            .catch((error) => {
                this.setState({ isSpinner: false })
                reject();
            });

    }

    handleOnVoidSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selectedVoid: [...this.state.selectedVoid, row.id],
                copyIds: [...this.state.copyIds, row.uniqueno]
            }));
        } else {
            this.setState(() => ({
                selectedVoid: this.state.selectedVoid.filter(x => x !== row.id),
                copyIds: this.state.copyIds.filter(x => x !== row.uniqueno)
            }));
        }
    }

    handleOnVoidSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        const uniquenos = rows.map(r => r.uniqueno);
        if (isSelect) {
            this.setState(() => ({
                selectedVoid: ids,
                copyIds: uniquenos
            }));
        } else {
            this.setState(() => ({
                selectedVoid: [],
                copyIds: [],
            }));
        }
    }

    dateFormat = (date) => {
        let day: any = date.getDate();
        let month: any = date.getMonth() + 1;
        let Year: any = date.getFullYear();
        if (day.toString().length < 2) {
            day = "0" + day;
        }
        if (month.toString().length < 2) {
            month = "0" + month;
        }
        if (Year.toString().length == 4) {
            Year = Year.toString().substring(2, 4);
        }
        return month + "/" + day + "/" + Year;

    }

    printConfirmationVoidBtnClicked = (row) => {

        let modifiedrow: any = {};

        let gdate = this.dateFormat(new Date(row.gdate));
        let billDate = this.dateFormat(new Date());

        modifiedrow.backHID = this.state.hidValue;
        modifiedrow.billDate = billDate
        modifiedrow.gdate = gdate;
        modifiedrow.uniqueno = row.uniqueNo;
        modifiedrow.oprid = row.oprID;
        modifiedrow.checkNo = row.chkno;
        modifiedrow.series = row.series;
        modifiedrow.accper = row.accper;

        if (row.etype === "Check") {
            confirmAlert({
                title: "Void Transaction",
                message:
                    // "Please note, When Voided, A duplicate entry will be created under the same COA's but with opposite sign amount. The duplicate entry would be posted in current active accounting period. The Invoice No. of original entry would be post-fixed with ''-R1-A'', where n is the serial of voided entry. The Invoice No. of duplicate entry would be post-fixed with ''-R1-B''. Both the entries would be reflected in Operating Account.",
                    "Once this transaction is voided, a duplicate entry will be created under the same COA with the opposite sign amount. The Invoice number of the original entry will show ''-Rn-A'', where n is the serial of the voided entry. The invoice number of the duplicate entry will show ''-Rn-B''",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => { this.voidAlertYesBtnClicked(modifiedrow) },
                    },
                    {
                        label: "No",
                        onClick: () => reject(),
                    },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });


        }
        else if (row.etype === "TransferCheck") {
            confirmAlert({
                title: "Void entry",
                message: "Please note, When Voided, A duplicate entry will be created under the same COA's but with opposite sign amount. The duplicate entry would be posted in selected active accounting period. Both the entries would be reflected in Operating Account.",
                buttons: [{
                    label: "Yes",
                    onClick: () => { this.transferVoidAlertYesBtnClicked(modifiedrow) },
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }

    }

    voidSuccess = (etype) => {
        let { reviewTable, hidValue, oprIDForCkGenerate } = this.state;
        let checkData = reviewTable.filter((o) => o.etype?.toLowerCase() === "check");
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let reqArray: any = [];
        checkData.forEach((item, index) => {
            let request: any = {};
            request.TenantID = tenantID;
            request.uniqueNos = item.uniqueno;
            request.Hid = hidValue;
            request.Oprid = etype === "Transfer" ? -1 : oprIDForCkGenerate;
            request.memo = item.internalNotes;
            request.CkDate = item.billDate === "" ? new Date() : item.billDate;
            request.etype = etype === "Transfer" ? "TransferCheck" : "";
            request.pvrno = this.state.pvrno;
            reqArray.push(request);
        });

        this.setState({ invoiceCHKData: [] });
        InvoiceApprovalS.InvoiceCkGenerate(reqArray)
            .then(async (result: any | null) => {
                if (result.success) {
                    if (result.result !== null) {
                        if (result.result.length > 0) {
                            result.result.map((items) => ((items["isPrintConfirmed"] = false)));
                            this.setState({ invoiceCHKData: result.result }, () => {
                            })
                        } else {
                            this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                            // Utils.toastError("No records found.", {
                            //     position: toast.POSITION.BOTTOM_RIGHT,
                            // });
                        }
                    } else {
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    this.setState({ reviewProcessStart: false });
                } else {
                    this.setState({ reviewProcessStart: false, isShowReviewPrintConfirmationModal: false, reviewPayModalShow: true });
                    Utils.toastError(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    return false;
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ reviewProcessStart: false });
                Utils.toastError("Something went wrong. " + error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });

    }

    handleBtnEvent = (event: any, row: any, type) => {
        let { tableData } = this.state;
        let etText = event.target.innerHTML.toString().toLocaleLowerCase();
        let { bulkSelect, selectedRows } = this.state;
        let transferEr: any = [];
        selectedRows.map((rowNo) => {
            let filterTr = tableData.filter(x => x.rowNo === rowNo && x.transferSource === "Reimbursement" && x.isRemDeletePermission === "No");
            if (filterTr.length > 0) {
                transferEr.push(filterTr);
            }

        });
        let entryMessage = bulkSelect && selectedRows.length > 1 ? "bulk entries" : "entry"
        let conmsg = transferEr.length > 0 ? 'You do not have permission to delete all the selected transactions. Continuing to delete will result in removal of only those transactions that you have access to . Are you sure you want to continue?' : `Are you sure you want to delete ${entryMessage}?`;




        if (etText === "delete") {
            confirmAlert({
                title: "Delete Entry",
                message: conmsg,
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            this.deleteEntry(row, type);
                        },
                    },
                    {
                        label: "No",
                        onClick: () => reject(),
                    },
                ],
            });
        } else if (etText === "mark as unpaid".toLocaleLowerCase()) {
            confirmAlert({
                title: "Confirm Mark Unpaid",
                message: "This will remove any associated payments.",
                buttons: [
                    {
                        label: "Mark Unpaid",
                        onClick: () => this.markUnpaid(row, type),
                    },
                    {
                        label: "Cancel",
                        onClick: () => reject(),
                    },
                ],
                overlayClassName: "mark-unpaid",
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        } else if (etText === "confirm print") {


        } else if (etText === "unapprove") {
            tableData = tableData.map((items) => {
                if (items.rowNo === row.rowNo) {
                    return { ...items, isSpinnerLoader: true };
                } else {
                    return items;
                }
            });
            this.setState({ tableData });
        }
        else if (etText === "void" && row.entryType === "Invoice") {
            confirmAlert({
                title: "Void Transaction",
                message:
                    // "Please note, When Voided, A duplicate entry will be created under the same COA's but with opposite sign amount. The duplicate entry would be posted in current active accounting period. The Invoice No. of original entry would be post-fixed with ''-R1-A'', where n is the serial of voided entry. The Invoice No. of duplicate entry would be post-fixed with ''-R1-B''. Both the entries would be reflected in Operating Account.",
                    "Once this transaction is voided, a duplicate entry will be created under the same COA with the opposite sign amount. The Invoice number of the original entry will show ''-Rn-A'', where n is the serial of the voided entry. The invoice number of the duplicate entry will show ''-Rn-B''",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => { this.voidAlertYesBtnClicked(row) },
                    },
                    {
                        label: "No",
                        onClick: () => reject(),
                    },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });


        }
        else if (etText === "void" && row.entryType === "Transfer") {
            confirmAlert({
                title: "Void entry",
                message: "Please note, When Voided, A duplicate entry will be created under the same COA's but with opposite sign amount. The duplicate entry would be posted in selected active accounting period. Both the entries would be reflected in Operating Account.",
                buttons: [{
                    label: "Yes",
                    onClick: () => { this.transferVoidAlertYesBtnClicked(row) },
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        } else if (etText === "partial payment") {
            tableData = tableData.map((items) => {
                if (items.rowNo === row.rowNo) {
                    return { ...items, isSpinnerPP: true };
                } else {
                    return items;
                }
            });
            this.setState({ tableData });
        }
    }

    hideUnApprovalModal = (value) => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            return { ...items, isSpinnerLoader: false, };
        });
        this.setState({ tableData }, () => {
            if (value) {
                this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
            }
            $("body").trigger("click");
        });
    }

    hidePPModal = (value) => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            return { ...items, isSpinnerPP: false, };
        });
        this.setState({ tableData }, () => {
            if (value) {
                this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
            }
            $("body").trigger("click");
        });
    }

    // modalRuleList = (roleIds) => {
    //     this.submitUnApproveInvoice(roleIds);
    // }

    transferVoidModalHide = () => {
        this.setState({ transferVoidReason: "", transferVoidModal: false })
    }

    transferVoidAlertYesBtnClicked = (row) => {
        let hidValue = row.backHID;
        this.GetAccountingPeriod(hidValue, "Transfer");
        let transferRowData = { ...row };
        let accper = transferRowData.accper;
        if (accper.length < 7) {
            let accperArr = accper.split('-');
            accper = accperArr[0] + '-01-' + accperArr[1];
            transferRowData.accper = this.newAccPer(accper);
        }
        this.setState({ transferRowData }, () => {
            setTimeout(() => {
                this.getAccPer(this.state.transferRowData.gdate);
                this.setState({ transferVoidModal: true });
            }, 1000);
        });
    }

    getAccPer = (date) => {
        let transferVoidAccountingPeriod = "";
        let isDate = date === null ? new Date() : date;
        let today = new Date(isDate);
        let month: any = today.getMonth() + 1;
        let Year: any = today.getFullYear();
        if (month.toString().length < 2) {
            month = "0" + month;
        }
        if (Year.toString().length == 4) {
            Year = Year.toString().substring(2, 4);
        }
        var CuuPeriod = month + "/" + "01" + "/" + Year;

        let isExist = this.state.voidPeriodList.some((item) => item.id == CuuPeriod);

        if (isExist)
            transferVoidAccountingPeriod = CuuPeriod;
        else
            transferVoidAccountingPeriod = 'Select';
        this.setState({ transferVoidAccountingPeriod });
    }

    newAccPer(accPeriod: any) {
        let accPer: any = new Date(new Date(accPeriod)).toLocaleDateString();
        let month: any = accPer.split("/")[0] < 10 ? "0" + accPer.split("/")[0] : accPer.split("/")[0];;
        let day: any = "01";
        let Year: any = accPer.split("/")[2].substr(2);
        return `${month}/${day}/${Year}`;
    }



    handleTransferVoidChange = (event: any, FieldType: string) => {
        let transferRowData = { ...this.state.transferRowData };
        let transferVoidAccountingPeriod = this.state.transferVoidAccountingPeriod;
        if (FieldType === "VoidDate") {
            let isDate = event === null ? new Date() : event;
            let today = new Date(isDate);
            let dateNew = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();

            let month: any = today.getMonth() + 1;
            let Year: any = today.getFullYear();
            if (month.toString().length < 2) {
                month = "0" + month;
            }
            if (Year.toString().length == 4) {
                Year = Year.toString().substring(2, 4);
            }
            var CuuPeriod = month + "/" + "01" + "/" + Year;
            let isExist = this.state.voidPeriodList.some((item) => item.id === CuuPeriod);

            if (isExist)
                transferVoidAccountingPeriod = CuuPeriod;
            else
                transferVoidAccountingPeriod = 'Select';

            transferRowData.billDate = dateNew;
            this.setState({ transferRowData, transferVoidAccountingPeriod });
            return;
        }
        else if (FieldType === "voidreason") {
            let transferVoidReason = event.target.value;
            this.setState({ transferVoidReason });
            return;
        }
        else if (FieldType === "voidAccPer") {
            this.setState({ transferVoidAccountingPeriod: event });
            return;
        }

    }

    createVoidEntry = () => {
        let checkDate = this.state.transferRowData.gdate;
        let currentDate = new Date(checkDate);
        var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        let date = new Date(this.state.transferRowData.billDate);
        let voidDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();

        if (new Date(voidDate) > lastDay) {
            Utils.toastError("Entry can not void in future date.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return;
        }
        const isCurrentPerActiveFound = this.state.voidPeriodList.some(
            (item) => item.name === this.state.transferVoidAccountingPeriod
        );

        if (!isCurrentPerActiveFound) {
            Utils.toastError("Transaction date should be in an active accounting period.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return;
        }

        this.setState({ isVoidStart: true });
        let request: any = {};
        request.uniqueno = this.state.transferRowData.uniqueno;
        request.Fyear = '';
        request.HID = this.state.transferRowData.backHID;
        request.Etype = 'Transfer';
        request.Period = this.state.transferVoidAccountingPeriod;
        request.VoidDate = voidDate;
        request.VoidReason = this.state.transferVoidReason;

        registersevice.VoidTranactionEntry(request)
            .then(async (result: any | null) => {
                if (result?.result?.result?.toLowerCase() === "success") {
                    toast.success("Entry void successfully.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setTimeout(() => {
                        this.setState({ isVoidStart: false });
                        this.transferVoidModalHide();
                        if (this.state.isShowReviewPrintConfirmationModal) {
                            this.voidSuccess("Transfer");
                        }
                        else {
                            this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                        }
                        // this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
                    }, 1500);
                } else if (result?.result?.toLowerCase() === "accperinact") {
                    Utils.toastError("Transaction date should be in an active accounting period.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.setState({ isVoidStart: false });
                } else {
                    Utils.toastError(result.result, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.setState({ isVoidStart: false });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ isVoidStart: false })
                reject();
            });
    }

    voidAlertYesBtnClicked = (row) => {
        let hidValue = row.backHID;
        this.GetAccountingPeriod(hidValue, "payment");
        this.getPaymentDetails(row);
        this.setState({ showVoidModal: true });

    }


    handleVoidSelectedItem = (control: any, id: any) => {
        let controlID = Number(control.split('_')[0])
        let rowIndex = Number(control.split('_')[1])
        if (controlID === 11) { // Void period
            this.setState({ voidPeriod: id });
        }
    };

    markUnpaid = (row, type) => {
        let { reviewTable, parentType } = this.state;
        this.setState({ loaderTrue: true });
        let requestArray: any = [];
        let request: any = {};
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let mOprID = "";
        if (type === 'single') {
            if (row.etype.toString().toLowerCase() === "Credit Card".toLowerCase()) {
                mOprID = row.cardUniqueno;
            } else {
                mOprID = row.oprid;
            }
            request = {};
            request.uniqueno = row.uniqueno;
            request.oprID = mOprID
            request.cardUniqueno = mOprID
            request.paymenttype = row.etype;
            request.gDate = row.gdate;
            request.tenantid = tenantID;
            request.hotelID = row.backHID;
            request.paymentNotes = row.paymentNotes.trim().replace("'", "");
            request.tenantid = tenantID;
            request.isTransferEntry = row.isTransferEntry !== undefined ? true : false;
            request.invoiceNo = row.invoiceNo;
            request.isPaid = "Unpaid";
            requestArray.push(request);

        } else {
            reviewTable.forEach(element => {
                if (element.etype.toString().toLowerCase() === "Credit Card".toLowerCase()) {
                    mOprID = element.cardUniqueno;
                } else {
                    mOprID = element.oprid;
                }
                request = {};
                request.uniqueno = element.uniqueno;
                request.oprID = mOprID
                request.cardUniqueno = mOprID
                request.paymenttype = element.etype;
                request.gDate = element.gdate;
                request.hotelID = element.backHID;
                request.paymentNotes = element.paymentNotes.trim().replace("'", "");
                request.tenantid = tenantID;
                request.isTransferEntry = element.isTransferEntry !== undefined ? true : false;
                request.invoiceNo = element.invoiceNo;
                request.isPaid = "Unpaid";
                requestArray.push(request);
            });
        }
        registersevice.markAsUnpaidMultiple(requestArray)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.saveStatus === "Success") {
                        toast.success("Payment unpaid successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ loaderTrue: false });
                        this.props.refreshTable(parentType, this.props?.parentState.key);
                    }
                    else {
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ loaderTrue: false });
                    }
                }

                resolve();
            })
            .catch((error) => {
                this.setState({ loaderTrue: false })
                reject();
            });

    }
    actTypeName = (e => {
        let acctType = "";
        e === "O" ? acctType = "Operating" : e === "P" ? acctType = "Payroll" : e === "M" && (acctType = "Miscellaneous");
        return acctType;
    });

    deleteEntry = (row: any, type) => {
        let { reviewTable, parentType } = this.state;
        let isTrEntry = this.props.parentState.key === "transfers";
        this.setState({ loaderTrue: true });
        let requestArray: any = [];
        let request: any = {};
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let trRequest: any = {};
        let isUno: any = [];
        let isTyp: any = [];
        if (type === "single") {
            if (isTrEntry) {
                trRequest.HID = row.backHID;
                trRequest.Type = this.actTypeName(row.company.split("-")[1].trim().charAt(0));
                trRequest.UniqueNo = row.uniqueno;
                trRequest.isrepeat = 0;
                trRequest.TenantID = tenantID;
            } else {
                request = {};
                request.uniqueno = row.uniqueno;
                request.fileNo = 0;
                request.type = 'INVOICES'
                request.hotelID = row.backHID;
                request.allDelete = "YES";
                request.tenantID = tenantID;
                requestArray.push(request);
            }
        } else {
            if (isTrEntry) {
                trRequest.HID = reviewTable[0].backHID;
                trRequest.isrepeat = 0;
                trRequest.TenantID = tenantID;
                trRequest.UniqueNo = '';
                trRequest.Type = '';
                reviewTable.forEach(element => {
                    let erPermission = this.state.tableData.filter(x => x.uniqueno === element.uniqueno && x.isRemDeletePermission === "Yes");
                    if (erPermission.length > 0) {
                        isUno.push(element.uniqueno);
                        isTyp.push(this.actTypeName(element.company.split("-")[1].trim().charAt(0)));
                    }


                });
                trRequest.UniqueNo = isUno.toString();
                trRequest.Type = isTyp.toString();
            } else {
                reviewTable.forEach(element => {
                    request = {};
                    request.uniqueno = element.uniqueno;
                    request.fileNo = 0;
                    request.type = 'INVOICES'
                    request.hotelID = element.backHID;
                    request.allDelete = "YES";
                    request.tenantID = tenantID;
                    requestArray.push(request);
                });
            }
        }
        if (isTrEntry) {
            let ispActivate: any = [];
            reviewTable.forEach(item => {
                let itmName = item.pActivate.toString().toLocaleLowerCase();
                if (itmName === "closed" || itmName === "inactive" || itmName === "ap closed") {
                    ispActivate.push(item);
                }
            });
            if (ispActivate.length > 0) {
                Utils.toastError("Transfers can't be deleted as accounting period has been closed for few transfers.", { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ loaderTrue: false });
            } else {
                if (trRequest.UniqueNo.toString().length > 0) {
                    registersevice.DeleteTransferEntry(trRequest)
                        .then(async (result: any | null) => {
                            if (result.result.toString().toLowerCase() === "Success".toLocaleLowerCase()) {
                                let msg = isUno.length === 0 ? "1 transfer deleted successfully." : `${isUno.length} transfers deleted successfully.`;
                                toast.success(`${msg}`, { position: toast.POSITION.BOTTOM_RIGHT });
                                this.setState({ loaderTrue: false });
                                this.props.refreshTable(parentType, this.props?.parentState.key);
                            } else {
                                this.setState({ loaderTrue: false });
                                Utils.toastError(result.result, { position: toast.POSITION.BOTTOM_RIGHT });
                            }
                            resolve();
                        })
                        .catch((error) => {
                            this.setState({ hasDeleteAccess: false })
                            reject();
                        });
                }
                else {
                    toast.success('No record selected to delete.', { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ loaderTrue: false });
                }
            }
        } else {
            const isAPClosedFound = reviewTable?.some(
                o => {
                    return (
                        o.pActivate?.toLocaleLowerCase() == 'closed' || o.pActivate?.toLocaleLowerCase() === "inactive" || o.pActivate?.toLocaleLowerCase() === "ap closed" ||
                        o.papClosed?.toLocaleLowerCase() == 'closed' || o.papClosed?.toLocaleLowerCase() === "inactive" || o.papClosed?.toLocaleLowerCase() === "ap closed"
                    )
                }
            );
            if (isAPClosedFound) {
                Utils.toastError("Invoices can't be deleted as accounting period has been closed for few invoices.", { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ loaderTrue: false });
            } else {
                registersevice.DeleteUploadedFileMultiple(requestArray)
                    .then(async (result: any | null) => {
                        if (result !== null) {
                            if (result === "Success" || result?.result === "Success") {
                                let msg = requestArray.length > 1 ? " invoices deleted successfully." : " invoice deleted successfully.";
                                toast.success(`${requestArray.length}${msg}`, {
                                    position: toast.POSITION.BOTTOM_RIGHT
                                });
                                this.setState({ loaderTrue: false });
                                this.props.refreshTable(parentType, this.props?.parentState.key);
                            }
                            else {
                                Utils.toastError(result.message, {
                                    position: toast.POSITION.BOTTOM_RIGHT
                                });
                                this.setState({ loaderTrue: false });
                            }
                        }
                        resolve();
                    })
                    .catch((error) => {
                        this.setState({ loaderTrue: false })
                        reject();
                    });
            }
        }
    }

    CheckACPeriodStatusByDate = () => {
        this.clearError();
        this.setState({ isSubmitPayment: true });
        let dateList = "";
        let { reviewTable } = this.state;
        for (let index = 0; index < reviewTable.length; index++) {
            let dateNew = reviewTable[index].billDate;
            if (dateNew === "") {
                let today = new Date();
                let month =
                    today.getMonth() + 1 <= 9
                        ? "0" + (today.getMonth() + 1)
                        : today.getMonth() + 1;
                let day = today.getDate() <= 9 ? "0" + today.getDate() : today.getDate();
                let year = today.getFullYear().toString().substr(-2);
                dateNew = `${month}/${day}/${year}`;
                reviewTable[index].billDate = dateNew;
            }
            dateList += dateNew + ",";
        }

        let request: any = {};
        request.Date = dateList?.replace(/,(?=\s*$)/, '');
        request.HID = this.state.hidValue;

        InvoiceApprovalS.CheckACPeriodStatusByDate(request)
            .then(async (result: any | null) => {
                if (result?.result?.message.toLowerCase() === "success") {
                    let closedDats = [] as any;
                    closedDats = result?.result?.result.filter((o) => o?.status?.toLowerCase() === "closed");
                    if (closedDats.length > 0) {
                        for (let i = 0; i < closedDats?.length; i++) {
                            var allIndex = [] as any;
                            reviewTable.forEach((o, index) => o.billDate === closedDats[i].date ? allIndex.push(index) : null)
                            allIndex.forEach(element => {
                                reviewTable[element].isDateErr = true;
                            });
                            this.setState({ isSubmitPayment: false, reviewTable });
                        }
                        Utils.toastError("Accounting period is closed for the highlighted dates.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else {
                        if (this.props.parentState.key === "transfers" || this.props.parentState.key === "credit card")
                            this.ValidatedSession();
                        else
                            this.CheckACCOpeningDateStatusByDate(dateList);
                    }
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ isSubmitPayment: false });
                reject();
            });
    }

    CheckACCOpeningDateStatusByDate = (dateList) => {
        this.setState({ isSubmitPayment: true });
        let { reviewTable } = this.state;


        let request: any = {};
        request.Date = dateList?.replace(/,(?=\s*$)/, '');
        request.HID = this.state.hidValue;
        request.OPRID = this.state.oprID;

        InvoiceApprovalS.CheckACCOpeningDateStatusByDate(request)
            .then(async (result: any | null) => {
                if (result?.result?.message.toLowerCase() === "success") {
                    if (result?.result?.result?.result?.toLowerCase() === "invalid") {
                        Utils.toastError("Entered date should be greater than Account Opening Date.", { position: toast.POSITION.BOTTOM_RIGHT });
                        this.setState({ isSubmitPayment: false });
                        return;
                    } else {
                        this.ValidatedSession();
                    }
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ isSubmitPayment: false });
                reject();
            });
    }
    clearError = () => {
        let { reviewTable } = this.state;
        for (let index = 0; index < reviewTable.length; index++) {
            reviewTable[index].isDateErr = false;
        }
        this.setState({ reviewTable });
    }
}